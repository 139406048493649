import http from '@/http/index'

/**
 * 取消收藏
 * @param params：{
 *}
 */
export function cancelCollect(params) {
  return http.post('/api/user/cancelCollect', params)
}

/**
 * 取消收藏课程
 * @param params：{
 *}
 */
export function cancelCollectLesson(params) {
  return http.post('/api/user/cancelCollectLesson', params)
}

/**
 * 取消会议收藏
 * @param params：{
 *}
 */
export function cancelRelease(params) {
  return http.post('/api/user/cancelRelease', params)
}

/**
 * 收藏文章
 * @param params：{
 *}
 */
export function collectArticle(params) {
  return http.post('/api/user/collectArticle', params)
}

/**
 * 收藏课程
 * @param params：{
 *}
 */
export function collectLesson(params) {
  return http.post('/api/user/collectLesson', params)
}

/**
 * 收藏会议
 * @param params：{
 *}
 */
export function collectRelease(params) {
  return http.post('/api/user/collectRelease', params)
}

/**
 * 完善用户信息
 * @param params：{
 *}
 */
export function completeUserInfo(params) {
  return http.post('/api/user/completeUserInfo', params)
}

/**
 * 忘记密码(邮箱)
 * @param params：{
 *}
 */
export function forgetPasswordEmail(params) {
  return http.post('/api/user/forgetPasswordEmail', params)
}

/**
 * 忘记密码(手机号)
 * @param params：{
 *}
 */
export function forgetPasswordPhone(params) {
  return http.post('/api/user/forgetPasswordPhone', params)
}

/**
 * 当前人员信息
 * @param params：{
 *}
 */
export function getMemberInfo(params) {
  return http.post('/api/user/getMemberInfo', params)
}

/**
 * 注册用户
 * @param params：{
 *}
 */
export function register(params) {
  return http.post('/api/user/register', params)
}

/**
 * 文章收藏记录
 * @param params：{
 *}
 */
export function selectMyFavArticle(params) {
  return http.post('/api/user/selectMyFavArticle', params)
}

/**
 * 课程收藏记录
 * @param params：{
 *}
 */
export function selectMyFavLesson(params) {
  return http.post('/api/user/selectMyFavLesson', params)
}

/**
 * 文章阅读记录
 * @param params：{
 *}
 */
export function selectMyReadArticle(params) {
  return http.post('/api/user/selectMyReadArticle', params)
}

/**
 * 课程阅读记录
 * @param params：{
 *}
 */
export function selectMyReadLesson(params) {
  return http.post('/api/user/selectMyReadLesson', params)
}

/**
 * 修改账户名
 * @param params：{
 *}
 */
export function updateSignAccount(params) {
  return http.post('/api/user/updateSignAccount', params)
}
/**
 * 添加播放记录
 * @param params：{
 *}
 */
export function addVideoCount(params) {
  return http.post('/api/user/addVideoCount', params)
}
