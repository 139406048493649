<template>
  <div class="main-center">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MainCenter',
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.mobile {
  .main-center {
    width: 100%;
  }
}

.main-center {
  max-width: 1200px;
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
}
</style>
