<template>
  <div :class="$route.name === '首页' ? 'home gray' : 'home'" style="overflow-x: hidden">
    <div :class="$route.name === '首页' ? 'block' : 'home_none'" v-show="none == 0">
      <div v-if="adv != null && switchValue == 1" class="ad">
        <a :href="adv.adUrl" target="_blank"><img :src="advImgHead" /></a>
        <span class="home_close" @click="close()">×</span>
      </div>
    </div>
    <HomeHeader class="home-header" :style="$route.name === '首页' ? 'top:' + top + 'px' : ''" />
    <div :style="!$isMobile ? 'width: 100%; height: 78px' : 'width: 100%; height: 50px'"></div>
    <div style="min-height: 620px">
      <keep-alive :include="keepAliveViews">
        <RouterView :key="$route.query._t" />
      </keep-alive>
    </div>
    <HomeFooter />
  </div>
</template>

<script>
import HomeFooter from '@/components/home/HomeFooter.vue'
import HomeHeader from '@/components/home/HomeHeader.vue'
import { getModularSwitch, selectAdvertList } from '@/api/adv'

export default {
  name: 'Home',
  components: { HomeFooter, HomeHeader },
  data() {
    return {
      top: 0,
      none: 0,
      adv: null,
      advImgHead: '',
      // loading: false,
      keepAliveViews: ['Index', 'AnimationList', 'ProfessionalArticleList'],
    }
  },
  async created() {
    await this.getModularSwitch()
  },
  mounted() {
    // 滚动条的获取
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    handleScroll() {
      this.top = 100
      if (window.pageYOffset <= 100) {
        this.top = this.top - window.pageYOffset
        console.log(this.top)
      } else {
        this.top = 0
      }
      this.none == 1 ? (this.top = 0) : ''
    },
    async getModularSwitch() {
      let res = await getModularSwitch({ moduleName: 'advert' })
      this.switchValue = res.switchValue
      if (this.switchValue == 1) {
        await this.getAdv()
      } else {
        this.none = 1
        this.top = 0
      }
    },
    async getAdv() {
      let res = await selectAdvertList({
        pageNo: 1,
        pageSize: 1,
        ifPublish: '是',
        advertPosition: '页眉',
      })
      // for (var i = 0; i < res.results.length; i++) {
      //   if (res.results[i].advertPosition == '底部') {
      //     this.adv = res.results[i]
      //   }
      //   continue
      // }
      this.adv = res.results[0]
      if (this.adv != null) {
        this.advImgHead = JSON.parse(this.adv.imgUrl)[0].url
        this.top = 100
      } else {
        this.none = 1
        this.top = 0
      }
    },
    showAdv() {
      window.open(this.adv.adUrl)
    },
    close() {
      this.none = 1
      this.top = 0
    },
  },
  // mounted() {
  //   let vm = this
  //   setTimeout(function () {
  //     vm.loading = true
  //   }, 2000)
  // },
}
</script>
<style lang="less" scoped>
// .gray {
//   /* 滤镜效果 */
//   filter: grayscale(100%);
//   /* webkit内核 */
//   -webkit-filter: grayscale(100%);
//   /* 火狐内核 */
//   -moz-filter: grayscale(100%);
//   /* 微软内核 */
//   -ms-filter: grayscale(100%);
//   /* 欧朋内核 */
//   -o-filter: grayscale(100%);
//   /* ie专有滤镜 */
//   filter: gray;
// }
.home-header {
  width: 100%;
  position: fixed;
  background-color: white;
  z-index: 100;
}
</style>
<style lang="less">
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  position: fixed;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  position: fixed;
}
.ant-dropdown {
  position: fixed;
}
.block {
  text-align: center;
  transition: 0.5s all;
  .ad {
    position: relative;
    img {
      max-width: 90%;
      max-height: 90px;
    }
  }
}
.home_none {
  display: none !important;
}
.home_close {
  position: absolute;
  top: -4px;
  font-size: 20px;
  color: red;
  cursor: pointer;
}
.swiper {
  .swiper-container {
    height: 100%;
  }
  .swiper-pagination {
    position: relative;
    width: 100%;
  }
}
.swiper-pagination-bullet {
  margin: 0 6px;
}
// .footer {
//   display: none;
// }
</style>
