<!-- 关于我们 -->
<template>
  <div class="container">
    <MainCenter class="mainCenter">
      <a-anchor v-if="!$isMobile" :offsetTop="90">
        <div class="sidebar">
          <!-- :default-open-keys="['our']" -->
          <a-menu style="width: 294px" mode="inline" @click="handleClick" class="cebianlan">
            <a-sub-menu style="width: 294px" key="our" @titleClick="titleClick">
              <div
                slot="title"
                class="menu-title"
                :class="
                  key === 'our' ||
                  key === 'gywm' ||
                  key === 'team' ||
                  key === 'translation' ||
                  key === 'medical' ||
                  key === 'quote' ||
                  key === 'corrigendum' ||
                  key === 'guide' ||
                  key === 'consult'
                    ? 'menu-title2'
                    : null
                "
              >
                <span
                  class="one"
                  :class="
                    key === 'our' ||
                    key === 'gywm' ||
                    key === 'team' ||
                    key === 'translation' ||
                    key === 'medical' ||
                    key === 'quote' ||
                    key === 'corrigendum' ||
                    key === 'guide' ||
                    key === 'consult'
                      ? 'one1'
                      : null
                  "
                >
                  关于我们
                </span>
              </div>
              <a-menu-item
                key="gywm"
                v-scroll-to="{
                  el: '#gywm',
                  offset: !$isMobile ? -105 : -55,
                }"
                :class="key === 'gywm' ? 'yangshi2' : null"
                class="gywm"
              >
                关于我们
              </a-menu-item>
              <a-menu-item
                key="team"
                v-scroll-to="{
                  el: '#team',
                  offset: !$isMobile ? -105 : -55,
                }"
                :class="key === 'team' ? 'yangshi2' : null"
                class="gywm"
              >
                编辑团队
              </a-menu-item>
              <a-menu-item
                key="translation"
                v-scroll-to="{
                  el: '#translation',
                  offset: !$isMobile ? -105 : -55,
                }"
                :class="key === 'translation' ? 'yangshi2' : null"
                class="gywm"
              >
                译文 免责声明
              </a-menu-item>
              <a-menu-item
                key="medical"
                v-scroll-to="{
                  el: '#medical',
                  offset: !$isMobile ? -105 : -55,
                }"
                :class="key === 'medical' ? 'yangshi2' : null"
                class="gywm"
              >
                医疗和药物 免责声明
              </a-menu-item>
              <a-menu-item
                key="quote"
                v-scroll-to="{
                  el: '#quote',
                  offset: !$isMobile ? -105 : -55,
                }"
                :class="key === 'quote' ? 'yangshi2' : null"
                class="gywm"
              >
                引用
              </a-menu-item>
              <a-menu-item
                key="corrigendum"
                v-scroll-to="{
                  el: '#corrigendum',
                  offset: !$isMobile ? -105 : -55,
                }"
                :class="key === 'corrigendum' ? 'yangshi2' : null"
                class="gywm"
              >
                勘误和更正
              </a-menu-item>
              <a-menu-item
                key="guide"
                v-scroll-to="{
                  el: '#guide',
                  offset: !$isMobile ? -105 : -55,
                }"
                :class="key === 'guide' ? 'yangshi2' : null"
                class="gywm"
              >
                NEJM统计学报告指南
              </a-menu-item>
              <a-menu-item
                key="consult"
                v-scroll-to="{
                  el: '#consult',
                  offset: !$isMobile ? -105 : -55,
                }"
                :class="key === 'consult' ? 'yangshi2' : null"
                class="gywm"
              >
                《新英格兰医学杂志》投稿咨询
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item key="2" class="two" :class="key === '2' ? 'yangshi1' : null">服务条款</a-menu-item>
            <a-menu-item key="9" class="six" :class="key === '9' ? 'yangshi1' : null">用户协议</a-menu-item>
            <a-menu-item key="3" class="three" :class="key === '3' ? 'yangshi1' : null">隐私政策</a-menu-item>
            <a-menu-item key="4" class="four" :class="key === '4' ? 'yangshi1' : null">联系我们</a-menu-item>
            <a-menu-item key="10" class="five" :class="key === '10' ? 'yangshi1' : null">下载App</a-menu-item>
            <!-- <a-menu-item key="5" class="five" :class="key === '5' ? 'yangshi1' : null">其他</a-menu-item>
          <a-menu-item key="6" class="six" :class="key === '6' ? 'yangshi1' : null">投稿资讯</a-menu-item>
          <a-menu-item key="7" class="seven" :class="key === '7' ? 'yangshi1' : null">使用说明</a-menu-item>
          <a-menu-item key="8" class="eight" :class="key === '8' ? 'yangshi1' : null">版权说明</a-menu-item> -->
          </a-menu>
        </div>
      </a-anchor>
      <!-- <a-anchor :offset-top="12" v-else class="anchorFit"> -->
      <div v-else class="anchorFit">
        <a-menu mode="horizontal" @click="handleClick">
          <a-sub-menu key="our" @titleClick="titleClick">
            <div slot="title">
              <span>关于我们</span>
            </div>
            <a-menu-item
              key="gywm"
              v-scroll-to="{
                el: '#gywm',
                offset: !$isMobile ? -105 : -55,
              }"
            >
              关于我们
            </a-menu-item>
            <a-menu-item
              key="team"
              v-scroll-to="{
                el: '#team',
                offset: !$isMobile ? -105 : -55,
              }"
            >
              编辑团队
            </a-menu-item>
            <a-menu-item
              key="translation"
              v-scroll-to="{
                el: '#translation',
                offset: !$isMobile ? -105 : -55,
              }"
            >
              译文 免责声明
            </a-menu-item>
            <a-menu-item
              key="medical"
              v-scroll-to="{
                el: '#medical',
                offset: !$isMobile ? -105 : -55,
              }"
            >
              医疗和药物 免责声明
            </a-menu-item>
            <a-menu-item
              key="quote"
              v-scroll-to="{
                el: '#quote',
                offset: !$isMobile ? -105 : -55,
              }"
            >
              引用
            </a-menu-item>
            <a-menu-item
              key="corrigendum"
              v-scroll-to="{
                el: '#corrigendum',
                offset: !$isMobile ? -105 : -55,
              }"
            >
              勘误和更正
            </a-menu-item>
            <a-menu-item
              key="guide"
              v-scroll-to="{
                el: '#guide',
                offset: !$isMobile ? -105 : -55,
              }"
            >
              NEJM统计学报告指南
            </a-menu-item>
            <a-menu-item
              key="consult"
              v-scroll-to="{
                el: '#consult',
                offset: !$isMobile ? -105 : -55,
              }"
            >
              《新英格兰医学杂志》投稿咨询
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item key="2">服务条款</a-menu-item>
          <a-menu-item key="9">用户协议</a-menu-item>
          <a-menu-item key="3">隐私政策</a-menu-item>
          <a-menu-item key="4">联系我们</a-menu-item>
          <a-menu-item key="10">下载App</a-menu-item>
        </a-menu>
      </div>
      <!-- </a-anchor> -->
      <div class="main1" ref="main1">
        <div>
          <div id="our" v-if="!$isMobile">{{ our }}</div>
          <span v-html="gywm" id="gywm"></span>
          <span v-html="team" id="team"></span>
          <span v-html="translation" id="translation"></span>
          <span v-html="medical" id="medical"></span>
          <span v-html="quote" id="quote"></span>
          <span v-html="corrigendum" id="corrigendum"></span>
          <span v-html="guide" id="guide"></span>
          <span v-html="consult" id="consult"></span>
        </div>
      </div>
      <div class="main2" ref="main2">
        <div>
          <div class="ourTitle" v-if="key != '10' && !$isMobile">{{ obj.title }}</div>
          <span v-html="obj.content" v-if="key != '10'"></span>
          <div v-if="key == '10'" style="display: flex; justify-content: center">
            <el-image :class="!$isMobile ? 'pcImage' : 'pcimageFit'" :src="downloadPng" fit="cover"></el-image>
          </div>
        </div>
      </div>
    </MainCenter>
  </div>
</template>

<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import { selectAbout } from '@/api/article'
import downloadPng from '@/assets/imgs/download.png'

export default {
  name: 'About',
  components: { MainCenter },
  data() {
    return {
      our: null,
      gywm: null,
      team: null,
      translation: null,
      medical: null,
      quote: null,
      corrigendum: null,
      guide: null,
      consult: null,
      arr: [],
      obj: {
        title: '',
        content: '',
      },
      activeClass: null,
      key: 'our',
      downloadPng,
      flag: false,
      type: null,
    }
  },
  created() {
    if (this.$route.query.type != null) {
      this.type = this.$route.query.type
    }
    if (window.sessionStorage.getItem('serviceKey') !== null) {
      this.key = window.sessionStorage.getItem('serviceKey')
      this.flag = true
    }
    this.getSelectAbout(this)
  },
  beforeRouteLeave(to, from, next) {
    if (window.sessionStorage.getItem('serviceKey') !== null) {
      window.sessionStorage.removeItem('serviceKey')
    }
    next()
  },
  methods: {
    // getCurrentAnchor() {
    //   const el = this.$refs.main1 // 父元素，其内部元素滚动
    //   const h = el.querySelectorAll('main1>span') // 页面中所有的内容的标题
    //   const T = document.querySelectorAll('gywm') //获取页面侧边标题
    //   const offettopList = [] // 存储标题距离父元素顶部的位置
    //   for (const k of h) {
    //     // 将所有内容的标题距离其父元素顶部的距离存成一个数组
    //     offettopList.push(k.offsetTop)
    //   }
    //   console.log(h, 'h')
    //   console.log(T, 'T')
    //   offettopList.forEach((t, i) => {
    //     // 当某个元素距离父元素顶部距离小于 5 时
    //     if (t - el.scrollTop - 10 < 5) {
    //       T.forEach((item, index) => {
    //         if (i == index) {
    //           T[index].setAttribute('class', 'yangshi2')
    //           console.log(item)
    //         }
    //       })
    //     }
    //   })
    // },
    async getSelectAbout(e) {
      if (e.key == 9) {
        this.type = '用户协议'
      }
      let res = await selectAbout()
      this.arr = res
      if (this.flag) {
        this.$refs.main1.style.display = 'none'
        this.obj.title = this.arr[1].title
        this.obj.content = this.arr[1].content
      } else {
        this.$refs.main2.style.display = 'none'
      }
      this.our = res[0].title //标题
      // 关于我们
      this.gywm = res[0].content.substring(
        0,
        res[0].content.indexOf(`<span label="标题" class="font-size-title">编辑团队</span>`)
      )
      // 编辑团队
      this.team = res[0].content.substring(
        res[0].content.indexOf(`<span label="标题" class="font-size-title">编辑团队</span>`),
        res[0].content.indexOf(
          `<span label="标题" class="font-size-title">译文 <span label="标题" classname="font-size-title"><strong>免责声明</strong></span></span>`
        )
      )
      // 译文 免责声明
      this.translation = res[0].content.substring(
        res[0].content.indexOf(
          `<span label="标题" class="font-size-title">译文 <span label="标题" classname="font-size-title"><strong>免责声明</strong></span></span>`
        ),
        res[0].content.indexOf(
          `<span label="标题" class="font-size-title">医疗和药物 <span label="标题" classname="font-size-title"><strong>免责声明</strong></span></span>`
        )
      )
      // 医疗和药物 免责声明
      this.medical = res[0].content.substring(
        res[0].content.indexOf(
          `<span label="标题" class="font-size-title">医疗和药物 <span label="标题" classname="font-size-title"><strong>免责声明</strong></span></span>`
        ),
        res[0].content.indexOf(`<span label="标题" class="font-size-title">引用</span>`)
      )
      // 引用
      this.quote = res[0].content.substring(
        res[0].content.indexOf(`<span label="标题" class="font-size-title">引用</span>`),
        res[0].content.indexOf(`<span label="标题" class="font-size-title">勘误和更正</span>`)
      )
      // 勘误和更正
      this.corrigendum = res[0].content.substring(
        res[0].content.indexOf(`<span label="标题" class="font-size-title">勘误和更正</span>`),
        res[0].content.indexOf(`<span label="标题" class="font-size-title">NEJM统计学报告指南</span>`)
      )
      // NEJM统计学报告指南
      this.guide = res[0].content.substring(
        res[0].content.indexOf(`<span label="标题" class="font-size-title">NEJM统计学报告指南</span>`),
        res[0].content.indexOf(`<span label="标题" class="font-size-title">《新英格兰医学杂志》投稿咨询</span>`)
      )
      // 《新英格兰医学杂志》投稿咨询
      this.consult = res[0].content.substring(
        res[0].content.indexOf(`<span label="标题" class="font-size-title">《新英格兰医学杂志》投稿咨询</span>`)
      )
      if (this.type == '简介') {
        this.titleClick({ key: 'our' })
      }
      if (this.type == '服务条款') {
        this.handleClick({ key: '2' })
      }
      if (this.type == '用户协议') {
        this.handleClick({ key: '9' })
      }
      if (this.type == '隐私政策') {
        this.handleClick({ key: '3' })
      }
      if (this.type == '联系我们') {
        this.handleClick({ key: '4' })
      }
      // this.handleClick(e)
    },
    handleClick(e) {
      this.key = e.key
      switch (e.key) {
        case 'gywm':
          this.$refs.main1.style.display = 'inline'
          this.$refs.main2.style.display = 'none'
          break
        case 'team':
          this.$refs.main1.style.display = 'inline'
          this.$refs.main2.style.display = 'none'
          break
        case 'translation':
          this.$refs.main1.style.display = 'inline'
          this.$refs.main2.style.display = 'none'
          break
        case 'medical':
          this.$refs.main1.style.display = 'inline'
          this.$refs.main2.style.display = 'none'
          break
        case 'quote':
          this.$refs.main1.style.display = 'inline'
          this.$refs.main2.style.display = 'none'
          break
        case 'corrigendum':
          this.$refs.main1.style.display = 'inline'
          this.$refs.main2.style.display = 'none'
          break
        case 'guide':
          this.$refs.main1.style.display = 'inline'
          this.$refs.main2.style.display = 'none'
          break
        case 'consult':
          this.$refs.main1.style.display = 'inline'
          this.$refs.main2.style.display = 'none'
          break
        case '10':
          this.$refs.main1.style.display = 'none'
          this.$refs.main2.style.display = 'inline'
          break
      }
      e.key = Number(e.key)
      this.arr.forEach((element) => {
        if (element.id === e.key) {
          this.$refs.main1.style.display = 'none'
          this.$refs.main2.style.display = 'inline'
          window.scrollTo(0, 0)
          this.obj = element
        }
      })
    },

    titleClick(e) {
      this.key = e.key
      if (e.key === 'our') {
        this.$refs.main1.style.display = 'inline'
        this.$refs.main2.style.display = 'none'
        window.scrollTo(0, 0)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.mobile {
  .container {
    .mainCenter {
      .anchorFit {
        position: absolute;
        margin-left: 0;
        width: 94%;
      }
      /deep/.ant-menu-submenu-title {
        width: 100% !important;
        height: 100% !important;
      }
      /deep/.ant-anchor {
        padding-left: 37.5px;
      }
      .ant-menu-horizontal {
        border-bottom: none;
      }
    }
    .main1,
    .main2 {
      padding: 0 15px !important;
      width: 100% !important;
      div {
        margin-top: 62px;
      }
    }
  }
}
.container {
  position: relative;
  height: 100%;
  padding: 12px 0;
  background-color: #f0f2f5;
  .mainCenter {
    display: flex;
    justify-content: flex-start;
    .sidebar {
      width: 25%;
      .cebianlan {
        padding: 30px 0;
        .menu-title {
          margin: 0 -34px 0 -24px;
          padding-left: 24px;
          border-left: 5px solid #fff;
        }
        .menu-title2 {
          border-left: 5px solid #e52e00;
          color: #e52e00 !important;
          background-color: rgba(229, 46, 0, 0.1);
        }
        height: 100%;
        .one,
        .two,
        .three,
        .four,
        .five,
        .six,
        .seven,
        .eight {
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          color: rgba(0, 0, 0, 0.85);
        }
        .two,
        .three,
        .four,
        .five,
        .six,
        .seven,
        .eight {
          padding-left: 19.5px;
          border-left: 5px solid #fff;
        }
        .one1 {
          color: #e52e00;
          font-weight: bold;
        }
        .gywm {
          font-size: 16px;
          // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          color: rgba(0, 0, 0, 0.65);
          // line-height: 24px;
        }
      }
    }
    .main1,
    .main2 {
      background-color: #fff;
      width: 75%;
      min-height: 620px;
      line-height: 25px;
      // padding: 0 39px 0px 40px;
      padding: 0 14px 0px 40px;
    }
    .main1 {
      padding-bottom: 64px;
    }
  }
  #our,
  .ourTitle {
    text-align: center;
    font-size: 28px;
    // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    margin: 48px 0 24px 0;
  }
  .pcImage {
    width: 700px;
    margin-top: 53px;
  }
  .pcimageFit {
    width: 700px;
    margin-top: 0 !important;
  }
}
.yangshi1 {
  border-left: 5px solid #e52e00 !important;
  color: #e52e00 !important;
  background-color: rgba(229, 46, 0, 0.1);
  font-weight: bold;
}
.yangshi2 {
  color: rgba(0, 0, 0, 0.85) !important;
  // font-weight: bold;
}
/deep/.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 8px solid #f0f2f5;
}
/deep/.ant-menu-submenu-title {
  width: 286px !important;
  height: 60px !important;
}
/deep/.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
}
/deep/.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  opacity: 0;
}
/deep/.ant-anchor {
  padding-left: 0px;
}
/deep/.ant-anchor-wrapper {
  margin-left: 0px;
  padding-left: 0px;
}
/deep/.ant-anchor-ink::before {
  width: 0px;
}
// /deep/.ant-anchor-link {
//   padding: 0px;
//   line-height: 40px;
// }
</style>
<style>
.main1 a,
.main2 a {
  color: blue;
  word-break: break-word;
  /* hyphens: auto;
  word-wrap: break-word; */
}
</style>
