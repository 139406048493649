import { render, staticRenderFns } from "./NejmCatalog.vue?vue&type=template&id=c567a4ba&scoped=true"
import script from "./NejmCatalog.vue?vue&type=script&lang=js"
export * from "./NejmCatalog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c567a4ba",
  null
  
)

export default component.exports