<template>
  <div class="box">
    <div class="title">{{ title }}</div>
    <div class="sub-title">{{ subTitle }}</div>
    <a-divider v-if="!$isMobile" />
    <div v-else class="dividerFit"><a-divider /></div>
    <a-row type="flex">
      <a-col v-if="!$isMobile" flex="260px">
        <slot name="form"></slot>
      </a-col>
      <a-col flex="1">
        <a-list v-bind="$attrs" :split="false" class="list">
          <a-list-item slot="renderItem" slot-scope="item">
            <slot v-bind:item="item"></slot>
          </a-list-item>
        </a-list>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'TitleFormList',
  props: {
    title: String,
    subTitle: String,
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.box {
  padding: 40px 0;

  .title {
    border-left: 4px solid @clr-primary;
    padding-left: 10px;
    font-size: 24px;
    font-weight: bold;
  }

  .sub-title {
    margin-top: 10px;
  }

  .list {
    padding-left: 24px;
    border-left: 1px solid @clr-border;
  }
}
.mobile {
  .box {
    padding: 10px 0;

    .title {
      margin-top: 15px;
      border-left: none;
      padding-left: 10px;
      font-size: 24px;
      font-weight: bold;
      padding: 0 15px;
    }
    .title::before {
      content: '';
      display: inline-block;
      margin-right: 7px;
      width: 4px;
      height: 22px;
      background-color: #e52e00;
    }

    .sub-title {
      margin-top: 10px;
      padding: 0 15px;
    }
    .dividerFit {
      padding: 0 15px;
    }
    .list {
      padding: 0 15px;
      border-left: none;
    }
  }
}
</style>

<style>
.box .ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: none;
}
</style>
