<template>
  <MainCenter :style="$isMobile ? 'padding-left: 15px; padding-right: 15px' : ''">
    <!-- 动画解读详情 -->
    <div :style="$isMobile ? 'margin-top: 15px' : 'margin-top: 45px'">
      <img class="logo" src="../../../../assets/imgs/logo/nejm_nejm_logo-old.png" alt="logo" />
    </div>
    <div class="title">
      <h2 :style="$isMobile ? 'font-size: 20px' : 'font-size: 32px'">{{ data ? data.title : '' }}</h2>
      <div :style="$isMobile ? 'margin: 7px 0px 10px 0px;font-size: 16px;' : 'margin-top: 15px;font-size: 24px'">
        {{ data ? data.outtitle : '' }}
      </div>
      <!--<RichText class="titleContent" :content="data ? data.content : ''" :about="data ? data.aboutcontent : ''" />-->
      <div :class="$isMobile ? 'titleDate-mb' : 'titleDate'">
        {{ data ? data.classNames + ' | ' + data.postdate : '' }}
      </div>
    </div>
    <div class="main">
      <div :class="$isMobile ? 'main_top_mb' : 'main_top'">
        <Share
          :isShow="{ navigation: false, collect: true, share: true, original: true }"
          :id="data.id"
          collect-type="article"
          :ifCollect.sync="data.ifCollect"
          :outlink="data.outlink"
        ></Share>
      </div>
      <div class="main_content">
        <div class="content_left" :style="$isMobile ? 'width:100%' : ''">
          <div class="content_video">
            <BzVideoPlayer
              v-if="(isShow && subscribe_info.service_type !=0 )||  usefreeCont || isShow&&subParam.is_subscribe == 2 || isShow&&subscribe_info.service_type == 1 ||  isShow&&subscribe_info.service_type == 2"
              :src="data ? assetsUrl + data.videoUrl : ''"
              :poster="data.thumb"
              :relation-id="data.id"
              relation-type="10"
              :subParam="subParam"
              @paramFun="getVideoParam"
            />
            <div v-if="!isShow || (isShow&&subParam.is_month_chance ==1&&subParam.is_have_view_auth==2&& subParam.is_subscribe==1) || isShow&&subParam.is_subscribe == 1 && subParam.is_have_view_auth == 2 &&subParam.is_month_chance ==2&&!usefreeCont" class="hiddenVideo">
              <el-image :src="data.thumb" fit="cover" style="width: 100%; height: 100%"></el-image>
              <div class="imgDiv" @click="showVideo">
                <!-- <div class="btnContainer">
                  <el-button type="text" @click="showVideo">登录后可观看</el-button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="video_text">
            <p>内容简介</p>
            <div  v-if="!isShow" :class="subParam.is_subscribe == 2 || subscribe_info.service_type !='0'|| usefreeCont?'':'login'">
              <nologinPop v-if="!isShow" @login="login"></nologinPop>
            </div>
            <div v-if="isShow" :class="subParam.is_subscribe == 2 || subscribe_info.service_type !='0'|| usefreeCont?'':'login'">
              <noFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow" ></noFreeReadPop><!--没有免费次数-->
              <noUseFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow && isClose && subParam.is_month_chance =='2'" @noclose="closePop" @getArticle="getArticleInfo" @content="getContent"></noUseFreeReadPop>
            </div>
            <RichText :content="isShow&&subParam.is_subscribe == 2 || subscribe_info.service_type !='0' || usefreeCont ?data.content:''" :about="data.aboutcontent" />
          </div>
        </div>
        <div class="content_right" :style="$isMobile ? 'width:100%;' : ''">
          <!--          v-if="correlationList.length != null && correlationList.length > 0"-->
          <Correlation
            title="相关文章"
            v-if="correlationList.page.total > 0"
            :correlationList="correlationList"
            :correlation="correlation"
          ></Correlation>
        </div>
      </div>
    </div>
      <FreeReadPop :subParam="subParam" :islogin="islogin" v-show="isShow&&isFree" @close="close" @getArticle="getArticleInfo"   @showContent="showContent" @content="getContent" ></FreeReadPop>
      <!--没有免费次数-->
      <!-- <noFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow&&subscribe_info.service_type == 0" ></noFreeReadPop> -->
      <!-- 点击不使用免费次数后，显示可以使用一次免费次数-->
      <!-- <noUseFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow && isClose && subscribe_info.service_type == 0  && subParam.is_month_chance =='2'" @noclose="closePop" @getArticle="getArticleInfo" @content="getContent"></noUseFreeReadPop> -->
  </MainCenter>
</template>

<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import RichText from '@/components/layout/RichText.vue'
import Share from '@/components/share/Share.vue'
import Correlation from '@/components/layout/Correlation.vue'
import { selectArticleList, getArticleDetail, getArticle, getArticleDetail2 } from '@/api/serach'
// import { selectArticleList, getArticleDetail, getArticle } from '@/api/serach'
import BzVideoPlayer from '@/components/video/BzVideoPlayer.vue'
import config from 'config'
import { format } from '@/utils/time-utils'
import { getSessionUser, setSessionUser } from '@/utils/app-utils'
import { mapMutations } from 'vuex'
import { loginWithoutPwd,loginWithoutPwdwithQueryload } from '@/api/login'
import { mapState } from 'vuex'

import FreeReadPop from '@/components/article/popup/FreeReadPopup.vue';
import noFreeReadPop from '@/components/article/popup/noFreeReadPopup3.vue';
// import nologinPop from '@/components/article/popup/nologinPopup.vue';
import nologinPop from '@/components/article/popup/nologinPop.vue';
import noUseFreeReadPop from '@/components/article/popup/noUseFreePopup.vue';
import { getMemberInfo } from '@/api/personal'
export default {
  components: { RichText, BzVideoPlayer, MainCenter, Correlation, Share,FreeReadPop,noFreeReadPop,nologinPop,noUseFreeReadPop },
  name: 'AnimationParticulars',
  data() {
    return {
      data: null,
      //新增功能 start
      isFree:false,
      isShowFree:false,
      noFree:false,
      islogin:false,
      subParam:{
        is_subscribe:null,//是否付费文章（1：是；2：否）
        is_have_view_auth:null,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
        is_month_chance:null//未付费或者已付费但是会员到期的用户，每个用户每个月有一次免费的机会 1：表示无机会；2：表示有机会
      },//会员信息
      subscribe_info:{
        service_type:"",
        service_end_date:""
      },//会员信息
      isClose:false,
      //新增功能 end
      assetsUrl: config.assetsUrl,
      correlation: {
        articleIds: [],
        pageNo: 1,
        pageSize: 10,
      },
      correlationList: null,
      adList: [],
      memberId:null,
      usefreeCont:false
    }
  },
  async created() {
    if(this.isShow){
      this.islogin = true
      console.log('1')
      this.getUseinfo()
    }else{
      this.islogin = false
    }
    this.dealOutLink(this.$route.query.sg)
    this.memberId = Number(window.sessionStorage.getItem('memberId'))
    if (this.$route.query.prev == 'D1Co7866D71DC3Ao9B3B692DoA2o86B4') {
      await getArticleDetail2({memberId: this.memberId, permalinks: this.$route.query.permalinks }).then((res) => {
        if (res.classNames != null) {
          res.classNames = res.classNames.replace(/,/g, ' ')
        }
        res.postdate = format(res.postdate, 'YYYY-MM-DD')
        this.data = res
        this.isFree = res.is_subscribe == '1' ? true:false
        this.noFree = res.is_subscribe == '1' ? true:false
        this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
        this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
        this.subParam.is_month_chance = res.is_month_chance
        this.flag2 =  res.is_have_view_auth == '1'?true:false
        this.subParam.articleId = res.id
        this.$forceUpdate()
      })
      await getArticleDetail({memberId: this.memberId, permalinks: this.$route.query.permalinks }).then((res) => {
        if (res.classNames != null) {
          res.classNames = res.classNames.replace(/,/g, ' ')
        }
        this.isFree = res.is_subscribe == '1' ? true:false
        this.noFree = res.is_subscribe == '1' ? true:false
        this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
        this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
        this.subParam.is_month_chance = res.is_month_chance
        this.flag2 =  res.is_have_view_auth == '1'?true:false
        this.subParam.articleId = res.id
        this.$forceUpdate()
        res.postdate = format(res.postdate, 'YYYY-MM-DD')
        this.data = res
      })
    } else {
      await getArticleDetail({memberId: this.memberId, permalinks: this.$route.query.permalinks }).then((res) => {
        if (res.classNames != null) {
          res.classNames = res.classNames.replace(/,/g, ' ')
        }
        this.isFree = res.is_subscribe == '1' ? true:false
        this.noFree = res.is_subscribe == '1' ? true:false
        this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
        this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
        this.subParam.is_month_chance = res.is_month_chance
        this.flag2 =  res.is_have_view_auth == '1'?true:false
        this.subParam.articleId = res.id
        this.$forceUpdate()
        res.postdate = format(res.postdate, 'YYYY-MM-DD')
        this.data = res
      })
    }
    if(this.isShow){
      this.islogin = true
      console.log('1',this.islogin)
    }else{
      this.islogin = false
      this.isClose = false
      this.memberId = null
      window.sessionStorage.setItem('memberId', null)
    }
    console.log('sourceid::', this.data.sourceid)
    if (this.data.sourceid !== 7) {
      if (this.data?.relationArticleIds?.length > 0) {
        this.correlation.articleIds = this.data.relationArticleIds
        await selectArticleList(this.correlation).then((res) => {
          if (res) {
            res.results.forEach((element) => {
              element.postdate = format(element.postdate, 'YYYY-MM-DD')
            })
            this.correlationList = res
          }
        })
      }
    } else {
      let loginUser = getSessionUser()
      let linkArt = await getArticle({
        memberId: null != loginUser ? loginUser.id : null,
        permalinks: this.data.equivalent,
        // permalinks: 'YXQYdo005831',
      })
      if (null != linkArt && linkArt.id != null) {
        let temp = linkArt
        temp.postdate = format(linkArt.postdate, 'YYYY-MM-DD')
        this.correlationList = {
          results: [linkArt],
          page: {
            total: 1,
          },
        }
      } else {
        this.correlationList = {
          results: [],
          page: {
            total: 0,
          },
        }
      }
      console.log('correlationList::', this.correlationList)
    }
  },
  watch:{
    isShow: function(newValue, oldValue) {
      console.log(newValue,oldValue)
      console.log(this.isShow,'isShow')
      if(this.isShow){
        this.getUseinfo()
        setTimeout(()=>{
          this.getArticleInfo()
        },300)
      }else{
        this.memberId = null
        window.sessionStorage.setItem('memberId', null)
        this.islogin = false
        this.isFree = false
        // this.subParam.is_subscribe = null//是否付费文章（1：是；2：否）
        this.subParam.is_have_view_auth= null//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
        this.subParam.is_month_chance = null
      }
    }
  },
  mounted(){
      if(this.$route.query.nejmjmedssodata){
        console.log(this.$route.query.nejmjmedssodata);
        let vm =this;
        loginWithoutPwdwithQueryload({data:this.$route.query.nejmjmedssodata}).then((res) => {
          console.log(res);
          if(res){
            vm.setUser(res)
            console.log(res,'====用户信息')
            vm.subscribe_info = res.subscribe_info
            vm.subscribe_info.service_type= res.subscribe_info.service_type
            setSessionUser(res)
            window.sessionStorage.setItem('memberId', res.id)
          }
        }).catch((err)=>{
          console.log(err);
          vm.$nextTick(() => {
              vm.$bus.emit('login')
            })
        })
      }
      //是否登录。登录查询用户信息
      if(this.isShow){
        this.initialize()
      }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isShow() {
      return !!this.user
    },
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    getVideoParam(event){
      console.log(event)
      this.isFree = event
    },
    async getUseinfo(){
        let res = await getMemberInfo()
        console.log(res,'====用户信息')
        // this.userInfo.truename = res.membername
        this.subscribe_info.service_type= res.subscribe_info.service_type
        this.memberId = res.id
        window.sessionStorage.setItem('memberId', res.id)
    },
    async initialize() {
      let res = await getMemberInfo()
      console.log(res,'====用户信息')
      this.subscribe_info = res.subscribe_info || {}
      this.subscribe_info.service_type= res.subscribe_info.service_type || ''
      this.memberId = res.id
      window.sessionStorage.setItem('memberId', res.id)
    },
    showContent(e){
      this.usefreeCont = e
    },
    getContent(e){
      console.log(e,'文章content')
      this.data.content = e
    },
    openVip(){
      this.$router.push({ path:'/personal', query:{ type: 'flag4' } })
        .catch((err) => {
            console.log('输出报错', err)
        })
    },
    close(e){
      console.log(e,'=====e')
      this.flag2 = false
      this.isFree = e.a
      this.isClose = e.b
    },
    //关闭弹窗
    closePop(e){
      console.log(e,'使用次数后')
      this.isClose = e
    },
    async  getArticleInfo(){
      if (this.$route.query.prev == 'D1Co7866D71DC3Ao9B3B692DoA2o86B4') {
        await getArticleDetail2({memberId: this.memberId, permalinks: this.$route.query.permalinks }).then((res) => {
          if (res.classNames != null) {
            res.classNames = res.classNames.replace(/,/g, ' ')
          }
          res.postdate = format(res.postdate, 'YYYY-MM-DD')
          this.data = res
          this.isFree = res.is_subscribe == '1' ? true:false
          this.noFree = res.is_subscribe == '1' ? true:false
          this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
          this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
          this.subParam.is_month_chance = res.is_month_chance
          this.flag2 =  res.is_have_view_auth == '1'?true:false
          this.subParam.articleId = res.id
          this.$forceUpdate()
        })
        // await getArticleDetail({memberId: this.memberId, permalinks: this.$route.query.permalinks }).then((res) => {
        //   if (res.classNames != null) {
        //     res.classNames = res.classNames.replace(/,/g, ' ')
        //   }
        //   res.postdate = format(res.postdate, 'YYYY-MM-DD')
        //   this.data = res
        //   this.isFree = res.is_subscribe == '1' ? true:false
        //   this.noFree = res.is_subscribe == '1' ? true:false
        //   this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
        //   this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
        //   this.subParam.is_month_chance = res.is_month_chance
        //   this.flag2 =  res.is_have_view_auth == '1'?true:false
        //   this.subParam.articleId = res.id
        //   this.$forceUpdate()
        // })
      } else {
        await getArticleDetail({memberId: this.memberId, permalinks: this.$route.query.permalinks }).then((res) => {
          if (res.classNames != null) {
            res.classNames = res.classNames.replace(/,/g, ' ')
          }
          res.postdate = format(res.postdate, 'YYYY-MM-DD')
          this.data = res
          this.isFree = res.is_subscribe == '1' ? true:false
          this.noFree = res.is_subscribe == '1' ? true:false
          this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
          this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
          this.subParam.is_month_chance = res.is_month_chance
          this.flag2 =  res.is_have_view_auth == '1'?true:false
          this.subParam.articleId = res.id
          this.$forceUpdate()
        })
      }
    },
    async dealOutLink(sg) {
      let loginUser = getSessionUser()
      if (sg != null && sg == 'AbW1N' && loginUser == null) {
        this.setUser({})
        setSessionUser({})
        await loginWithoutPwd()
      }
    },
    login() {
      // this.$emit('login')
      this.$bus.emit('login')
    },
    showVideo() {
      if(!this.isShow){
        this.$message.warning('登录即可观看')
        this.$bus.emit('login')
        return
      }
      if(this.isShow&&this.subParam.is_month_chance ==1&&this.subParam.is_have_view_auth==2&& this.subParam.is_subscribe==1){
        this.$message.warning('您本月的免费浏览次数已用完，请付费观看！')
        return
      }
      if(this.isShow&&this.subParam.is_month_chance ==2&&this.subParam.is_have_view_auth==2&& this.subParam.is_subscribe==1){
       this.isFree = true
        return
      }

    },
  },
}
</script>

<style lang="less" scoped>
.no_vips{
  height: 215px;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.53) 1%, #FFFFFF 40%, #FFFFFF 100%);
  margin-bottom: -65px;
  opacity: 0.85;
}
.logo {
  width: 230px;
}
.title {
  width: 100%;
  min-height: 108px;
  padding-bottom: 8px;
  //border-bottom: 1px solid #d6d6d6;
  margin-top: 38px;
  h2 {
    font-size: 32px;
    font-weight: 700;
    position: relative;
    line-height: 38px;
    letter-spacing: 1.5px;
  }
  .titleContent {
    width: 100%;
    overflow: hidden; /*必须结合的属性,当内容溢出元素框时发生的事情*/
    text-overflow: ellipsis; /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
    -webkit-line-clamp: 2; /*用来限制在一个块元素显示的文本的行数。*/
    padding: 6px 0;
    background-color: #f2f2f2;
    line-height: 20px;
    font-size: 14px;
    margin-top: 15px;
  }
  .titleDate {
    margin-top: 20px;
    color: #666;
    font-size: 18px;
  }
}
.title h2::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 32px;
  background-color: #e52e00;
  border-radius: 3px;
  margin-right: 13px;
}
.main {
  width: 100%;
  overflow: hidden;
}
.main_content {
  width: 100%;
  overflow: hidden;
  .content_left {
    width: 70%;
    float: left;
  }
  .content_right {
    width: 25%;
    float: right;
  }
}
.content_video {
  width: 100%;
  text-align: center;
  margin: 20px 0;
  video {
    width: 100%;
  }
  .hiddenVideo {
    width: 840px;
    height: 630px;
    position: relative;
    .imgDiv {
      position: absolute;
      cursor: pointer;
      // background-color: rgba(204, 204, 204, 0.5);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // .btnContainer {
      //   width: 100%;
      //   height: 100%;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   .el-button {
      //     width: 192px;
      //     height: 48px;
      //     border: 1px solid #e52e00;
      //     background-color: #fff;
      //     border-radius: 3px;
      //     font-size: 14px;
      //   }
      // }
    }
  }
}
.video_text {
  padding-bottom: 40px;
  p {
    margin-bottom: 10px;
    &:nth-of-type(2) {
      line-height: 20px;
      font-size: 14px;
    }
  }
}
.main_top_mb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: 17%;
}
.login{
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
.mobile {
  title h2::before {
    width: 4px;
    height: 20px;
  }
  .titleDate-mb {
    color: #666666;
    font-size: 14px;
    padding: 4px 0;
  }
  .main {
    .main_content {
      .content_left {
        .content_video {
          .hiddenVideo {
            width: 384.4px;
            height: 288.3px;
          }
        }
      }
    }
  }
}
</style>
