<template>
  <div class="footer" :class="{ theme2: theme2 }">
    <div class="footer_top fr">
      <img v-for="(item, i) of logoList" :src="item.img" :key="i" class="logo" @click="onLogoClick(item)" />
    </div>
    <div class="footer_bottom fc">
      <p>
        <span>《NEJM医学前沿》 &copy; {{ time }} 年版权所有。保留所有权利。</span>
        <a v-if="!$isMobile" href="https://beian.miit.gov.cn/">沪ICP备16034358号-2</a>
      </p>
      <a v-if="$isMobile" href="https://beian.miit.gov.cn/">沪ICP备16034358号-2</a>
      <p>
        <img src="../../assets/imgs/wa.png" alt="" width="14" />
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402004405">
          沪公网安备 31010402004405号
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import logoWhite from '@/assets/imgs/logo/logo_white.png'
import groupWhite from '@/assets/imgs/logo/group_white.png'
import jmWhite from '@/assets/imgs/logo/jm_white.png'

export default {
  name: 'HomeFooter',
  data() {
    return {
      time: '',
      logoList: [
        { img: groupWhite, link: 'https://www.nejmgroup.org/' },
        { img: logoWhite, link: 'http://www.nejmqianyan.cn' },
        { img: jmWhite, link: 'https://j-med.com/' },
      ],
    }
  },
  computed: {
    theme2() {
      return this.$route.meta.theme2
    },
  },
  mounted() {
    this.dateYear()
  },
  methods: {
    onLogoClick(item) {
      if (item.link) {
        window.open(item.link)
      }
    },
    dateYear() {
      let nowDate = new Date()
      let date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      }
      // let newmonth = date.month > 10 ? date.month : "0" + date.month
      // let newday = date.date > 10 ? date.date : "0" + date.date
      // let newminutes = date.minutes < 10 ? "0" + date.minutes : date.minutes
      // let newseconds = date.seconds < 10 ? "0" + date.seconds : date.seconds
      // 我需要的时间格式为 "yyyy-MM-dd HH:mm:ss" 有不一样的自己拼别的，都是字符串拼接
      this.time = date.year
      console.log(this.time + 'time')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';
.footer_top {
  align-items: center;
  justify-content: center;
  height: 124px;
  background-color: @clr-primary;
  /deep/img:nth-of-type(1),
  /deep/img:nth-of-type(2) {
    height: 54px;
    object-fit: cover;
  }
  /deep/img {
    object-fit: cover;
  }
  .logo {
    cursor: pointer;
    & + .logo {
      margin-left: 20%;
      height: 54px;
    }
  }
}
.footer_bottom {
  align-items: center;
  justify-content: center;
  padding: 18px 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  line-height: 24px;
}

// theme2
.theme2 {
  .footer_top {
    background-color: #5b6770;
  }
}

// 移动端
.mobile {
  .footer_top {
    height: 90px;
    /deep/img {
      width: 90px;
      height: 28px;
      object-fit: cover;
    }
    .logo {
      width: 80px;
      height: auto;
      & + .logo {
        margin-left: 10%;
        height: auto;
      }
    }
  }
  .footer_bottom {
    font-size: 14px;
  }
}
</style>
