<template>
  <div>
    <el-button v-if="$isMobile" type="primary" @click="drawer = true" class="articleAnimationAudio2ShaiXuan">
      筛选
    </el-button>
    <el-drawer :visible.sync="drawer" :with-header="false" size="80%" class="articleAnimationAudio2Drawer">
      <div class="shaiXuanContainer1">
        <van-cell title="日期" :value="timeFit" @click="show = true" />
        <van-calendar :min-date="minDate" :max-date="maxDate" v-model="show" type="range" @confirm="changeTime2" />
      </div>
      <div class="shaiXuanContainer2">
        <div>专业领域</div>
        <el-select
          @change="changeZhuanye"
          multiple
          collapse-tags
          v-model="params.articleClassIds"
          placeholder="请选择专业领域"
        >
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="shaiXuanContainer3">
        <div>类型</div>
        <el-select @change="changeLeiXing" multiple collapse-tags v-model="params.sourceIds" placeholder="请选择类型">
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
    </el-drawer>

    <div style="width: 100%; height: 1px; background-color: #d4d4d4; margin: 2px 0 16px 0"></div>
    <div v-if="!$isMobile" class="header">
      <el-date-picker
        unlink-panels
        v-model="time"
        @change="changeTime"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="width: 240px"
      ></el-date-picker>
      <el-select
        @change="changeZhuanye"
        multiple
        collapse-tags
        v-model="params.articleClassIds"
        placeholder="请选择专业领域"
        class="zhuanye"
      >
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-select @change="changeLeiXing" multiple collapse-tags v-model="params.sourceIds" placeholder="请选择类型">
        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </div>
    <a-list
      :split="false"
      class="list"
      item-layout="vertical"
      size="large"
      :pagination="flag === true ? pagination : null"
      :data-source="listData"
      :loading="loading"
    >
      <a-list-item slot="renderItem" :key="index" slot-scope="item, index">
        <div v-if="item.sourceName !== '视频'" class="contentType">
          {{ item.sourceName + '：' }}{{ item.typeName ? item.typeName : item.filterName }}
          <i v-if="item.ifCollect != 0" class="iconfont icon-shanchu" @click="collect(item)"></i>
        </div>
        <div v-if="item.sourceName === '视频'" class="contentType">
          {{ item.typeName ? item.typeName : item.filterName }}
          <i v-if="item.ifCollect != 0" class="iconfont icon-shanchu" @click="collect(item)"></i>
        </div>
        <a
          :href="'/animation-particulars' + generate(item.permalinks)"
          v-if="item.sourceName === '视频'"
          style="display: block; margin-bottom: 8px"
          @click.prevent="onItemClick3(item)"
        >
          <el-image style="width: 227px; height: 127px" :src="item.thumb" fit="cover"></el-image>
        </a>
        <div class="contentTitle">
          <a
            :href="'/animation-particulars' + generate(item.permalinks)"
            v-if="item.sourceName === '视频'"
            @click.prevent="onItemClick3(item)"
          >
            {{ item.title }}
          </a>
          <a
            :href="'/article-info' + generate(item.permalinks)"
            v-if="item.sourceName !== '视频'"
            @click.prevent="onItemClick(item)"
          >
            {{ item.title }}
          </a>
        </div>
        <div :class="item.sourceName !== '视频' ? 'contentAuthor' : null" v-if="item.authorText !== ''">
          <div>
            {{ item.authorText }}
          </div>
        </div>
        <a
          :href="'/article-info' + generate(item.permalinks)"
          @click.prevent="onItemClick(item)"
          v-if="item.sourceName !== '视频' && item.intro !== ''"
          v-html="item.intro"
          class="content"
        ></a>
        <div class="contentOther">
          <a @click.prevent="onItemClick2" class="contentOther1" v-for="(value, index) in item.classNames" :key="index">
            {{ value }}
          </a>
          <span v-if="item.classNames == null"></span>
          <span v-else>|</span>
          <!-- <span v-if="item.classNames.length != 0">|</span> -->
          {{ item.postdate }}
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>
<script>
import moment from 'moment'
import { format } from '@/utils/time-utils.js'
import { zyly, leixing } from './data'
import { selectMyReadArticle } from '@/api/personal'
import { generateHref } from '@/utils/utils.js'
import { mapState } from 'vuex'
import { collectArticle, cancelCollect } from '@/api/user-api.js'
import { getSessionUser } from '@/utils/app-utils'

const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}
export default {
  name: 'ArticleAnimationAudio2',
  data() {
    return {
      time: [],
      params: {
        startDate: null,
        endDate: null,
        articleClassIds: null,
        sourceIds: null,
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      },
      options: zyly, //专业领域
      options2: leixing, //类型
      pagination: {
        onChange: (page) => {
          this.getSelectMyReadArticle(page)
        },
        current: 1,
        pageSize: 20,
        total: 0,
      },
      listData: [],
      flag: false,
      loading: true,
      drawer: false,
      timeFit: '',
      show: false,
      minDate: new Date(2010, 12, 1),
      maxDate: new Date(),
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    this.getData()
  },
  methods: {
    generate(item) {
      return generateHref('permalinks', item)
    },
    onItemClick(item) {
      this.$router.push({ path: '/article-info', query: { permalinks: item.permalinks } })
    },
    onItemClick2(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    onItemClick3(item) {
      this.$router.push({ path: '/animation-particulars', query: { permalinks: item.permalinks } })
    },
    // 初始化请求
    async getData() {
      let param = { ...this.params, pageNo: 1, pageSize: this.pagination.pageSize }
      let res = await selectMyReadArticle(param)
      if (res.results.length == 0) {
        this.flag = false
      } else {
        this.flag = true
      }
      res.results.map((item) => {
        // 切割作者
        item.authorText = this._sliceAuthor(item.author)
        item.postdate = format(item.postdate, 'YYYY-MM-DD')
        if (item.classNames != null) {
          item.classNames = item.classNames.split(',')
          let classNames = []
          item.classNames.forEach((item) => {
            if (item !== '') {
              classNames.push(item)
            }
          })
          item.classNames = classNames
        }
      })
      let data = res.results
      this.pagination.total = res.page.total
      this.listData = data
      this.loading = false
    },
    // 分页请求
    async getSelectMyReadArticle(pageNo) {
      let param = { pageNo, pageSize: this.pagination.pageSize, ...this.params }
      let res = await selectMyReadArticle(param)
      res.results.map((item) => {
        // 切割作者
        item.authorText = this._sliceAuthor(item.author)
        item.postdate = format(item.postdate, 'YYYY-MM-DD')
        if (item.classNames != null) {
          item.classNames = item.classNames.split(',')
          let classNames = []
          item.classNames.forEach((item) => {
            if (item !== '') {
              classNames.push(item)
            }
          })
          item.classNames = classNames
        }
      })
      let data = res.results
      this.pagination.total = res.page.total
      this.pagination.current = pageNo
      this.listData = data
    },
    // 选择时间或专业或类型请求
    async getSelectMyReadArticle2() {
      let param = { pageNo: 1, pageSize: this.pagination.pageSize, ...this.params }
      let res = await selectMyReadArticle(param)
      res.results.map((item) => {
        // 切割作者
        item.authorText = this._sliceAuthor(item.author)
        item.postdate = format(item.postdate, 'YYYY-MM-DD')
        if (item.classNames != null) {
          item.classNames = item.classNames.split(',')
          let classNames = []
          item.classNames.forEach((item) => {
            if (item !== '') {
              classNames.push(item)
            }
          })
          item.classNames = classNames
        }
      })
      let data = res.results
      this.pagination.total = res.page.total
      this.pagination.current = param.pageNo
      this.listData = data
    },
    _sliceAuthor(author, limit = 3) {
      author = author.split(',')
      const postList = author.filter((item) => item.charAt(item.length - 1) === '.').map((item) => item.trim())
      let indexArr = []
      let count = 0
      let delLength = 0
      for (let i = 0; i < author.length; i++) {
        // 判断是否是职称
        // 如果是：当前字符串放入indexArr，continue
        // 如果否：说明是作者姓名，判断字符串长度是否超过限制,否：count+1，当前字符串放入indexArr，continue，是：删除上一次添加的名字，中断循环；如果数组长度是否超出limit，否：count+1，当前字符串放入indexArr，continue，是：中断循环
        let index = author[i]
        if (!postList.includes(index.trim())) {
          // 判断字符串长度是否超过限制或数组的长度是否满足limit
          if (indexArr.join(',').length >= 100) {
            indexArr.splice(indexArr.length - 1 - delLength, delLength + 1)
            break
          }
          if (count >= limit) {
            break
          }
          count++
          // console.log(delLength, 'ss')
          delLength = 0
          indexArr.push(index)
        } else {
          delLength++
          indexArr.push(index)
        }
      }
      if (indexArr.length < author.length) {
        return indexArr.join(',') + '...'
      }
      return indexArr.join(',')
    },
    // 选择时间
    changeTime(val) {
      if (val !== null) {
        this.params.startDate = moment(val[0]).format('YYYYMMDD')
        this.params.endDate = moment(val[1]).format('YYYYMMDD')
        this.getSelectMyReadArticle2()
      } else {
        this.params.startDate = null
        this.params.endDate = null
        this.getSelectMyReadArticle2()
      }
    },
    // 手机版选择时间
    changeTime2(val) {
      this.params.startDate = moment(val[0]).format('YYYYMMDD')
      this.params.endDate = moment(val[1]).format('YYYYMMDD')
      this.show = false
      this.getSelectMyReadArticle2()
      const [start, end] = val
      this.timeFit = `${this.formatDate(start)} - ${this.formatDate(end)}`
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    // 选择专业
    changeZhuanye() {
      this.getSelectMyReadArticle2()
    },
    // 选择类型
    changeLeiXing() {
      this.getSelectMyReadArticle2()
    },
    async collect(item) {
      if (this.user) {
        if (item.ifCollect === 0) {
          //收藏
          await collectArticle({ id: item.id })
          this.getSelectMyReadArticle(this.pagination.current)
        } else {
          //取消收藏
          let res = await cancelCollect({ id: item.id })
          if (res) {
            this.getSelectMyReadArticle(this.pagination.current)
          }
        }
      } else {
        this.$bus.emit('login')
      }
    },
  },
}
</script>
<style lang="less" scoped>
.header {
  margin-bottom: 8px;
  .zhuanye {
    margin: 0 16px;
  }
}
.list {
  margin-bottom: 40px;
  .contentType {
    font-size: 18px;
    // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
    line-height: 30px;
    color: #e52e00;
    margin-bottom: 8px;
    i {
      float: right;
      cursor: pointer;
      display: none;
    }
  }
  .contentTitle {
    color: rgba(0, 0, 0, 0.85);
    line-height: 26px;
    font-size: 18px;
    // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
    margin-bottom: 8px;
  }
  .contentAuthor {
    font-size: 14px;
    // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    margin-bottom: 8px;
  }
  .content {
    display: inline-block;
    font-size: 14px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
    margin-bottom: 8px;
    width: 100%;
    // height: 40px;
    // overflow: hidden;
  }
  .contentOther {
    font-size: 14px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
  }
  .contentOther1 {
    font-size: 14px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
  }
}
.contentOther1:hover {
  color: #e52e00 !important;
}
/deep/.ant-list-pagination {
  margin-top: 40px;
  text-align: center;
}
/deep/.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: none;
}
.articleAnimationAudio2ShaiXuan {
  position: absolute;
  top: 102px;
  right: 15px;
}
.shaiXuanContainer1,
.shaiXuanContainer2,
.shaiXuanContainer3 {
  margin-bottom: 10px;
}
.shaiXuanContainer2 {
  div {
    margin-bottom: 8px;
  }
}
.shaiXuanContainer3 {
  div {
    margin-bottom: 8px;
  }
}
</style>
<style lang="less">
.articleAnimationAudio2Drawer {
  .el-drawer__body {
    padding: 10px;
  }
  .van-cell {
    padding: 0;
  }
}
.shaiXuanContainer1 {
  .van-cell {
    .van-cell__title {
      span {
        font-size: 16px;
      }
    }
  }
}
</style>
