import http from '@/http/index'

/**
 * 热门阅读列表
 * @param params：{
 *}
 */
export function selectHotReadList(params) {
  return http.post('/api/hotRead/selectHotReadList', params)
}

/**
 * 模块开关
 * @param params：{
 *}
 */
export function getModularSwitch(params) {
  return http.post('/api/base/getModularSwitch', params)
}
