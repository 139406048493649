import http from '@/http/index'

// 文章详情
export function getArticleDetail(params) {
  return http.post('/api/article/getArticleDetail', params)
}
// 文章详情使用免费次数阅读
export function useFreeRead(params) {
  return http.get(`/api/index.php?c=program&m=articleUseMonthChance&id=${params.id}`, params)
}
// 关于我们
export function selectAbout(params) {
  return http.post('/api/article/selectAbout', params)
}

// 文章分类下拉
export function selectArticleClass(params) {
  return http.post('/api/article/selectArticleClass', params)
}

// 文章类型下拉
export function selectArticleFilter(params) {
  return http.post('/api/article/selectArticleFilter', params)
}

// 文章列表
export function selectArticleList(params) {
  return http.post('/api/article/selectArticleList', params)
}

// 资源列表
export function selectArticleSource(params) {
  return http.post('/api/article/selectArticleSource', params)
}

// 文章Type下拉
export function selectArticleType(params) {
  return http.post('/api/article/selectArticleType', params)
}

// 年份统计
export function yearArticleCount(params) {
  return http.post('/api/article/yearArticleCount', params)
}
// 期刊列表
export function selectQiKanList(params) {
  return http.post('/api/article/selectQiKanList', params)
}
// 根据年份获取期刊月份
export function getMonthOfQiKanYear(params) {
  return http.post('/api/article/getMonthOfQiKanYear', params)
}
