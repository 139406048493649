<template>
  <MainCenter>
    <el-button v-if="$isMobile" @click="drawer = true" class="shaiXuanMore" type="primary">筛选</el-button>
    <el-drawer :visible.sync="drawer" :with-header="false" size="50%">
      <div class="drawerContainer">
        <div class="drawerPaixuContainer">
          <div>排序</div>
          <SortSelect class="drawerPaixu" v-model="form.sortType" :params="params" @change="shaiXuanGetData" />
        </div>
        <div class="drawer1">
          <van-cell title="日期" :value="timeFit" @click="show = true" />
          <van-calendar :min-date="minDate" :max-date="maxDate" v-model="show" type="range" @confirm="onParamChange" />
        </div>
      </div>
    </el-drawer>

    <TitleFormList
      title="音频"
      sub-title="Audio Frequency"
      :data-source="list"
      :pagination="flag === true ? pagination : null"
      :grid="$isMobile ? '' : { gutter: 0, column: 3 }"
      :loading="loading"
      class="quickTakes"
    >
      <el-form v-if="!$isMobile" :model="form" class="form" slot="form" label-position="top">
        <a-anchor :offsetTop="!$isMobile ? 112 : 62">
          <el-form-item label="排序" prop="sort">
            <SortSelect class="paixu" @change="change" v-model="form.sortType" :params="params" />
          </el-form-item>
          <el-form-item label="日期" prop="date">
            <el-date-picker
              unlink-panels
              v-model="date"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 240px"
              @change="onParamChange"
            />
          </el-form-item>
        </a-anchor>
      </el-form>
      <div v-if="!$isMobile" class="item" slot-scope="{ item }">
        <a @click.prevent="onItemClick(item)">
          <el-image
            style="width: 256px; height: 180px"
            :src="
              item.thumb != ''
                ? item.thumb
                : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg08.viwik.com%2Fimages%2F20180820%2Ftooopen_sy_154340434039264.jpg&refer=http%3A%2F%2Fimg08.viwik.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1634519796&t=256bfdfa3d04ce6d27214dd3de65664c'
            "
            fit="cover"
          ></el-image>
        </a>
        <div class="item-title">
          <a @click.prevent="onItemClick(item)">{{ item.title }}</a>
        </div>
        <div class="item-date ellipsis">
          <a @click.prevent="onItemClick2" class="item-date" v-for="(value, index) in item.classNames" :key="index">
            {{ value }}
          </a>
          <span v-if="item.classNames == null"></span>
          <span v-else>|</span>
          <!-- <span v-if="item.classNames.length != 0">|</span> -->
          {{ item.postdate }}
        </div>
      </div>
      <div v-else class="item" slot-scope="{ item }">
        <a @click.prevent="onItemClick(item)">
          <el-image
            :src="
              item.thumb != ''
                ? item.thumb
                : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg08.viwik.com%2Fimages%2F20180820%2Ftooopen_sy_154340434039264.jpg&refer=http%3A%2F%2Fimg08.viwik.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1634519796&t=256bfdfa3d04ce6d27214dd3de65664c'
            "
            fit="cover"
          ></el-image>
        </a>
        <div class="item-title">
          <a @click.prevent="onItemClick(item)">{{ item.title }}</a>
        </div>
        <div class="item-date ellipsis">
          <a @click.prevent="onItemClick2" class="item-date" v-for="(value, index) in item.classNames" :key="index">
            {{ value }}
          </a>
          <span v-if="item.classNames == null"></span>
          <span v-else>|</span>
          <!-- <span v-if="item.classNames.length != 0">|</span> -->
          {{ item.postdate }}
        </div>
      </div>
    </TitleFormList>
  </MainCenter>
</template>

<script>
import SortSelect from '@/components/form/select/SortSelect.vue'
import MainCenter from '@/components/layout/MainCenter.vue'
import TitleFormList from '@/components/layout/TitleFormList.vue'
import { selectArticleList } from '@/api/article'
import moment from 'moment'
import { format } from '@/utils/time-utils.js'

const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}

export default {
  name: 'AudioList',
  components: { SortSelect, TitleFormList, MainCenter },
  data() {
    return {
      params: [
        { value: 0, label: '最新在前' },
        { value: 1, label: '最早在前' },
        { value: 2, label: '阅读最多' },
      ],
      list: [],
      pagination: {
        onChange: this.onPageChange,
        showSizeChanger: false,
        pageNo: 1,
        pageSize: 9,
        total: 100,
        current: 1,
      },
      date: [],
      form: {
        sortType: null,
        sourceIds: [8],
        startDate: null,
        endDate: null,
      },
      flag: false,
      drawer: false,
      timeFit: '',
      show: false,
      minDate: new Date(1970, 1, 1),
      maxDate: new Date(2050, 12, 31),
      loading: true,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    shaiXuanGetData() {
      this.pagination.pageNo = 1
      this.getData()
    },
    async getData() {
      let param = { pageNo: this.pagination.pageNo, pageSize: this.pagination.pageSize, ...this.form }
      let res = await selectArticleList(param)
      if (res.results.length == 0) {
        this.flag = false
      } else {
        this.flag = true
      }
      res.results.map((itme) => {
        itme.postdate = format(itme.postdate, 'YYYY-MM-DD')
        if (itme.classNames != null) {
          itme.classNames = itme.classNames.split(',')
          let classNames = []
          itme.classNames.forEach((item) => {
            if (item !== '') {
              classNames.push(item)
            }
          })
          itme.classNames = classNames
        }
      })
      let data = res.results
      this.pagination.total = res.page.total
      this.pagination.current = this.pagination.pageNo
      this.list = data
      this.loading = false
    },
    change() {
      this.pagination.pageNo = 1
      this.getData()
    },
    onItemClick(item) {
      console.log(item,'====11123')
      this.$router.push({ path: '/audio-particulars', query: { permalinks: item.permalinks } })
    },
    onItemClick2(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    onParamChange(val) {
      this.pagination.pageNo = 1
      if (val !== null) {
        this.form.startDate = moment(val[0]).format('YYYYMMDD')
        this.form.endDate = moment(val[1]).format('YYYYMMDD')
        this.getData()
      } else {
        this.form.startDate = null
        this.form.endDate = null
        this.getData()
      }
      if (this.$isMobile) {
        const [start, end] = val
        this.show = false
        this.timeFit = `${this.formatDate(start)} - ${this.formatDate(end)}`
      }
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`
    },
    async onPageChange(page) {
      let param = { pageNo: page, pageSize: this.pagination.pageSize, ...this.form }
      let res = await selectArticleList(param)
      res.results.map((itme) => {
        itme.postdate = format(itme.postdate, 'YYYY-MM-DD')
        if (itme.classNames != null) {
          itme.classNames = itme.classNames.split(',')
          let classNames = []
          itme.classNames.forEach((item) => {
            if (item !== '') {
              classNames.push(item)
            }
          })
          itme.classNames = classNames
        }
      })
      let data = res.results
      this.pagination.total = res.page.total
      this.pagination.current = page
      this.list = data
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';
.drawerContainer {
  padding: 20px 10px;
  .drawerPaixuContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .drawerPaixu {
      margin: 8px 0 16px 0;
    }
  }
  .drawer1 {
    .van-cell {
      padding: 0;
    }
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .drawer2 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    div {
      margin: 16px 0 8px 0;
    }
    /deep/.el-checkbox-group {
      margin: 0px;
      .el-checkbox {
        width: 100%;
        margin: 4px 0;
      }
    }
  }
}
.form {
  /deep/.ant-anchor-ink::before {
    width: 0px;
  }
  .el-form-item {
    /deep/.el-form-item__label {
      font-size: 16px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
    }
    .paixu {
      width: 240px;
    }
  }
}
.item {
  // width: 236px;
  width: 256px;
  height: 277px;
  margin-bottom: 43px;
  .item-title {
    // font-size: 18px;
    font-size: 16px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    // height: 42px;
    height: auto;
    margin-top: 5px;
    // 三行...
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .item-date {
    margin-top: 8px;
    font-size: 14px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    a:hover {
      color: #e52e00;
    }
  }
}
.mobile {
  .item {
    width: 100%;
    height: auto;
    margin-bottom: 43px;
    .item-title {
      // font-size: 18px;
      font-size: 16px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.85);
      line-height: 20px;
      // height: 42px;
      height: auto;
      margin-top: 5px;
      // 三行...
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .item-date {
      margin-top: 8px;
      font-size: 14px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      a:hover {
        color: #e52e00;
      }
    }
  }
}
/deep/.ant-list-pagination {
  margin: 13px 0 14px 0;
  text-align: center;
}
.shaiXuanMore {
  position: absolute;
  right: 15px;
  top: 85px;
}
</style>
<style lang="less">
.quickTakes {
  .ant-list-item {
    margin-bottom: 0 !important;
  }
  .ant-col-8 {
    border-right: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    padding: 24px 24px 24px 24px;
  }
  .ant-col-8:nth-of-type(3n-3) {
    border-right: none;
  }
  .ant-col-8:nth-of-type(7) {
    border-bottom: none;
  }
  .ant-col-8:nth-of-type(8) {
    border-bottom: none;
  }
  .ant-col-8:nth-of-type(9) {
    border-bottom: none;
  }
  .ant-col-8:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.2) inset;
  }
}
</style>
