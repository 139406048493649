import Index from '@/views/index/Index.vue'
import ArticleParticulars from '@/views/index/particulars/ArticleParticulars.vue'

export default [
  {
    path: '/index',
    name: '首页',
    component: Index,
  },
  {
    path: '/article-particulars',
    name: '专家详情页',
    component: ArticleParticulars,
  },
]
