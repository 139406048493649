import Personal from '@/views/personal/Personal.vue'
import Register from '@/views/personal/Register'

export default [
  {
    path: '/personal',
    name: '个人中心',
    component: Personal,
  },
  {
    path: '/register',
    name: '注册',
    component: Register,
  },
]
