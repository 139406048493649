import { message, notification } from 'ant-design-vue'
import { DatePicker } from 'element-ui'
import moment from 'moment'
import Vue from 'vue'

Vue.component(DatePicker.name, {
  props: {
    pickerOptions: {
      type: Object,
      default() {
        return {
          disabledDate(time) {
            let now = Date.now()
            let minDate = moment('2011-01-01', 'YYYY-MM-DD')
            return time.getTime() > now || time.getTime() < minDate
          },
        }
      },
    },
    format: {
      type: String,
      default() {
        return 'yyyy-MM-DD'
      },
    },
  },
  extends: DatePicker,
})

Vue.prototype.$message = message
message.config({
  duration: 2.5,
  top: '30%',
  maxCount: 3,
})
Vue.prototype.$notification = notification
notification.config({
  top: '110px',
})
