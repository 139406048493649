<template>
  <div :class="{ theme2: theme2 }">
    <div class="home-header">
      <MainCenter class="fr header">
        <a-icon v-if="$isMobile" type="menu" @click="menuVisible = !menuVisible" class="menu-icon" />
        <a :href="'/'">
          <img v-if="theme2" class="logo" src="../../assets/imgs/logo/logo_white.png" alt="NEJM医学前沿" />
          <!--          @click="$router.push('/')"-->

          <img
            v-else
            class="logo"
            src="../../assets/imgs/logo/logo.png"
            alt="NEJM医学前沿"
            @click="$router.push('/')"
          />
        </a>
        <a-menu
          v-if="!$isMobile"
          v-model="current"
          mode="horizontal"
          style="border-bottom: none; font-size: 22px; padding-top: 0; line-height: 72px"
          @click="onMenuClick"
        >
          <a-menu-item key="/index"><a :href="'/'">首页</a></a-menu-item>
          <a-menu-item key="qk">
            <a-popover style="display: block" trigger="hover" v-model="qkVisible" placement="bottom" @click.stop="">
              <a class="head-txt">期刊</a>
              <template slot="content">
                <TitleNav :title="qkNavs[0].title" :navs="qkNavs[0].navs" @nav-click="onNavClick('qk', $event)">
                  <template #action>
                    <a-popover placement="rightTop" v-model="yearVisible">
                      更多
                      <template slot="content">
                        <div class="fc" style="margin: -6px -16px">
                          <div
                            v-for="item of moreYears"
                            :key="item.text"
                            class="qk-year"
                            @click="onNavClick('qk', item)"
                          >
                            <span>
                              <a :href="'' + item.path">{{ item.text }}年</a>
                            </span>
                          </div>
                        </div>
                      </template>
                    </a-popover>
                  </template>
                </TitleNav>
                <TitleNav :title="qkNavs[1].title" :navs="qkNavs[1].navs" @nav-click="onNavClick('qk', $event)" />
                <TitleNav :title="qkNavs[2].title" :navs="qkNavs[2].navs" @nav-click="onNavClick('qk', $event)" />
                <TitleNav :title="qkNavs[3].title" :navs="qkNavs[3].navs" @nav-click="onNavClick('qk', $event)" />
              </template>
            </a-popover>
          </a-menu-item>
          <a-menu-item key="/professional-article-list">
            <a-dropdown :trigger="['click', 'hover']">
              <a class="head-txt" @click.stop="(e) => e.preventDefault()">专业领域</a>
              <a-menu slot="overlay">
                <a-menu-item
                  v-for="item of options"
                  :key="item.text"
                  @click="onNavClick('/professional-article-list', item)"
                >
                  <span>
                    <a :href="'' + item.path">{{ item.text }}</a>
                  </span>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-menu-item>
          <a-menu-item key="/animation-list"><a :href="'/animation-list'">动画解读</a></a-menu-item>
          <!--          <a-menu-item key="/audio-list">音频</a-menu-item>-->
          <a-menu-item>
            <a href="http://med-humanity.nejmqianyan.cn/" target="_blank">医学人文</a>
          </a-menu-item>
          <a-menu-item key="/course-resources"><a :href="'/course-resources'">课程</a></a-menu-item>
          <a-menu-item key="/about">
            <a :href="'/about'">关于我们</a>
          </a-menu-item>
        </a-menu>
        <a-drawer v-else placement="left" :visible="menuVisible" @close="menuVisible = !menuVisible">
          <a-menu v-model="current" style="border-right: none; font-size: 20px" @click="onMenuClick">
            <a-menu-item key="/index">首页</a-menu-item>
            <a-menu-item v-for="item of qkNavs" :key="item.path">
              <a @click.stop="onDrawerMenuClick(item.path, item.navs)">{{ item.title }}</a>
            </a-menu-item>
            <a-menu-item key="/professional-article-list">
              <a @click.stop="onDrawerMenuClick('/professional-article-list', options)">专业领域</a>
            </a-menu-item>
            <a-menu-item key="/animation-list">动画解读</a-menu-item>
            <!--            <a-menu-item key="/audio-list">音频</a-menu-item>-->
            <a-menu-item key="/clinical-research-list">临床研究论文撰写</a-menu-item>
            <a-menu-item key="/course-resources">课程</a-menu-item>
            <a-menu-item key="/about">关于我们</a-menu-item>
          </a-menu>
          <a-drawer placement="left" :visible="subMenuVisible" @close="subMenuVisible = !subMenuVisible">
            <a-menu v-model="subMenuCurrent" style="border-right: none; font-size: 20px">
              <a-menu-item v-for="item of subMenuOptions" :key="item.text" @click="onNavClick(subMenuCurrent[0], item)">
                {{ item.text }}
              </a-menu-item>
            </a-menu>
          </a-drawer>
        </a-drawer>
        <!--        <a-icon type="search" @click="showSearch = !showSearch" class="search-icon" />-->
        <!--        <div class="search-icon" @click="showSearch = !showSearch">-->
        <!--          <i class="iconfont icon-sousuo search-icon" @click="showSearch = !showSearch"></i>-->
        <!--        </div>-->
        <i class="iconfont icon-sousuo1 search-icon" @click="ifShowSearch"></i>
        <div style="width: 50px; display: flex; justify-content: center">
          <i
            v-if="!isOutLinkLogin"
            class="iconfont icon-user-copy user-icon"
            @click="showModal"
            :class="{ active: isLogin }"
          >
            <div class="icon-name" v-if="isLogin && !$isMobile">
              <!--              <el-tag :color="theme2 ? 'white' : 'black'" size="mini">-->
              {{ user.truename ? user.truename : user.membername }}
              <!--              </el-tag>-->
            </div>
          </i>
          <el-button v-if="isOutLinkLogin && !$isMobile" type="text" style="font-size: 14px" @click="logout">
            退出
          </el-button>
        </div>
        <UserLogin
          ref="userLogin"
          style="padding-top: 20px"
          :info="info"
          :visible.sync="visible"
          :status="loginStatus"
        ></UserLogin>
        <el-button v-if="isOutLinkLogin && $isMobile" type="text" class="out-login-bt" @click="logout">退出</el-button>
      </MainCenter>
    </div>
    <div class="fr search-c" :style="showSearch ? 'height: 70px;' : 'height: 0px;'">
      <BzSearch ref="bzSearch" @search="onSearch" :showSearch="showSearch" style="width: 880px" />
    </div>
  </div>
</template>

<script>
import BzSearch from '@/components/form/BzSearch.vue'
import TitleNav from '@/components/home/components/TitleNav.vue'
import UserLogin from '@/components/home/components/UserLogin'
import { nejm, Catalyst, nejmQkhc } from '@/components/home/navs.js'
import MainCenter from '@/components/layout/MainCenter.vue'
import {
  PATH_NEJM_LIST,
  PATH_NEJM_CATALYST_LIST,
  PATH_NEJM_PERIODICAL_LIST,
  PATH_PERIOD_ARTICLE,
  PATH_PROFESSIONAL_ARTICLE_LIST,
} from '@/router/article/paths.js'
import { PATH_SEARCH } from '@/router/search/paths.js'
import { getSessionUser, logoutLocal } from '@/utils/app-utils'
import moment from 'moment'
import { mapMutations, mapState } from 'vuex'
import { selectArticleClass } from '@/api/article'
import { generateHref } from '@/utils/utils'

const qkPaths = [PATH_PERIOD_ARTICLE, PATH_NEJM_LIST, PATH_NEJM_CATALYST_LIST, PATH_NEJM_PERIODICAL_LIST]

export default {
  name: 'HomeHeader',
  components: { TitleNav, MainCenter, BzSearch, UserLogin },
  data() {
    return {
      current: [],
      options: [],
      qkVisible: false,
      yearVisible: false,
      showSearch: false,
      visible: false,
      loginStatus: 'unLogin',
      info: {},
      menuVisible: false,
      subMenuVisible: false,
      subMenuOptions: [],
      subMenuCurrent: [],
    }
  },
  async created() {
    this.$bus.on('login', this.showModal)
    // this.isOutLinkLogin = JSON.stringify(getSessionUser()) == '{}'
    this.changeActiveNav()
    await this.selectArticleClass()
  },
  beforeDestroy() {
    this.$bus.off('login')
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    moreYears() {
      let year = moment().year()
      let i = year
      let res = []
      while (year - i < 11) {
        res.unshift({ text: `${i}`, path: `${PATH_PERIOD_ARTICLE}?year=${i}` })
        i--
      }
      return res
    },
    qkNavs() {
      let navs = this.moreYears.slice(5).reverse()
      console.log('navs::', navs)
      return [
        {
          title: '按期浏览',
          navs,
        },
        nejm,
        Catalyst,
        nejmQkhc,
      ]
    },
    isLogin() {
      return this.user != null
    },
    isOutLinkLogin() {
      return JSON.stringify(this.user) == '{}'
    },
    theme2() {
      return this.$route.meta.theme2
    },
  },
  watch: {
    $route() {
      this.changeActiveNav()
    },
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    logout() {
      console.log('headLoginOut::')
      logoutLocal()
      this.setUser(null)
      if (this.$route.path === '/personal') {
        this.$router.push('/')
      }
    },
    generate(item) {
      return generateHref('permalinks', item)
    },
    changeActiveNav() {
      let currentNav = this.$route.path
      if (qkPaths.includes(currentNav)) {
        // 期刊导航特殊处理
        currentNav = 'qk'
      }
      if (currentNav === '/') {
        currentNav = '/index'
      }
      this.current = [currentNav]
    },
    onNavClick(current, item) {
      if (item.path) {
        this.qkVisible = false
        this.yearVisible = false
        this.$router.push({ path: item.path })
        this.current = [current]
        this.menuVisible = false
        this.subMenuVisible = false
      }
    },
    onMenuClick({ key }) {
      if (key.startsWith('/')) {
        this.$router.push({ path: key })
        this.menuVisible = false
      }
    },
    onSearch(query) {
      this.showSearch = false
      this.$router.push({ path: `${PATH_SEARCH}?query=${query}` })
      this.menuVisible = false
      this.$refs.bzSearch.hide()
    },
    async showModal() {
      console.log(getSessionUser())
      this.info = getSessionUser() == null ? {} : getSessionUser()

      this.loginStatus = !this.isLogin || getSessionUser() == null ? 'unLogin' : 'logined'
      if (this.loginStatus == 'unLogin') {
        this.$refs.userLogin.accountName = null
        this.$refs.userLogin.password = null
      }
      // console.log('BBB', this.info, this.info == {}, this.loginStatus, this.isLogin)

      this.visible = true
    },
    onDrawerMenuClick(current, options) {
      this.subMenuOptions = options
      this.subMenuCurrent = [current]
      this.subMenuVisible = true
    },
    async selectArticleClass() {
      let res = await selectArticleClass()
      let arr = []
      for (const item of res) {
        if (item.classname == '心脑血管疾病') {
          arr.push({ text: '心脑血管疾病', path: `${PATH_PROFESSIONAL_ARTICLE_LIST}?code=10` })
        }
        if (item.classname == '肿瘤') {
          arr.push({ text: '肿瘤', path: `${PATH_PROFESSIONAL_ARTICLE_LIST}?code=20` })
        }
        if (item.classname == '糖尿病') {
          arr.push({ text: '糖尿病', path: `${PATH_PROFESSIONAL_ARTICLE_LIST}?code=30` })
        }
        if (item.classname == '呼吸系统疾病') {
          arr.push({ text: '呼吸系统疾病', path: `${PATH_PROFESSIONAL_ARTICLE_LIST}?code=40` })
        }
        if (item.classname == '妇产科和儿科') {
          arr.push({ text: '妇产科和儿科', path: `${PATH_PROFESSIONAL_ARTICLE_LIST}?code=50` })
        }
        if (item.classname == '传染病') {
          arr.push({ text: '传染病', path: `${PATH_PROFESSIONAL_ARTICLE_LIST}?code=70` })
        }
        if (item.classname == '其他') {
          arr.push({ text: '其他', path: `${PATH_PROFESSIONAL_ARTICLE_LIST}?code=60` })
        }
      }
      this.options = arr
    },
    ifShowSearch() {
      if (this.$route.path !== '/search') {
        this.showSearch = !this.showSearch
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.home-header {
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.15);

  .fr {
    height: 78px;
    align-items: center;
  }

  .logo {
    // width: 136px;
    height: 60px;
    margin-right: 55px;
    margin-top: 0;
    cursor: pointer;
  }
}

.search-icon {
  margin: 6px 0 0 16px;
  font-size: 18px;
  padding-top: 0;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.user-icon {
  position: relative;
  margin-left: auto;
  margin-top: 6px;
  font-size: 18px;
  padding-top: 0;
  cursor: pointer;
  width: 22px;
  height: 22px;
  &.active {
    color: @clr-primary;
  }
}
.icon-name {
  width: 122px;
  position: absolute;
  top: 0;
  right: -126px;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;
  line-height: 1.5rem;
  margin-top: -3px;
}
.search-c {
  overflow: hidden;
  height: 0px;
  align-items: center;
  justify-content: center;
  transition: height 0.3s;
  background-color: #fff;

  &.show {
    height: 70px;
  }
}

.qk-year {
  padding: 5px 16px;
  cursor: pointer;

  &:hover {
    color: @clr-primary;
    background-color: lighten(@clr-primary, 50%);
  }
}

.info-img {
  width: 40%;
}

.info-img img {
  width: 95px;
  height: 95px;
  border-radius: 100px;
  border: solid 1px #d8372d;
}
.head-txt {
}
.head-txt:hover {
  font-weight: 500;
}
.info-ct {
  width: 60%;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 4px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #e52e00;
  border-bottom-color: #e52e00;
  font-weight: 500;
  border-bottom: 4px solid #e52e00;
}
// theme2
.theme2 {
  .home-header {
    background-color: #5b6770;
    color: #fff;
  }
  .ant-menu {
    background-color: #5b6770;
    color: #fff;
  }
  .ant-menu-horizontal > .ant-menu-item > a {
    color: #fff;
  }
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: #fff;
    border-bottom-color: #fff;
    font-weight: 500;
  }
  .icon-name {
    width: 142px;
    position: absolute;
    top: 0;
    right: -146px;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white;
    line-height: 1.5rem;
    margin-top: -3px;
  }
}

// 移动端样式
.mobile {
  .home-header {
    .fr {
      justify-content: center;
      height: 50px;
    }

    .logo {
      width: 72px;
      height: 32px;
      margin: 0;
      margin-left: 40px;
    }
  }

  .menu-icon {
    position: absolute;
    left: 18px;
    font-size: 18px;
  }

  .search-icon {
    position: absolute;
    right: 50px;
    margin: 0;
    padding-top: 0;
  }

  .user-icon {
    position: absolute;
    margin-top: 11px;
    right: 18px;
    top: 3px;
    padding-top: 0;

    &.active {
      color: @clr-primary;
    }
  }
  .out-login-bt {
    position: absolute;
    margin-top: 13.5px;
    right: 20px;
    top: 3px;
    padding-top: 0;
  }
}
</style>

<style lang="less">
@import '~@/assets/less/variables.less';

.ant-drawer-body {
  padding: 5px 0;
}

.ant-menu-item {
  padding: 0 15px;
  //border-bottom: 1px solid @clr-border;
  //margin: 0;
}
</style>
