import About from '@/views/about/About.vue'
import Download from '@/views/about/Download.vue'

export default [
  {
    path: '/about',
    name: '关于我们',
    component: About,
  },
  {
    path: '/download',
    name: 'APP下载',
    component: Download,
  },
]
