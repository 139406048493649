<template>
  <div style="width: 100%; min-height: 28px">
    <el-button class="share" @click="navigation" v-show="isShow.navigation" type="text">
      <i class="iconfont icon-daohang"></i>
      导航
    </el-button>
    <el-button class="share" @click="collect" v-show="isShow.collect" type="text">
      <i
        class="iconfont"
        :class="{ 'icon-shoucang1': ifCollect === 0, 'icon-shoucang2 shoucang': ifCollect !== 0 }"
      ></i>
      收藏
    </el-button>
    <!-- <el-button class="share" @click="share" v-show="isShow.share" type="text">
      <i class="iconfont icon-fenxiang"></i>
      分享
    </el-button> -->
    <el-popover v-if="!$isMobile" placement="bottom" width="40" trigger="click" class="share" v-show="isShow.share">
      <div class="fr">
        <img class="share-icon" src="../../assets/imgs/weixin.png" width="32" @click="shareWX" />
        <img class="share-icon" src="../../assets/imgs/weibo.png" width="32" @click="shareXL" />
      </div>
      <el-button type="text" slot="reference" style="font-size: 16px; color: #fff">
        <i class="iconfont icon-fenxiang" style="font-size: 16px; color: #fff"></i>
        分享
      </el-button>
    </el-popover>
    <el-button v-else type="text" slot="reference" style="font-size: 16px; color: #fff" @click="shareFX">
      <i class="iconfont icon-fenxiang" style="font-size: 16px; color: #fff"></i>
      分享
    </el-button>
    <el-button class="share" icon="el-search" @click="original" v-show="isShow.original && outlink !== ''" type="text">
      <i class="iconfont icon-yuanwenchuandi"></i>
      原文
    </el-button>
    <CodeDialog v-if="dialogVisible" :visible.sync="dialogVisible" :codeUrl="codeUrl" />
    <FengXiang v-if="dialogVisible1" :visible.sync="dialogVisible1" :isweixin="isweixin" />
  </div>
</template>

<script>
import {
  cancelCollect,
  cancelCollectLesson,
  cancelRelease,
  collectArticle,
  collectLesson,
  collectRelease,
} from '@/api/user-api.js'
import CodeDialog from '@/components/share/CodeDialog'
import FengXiang from '@/components/share/FengXiang'
import { mapState } from 'vuex'

const collectApi = {
  article: collectArticle,
  lesson: collectLesson,
  meeting: collectRelease,
}

const cancelCollectApi = {
  article: cancelCollect,
  lesson: cancelCollectLesson,
  meeting: cancelRelease,
}

export default {
  name: 'share',
  components: {
    CodeDialog,
    FengXiang,
  },
  props: {
    isShow: {
      type: Object,
      default: function () {
        return { navigation: true, collect: true, share: true, original: true }
      },
    },
    id: {
      type: Number,
      default: 0,
    },
    ifCollect: {
      type: Number,
      default: 0,
    },
    // 收藏类型
    // article  文章类型
    // lesson   课程类型
    // meeting  会议类型
    collectType: {
      type: String,
      required: true,
      // default: 'article',
    },
    outlink: {
      type: String,
      default: '',
    },
    query: {
      type: String,
      default: '',
    },
    appQuery: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      codeUrl: '',
      isweixin: '',
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    navigation() {
      console.log('导航')
    },
    async collect() {
      if (this.user) {
        if (this.ifCollect === 0) {
          //收藏
          let res = await collectApi[this.collectType]({ id: this.id })
          if (res) {
            this.$emit('update:ifCollect', 1)
          }
        } else {
          //取消收藏
          let res = await cancelCollectApi[this.collectType]({ id: this.id })
          if (res) {
            this.$emit('update:ifCollect', 0)
          }
        }
      } else {
        this.$bus.emit('login')
      }
    },
    shareWX() {
      this.codeUrl = window.location.href + '&sg=AbW1N&bsh_bid=5654238221'
      this.dialogVisible = true
    },
    shareFX() {
      var ua = window.navigator.userAgent.toLowerCase()
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      //判断是否chorme浏览器
      if (ua.indexOf('micromessenger') > -1 || ua.indexOf('crios') > -1) {
        this.isweixin = '1'
        //判断是否Safari浏览器
      } else if (ua.indexOf('mac') > -1) {
        this.isweixin = '2'
      } else {
        this.isweixin = ''
      }
      this.dialogVisible1 = true
    },
    shareXL(title, url, pic) {
      var param = {
        url: url || window.location.href,
        type: '3',
        count: '1' /** 是否显示分享数，1显示(可选)*/,
        appkey: '' /** 您申请的应用appkey,显示分享来源(可选)*/,
        title: '' /** 分享的文字内容(可选，默认为所在页面的title)*/,
        pic: pic || '' /**分享图片的路径(可选)*/,
        ralateUid: '' /**关联用户的UID，分享微博会@该用户(可选)*/,
        rnd: new Date().valueOf(),
      }
      var temp = []
      for (var p in param) {
        temp.push(p + '=' + encodeURIComponent(param[p] || ''))
      }
      var targetUrl = 'http://service.weibo.com/share/share.php?' + temp.join('&')
      window.open(targetUrl, 'sinaweibo', 'height=430, width=400')
    },
    original() {
      if (!this.$isMobile) {
        window.open(this.outlink + this.query)
      } else {
        window.open(this.outlink + this.appQuery)
      }
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.share {
  margin: 9px 30px 9px 0;
  color: #fff !important;
  font-size: 16px;
  // i::before {
  //   font-size: 16px;
  // }
}
.el-button {
  color: rgba(0, 0, 0, 0.65);
}
.fr {
  align-items: center;
  justify-content: space-around;
  .share-icon {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}
.mobile {
  .share {
    margin: 6px 15px;
  }
}
.shoucang {
  font-size: 16px;
  color: #e52e00;
}
</style>
