<template>
  <div class="box">
    <div class="fr">
      <h3 class="title">{{ title }}</h3>
      <a v-if="hasMore" :href="'/latest-periodical'" type="link" size="small" class="more" @click.prevent="more">
        更多
        <!-- <a-icon type="right" /> -->
      </a>
    </div>
    <a-list :grid="grid" :data-source="list">
      <a-list-item slot="renderItem" slot-scope="item" style="margin-bottom: 8px">
        <div class="item">
          <div class="item-filter">
            {{ item.typeName }}
          </div>
          <div class="ellipsisTwo">
            <a
              :href="'/article-info' + generate(item.permalinks)"
              class="item-title"
              @click.prevent="$emit('item-click', item)"
            >
              {{ item.title }}
            </a>
          </div>
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import { generateHref } from '@/utils/utils'

export default {
  name: 'PeriodicalBox',
  props: {
    title: {
      type: String,
    },
    list: {
      type: Array,
      required: true,
    },
    hasMore: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {}
  },
  created() {},
  computed: {
    grid() {
      let column = 1
      // if (this.$isMobile) {
      //   column = 1
      // }
      return { gutter: 40, column }
    },
  },
  methods: {
    generate(item) {
      return generateHref('permalinks', item)
    },
    more() {
      this.$emit('more')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.box {
  margin-top: 32px;
  .item {
    line-height: 1.5;
    width: 100%;
    height: auto;
  }
  .item-title {
    height: 4em;
    cursor: pointer;
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    overflow: hidden;
    //white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    color: #666666;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
    &:hover {
      color: @clr-primary;
    }
  }
  .item-filter {
    font-size: 14px;
    margin-top: 0;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 700;
    float: left;
    width: 160px;
  }
}

.fr {
  margin-bottom: 16px;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #414347;
  padding-bottom: 16px;
  .title {
    font-size: 18px;
    color: #e52e00;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 700;
  }
  .more {
    font-size: 18px;
    color: #1e4ea6;
    letter-spacing: 0;
    text-align: right;
    line-height: 26px;
    font-weight: 400;
  }
  .more:hover {
    color: #e52e00;
  }
}
// .ellipsisTwo {
//   text-overflow: -o-ellipsis-lastline;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 1;
//   line-clamp: 1;
//   -webkit-box-orient: vertical;
// }
// 移动端样式
.mobile {
  .box {
    margin-top: 24px;
    .item {
      line-height: 1.5;
      width: 100%;
      height: 100%;
    }
    .item-filter {
      font-size: 16px;
      width: 145px;
    }
  }
  .item {
    // padding: 0 10px;
  }
  .fr {
    .title {
      font-size: 18px;
    }
    .more {
      font-size: 16px;
    }
  }
}
</style>
