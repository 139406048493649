/**
 * 弹层类
 */
import {Loading, Message, MessageBox} from "element-ui";
import { Dialog } from 'vant';

export const loading = function (message, options){
    let default_options = {
        text: message
        ,spinner: 'el-icon-loading'
        ,customClass: 'moe_loadings'
        ,background: 'rgba(0, 0, 0, 0.7)'
    };
    return Loading.service(Object.assign({}, default_options, options));
}

export const toast = function (message, options){
    let default_options = {
        message
    };
    return Message(Object.assign({}, default_options, options));
}

export const alert = function (message, options){
    let default_options = {
        title: '',
        message
    };
    return Dialog.alert(Object.assign({}, default_options, options))
}

export const confirmPC = function (message, title, options){
    let default_options = {

    };
    return MessageBox.confirm(message, title, Object.assign({}, default_options, options))
}
