import http from '@/http/index'

/**
 * 获取轮播图
 * @param params：{
 *}
 */
export function getBanner(params) {
  return http.post('/api/base/getBanner', params)
}
/**
 * 科室下拉
 * @param params：{
 *}
 */
export function selectDivision(params) {
  return http.post('/api/base/selectDivision', params)
}
