<template>
  <el-select :value="value" placeholder="请选择学历" v-bind="$attrs" v-on="$listeners">
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'DatasetSelect',
  props: {
    value: {
      required: true,
    },
    size: {
      type: String,
      default() {
        return 'medium'
      },
    },
    params: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: [],
    }
  },
  watch: {
    params: {
      handler() {
        this._loadData()
      },
      immediate: true,
    },
  },
  methods: {
    _loadData() {
      if (this.params == null) {
        this.options = []
      } else {
        this.options = [
          { label: '大专以下', value: '大专以下' },
          { label: '大专', value: '大专' },
          { label: '本科', value: '本科' },
          { label: '硕士', value: '硕士' },
          { label: '博士', value: '博士' },
          { label: '博士后', value: '博士后' },
        ]
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
