<template>
  <el-dialog :before-close="_handleClose" @close="_handleClose" v-bind="$attrs" v-on="$listeners" class="changeDialog">
    <div class="ligin-title">
      <img class="logo" src="../../../assets/imgs/logo/logo.png" alt="NEJM医学前沿" />
    </div>
    <div class="tabs" @click="changeTab('mobile')">
      <hr class="hrBorder" />
      <img class="tabImg" src="../../../assets/imgs/shouji1.png" />
      <hr class="hrBorder" />
    </div>
    <a-form-model :model="mobileParams" :rules="rules" ref="ruleForm" label-width="30px">
      <a-form-model-item prop="mobile">
        <a-input v-model="mobileParams.mobile" placeholder="输入新手机号">
          <a-icon slot="prefix" type="mobile" />
        </a-input>
      </a-form-model-item>
      <div style="position: relative">
        <a-form-model-item prop="code" class="mobiledialog">
          <a-input v-model="mobileParams.code" placeholder="输入验证码">
            <a-icon slot="prefix" type="safety-certificate" />
          </a-input>
        </a-form-model-item>
        <a-button :disabled="timeCount > 0" class="send" @click="sendCodeOpt">
          发送验证码
          <span class="time">{{ timeCount }}</span>
        </a-button>
      </div>
      <a-form-model-item class="submitContainer">
        <a-button type="primary" style="width: 150px" @click="submit">确认</a-button>
      </a-form-model-item>
    </a-form-model>
    <el-dialog
      title="图片验证码"
      append-to-body
      :visible.sync="centerDialogVisible"
      :destroy-on-close="true"
      style="width: 100%"
      center
    >
      <el-row>
        <el-col :style="!$isMobile ? 'width:33%' : 'width:50%'">
          <img id="baseimg" @click="getImg" :src="imgsrc" alt="" />
        </el-col>
        <el-col :style="!$isMobile ? 'width:67%' : 'width:50%'" class="imgput">
          <el-input v-model="uucode" placeholder="请输入计算后的结果"></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            centerDialogVisible = false
            sendcodestart()
          "
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { sendCode2, updatePhoneNoOrEmail } from '@/api/personal'
import { sendimgCode } from '@/api/login'
// import { checkCode } from '@/api/login'
export default {
  name: 'ChangeMobileDialog',
  props: {
    mobileVisible: {
      type: Boolean,
      default() {
        return false
      },
    },
    mobileParams: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    let validatePhone = (rule, value, callback) => {
      var isPhone = /^1\d{10}$/
      if (!isPhone.test(value)) {
        callback(new Error('手机输入不正确，请重新输入'))
      } else {
        this.flag = true
        callback()
      }
    }
    return {
      imgsrc: '',
      uuid: '',
      uucode: '',
      centerDialogVisible: false,
      rules: {
        mobile: [
          { required: true, message: '请输入新手机号', trigger: 'blur' },
          { required: true, validator: validatePhone, trigger: 'blur' },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      timeCount: null,
      flag: false, //假不发送，真才发送验证码
      disableRepeatSend: false, //禁止狂点
    }
  },
  watch: {
    timeCount: {
      handler() {
        if (this.timeCount == -1) {
          clearInterval(this.timer)
          this.timeCount = null
          this.disableRepeatSend = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getImg() {
      var _this = this
      let res = await sendimgCode({})
      _this.imgsrc = 'data:image/jpeg;base64,' + res.img
      _this.uuid = res.uuid
    },
    _handleClose() {
      this.$emit('update:visible', false)
      this.$refs.ruleForm.resetFields()
    },
    sendCodeOpt() {
      var _this = this
      if (this.mobileParams.mobile == null || this.mobileParams.mobile == '') {
        return this.$message.error('请输入手机号')
      }
      _this.centerDialogVisible = true
      _this.getImg()
    },
    async sendcodestart() {
      var params = {}
      if (!this.disableRepeatSend) {
        if (this.mobileParams.mobile !== null && this.flag) {
          params.phoneNo = this.mobileParams.mobile
          params.uuid = this.uuid
          params.code = this.uucode
          let res = await sendCode2(params)
          if (res) {
            this.disableRepeatSend = true
            this.timeCount = 59
            this.timer = setInterval(() => {
              this.timeCount--
            }, 1000)
            this.$message.success('发送成功')
          }
        }
      }
    },
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // let params = {
          //   phoneNo: this.mobileParams.mobile,
          //   code: this.mobileParams.code,
          // }
          // let isCorrectCode = await checkCode(params)
          let res = await updatePhoneNoOrEmail(this.mobileParams)
          console.log(res, 'res')
          if (res) {
            this.$message.success('修改手机号成功')
            this._handleClose()
            this.$emit('editAvatar')
            this.$bus.emit('refresh-page')
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.imgput {
  /deep/.el-input,
  /deep/.el-input__inner {
    height: 60px;
    line-height: 60px;
  }
}
.ligin-title {
  font-size: 16px;
  width: 100%;
  .logo {
    width: 100px;
  }
}
.tabs {
  display: flex;
  align-items: center;
  position: relative;
  float: left;
  width: 100%;
  height: 75px;
  .tabImg {
    position: relative;
    width: 48px;
    z-index: 1000;
  }
  .hrBorder {
    flex: 1;
    background-color: #e52e00;
    height: 4px;
    border: none;
  }
}
.send {
  width: 120px;
  position: absolute;
  top: 4px;
  right: 0;
  color: #e52e00;
  border: 1px solid #e52e00;
  .time {
    display: inline-block;
    margin-left: 5px;
  }
}
.submitContainer {
  display: flex;
  justify-content: center;
}
</style>
<style lang="less">
.mobiledialog {
  width: 250px;
}
.changeDialog {
  .el-dialog__body {
    padding: 0px 40px 10px 40px;
  }
}
.mobile {
  .mobiledialog {
    width: 150px;
  }
  .changeDialog {
    .el-dialog__body {
      padding: 0px 20px 20px 20px;
    }
  }
}
</style>
