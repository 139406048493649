<template>
    <div class="member_ali_pay_box">
        <div class="back_btn" @click="backPersonal">返回个人中心</div>
        <div  v-html="aliWapPayForm"></div>
    </div>
</template>

<script>
import axios from "axios";
import * as dialogUtils from "../../utils/dialog-utils";

export default {
    name: "MemberAliPay",
    data(){
        return {
            qrcode_id: '',
            token: '',

            loading: null,

            service_id: '',
            service_type: null,

            aliWapPayForm: "",
        }
    },
    created() {
        let params = this.$route.params;
        this.token = params.token;
        this.qrcode_id = params.qrcode_id;
    },
    mounted() {
        this.loading = dialogUtils.loading("调起支付中...");
        this.getQrcodeInfo();
    },
    methods: {
        getQrcodeInfo(){
            let url = this.$Config.API_PATH_PHP + '?c=qrcode&m=getInfo&id=' +this.qrcode_id;
            axios.get(url).then(res => {
                if(res.data.result == 1){
                    let data = res.data.data;
                    let dataResultData = JSON.parse(data.result_data)

                    if(data.status == '0' && dataResultData){
                        this.service_id = dataResultData.service_id;

                        this.updateQrcodeInfo();
                        this.getServiceInfo();
                    }else{
                        this.loading.close();
                        dialogUtils.alert("二维码已失效，请刷新二维码重新扫码")
                    }
                }else{
                    this.loading.close();
                    dialogUtils.alert(res.data.msg)
                }
            })
        },
        updateQrcodeInfo(){
            axios.get(this.$Config.API_PATH_PHP + '?c=qrcode&m=updateStatus&id=' +this.qrcode_id + '&status=1');
        },

        /** 获取服务信息 */
        getServiceInfo(){
            let url = this.$Config.API_PATH_PHP + '?c=program&m=getServiceInfo&id=' + this.service_id;
            axios.get(url).then(res => {
                if(res.data.result =='1'){
                    this.service_type = res.data.data.type;
                    this.aliWapPay();
                }else{
                    // 报错
                    this.loading.close();
                    this.$toast(res.data.msg);
                }
            })
        },

        /** 支付寶H5支付 */
        aliWapPay(){
            let model = 'memberOrderWapPay';
            if(this.service_type == 3 || this.service_type == 4) model = 'memberOrderSignPay'

            let url = this.$Config.API_PATH_PHP + '?c=alipay&m=' + model;
            url += '&service_id=' + this.service_id;
            url += '&sf=' + 'pc';
            url += '&token=' + this.token;
            axios.get(url).then(res => {
                if(res.data.result =='1'){
                    this.aliWapPayForm = res.data.form;
                    this.$nextTick(()=>{
                        this.loading.close();
                        document.forms['alipaysubmit'].submit();
                    })
                }else{
                    // 报错
                    this.loading.close();
                    this.$toast(res.data.msg);
                }
            })
        },
        backPersonal(){
            this.$router.push({ path:'/personal', query:{ type: 'flag1' } }).catch((err) => {
                console.log('输出报错', err)
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .member_ali_pay_box{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;

        .back_btn{
            width: 144px;
            height: 50px;
            border: 1px solid rgba(51, 51, 51, 1);
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #333333;
            font-weight: 400;
            cursor: pointer;
            // padding: 10px 50px;
        }
    }
</style>
