<template>
  <div class="" v-bind="$attrs" v-on="$listeners">
    <a-modal v-model="visible" width="600px" title="" footer="" @cancel="_close()">
      <div style="margin-top: 30px; display: flex; align-items: center; justify-content: center">
        <div style="width: 80%; margin-left: 10%; margin-right: 10%">
          <div class="ligin-title">
            <img class="logo" src="../../../assets/imgs/logo/logo.png" alt="NEJM医学前沿" />
          </div>
          <div>
            <div class="tabs" @click="changeTab('mobile')">
              <hr class="hrBorder" :style="activeTab == 'mobile' ? '' : 'background-color: #8a8a8a;'" />
              <img v-if="activeTab == 'mobile'" class="tabImg" src="../../../assets/imgs/shouji1.png" />
              <img v-else class="tabImg" src="../../../assets/imgs/shouji.png" />
              <hr class="hrBorder" :style="activeTab == 'mobile' ? '' : 'background-color: #8a8a8a;'" />
            </div>
            <div class="tabs" @click="changeTab('email')">
              <hr class="hrBorder" :style="activeTab == 'email' ? '' : 'background-color: #8a8a8a;'" />
              <img v-if="activeTab == 'email'" class="tabImg" src="../../../assets/imgs/youxiang1.png" />
              <img v-else class="tabImg" src="../../../assets/imgs/youxiang.png" />
              <hr class="hrBorder" :style="activeTab == 'email' ? '' : 'background-color: #8a8a8a;'" />
            </div>
          </div>
          <!--表单内容-->
          <a-form-model ref="form" :model="form" :rules="rules">
            <a-form-model-item v-if="activeTab == 'mobile'" prop="mobile">
              <a-input required ref="userNameInput" v-model="form.mobile" placeholder="输入手机号码">
                <a-icon slot="prefix" type="mobile" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item v-if="activeTab == 'email'" prop="email">
              <a-input ref="userNameInput" v-model="form.email" placeholder="输入邮箱地址">
                <a-icon slot="prefix" type="mail" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item style="margin-top: -10px" prop="code">
              <a-input class="valid-code" ref="code" v-model="form.code" placeholder="输入验证码">
                <a-icon slot="prefix" type="safety-certificate" />
              </a-input>
              <a-button
                :disabled="timeCount"
                @click="sendCodeOpt"
                :style="timeCount ? 'width: 120px;' : 'width: 120px; color: #e52e00; border: 1px solid #e52e00'"
              >
                发送验证码 {{ timeCount }}
              </a-button>
            </a-form-model-item>
            <a-form-model-item style="margin-top: -10px" prop="password">
              <a-input
                class="pwdInput"
                autocomplete="off"
                type="text"
                v-model="form.password"
                placeholder="设置密码(至少6位)"
              >
                <a-icon slot="prefix" type="lock" />
              </a-input>
              <a-input type="password" autocomplete="new-password" style="display: none" />
            </a-form-model-item>
            <a-form-model-item style="margin-top: -10px" prop="confirmPwd">
              <a-input
                class="pwdInput"
                type="text"
                autocomplete="off"
                v-model="form.confirmPwd"
                placeholder="再次输入密码"
              >
                <a-icon slot="prefix" type="lock" />
              </a-input>
              <a-input type="password" autocomplete="new-password" style="display: none" />
            </a-form-model-item>
            <div style="margin-top: 25px; text-align: center">
              <a-button @click="submitData('form')" style="width: 50%" type="primary">确认</a-button>
            </div>
            <el-dialog
              title="图片验证码"
              append-to-body
              :visible.sync="centerDialogVisible"
              :destroy-on-close="true"
              style="width: 100%"
              center
            >
              <el-row>
                <el-col :style="!$isMobile ? 'width:33%' : 'width:50%'">
                  <img id="baseimg" @click="getImg" :src="imgsrc" alt="" />
                </el-col>
                <el-col :style="!$isMobile ? 'width:67%' : 'width:50%'" class="imgput">
                  <el-input v-model="uucode" placeholder="请输入计算后的结果"></el-input>
                </el-col>
              </el-row>
              <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button
                  type="primary"
                  @click="
                    centerDialogVisible = false
                    sendcodestart()
                  "
                >
                  确 定
                </el-button>
              </span>
            </el-dialog>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { sendCode, forgetPasswordPhone, forgetPasswordEmail, sendimgCode } from '@/api/login'
import { message } from 'ant-design-vue'
export default {
  name: 'ForgetPwdDialog',
  props: {
    visible: {
      type: Boolean,
      default() {
        return false
      },
    },
    status: {
      type: String,
    },
    info: {
      type: Object,
    },
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('请输入密码'))
      } else if (value.length < 6) {
        callback(new Error('请至少输入6位'))
      } else {
        if (this.form.confirmPwd !== '') {
          this.$refs.form.validateField('confirmPwd')
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    let validatePhone = (rule, value, callback) => {
      var isPhone = /^1\d{10}$/
      console.log(isPhone.test(value), 'isPhone.test(value)')
      if (value === '' || value === null) {
        callback(new Error('请输入手机号码'))
      } else if (!isPhone.test(value)) {
        callback(new Error('手机输入不正确，请重新输入'))
        this.flag1 = true
      } else {
        callback()
        this.flag1 = false
      }
    }
    let validateEMail = (rule, value, callback) => {
      var isEMail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
      console.log(isEMail.test(value), 'isPhone.test(value)')
      if (value === '' || value === null) {
        callback(new Error('请输入邮箱'))
      } else if (!isEMail.test(value)) {
        callback(new Error('邮箱输入不正确，请重新输入'))
        this.flag1 = true
      } else {
        callback()
        this.flag1 = false
      }
    }
    return {
      imgsrc: '',
      uuid: '',
      uucode: '',
      centerDialogVisible: false,
      accountName: null,
      password: null,
      activeTab: 'mobile',
      form: {
        mobile: null,
        email: null,
        code: null,
        password: null,
        confirmPwd: null,
      },
      rules: {
        password: [{ validator: validatePass, trigger: 'blur' }],
        confirmPwd: [{ validator: validatePass2, trigger: 'blur' }],
        mobile: [{ validator: validatePhone, trigger: 'blur' }],
        email: [{ validator: validateEMail, trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      isCorrectCode: false,
      iconLoading: false,
      timeCount: null,
      flag1: true,
    }
  },
  watch: {
    timeCount: {
      handler() {
        if (this.timeCount == 0) {
          clearInterval(this.timer)
          this.timeCount = null
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    async getImg() {
      var _this = this
      let res = await sendimgCode({})
      _this.imgsrc = 'data:image/jpeg;base64,' + res.img
      _this.uuid = res.uuid
    },
    _close() {
      this.$emit('update:visible', false)
    },
    changeTab(value) {
      this.activeTab = value
      this.form = {
        mobile: null,
        email: null,
        code: null,
        password: null,
        confirmPwd: null,
      }
      clearInterval(this.timer)
      this.timeCount = null
      this.$refs['form'].resetFields()
    },
    submitData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.checkCode()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    sendCodeOpt() {
      var _this = this
      if (this.activeTab == 'mobile') {
        if (this.form.mobile == null || this.form.mobile == '') {
          return this.$message.error('请输入手机号')
        }
        _this.centerDialogVisible = true
        _this.getImg()
      } else if (this.currentKey == 2) {
        if (this.codeForm.email == null || this.codeForm.email == '') {
          return this.$message.error('请输入邮箱号码')
        } else {
          this.sendcodestart()
        }
      } else {
        this.sendcodestart()
      }
    },
    sendcodestart() {
      var _this = this
      var params = {}
      var flag = true
      if (this.activeTab == 'mobile') {
        params.phoneNo = this.form.mobile
        params.uuid = this.uuid
        params.code = this.uucode
        if (params.phoneNo == null || params.phoneNo == '' || _this.flag1) {
          flag = false
        }
      } else if (this.activeTab == 'email') {
        params.email = this.form.email
        if (params.email == null || params.email == '' || _this.flag1) {
          flag = false
        }
      }
      if (flag) {
        params.codeType = 1
        sendCode(params).then((res) => {
          console.log(res)
          this.timeCount = 59
          this.timer = setInterval(function () {
            _this.timeCount = _this.timeCount - 1
          }, 1000)
          this.$message.success('发送成功')
        })
      }
    },
    async checkCode() {
      // var params = {
      //   code: this.form.code,
      //   email: this.form.email,
      //   phoneNo: this.form.mobile,
      // }
      // this.isCorrectCode = await checkCode(params)
      // console.log(this.isCorrectCode, 'this.isCorrectCode')
      this.isCorrectCode = true
      if (this.isCorrectCode) {
        var url = ''
        if (this.activeTab == 'mobile') {
          url = forgetPasswordPhone
        } else if (this.activeTab == 'email') {
          url = forgetPasswordEmail
        }
        url(this.form).then((res) => {
          console.log(res)
          message.success('密码成功重置，请使用新密码重新登录', 3)

          this.$emit('update:visible', false)
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.imgput {
  /deep/.el-input,
  /deep/.el-input__inner {
    height: 60px;
    line-height: 60px;
  }
}
.valid-code {
  width: 310px;
  margin-right: 10px;
}
// 移动端样式
.mobile {
  .valid-code {
    width: 100%;
  }
}

.info-img {
  width: 40%;
  text-align: center;
}
.info-img img {
  width: 95px;
  height: 95px;
  border-radius: 100px;
  border: solid 1px #d8372d;
}
.info-ct {
  width: 60%;
}
.ligin-title {
  font-size: 16px;
  width: 100%;
}
.optBtn {
  flex: 1;
  line-height: 30px;
}
.optBtn + .optBtn {
  margin-left: 10px;
}
.tabs {
  display: flex;
  align-items: center;
  position: relative;
  float: left;
  width: 50%;
  height: 75px;
  .tabImg {
    position: relative;
    width: 48px;
    z-index: 1000;
  }
  .hrBorder {
    flex: 1;
    background-color: #e52e00;
    height: 4px;
    border: none;
  }
}
.logo {
  margin-top: -20px;
  margin-bottom: 20px;
  width: 100px;
}
.zc_display {
  position: absolute;
  left: -10000px;
  top: -10000px;
  height: 0;
  width: 0;
}
.pwdInput {
  -webkit-text-security: disc;
}
</style>
