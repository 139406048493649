<template>
  <MainCenter>
    <div :class="$route.name === '首页' ? 'block' : 'home_none'">
      <div v-if="adv != null && switchValue == 1" class="ad">
        <a :href="adv.adUrl" target="_blank"><img :src="advImgFoot" /></a>
      </div>
    </div>
    <a-row :type="rowType" class="footer-nav">
      <template v-if="!$isMobile">
        <a-col :flex="1" v-for="(item, i) of navs" :key="i" class="nav">
          <div class="title" :style="i == 0 ? 'margin-left:-32px' : i == 1 ? 'margin-left:-5px' : ''">
            {{ item.title }}
          </div>
          <ul>
            <li v-for="nav of item.navs" :key="nav.path" class="desc" @click.prevent="onNavClick(nav)">
              <a
                :href="nav.path"
                style="font-size: 14px; font-weight: 400; text-align: left; color: rgba(0, 0, 0, 0.65)"
              >
                {{ nav.text }}
              </a>
            </li>
          </ul>
        </a-col>
      </template>
      <a-col :flex="1" class="nav">
        <div class="title">关注公众号</div>
        <img src="../../assets/imgs/shouye-gongzhonghao.jpg" alt="" height="120" width="120" />
        <div class="desc">微信扫二维码关注公众号</div>
      </a-col>
      <a-col :flex="1" class="nav">
        <div class="title">小程序</div>
        <img src="../../assets/imgs/shouye-xiazai.jpg" alt="" height="120" width="120" />
        <div class="desc">微信扫二维码打开小程序</div>
      </a-col>
    </a-row>
  </MainCenter>
</template>

<script>
import { aboutUs, nejm, nejmQkhc } from '@/components/home/navs.js'
import MainCenter from '@/components/layout/MainCenter.vue'
import { getModularSwitch, selectAdvertList } from '@/api/adv'

export default {
  name: 'FooterNav',
  components: { MainCenter },
  data() {
    return {
      adv: null,
      advImgFoot: '',
      navs: [nejm, nejmQkhc, aboutUs],
    }
  },
  computed: {
    rowType() {
      return this.$isMobile ? '' : 'flex'
    },
  },
  async created() {
    await this.getModularSwitch()
  },
  methods: {
    onNavClick(item) {
      if (item.path) {
        this.$router.push({ path: item.path })
      }
    },
    async getModularSwitch() {
      let res = await getModularSwitch({ moduleName: 'advert' })
      this.switchValue = res.switchValue
      if (this.switchValue == 1) {
        await this.getAdv()
      }
    },
    async getAdv() {
      let res = await selectAdvertList({
        pageNo: 1,
        pageSize: 10,
        ifPublish: '是',
        advertPosition: '底部',
      })
      // for (var i = 0; i < res.results.length; i++) {
      //   if (res.results[i].advertPosition == '底部') {
      //     this.adv = res.results[i]
      //   }
      //   continue
      // }
      this.adv = res.results[0]
      if (this.adv != null) {
        this.advImgFoot = JSON.parse(this.adv.imgUrl)[0].url
      }
    },
    // showAdv() {
    //   window.open(this.adv.adUrl)
    // },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';
.block {
  .ad {
    img {
      width: 100%;
      max-height: none;
      padding: 40px 0;
    }
  }
}
.footer-nav {
  padding: 40px 0;

  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    + .nav {
      border-left: 1px solid @clr-border;
    }
  }

  .title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }
  li {
    margin-left: 2em;
    list-style: disc;
    cursor: pointer;
  }
  .desc {
    line-height: 2.2;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
}

// 移动端样式
.mobile {
  .footer-nav {
    .nav {
      + .nav {
        margin-top: 20px;
        border-left: none;
      }
    }
  }
}
</style>
