<template>
  <div class="userContainer">
    <!-- <div class="user">
      <div class="avatarContainer" @click="editAvatar()">
        <a-avatar class="avatar" :size="87" :src="res.avatar" />
      </div>
      <div class="user2">
        <div>
          <span>用户名：</span>
          {{ membername }}
          <i style="margin-left: 2px; cursor: pointer" class="el-icon-edit" @click="changeMemberName"></i>
        </div>
        <div>
          <span>手机号码：</span>
          {{ res.mobile }}
          <i v-if="$isMobile" style="margin-left: 2px; cursor: pointer" class="el-icon-edit" @click="changeMobile"></i>
        </div>
        <div>
          <span>邮箱：</span>
          {{ res.email }}
          <i v-if="$isMobile" style="margin-left: 2px; cursor: pointer" class="el-icon-edit" @click="changeEmail"></i>
        </div>
      </div>
    </div> -->
    <!-- 用户会员信息 start-->
    <div class="user_vip_wrap"
      :class="subscribe_info.service_type && !$isMobile ? (subscribe_info.service_type == 1 || subscribe_info.service_type == 3  ? 'vipby' : 'vipbn') : 'novip'">
      <div class="user_vip_box">
        <div class="user_vip_logo" @click="editAvatar()"  v-if="$isMobile">
          <a-avatar class="avatar" :size="87" :src="res.avatar" />
        </div>
        <!--会员logo去除，需要可以放出来-->
        <!-- <div class="user_vip_logo" >
          <img class="avatar" :size="87" src="@/assets/vip/VIP_1.png" v-if="subscribe_info.service_type == 0 || subscribe_info.service_type == 6" />
          <img class="avatar" :size="87" src="@/assets/vip/VIP_2.png" v-if="subscribe_info.service_type == 1" />
          <img class="avatar" :size="87" src="@/assets/vip/VIP_3.png" v-if="subscribe_info.service_type == 2" />
          <img class="avatar" :size="87" src="@/assets/vip/VIP_2.png" v-if="subscribe_info.service_type == 3" />
          <img class="avatar" :size="87" src="@/assets/vip/VIP_3.png" v-if="subscribe_info.service_type == 4" />
        </div> -->
        <div class="user_vip_info">
          <div style="color:#E52E00;" v-if="!$isMobile">
            <span style="color:#E52E00;">用户身份：</span>
            <!-- {{ ['注册用户', '月度用户', '年度用户'][subscribe_info.service_type] }} -->
            <span v-if="isLogin && subscribe_info.service_type == 0" style="color:#E52E00;">注册用户</span>
            <span v-if="isLogin && subscribe_info.service_type == 1" style="color:#E52E00;">包月用户</span>
            <span v-if="isLogin && subscribe_info.service_type == 2" style="color:#E52E00;">包年用户</span>
            <span v-if="isLogin && subscribe_info.service_type == 3" style="color:#E52E00;">连续包月用户</span>
            <span v-if="isLogin && subscribe_info.service_type == 4" style="color:#E52E00;">连续包年用户</span>
            <span v-if="isLogin && subscribe_info.service_type == 6" style="color:#E52E00;">会员过期用户</span>
            <i v-if="$isMobile" style="margin-left: 2px; cursor: pointer" class="el-icon-edit"
              @click="changeMemberName"></i>
          </div>
          <div>
            <span>用户名：</span>
            {{ membername }}
            <i v-if="$isMobile" style="margin-left: 2px; cursor: pointer" class="el-icon-edit"
              @click="changeMemberName"></i>
          </div>
          <div v-if="$isMobile">
            <span>手机号码：</span>
            {{ res.mobile }}
            <i v-if="$isMobile" style="margin-left: 2px; cursor: pointer" class="el-icon-edit" @click="changeMobile"></i>
          </div>
          <div v-if="isLogin && subscribe_info.service_type != 0 && !$isMobile">
            <span>会员到期时间：</span>
            {{ subscribe_info.service_end_date }}
          </div>
          <div>
            <span>邮箱：</span>
            {{ res.email }}
            <i v-if="$isMobile" style="margin-left: 2px; cursor: pointer" class="el-icon-edit" @click="changeEmail"></i>
          </div>
        </div>
        <div class="user_vip_open" v-if="!$isMobile" @click="getVipOrder()" 
          :class="subscribe_info.service_type ? (subscribe_info.service_type == '1'||subscribe_info.service_type == '3' ? 'by_btn' : 'bn_btn') : 'novip_btn'">
          {{ subscribe_info.service_type ==0 ? '开通订阅服务':'立即续订' }}
        </div>
      </div>
    </div>
    <!-- 用户会员信息 end-->
    <div class="update">
      <el-button v-if="$isMobile" class="zhuxiao" type="text" @click="showChangePwd">修改密码</el-button>
      <el-button v-if="$isMobile" class="zhuxiao" type="text" style="margin-left: 0" @click="logout">
        退出登录
      </el-button>
    </div>
    <div v-if="!$isMobile" style="width: 100%; height: 1px; background-color: #d4d4d4; margin: 14px 0 36px 0"></div>
    <div v-else style="width: 100%; height: 1px; background-color: #d4d4d4; margin: 15px 0 25px 0"></div>
    <!-- 用户会员到期信息 start-->
    <div class="user_vip_wrap_box" v-if="$isMobile && isLogin">
      <div class="user_vip_wrap_mobile "
        :class="subscribe_info.service_type == 0 ? 'novip_mobile' : (subscribe_info.service_type == '1' ||subscribe_info.service_type == '3' ? 'vipby' : 'vipbn')">
        <div class="user_vip_wrap_lf" id="user_vip_wrap_lf">
          <div class="user_vip_time">
            <span>{{ isLogin && subscribe_info.service_type == 0 ? '开通订阅，持续享特权' : '会员到期时间:' }} </span> <span
              v-if="isLogin && subscribe_info.service_type != 0 ">{{ subscribe_info.service_end_date || ''
              }}</span>
          </div>
          <div class="user_vip_type">
            <span>{{ isLogin && subscribe_info.service_type == 0 ? '用户身份:' : '用户身份:' }}</span>
            <span v-if="isLogin && subscribe_info.service_type == 0">注册用户</span>
            <span v-if="isLogin && subscribe_info.service_type == 1">包月用户</span>
            <span v-if="isLogin && subscribe_info.service_type == 2">包年用户</span>
            <span v-if="isLogin && subscribe_info.service_type == 3">连续包月用户</span>
            <span v-if="isLogin && subscribe_info.service_type == 4">连续包年用户</span>
            <span v-if="isLogin && subscribe_info.service_type == 6">会员过期用户</span>
          </div>
        </div>
        <div class="user_vip_open"
          :class="subscribe_info.service_type == 0 ? 'novip_btn_mobile' : (subscribe_info.service_type == 1||subscribe_info.service_type == 3 ? 'by_btn' : 'bn_btn')"
          @click="getVipOrder()">
          {{ isLogin && subscribe_info.service_type == 0 ? '立即开通' : '立即续订' }}
        </div>
      </div>
    </div>
    <!-- 用户会员到期信息 end-->

    <!-- 用户会员权益 start-->
    <div class="user_vip_Equity" v-if="$isMobile&&subscribe_info.service_type!='0'">
      <div class="no_vip_title">
        会员权益
      </div>
      <div class="vip_Equity" id="vip_Equitylist">
        <div class="vip_Equity_item" v-for="(item, index) in equitylist" :key="index" :data-id="item.id">
          <img :src="item.icon" />
          <div class="vip_Equity_item_info">
            <div>{{ item.title }}</div>
            <div>{{ item.infos }}</div>
          </div>
          <img class="lock" :data-id="item.id"  src="../../assets/vip/lock_close.png" alt="lock">
        </div>
      </div>
    </div>
    <!-- 用户会员权益 end-->
    <div class="clearboth"></div>
    <el-form :inline="true" :model="ruleForm" ref="ruleForm" label-width="95px" style="position:relative;">
      <el-form-item label="姓名：" key="truename1" prop="truename"
        :rules="[{ required: true, trigger: 'blur', message: '请输入姓名' }]">
        <span v-if="flag === false">{{ res.truename ? res.truename : '-' }}</span>
        <el-input v-else v-model="ruleForm.truename" placeholder="请输入姓名" class="xingming"></el-input>
      </el-form-item>
      <el-form-item v-if="!ruleForm.identity && !$isMobile"></el-form-item>

      <el-form-item label="手机：" prop="mobile" key="mobile">
        <span v-if="flag === false">{{ res.mobile ? res.mobile : '-' }}</span>
        <el-input v-else v-model="ruleForm.mobile" placeholder="请输入手机号" disabled></el-input>
      </el-form-item>
      <el-button v-if="flag === false && !$isMobile" type="primary" @click="edit">编 辑</el-button>
      <el-button v-if="flag === true && !$isMobile" type="primary" @click="preserve">确 定</el-button>
      <el-button v-if="flag === true && !$isMobile" type="primary" @click="closeEdit" style="position:absolute;right: 15px;top: 40px;">取 消</el-button>
      <el-form-item label="科室：" v-if="ruleForm.identity == '执业医师' || ruleForm.roleid ==1"
        :rules="[{ required: true, trigger: 'change', message: '请选择科室' }]" key="division1" prop="division">
        <span v-if="flag === false">{{ res.division ? res.division : '-' }}</span>
        <!-- <el-cascader
          v-else
          v-model="ruleForm.division"
          :props="{ label: 'division', value: 'division', children: 'childList' }"
          :key="isRescourceShow"
          :options="options2"
          :class="$isMobile ? '' : 'keshi'"
        ></el-cascader> -->
        <el-cascader v-else class="xingming" v-model="ruleForm.division" ref="divisioncascader" :options="divisionOptions"
          :props="divisionProps" placeholder="请选择科室：" clearable></el-cascader>
      </el-form-item>
      <!-- 地区-->
      <el-form-item v-if="!!ruleForm.identity" label="地区：" key="city1" prop="city"
        :rules="[{ required: true, trigger: 'change', message: '请选择城市' }]">
        <span v-if="flag === false">{{ res.city ? res.city : '-' }}</span>
        <!-- <el-input v-else v-model="ruleForm.city" placeholder="请输入城市"></el-input> -->
        <template v-else>
          <el-cascader class="xingming" v-if="!$isMobile" v-model="ruleForm.city" :options="cityOptions"
            ref="citycascader" :props="cityProps" placeholder="请选择城市：" @change="handleCityChange" clearable></el-cascader>
          <template v-else>
            <el-input v-model="fieldValue" placeholder="请选择地区：" @click.native="show = true" readonly clearable></el-input>
            <van-popup v-model="show" round position="bottom">
              <van-cascader v-model="fieldCityIdValue" title="请选择所在地区" :options="cityOptions" @close="show = false"
                @finish="onFinish" :field-names="fieldNames" />
            </van-popup>
          </template>
        </template>
      </el-form-item>


      <!-- <el-form-item label="手机：" prop="mobile" key="mobile" >
        <span v-if="flag === false">{{ res.mobile ? res.mobile : '-' }}</span>
        <el-input v-else v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
      </el-form-item> -->
      <el-form-item v-if="ruleForm.identity == '其他' || ruleForm.roleid ==0" key="otherDepartment1" label="部门："
        :rules="[{ required: true, trigger: 'blur', message: '请输入部门' }]" prop="otherDepartment">
        <span v-if="flag === false">{{ res.otherDepartment ? res.otherDepartment : '-' }}</span>
        <el-input v-else class="xingming" v-model="ruleForm.otherDepartment" placeholder="请输入"></el-input>
      </el-form-item>

      <!-- <el-form-item label="手机：" prop="mobile">
        <span v-if="flag === false">{{ res.mobile ? res.mobile : '-' }}</span>
        <el-input v-else v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-button v-if="flag === false && !$isMobile" type="primary" @click="edit">编 辑</el-button>
      <el-button v-if="flag === true && !$isMobile" type="primary" @click="preserve">确 定</el-button>
      <el-form-item label="邮箱：" prop="email">
        <span v-if="flag === false">{{ res.email ? res.email : '-' }}</span>
        <el-input
          v-else
          v-model="ruleForm.email"
          placeholder="请输入邮箱"
          class="xingming"
        ></el-input>
      </el-form-item> -->

      <el-form-item label="专业：" prop="majors" key="majors1"
        :rules="[{ required: true, trigger: 'blur', message: '请输入专业' }]" v-if="ruleForm.identity == '医学生'  || ruleForm.roleid ==5">
        <span v-if="flag === false">{{ res.majors ? res.majors : '-' }}</span>
        <el-input class="xingming" v-else v-model="ruleForm.majors" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item v-if="ruleForm.identity == '执业医师'||ruleForm.roleid ==1" key="joblevel1" label="职称：" prop="joblevel"
        :rules="[{ required: true, trigger: 'change', message: '请选择职称' }]">
        <span v-if="flag === false">{{ res.joblevel ? res.joblevel : '-' }}</span>
        <joblevel-select class="xingming" v-else v-model="ruleForm.joblevel"></joblevel-select>
        <!-- <el-input v-else v-model="ruleForm.joblevel" placeholder="请输入职称"></el-input> -->
      </el-form-item>
      <el-form-item label="补全信息：" key="identity1" prop="identity">
        <span v-if="flag === false">{{ this.identity ? this.identity : '-' }}</span>
        <!-- <el-select
          v-else
          @change="change"
          v-model="ruleForm.identity"
          placeholder="请选择身份"
          :class="$isMobile ? '' : 'shenfen'"
        >
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select> -->
        <identity-select v-else @change="handleTaget" class="xingming" v-model="ruleForm.identity"></identity-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.identity == '医学生' || ruleForm.roleid ==5" key="entranceYear1" prop="entranceYear" label="入学年份："
        :rules="[{ required: true, trigger: 'change', message: '请选择学年份' }]">
        <span v-if="flag === false">{{ res.entranceYear ? res.entranceYear : '-' }}</span>
        <el-date-picker class="xingming" v-else v-model="ruleForm.entranceYear" :picker-options="pickerOptions"
          type="year" format="yyyy" value-format="yyyy" placeholder="选择年">
        </el-date-picker>
      </el-form-item>



      <el-form-item v-if="ruleForm.identity == '其他' || ruleForm.roleid ==0" key="otherJobname1" label="职务："
        :rules="[{ required: true, trigger: 'blur', message: '请输入职务' }]" prop="otherJobname">
        <span v-if="flag === false">{{ res.otherJobname ? res.otherJobname : '-' }}</span>
        <el-input v-else class="xingming" v-model="ruleForm.otherJobname" placeholder="请输入"></el-input>
      </el-form-item>




      <el-form-item label="职务：" key="jobname1" v-if="ruleForm.identity == '执业医师'|| ruleForm.roleid ==1"
        :rules="[{ required: true, trigger: 'change', message: '请选择职务' }]" prop="jobname">
        <span v-if="flag === false">{{ res.joblevel ? res.jobname : '-' }}</span>
        <jobtitle-select v-else class="xingming" v-model="ruleForm.jobname"></jobtitle-select>
        <!-- <el-input v-else v-model="ruleForm.jobname" placeholder="请输入职务"></el-input> -->
      </el-form-item>
      <el-form-item label="学校：" key="university1" v-if="ruleForm.identity == '医学生' || ruleForm.roleid ==5" prop="university"
        :rules="[{ required: true, trigger: 'change', message: '请选择学校' }]">
        <span v-if="flag === false">{{ res.university ? res.university : '-' }}</span>
        <el-select v-else class="xingming" v-model="ruleForm.university" :disabled="!ruleForm.city" placeholder="请选择">
          <el-option v-for="item in universityOptions" :key="item.id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>


      <!-- <el-form-item label="详细信息：" v-if="ruleForm.identity == '医学院师生'" prop="roleid">
        <span v-if="flag === false">{{ res.roleid ? res.roleid : '-' }}</span>
        <el-cascader
          v-else
          v-model="ruleForm.roleid"
          :options="options3"
          :class="$isMobile ? '' : 'xxxx'"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细信息：" v-if="ruleForm.identity == '其他'" prop="roleid">
        <span v-if="flag === false">{{ res.roleid ? res.roleid : '-' }}</span>
        <el-cascader
          v-else
          v-model="ruleForm.roleid"
          :options="options4"
          :class="$isMobile ? '' : 'xxxx'"
        ></el-cascader>
      </el-form-item> -->
      <el-form-item label="医院：" key="hospital1" v-if="ruleForm.identity == '执业医师' || ruleForm.roleid ==1" prop="hospital"
        :rules="[{ required: true, trigger: 'change', message: '请选择医院' }]">
        <span v-if="flag === false">{{ res.hospital ? res.hospital : '-' }}</span>
        <!-- <el-input v-else v-model="ruleForm.hospital" placeholder="请输入医院/机构"></el-input> -->
        <el-select v-else class="xingming" filterable v-model="ruleForm.hospital" :disabled="!ruleForm.city"
          placeholder="请选择">
          <el-option v-for="item in hospitalOptions" :key="item.id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学历：" key="qualification1" v-if="ruleForm.identity == '医学生' || ruleForm.roleid ==5" prop="qualification"
        :rules="[{ required: true, trigger: 'change', message: '请选择学历' }]">
        <span v-if="flag === false">{{ res.qualification ? res.qualification : '-' }}</span>
        <dataset-select class="xingming" v-else v-model="ruleForm.qualification"></dataset-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.identity == '其他' || ruleForm.roleid ==0" key="otherCompany1" label="单位："
        :rules="[{ required: true, trigger: 'blur', message: '请输入单位' }]" prop="otherCompany">
        <span v-if="flag === false">{{ res.otherCompany ? res.otherCompany : '-' }}</span>
        <el-input v-else class="xingming" v-model="ruleForm.otherCompany" placeholder="请输入"></el-input>
      </el-form-item>
      <!-- 
        

        收货地址
        
        
        start-->
      <div v-if="!$isMobile && subscribe_info.service_type =='2' || !$isMobile && subscribe_info.service_type =='4'" style="width: 100%; height: 1px; background-color: #d4d4d4; margin: 8px 0 25px 0"></div>
        <div v-if="!$isMobile && subscribe_info.service_type =='2' || !$isMobile && subscribe_info.service_type =='4'" class="task_address">收货地址</div>
        <el-form-item v-if="subscribe_info.service_type =='2' || subscribe_info.service_type =='4'" label="收货人：" prop="shName" key="shName" :rules="[{ required: (subscribe_info.service_type =='2' || subscribe_info.service_type =='4')? true:false, trigger: 'change', message: '请填写收货人' }]">
          <span v-if="flag === false">{{ ruleForm.shName ? ruleForm.shName : '-' }}</span>
          <el-input  class="xingming" v-else v-model="ruleForm.shName" placeholder="请输入收货人"></el-input>
        </el-form-item>
        <el-form-item v-if="subscribe_info.service_type =='2' || subscribe_info.service_type =='4'" label="收货手机：" prop="shPhone" key="shPhone" :rules="[{ required: (subscribe_info.service_type =='2' ||subscribe_info.service_type =='4')? true:false, trigger: 'change', message: '请填写收货手机' }]">
          <span v-if="flag === false">{{ ruleForm.shPhone ?ruleForm.shPhone : ruleForm.mobile }}</span>
          <el-input  v-else v-model="ruleForm.shPhone" placeholder="请输入收货手机号"></el-input>
        </el-form-item>
        <el-form-item v-if="subscribe_info.service_type =='2' || subscribe_info.service_type =='4'" label="地区：" key="city_sh" prop="city_sh" :rules="[{ required: (subscribe_info.service_type =='2' || subscribe_info.service_type =='4')? true:false, trigger: 'change', message: '请选择城市' }]">
          <span v-if="flag === false">{{ ruleForm.city_sh ? ruleForm.city_sh : '-' }}</span>
          <!-- <el-input v-else v-model="ruleForm.city" placeholder="请输入城市"></el-input> -->
          <template v-else>
            <el-cascader class="xingming" v-if="!$isMobile" v-model="ruleForm.city_sh" :options="cityOptions_sh"
              ref="citycascader_sh" :props="cityProps_sh" placeholder="请选择城市："  @change="handleCityChange_sh" clearable></el-cascader>
            <template v-else>
              <el-input v-model="fieldValue_sh" placeholder="请选择地区：" @click.native="show_sh = true" readonly clearable></el-input>
              <van-popup v-model="show_sh" round position="bottom">
                <van-cascader v-model="fieldCityIdValue_sh" title="请选择所在地区" :options="cityOptions_sh" @close="show_sh = false"
                  @finish="onFinish_sh" :field-names="fieldNames_sh" />
              </van-popup>
            </template>
          </template>
        </el-form-item>
        <el-form-item v-if="subscribe_info.service_type =='2' || subscribe_info.service_type =='4'" label="详细地址：" prop="shAddress" key="shAddress" :rules="[{ required: (subscribe_info.service_type =='2' || subscribe_info.service_type =='4')? true:false, trigger: 'change', message: '请填写详细地址' }]">
          <span v-if="flag === false">{{ ruleForm.shAddress ? ruleForm.shAddress : '-' }}</span>
          <el-input  class="xingming"  v-else v-model="ruleForm.shAddress" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <!-- 
          
          
          收货地址
          
          end-->  
      <div v-if="!$isMobile" style="width: 100%; height: 1px; background-color: #d4d4d4; margin: 8px 0 25px 0"></div>
      <el-form-item class="res">
        <a-checkbox style="margin-left: 7px" @change="onChange1" :checked="ruleForm.ifReceiveNejm">
          A. 我同意接收《NEJM医学前沿》发送的邮件或短信。
        </a-checkbox>
      </el-form-item>
      <el-form-item class="res">
        <a-checkbox style="margin-left: 7px" @change="onChange2" :checked="ruleForm.ifReceiveJh">
          B. 我同意接收嘉会医学研究和教育集团（J-Med）发送的邮件或短信。
        </a-checkbox>
      </el-form-item>
      <el-form-item class="res">
        <a-checkbox style="margin-left: 7px" @change="onChange3" :checked="ruleForm.ifReceiveDsf">
          C. 我同意接收《NEJM医学前沿》相关第三方发送的邮件或短信。
        </a-checkbox>
      </el-form-item>
      <div v-if="$isMobile" class="editFitContainer">
        <el-button class="editFit" v-if="flag === false && $isMobile" type="primary" @click="edit">编 辑</el-button>
        <el-button class="editFit" v-if="flag === true && $isMobile" type="primary" @click="closeEdit">取 消</el-button>
        <el-button class="editFit" v-if="flag === true && $isMobile" type="primary" @click="preserve">确 定</el-button>
      </div>
    </el-form>
    <el-dialog title="提示" :visible.sync="dialogVisible" :width="$isMobile ? '90%' : '30%'" :before-close="handleClose">
      <ImageUploader v-model="upAvatar" :limit="1"></ImageUploader>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAvatar">确 定</el-button>
      </span>
    </el-dialog>
    <ChangeAccountNameDialog v-if="memberNameVisible" :visible.sync="memberNameVisible"
      :memberNameParams="memberNameParams" @changeAccount="getData"></ChangeAccountNameDialog>
    <ChangePwdDialogVue v-if="dialogVisible1" :visible.sync="dialogVisible1" :ruleForm="ruleForm"></ChangePwdDialogVue>
    <ChangeMobileDialog v-if="mobileVisible" :visible.sync="mobileVisible" :mobileParams="mobileParams"
      @editAvatar="editAvatar1"></ChangeMobileDialog>
    <ChangeEmailDialog v-if="emailVisible" :visible.sync="emailVisible" :emailParams="emailParams"
      @editAvatar="editAvatar1"></ChangeEmailDialog>
  </div>
</template>
<script>
//权益图片
import pc_qyimg1_on from '@/assets/vip/7.png';
import pc_qyimg1_off from '@/assets/vip/7_1.png';
import pc_qyimg2_on from '@/assets/vip/8.png';
import pc_qyimg2_off from '@/assets/vip/8_1.png';
import pc_qyimg3_on from '@/assets/vip/9.png';
import pc_qyimg3_off from '@/assets/vip/9_1.png';
import pc_qyimg4_on from '@/assets/vip/10.png';
import pc_qyimg4_off from '@/assets/vip/10_1.png';
import pc_qyimg5_on from '@/assets/vip/11.png';
import pc_qyimg5_off from '@/assets/vip/11_1.png';
// import vip_1 from '@/assets/vip/VIP_1.png';
// import vip_2 from '@/assets/vip/VIP_2.png';
// import vip_3 from '@/assets/vip/VIP_3.png';
import { getMemberInfo, completeUserInfo } from '@/api/personal'
import { selectDivision } from '@/api/base'
import { shenFen, jueSe, jueSe2 } from './data'
import mrtx from '@/assets/imgs/mrtx.png'
import axios from 'axios'
import ImageUploader from '../../components/other/ImageUploader'
import ChangeAccountNameDialog from '../../components/home/components/ChangAccountNameDialog.vue'
import ChangePwdDialogVue from '@/components/home/components/ChangePwdDialog.vue'
import ChangeMobileDialog from '@/components/home/components/ChangeMobileDialog.vue'
import ChangeEmailDialog from '@/components/home/components/ChangeEmailDialog.vue'
import IdentitySelect from '@/components/select/IdentitySelect'
import JoblevelSelect from '@/components/select/JoblevelSelect'
import JobtitleSelect from '@/components/select/JobtitleSelect'
import DatasetSelect from '@/components/select/DatasetSelect'
import { setSessionUser,logoutLocal } from '@/utils/app-utils'
import { MessageBox } from 'element-ui'
import { mapMutations } from 'vuex'
import { mapState } from 'vuex'
import { userLogout, get_city, get_hospital, get_division, get_university } from '@/api/login'
import merge from 'webpack-merge'
// const jueSeOf = {
//   1: '教师',
//   2: '科研人员',
//   3: '医学生',
// }
// const jueSeOf3 = {
//   1: '医药企业',
//   2: '研究院所',
//   3: '其他',
// }
const jueSeOf = {
  1: '执业医师',
  5: '医学生',
  0: '其他',
}

// const jueSeOf2 = {
//   教师: 1,
//   科研人员: 2,
//   医学生: 3,
// }

const jueSeOf1 = {
  '执业医师': 1,
  '医学生': 5,
  '其他': 0,
}
// const jueSeOf4 = {
//   医药企业: 1,
//   研究院所: 2,
//   其他: 3,
// }
export default {
  name: 'PersonalData',
  components: { ImageUploader, ChangeAccountNameDialog, ChangeMobileDialog, ChangeEmailDialog, ChangePwdDialogVue, IdentitySelect, JoblevelSelect, JobtitleSelect, DatasetSelect },
  data() {
    return {
      isLogin: true,
      subscribe_info: {
        service_type: null,//会员等级
      },
      equitylist: [
        {
          pc_qyimg_on: pc_qyimg1_on,
          pc_qyimg_off: pc_qyimg1_off,
          price: 25,
          title: 'NEJM 中译全文',
          infos: '每周同步上新，官方授权'
        },
        {
          pc_qyimg_on: pc_qyimg2_on,
          pc_qyimg_off: pc_qyimg2_off,
          price: 200,
          title: 'NEJM Journal Watch中译全文',
          infos: '每周同步上新，官方授权'
        }, {
          pc_qyimg_on: pc_qyimg3_on,
          pc_qyimg_off: pc_qyimg3_off,
          price: 35,
          title: 'NEJM 动画解读',
          infos: '2分钟了解NEJM 最新论著'
        },
        {
          pc_qyimg_on: pc_qyimg4_on,
          pc_qyimg_off: pc_qyimg4_off,
          price: 300,
          title: '线下研讨会入场券',
          infos: '品牌主办会议，全年参会资格'
        },
        {
          pc_qyimg_on: pc_qyimg5_on,
          pc_qyimg_off: pc_qyimg5_off,
          price: 300,
          title: '品牌定制文创礼品',
          infos: '定制帆布袋文件夹，医学图书等'
        }
      ],
      dialogVisible: false,
      ruleForm: {
        truename: null,
        // mobile: null,
        // email: null,
        city: null,
        hospital: null,
        joblevel: null,
        important: null,
        identity: null,
        roleid: null,
        division: null,
        id: null,
        jobname: null,//+
        university: null,//+
        majors: null,//+
        entranceYear: null,//+
        qualification: null,//+
        // 
        otherCompany: null,//+
        otherDepartment: null,//+
        otherJobname: null,//+
        ifReceiveNejm: true,
        ifReceiveJh: true,
        ifReceiveDsf: true,
        shName:'',
        shAddress:'',
        shPhone:'',
        city_sh:null
      },
      //收货信息
      ruleForm_sh:{
        pro_id:'',
        city_id:'',
        area_id:'',
        pro_name:'',
        city_name:'',
        area_name:'',
        sh_name:'',
        sh_phone:'',
        sh_address:'',
        city_sh:null
      },
      options: shenFen,
      options2: [], //科室
      options3: [], //详细信息
      options4: [], //详细信息
      isRescourceShow: 0,
      roleidFilter: [{ roleid: 1, label: '执业医师' }, { roleid: 5, label: '医学生' }, { roleid: 0, label: '其他' },],
      rules: {
        truename: [{ required: true, trigger: 'blur', message: '请输入姓名' }],
        mobile: [{ required: true, trigger: 'blur', message: '请输入手机号' }],
        // email: [{ required: true, trigger: 'blur', message: '请输入邮箱' }],
        city: [{ required: true, trigger: 'blur', message: '请输入城市' }],
        city_sh: [{ required: true, trigger: 'blur', message: '请输入城市' }],
        shName: [{ required: true, trigger: 'blur', message: '请输入收货人名称' }],
        shPhone: [{ required: true, trigger: 'blur', message: '请填写收货人手机号' }],
        shAddress: [{ required: true, trigger: 'blur', message: '请填写收货人地址' }],
        hospital: [{ required: true, trigger: 'blur', message: '请输入医院/机构' }],
        joblevel: [{ required: true, trigger: 'blur', message: '请输入职称' }],
        important: [{ required: true, trigger: 'blur', message: '请输入临床研究重点' }],
        identity: [{ required: true, trigger: 'change', message: '请选择身份' }],
        roleid: [{ required: true, trigger: 'change', message: '请选择详细信息' }],
        division: [{ required: true, trigger: 'change', message: '请选择科室' }],
      },
      identity: null,
      divisionArr: null,
      roleid: null,
      res: {
        avatar: '',
      }, //返回参数
      flag: false,
      upAvatar: [],
      division: null, //保存头像时用到
      membername: null, //账户名
      mobile: null,
      email: null,
      memberNameVisible: false,
      mobileVisible: false,
      emailVisible: false,
      dialogVisible1: false,
      memberNameParams: {
        membername: null,
      },
      mobileParams: {
        mobile: null,
        code: null,
      },
      emailParams: {
        email: null,
        code: null,
      },
      cityOptions: [],
      cityOptions_sh:[],
      hospitalOptions: [],
      universityOptions: [],
      divisionOptions: [],
      cityProps: {
        "multiple": false,
        value: 'id',
        label: 'name',
        children: 'children'
      },
      cityProps_sh: {
        "multiple": false,
        value: 'id',
        label: 'name',
        children: 'children'
      },
      divisionProps: {
        "multiple": false,
        value: 'name',
        label: 'name',
        children: 'children'
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      fieldValue: '',
      fieldValue_sh:"",
      fieldCityIdValue: '',
      fieldCityIdValue_sh:"",
      show: false,
      show_sh:false,
      fieldNames: {
        text: 'name',
        value: 'id',
        children: 'children',
      },
      fieldNames_sh: {
        text: 'name',
        value: 'id',
        children: 'children',
      },
      vipList:[],
      noEquitylist:[]
    }
  },
  created() {
    this.getData()
    this.getViplist()
    get_city().catch((res) => {
      this.cityOptions = res.children || [];
      this.cityOptions_sh = res.children || [];
    })
    this.get_division();
    this.$bus.on('change-avatar', () => {
      this.editAvatar()
    })
    this.$bus.on('refresh-page', () => {
      this.getData()
    })
    // this.initialize()
    this.$bus.on('user-change', (user) => {
      this.res = user
    })
  },
  destroyed() {
    this.$bus.off('change-avatar')
    this.$bus.off('refresh-page')
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isShow() {
      return !!this.user
    },
  },
  watch:{
    isShow: function(newValue, oldValue) {
      console.log(newValue,oldValue)
      console.log(this.isShow,'isShow')
      let that = this
      if(that.isShow){
        that.initialize()
        setTimeout(()=>{
          that.get_shaddress()
        },300)
      }else{
        console.log('退出登录')
        // this.setUser({})
        // setSessionUser({})
        that.isClose = false //退出登录，弹窗关闭
        that.memberId = null
        window.sessionStorage.setItem('memberId', null)
        window.sessionStorage.setItem('token', null)
        that.subscribe_info.service_type = 0
        that.$forceUpdate()
      }
    }
  },
  onLoad(){
    // 需要可以放出来
    // if(this.isShow&&this.subscribe_info.service_type =='2'){
    //     this.$notify({
    //         title: '提示消息',
    //         message: '感谢您购买NEJM医学前沿年度学术会员，请及时补充收货地址，方便我们寄送定制文创礼品',
    //         type: 'success',
    //         duration:5000,
    //         offset:200
    //     });
    // }
  },
  mounted () {
    //是否登录。登录查询用户信息
    if(this.isShow){
      this.initialize()
      this.get_shaddress()
      setTimeout(()=>{
        //尊敬的年费会员用户,您还没有填写收货地址，是否前往？
        //感谢您购买NEJM医学前沿年度学术会员，请及时补充收货地址，方便我们寄送定制文创礼品
        if((this.subscribe_info&&this.subscribe_info.service_type ==2 || this.subscribe_info&&this.subscribe_info.service_type ==4 )&& !this.ruleForm.shAddress){
        MessageBox.confirm('感谢您购买NEJM医学前沿年度学术会员，请及时补充收货地址，方便寄送定制文创礼品', '提示', {
            confirmButtonText: '确定前往',
            cancelButtonText: '稍后编辑',
            // type: 'success',
        }).then((ec) => {
            console.log(ec)
            this.edit()
            this.$router.push({
                query: merge(this.$route.query, { type: 'flag1' }),
              }).catch((err) => {
                console.log('输出报错', err)
              })
        }).catch((err) => {
            console.log(err)        
        });  
      }
    },500)
    }
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    handleChange(e){
      console.log(e,'====e')
    },
      /****
       * 
       * 会员服务列表  vipList
       * 
       * VIP权益列表  equitylist
       * 
       */
      getViplist(){
        console.log('VIP权益列表');
        let token = window.sessionStorage.getItem('token');
        console.log(token,'token');    
        axios.get('https://www.nejmqianyan.cn/index.php?c=program&m=getServiceList&ut=1&sf=pc'+'&token='+token).then(res => {
            console.log(res);
            if(res.data.result =='1'){
                // this.vipList = res.data.equitylist
                this.vipList = res.data.servicelsit
                this.equitylist = res.data.equitylist 
                return
            }
            this.$message.error(res.data.msg)
        })
    },
    //获取收货地址
    get_shaddress(){
        let token = window.sessionStorage.getItem('token');
        axios.get('https://www.nejmqianyan.cn/index.php?c=program&m=getMyAddress&sf=pc&token='+token).then(res => {
            console.log(res);
            if(res.data.result == 1){
                // this.vipList = res.data.equitylist
                if(res.data.item.pro_name || res.data.item.city_name){
                  this.ruleForm.city_sh = res.data.item.pro_name +'/'+ res.data.item.city_name+'/' + res.data.item.area_name
                  this.ruleForm_sh.city_sh = res.data.item.pro_name +'/'+ res.data.item.city_name+'/' + res.data.item.area_name
                }else{
                  this.ruleForm.city_sh = ''
                  this.ruleForm_sh.city_sh = ''
                }
                // this.ruleForm
                this.ruleForm.cityName = res.data.item.city_name || ''
                this.ruleForm.areaName = res.data.item.area_name || ''
                this.ruleForm.sh_address = res.data.item.sh_address || null
                this.ruleForm.shAddress = res.data.item.sh_address || null
                this.ruleForm_sh.sh_address = res.data.item.sh_address || null
                this.ruleForm.shName = res.data.item.sh_name || null
                this.ruleForm_sh.sh_name = res.data.item.sh_name || null
                // this.ruleForm.sh_phone = res.data.item.sh_phone
                // this.ruleForm_sh.shPhone = res.data.item.sh_phone
                this.ruleForm.shPhone = res.data.item.sh_phone || null
                this.ruleForm_sh.pro_name = res.data.item.pro_name || null
                this.ruleForm_sh.pro_id = res.data.item.pro_id || null
                this.ruleForm_sh.city_name = res.data.item.city_name || null
                this.ruleForm_sh.city_id = res.data.item.city_id || null
                this.ruleForm_sh.area_name = res.data.item.area_name || null
                this.ruleForm_sh.area_id = res.data.item.area_id || null
                this.$forceUpdate()
                return
            }
            this.$message.error(res.data.msg)
        })
    },
    submit_ShAddressFun(){
      let token = window.sessionStorage.getItem('token');
      let city_sh = []
      if(!this.ruleForm_sh.pro_name || !this.ruleForm_sh.city_name || !this.ruleForm_sh.area_name){
        // this.ruleForm.city_sh = this.$refs['citycascader_sh'].getCheckedNodes()[0].pathLabels.join('/');
        this.ruleForm.city_sh = this.$refs['citycascader_sh'].getCheckedNodes()[0].pathLabels.join('/');
        city_sh = this.$refs['citycascader_sh'].getCheckedNodes()[0].pathLabels
      }
      console.log(this.ruleForm.city_sh,'this.ruleForm.city_sh')
      console.log(token,'token');    
        let jsons = {
            pro_id:this.ruleForm_sh.pro_id,
            city_id:this.ruleForm_sh.city_id,
            area_id:this.ruleForm_sh.area_id,
            pro_name:this.ruleForm_sh.pro_name || city_sh[0],
            city_name:this.ruleForm_sh.city_name || city_sh[1],
            area_name:this.ruleForm_sh.area_name || city_sh[2],
            sh_name:this.ruleForm.shName,
            sh_phone:this.ruleForm.shPhone,
            sh_address:this.ruleForm.shAddress
        }
      axios({
        url:'https://www.nejmqianyan.cn/index.php?c=program&m=addOrModifyAddress&sf=pc&token='+token,
        method:"post",
        data:jsons,
        headers:{
          'content-type':"application/json"
          // 'content-type': 'application/x-www-form-urlencoded',
        }
      }).then(res=>{
        console.log('响应成功',res)
        if (!this.$isMobile) {
          this.ruleForm.city_sh = this.ruleForm_sh.pro_name+'/'+this.ruleForm_sh.city_name+'/'+this.ruleForm_sh.area_name;
          console.log(this.ruleForm.cityidspliter_sh,'this.ruleForm.cityidspliter_sh111',this.ruleForm.city_sh)
        } else {
          if (this.ruleForm.city_sh && this.ruleForm.city_sh.length > 0) {
            this.ruleForm.cityidspliter_sh = this.ruleForm.city_sh.join(',');
            console.log(this.ruleForm.cityidspliter_sh,'this.ruleForm.cityidspliter_sh111')
            this.ruleForm.city_sh = this.fieldValue_sh;
          }
        }
      },reject=>{
        console.log('响应失败',reject)
      })

    },
    getVipOrder() {
      this.$router
        .push({
          query: merge(this.$route.query, { type: 'flag4' }),
        })
        .catch((err) => {
          console.log('输出报错', err)
        })
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      console.log(selectedOptions,'selectedOptions');
      // console.log(this.formData.field111);
      console.log(this.fieldValue,'this.fieldValue');
      if (!selectedOptions) {
        return
      }
      this.fieldValue = selectedOptions.map((option) => option.name).join('/')
      this.ruleForm.city = selectedOptions.map((option) => option.id);
      console.log(this.ruleForm.city ,'this.ruleForm.city ')
      this.handleCityChange();
    },
    onFinish_sh({ selectedOptions }){
      this.show_sh = false;
      console.log(this.ruleForm.city_sh,'city_sh==')
      console.log(selectedOptions,'====selectedOptions_sh');
      console.log(this.fieldValue_sh,'this.fieldValue_sh');
      // console.log(this.formData.field111);
      // console.log(this.fieldValue);
      if (!selectedOptions) {
        return
      }
      this.fieldValue_sh = selectedOptions.map((option) => option.name).join('/')
      console.log(this.fieldValue_sh,'===fieldValue_sh')
      let city_sh = selectedOptions.map((option) => option.id);
      let city_sh_name = selectedOptions.map((option) => option.name);
      this.ruleForm_sh.pro_id = city_sh[0]
      this.ruleForm_sh.city_id = city_sh[1]
      this.ruleForm_sh.area_id = city_sh[2]
      this.ruleForm_sh.pro_name = city_sh_name[0]
      this.ruleForm_sh.city_name = city_sh_name[1]
      this.ruleForm_sh.area_name = city_sh_name[2]
      console.log( this.ruleForm_sh,'==== this.ruleForm_sh',this.ruleForm.shPhone)
      this.handleCityChange_sh();
    },
    /******
     * 
     * 补全身份。。。
     * 
     */
    handleTaget(val) {
      var trueName = this.ruleForm.truename;
      var identity = val;
      var city = this.ruleForm.city;
      var fieldValue = this.fieldValue;
      this.$refs['ruleForm'].resetFields();
      this.ruleForm.roleid = jueSeOf1[val];
      console.log(this.ruleForm.roleid,'this.ruleForm.roleid')
      // this.ruleForm.city = null;
      this.ruleForm.division = null;
      //
      this.ruleForm.hospital = null
      this.ruleForm.joblevel = null
      this.ruleForm.jobname = null

      this.ruleForm.majors = null
      this.ruleForm.entranceYear = null
      this.ruleForm.qualification = null
      this.ruleForm.university = null

      this.ruleForm.otherCompany = null
      this.ruleForm.otherDepartment = null
      this.ruleForm.otherJobname = null

      //    
      this.ruleForm.city = city;
      this.ruleForm.city_sh = city;
      this.fieldValue = fieldValue;
      this.ruleForm.truename = trueName;
      this.ruleForm.identity = identity;
      this.handleCityChange()
    },
    handleCityChange() {
      if (this.ruleForm.identity == '执业医师') {
        this.get_hospital();
      } else if (this.ruleForm.identity == '医学生') {
        this.get_university();
      }
    },
    handleCityChange_sh(e){
      console.log(e,'===e',this.$refs['citycascader_sh'].getCheckedNodes()[0].pathLabels)
      // let city_name = this.$refs['citycascader_sh'].getCheckedNodes()[0].pathLabels.join('/')
      this.ruleForm_sh.pro_id = e[0]
      this.ruleForm_sh.city_id = e[1]
      this.ruleForm_sh.area_id = e[2]
      // this.ruleForm_sh.city_name = city_name
      this.ruleForm_sh.city_name = this.$refs['citycascader_sh'].getCheckedNodes()[0].pathLabels[1]
      this.ruleForm_sh.pro_name = this.$refs['citycascader_sh'].getCheckedNodes()[0].pathLabels[0]
      this.ruleForm_sh.area_name = this.$refs['citycascader_sh'].getCheckedNodes()[0].pathLabels[2]
    },
    get_hospital() {
      if (this.ruleForm.city == null || this.ruleForm.city.length == 0) {
        return
      }
      var params = {};
      params.p_code = this.ruleForm.city[0]
      params.c_code = this.ruleForm.city[1]
      params.a_code = this.ruleForm.city[2]
      get_hospital(params).then((res) => {
        this.hospitalOptions = res;
      })
    },
    get_division() {
      var params = {};
      get_division(params).catch((res) => {
        console.log(res);
        this.divisionOptions = res.children;
      })
    },
    get_university() {
      if (this.ruleForm.city == null || this.ruleForm.city.length == 0) {
        return
      }
      var params = {};
      params.p_code = this.ruleForm.city[0]
      params.c_code = this.ruleForm.city[1]
      get_university(params).then((res) => {
        this.universityOptions = res;
      })
    },
    async logout() {
      window.sessionStorage.clear()
      await userLogout({})
      this.setUser(null)
      // this.$router.push('/index')
      logoutLocal()
      // this.$message.success('退出成功')
      this.$emit('update:visible', false)
      localStorage.removeItem('token')
      window.sessionStorage.setItem('token',null)

      this.$router.push('/index')
    },
    onChangeOne() {
      var params = { ...this.ruleForm }
      // if (this.ruleForm.division) {
      //   var length = this.ruleForm.division.length
      //   params.division = this.ruleForm.division[length - 1]
      // }
      params.emailUnsubscribe = params.ifReceiveDsf || params.ifReceiveJh || params.ifReceiveNejm ? 0 : 1
      params.ifReceiveDsf = params.ifReceiveDsf ? 1 : 0
      params.ifReceiveJh = params.ifReceiveJh ? 1 : 0
      params.ifReceiveNejm = params.ifReceiveNejm ? 1 : 0
      completeUserInfo(params).then((res) => {
        setSessionUser(res)
      })
    },
    onChange1(e) {
      // this.ruleForm.emailUnsubscribe = e.target.checked
      var _this = this
      if (e.target.checked) {
        this.ruleForm.ifReceiveNejm = e.target.checked
        this.onChangeOne()
      } else {
        MessageBox.confirm('确认是否取消勾选?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            _this.$message.success('已成功取消!')
            _this.ruleForm.ifReceiveNejm = e.target.checked
            _this.onChangeOne()
          })
          .catch(() => { })
      }
    },
    onChange2(e) {
      // this.ruleForm.emailUnsubscribe = e.target.checked
      var _this = this
      if (e.target.checked) {
        this.ruleForm.ifReceiveJh = e.target.checked
        this.onChangeOne()
      } else {
        MessageBox.confirm('确认是否取消勾选?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            _this.$message.success('已成功取消!')
            _this.ruleForm.ifReceiveJh = e.target.checked
            _this.onChangeOne()
          })
          .catch(() => { })
      }
    },
    onChange3(e) {
      // this.ruleForm.emailUnsubscribe = e.target.checked
      var _this = this
      if (e.target.checked) {
        this.ruleForm.ifReceiveDsf = e.target.checked
        this.onChangeOne()
      } else {
        MessageBox.confirm('确认是否取消勾选?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            _this.$message.success('已成功取消!')
            _this.ruleForm.ifReceiveDsf = e.target.checked
            _this.onChangeOne()
          })
          .catch(() => { })
      }
    },
    changeMemberName() {
      this.memberNameVisible = true
    },
    changeMobile() {
      this.mobileVisible = true
    },
    changeEmail() {
      this.emailVisible = true
    },
    showChangePwd() {
      this.dialogVisible1 = true
    },
    handleClose(done) {
      done()
    },
    async getData() {
      let res = (await getMemberInfo()) || {}
      this.membername = res.membername
      // this.identity = res.identity
      // this.ruleForm.identity = res.identity
      this.ruleForm.roleid = res.roleid
      if(this.ruleForm.roleid ==1){
        this.ruleForm.identity = '执业医师'
        this.identity = '执业医师'
      }else if(this.ruleForm.roleid ==5){
        this.ruleForm.identity = '医学生'
        this.identity = '医学生'
      }else if(this.ruleForm.roleid ==0){
        this.ruleForm.identity = '其他'
        this.identity = '其他'
      }
      // if (res.roleid != null && res.identity == '医学院师生') {
      //   res.roleid = jueSeOf[res.roleid]
      //   this.roleid = res.roleid
      // }
      // if (res.roleid != null && res.identity == '其他') {
      //   res.roleid = jueSeOf[res.roleid]
      //   this.roleid = res.roleid
      // }
      this.roleid = res.roleid
      this.subscribe_info.service_type = res.subscribe_info.service_type || ''
      this.subscribe_info.service_end_date = res.subscribe_info.service_end_date
      console.log(this.subscribe_info.service_type,'typeof',typeof this.subscribe_info.service_type)
      // if (res.division != null) {
      //   if (res.division.indexOf('[') != -1) {
      //     this.division = res.division
      //     this.divisionArr = JSON.parse(res.division)
      //   }
      //   if (res.division == 'null') {
      //     res.division = ''
      //   }
      //   if (null != this.divisionArr) {
      //     res.division = this.divisionArr[this.divisionArr.length - 1]
      //   }
      // }
      this.ruleForm.id = res.id
      if (res.avatar == null || res.avatar == '' || res.avatar == 'null') {
        res.avatar = mrtx
      }
      this.mobile = res.mobile
      this.ruleForm.mobile = res.mobile || ''
      if(this.ruleForm.shPhone == '' || !this.ruleForm.shPhone){
        this.ruleForm.shPhone = res.mobile || ''
      }
      this.email = res.email
      this.res = res
      this.ruleForm.ifReceiveNejm = res.ifReceiveNejm == 1 ? true : res.ifReceiveNejm == null ? true : false
      this.ruleForm.ifReceiveJh = res.ifReceiveJh == 1 ? true : res.ifReceiveJh == null ? true : false
      this.ruleForm.ifReceiveDsf = res.ifReceiveDsf == 1 ? true : res.ifReceiveDsf == null ? true : false
    },
    closeEdit(){
      this.flag = false
      if(this.ruleForm_sh.pro_name&&this.ruleForm_sh.city_name&&this.ruleForm_sh.area_name){
        // this.ruleForm.city_sh = cityidspliter_sh.split(',');
        this.ruleForm.city_sh = this.ruleForm_sh.pro_name+'/'+this.ruleForm_sh.city_name+'/'+this.ruleForm_sh.area_name
        this.$isMobile && (this.fieldValue_sh = this.ruleForm_sh.pro_name+'/'+this.ruleForm_sh.city_name+'/'+this.ruleForm_sh.area_name);
      }
      if(this.ruleForm_sh.pro_id&&this.ruleForm_sh.city_id&&this.ruleForm_sh.area_id){
        let cityidspliter_sh = this.ruleForm_sh.pro_id + ','+this.ruleForm_sh.city_id + ','+this.ruleForm_sh.area_id
        // console.log(cityidspliter_sh.split(','),'cityidspliter_sh.split(',')')
        if(this.ruleForm_sh.pro_id ==0 || this.ruleForm_sh.city_id==0 || this.ruleForm_sh.area_id==0){
          this.ruleForm.city_sh = null
        }else{
          this.ruleForm.city_sh = cityidspliter_sh.split(',');
        }

        this.$isMobile && (this.fieldValue_sh = this.ruleForm_sh.pro_name+'/'+this.ruleForm_sh.city_name+'/'+this.ruleForm_sh.area_name);
      }
    },
    // 编辑
    edit() {
      this.flag = true
      // this.ruleForm.membername = this.res.membername
      this.ruleForm.truename = this.res.truename
      // this.ruleForm.mobile = this.res.mobile
      // this.ruleForm.email = this.res.email
      // this.ruleForm.city = this.res.city;
      this.$isMobile && (this.fieldValue = this.res.city);
      this.ruleForm.city = this.res.cityidspliter && this.res.cityidspliter.split(',');
      if(this.ruleForm_sh.pro_id&&this.ruleForm_sh.city_id&&this.ruleForm_sh.area_id){
        let cityidspliter_sh = this.ruleForm_sh.pro_id + ','+this.ruleForm_sh.city_id + ','+this.ruleForm_sh.area_id
        // console.log(cityidspliter_sh.split(','),'cityidspliter_sh.split(',')')
        this.ruleForm.city_sh = cityidspliter_sh.split(',');
        this.$isMobile && (this.fieldValue_sh = this.ruleForm_sh.pro_name+'/'+this.ruleForm_sh.city_name+'/'+this.ruleForm_sh.area_name);
      }
      // this.ruleForm.identity = this.res.identity
      this.ruleForm.roleid = this.res.roleid;
      if(this.ruleForm.roleid ==1){
        this.ruleForm.identity = '执业医师'
        this.identity = '执业医师'
      }else if(this.ruleForm.roleid ==5){
        this.ruleForm.identity = '医学生'
        this.identity = '医学生'
      }else if(this.ruleForm.roleid ==0){
        this.ruleForm.identity = '其他'
        this.identity = '其他'
      }
      this.ruleForm.identity == '执业医师' && this.get_hospital();
      this.ruleForm.division = this.res.division;
      this.ruleForm.hospital = this.res.hospital
      this.ruleForm.joblevel = this.res.joblevel
      this.ruleForm.jobname = this.res.jobname

      this.ruleForm.majors = this.res.majors
      this.ruleForm.entranceYear = this.res.entranceYear
      this.ruleForm.qualification = this.res.qualification
      this.ruleForm.university = this.res.university

      this.ruleForm.otherCompany = this.res.otherCompany
      this.ruleForm.otherDepartment = this.res.otherDepartment
      this.ruleForm.otherJobname = this.res.otherJobname

      this.ruleForm.important = this.res.important

      // if (this.identity === '医生' || this.identity === '住院医生' || this.identity === '其他医务工作者') {
      //   // selectDivision().then((res) => {
      //   //   this.options2 = res
      //   // })
      //   // this.ruleForm.division = this.divisionArr
      //   this.ruleForm.roleid = null
      // } else if (this.identity === '医学院师生') {
      //   this.options3 = jueSe
      //   this.ruleForm.roleid = []
      //   this.ruleForm.roleid.push(jueSeOf1[this.roleid])
      //   this.ruleForm.division = null
      // } else if (this.identity === '其他') {
      //   this.options4 = jueSe2
      //   this.ruleForm.roleid = []
      //   this.ruleForm.roleid.push(jueSeOf1[this.roleid])
      //   this.ruleForm.division = null
      // }
      this.ruleForm.id = this.res.id
    },
    // 保存
    preserve() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // this.ruleForm.division = JSON.stringify(this.ruleForm.division)
          // if (this.ruleForm.roleid != null) {
          //   this.ruleForm.roleid.map((item) => {
          //     this.ruleForm.roleid = item
          //   })
          // }
          this.ruleForm.avatar = this.res.avatar
          delete this.ruleForm.mobile
          delete this.ruleForm.email
          if(this.ruleForm_sh.mobile ==''){
            this.ruleForm_sh.mobile = this.ruleForm.mobile
            this.ruleForm.shPhone  = this.ruleForm.shPhone?this.ruleForm.shPhone:this.ruleForm.mobile
          }
          // var params = { ...this.ruleForm,...this.ruleForm_sh }
          if(this.ruleForm.roleid ==1){
            this.ruleForm.identity = '执业医师'
            this.identity = '执业医师'
          }else if(this.ruleForm.roleid ==5){
            this.ruleForm.identity = '医学生'
            this.identity = '医学生'
          }else if(this.ruleForm.roleid ==0){
            this.ruleForm.identity = '其他'
            this.identity = '其他'
          }
          var params = { ...this.ruleForm }
          if(this.subscribe_info.service_type =='2' || this.subscribe_info.service_type =='4'){
            //包年用户才展示收获地址 
            this.submit_ShAddressFun()
          }
          if (Array.isArray(this.ruleForm.division) && this.ruleForm.division.length > 0) {
            var length = this.ruleForm.division.length
            params.division = this.ruleForm.division[length - 1]
          }
          if (!this.$isMobile) {
            if (this.ruleForm.city && this.ruleForm.city.length > 0) {
              params.cityidspliter = this.ruleForm.city.join(',');
              params.city = this.$refs['citycascader'].getCheckedNodes()[0].pathLabels.join('/');
            }
          } else {
            if (this.ruleForm.city && this.ruleForm.city.length > 0) {
              params.cityidspliter = this.ruleForm.city.join(',');
              params.city = this.fieldValue;
            }
          }
          params.emailUnsubscribe = params.ifReceiveDsf || params.ifReceiveJh || params.ifReceiveNejm ? 0 : 1
          params.ifReceiveDsf = params.ifReceiveDsf ? 1 : 0
          params.ifReceiveJh = params.ifReceiveJh ? 1 : 0
          params.ifReceiveNejm = params.ifReceiveNejm ? 1 : 0
          completeUserInfo(params).then((res) => {
            setSessionUser(res)
          })

          let ruleForm = this.ruleForm
          ruleForm.mobile = this.mobile
          ruleForm.email = this.email
          this.identity = ruleForm.identity
          // ruleForm.division = JSON.parse(ruleForm.division)
          // if (ruleForm.division != null) {
          //   this.divisionArr = ruleForm.division
          //   ruleForm.division = ruleForm.division[ruleForm.division.length - 1]
          // }
          if (Array.isArray(this.ruleForm.division) && this.ruleForm.division.length > 0) {
            // var length = this.ruleForm.division.length
            this.ruleForm.division = this.ruleForm.division[this.ruleForm.division.length - 1]
          }
          if (ruleForm.roleid != null && this.identity == '医学院师生') {
            ruleForm.roleid = jueSeOf[ruleForm.roleid]
            this.roleid = ruleForm.roleid
          }
          if (ruleForm.roleid != null && this.identity == '其他') {
            ruleForm.roleid = jueSeOf[ruleForm.roleid]
            this.roleid = ruleForm.roleid
          }
          if (!this.$isMobile) {
            if (this.ruleForm.city && this.ruleForm.city.length > 0) {
              ruleForm.cityidspliter = this.ruleForm.city.join(',');
              ruleForm.city = this.$refs['citycascader'].getCheckedNodes()[0].pathLabels.join('/');
            }
          } else {
            if (this.ruleForm.city && this.ruleForm.city.length > 0) {
              ruleForm.cityidspliter = this.ruleForm.city.join(',');
              ruleForm.city = this.fieldValue;
            }
          }
          this.res = ruleForm
          this.$message.success('保存成功')
          this.$bus.emit('user-change', this.ruleForm)
          this.flag = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    change(val) {
      ++this.isRescourceShow
      if (val === '医生' || val === '住院医生' || val === '其他医务工作者') {
        // this.options3 = []
        // this.options4 = []
        selectDivision().then((res) => {
          this.options2 = res
        })
        this.ruleForm.roleid = null
      } else if (val === '医学院师生') {
        // this.options2 = []
        // this.options4 = []
        this.ruleForm.roleid = null
        this.ruleForm.division = null
        this.options3 = jueSe
      } else if (val === '其他') {
        // this.options2 = []
        // this.options3 = []
        this.ruleForm.roleid = null
        this.ruleForm.division = null
        this.options4 = jueSe2
      }
    },
    editAvatar() {
      this.upAvatar = []
      this.upAvatar.push({ url: this.res.avatar })
      this.dialogVisible = true
    },
    editAvatar1() {
      this.initialize()
    },
    async initialize() {
      let res = await getMemberInfo()
      if (res.avatar == null) {
        res.avatar = mrtx
      }
      this.res = res
      this.ruleForm.roleid = res.roleid
      if(this.ruleForm.roleid ==1){
        this.ruleForm.identity = '执业医师'
        this.identity = '执业医师'
      }else if(this.ruleForm.roleid ==5){
        this.ruleForm.identity = '医学生'
        this.identity = '医学生'
      }else if(this.ruleForm.roleid ==0){
        this.ruleForm.identity = '其他'
        this.identity = '其他'
      }
      if(this.vipList.length>0&&res.subscribePo&&this.$isMobile){
        let vipInfo =  this.vipList.filter(item=>item.title == res.subscribePo.serviceTitle)
      // console.log(vipInfo,'vipInfo') 
      // console.log(vipInfo[0].member_equity_id,'vipInfo.member_equity_id') 
        let vipitem = vipInfo[0].member_equity_id
        let list = vipitem.split(",")//包含的权益
        // console.log(list,'list')
        let nolist = this.equitylist.filter(item=>!list.includes(item.id))
        // console.log(nolist,'===不包含的权益')
        this.noEquitylist = nolist || [];
        let classlist = document.getElementById('vip_Equitylist'); 
        // console.log(classlist,'classlist')
        list.forEach(item => {
            let listItemss = classlist.querySelector('.vip_Equity_item[data-id="' + item + '"]');
            let lockItem = classlist.querySelector('.lock[data-id="' + item + '"]');
            // console.log(listItemss,'listItemss')
            // console.log(lockItem,'lockItem1')
            listItemss.classList.remove('opacity')
            lockItem.classList.remove('display_block')
        });
        this.noEquitylist.forEach(n=>{
            let listItem = classlist.querySelector('.vip_Equity_item[data-id="' + n.id + '"]');
            let lockItem = classlist.querySelector('.lock[data-id="' + n.id + '"]');
            listItem.classList.add('opacity')
            lockItem.classList.add('display_block')
        })
        if(this.subscribe_info.service_type ==0){
          let classlist = document.getElementById('vip_Equitylist'); 
          this.equitylist.forEach(n=>{
              let listItem = classlist.querySelector('.vip_Equity_item[data-id="' + n.id + '"]');
              let lockItem = classlist.querySelector('.lock[data-id="' + n.id + '"]');
              listItem.classList.add('opacity')
              lockItem.classList.add('display_block')
          })
        }  
      }    
      this.$forceUpdate()
      setSessionUser(res)
    },
    async saveAvatar() {
      this.ruleForm.avatar = this.upAvatar[0].url
      this.res.avatar = this.upAvatar[0].url
      let params = JSON.parse(JSON.stringify(this.res))
      params.division = this.division
      if (params.roleid != null && params.identity == '医学院师生') {
        params.roleid = jueSeOf[params.roleid]
      }
      if (params.roleid != null && params.identity == '其他') {
        params.roleid = jueSeOf[params.roleid]
      }
      await completeUserInfo(params)
      this.$message.success('修改成功')
      this.dialogVisible = false
      this.$emit('editAvatar')
    },
  },
}
</script>
<style lang="less" scoped>
.clearboth{
  clear: both;
}
// mobile 开始
.mobile {
  
 /* 调整iPhone X 的刘海屏 */
 @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        .userContainer  #user_vip_wrap_lf{
            gap: 18px !important;
        }
        .vip_Equity .vip_Equity_item{
          padding: 5px 2px !important;
          margin-right: 3px !important;
        }
    }
  .userContainer {
    .el-form {
      display: flex;
      flex-wrap: wrap;

      .el-form-item {
        width: 100%;
        font-size: 16px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;

        .el-input {
          width: 250px;
        }
      }

      .editFitContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .editFit {
          font-size: 14px;
          width: 80px;
        }
      }
    }

    .user_vip_wrap_box {
      margin-bottom: 10px;
    }

    .user_vip_wrap_lf {
      gap: 14px !important;
    }

    .user_vip_time {
      font-family: PingFangSC-Medium;
      font-size: 16px !important;
      color: #874408;
      font-weight: 500;
      // span{
      //   &:nth-child(1){
      //     font-family: PingFangSC-Medium;
      //     font-size: 14px;
      //     color: #492607;
      //   }
      //   &:nth-child(2){
      //     font-family: PingFangSC-Regular;
      //     font-size:12px;
      //     color: #492607;
      //     font-weight: 400;
      //   }
      // }
    }

    .user_vip_type {
      font-family: PingFangSC-Regular;
      font-size: 14px !important;
      color: #492607;
      font-weight: 400;
    }

    .user_vip_open {
      padding: 6px 12px;
    }
  }
  .opacity {
      opacity: 0.5;
  }
  .display_block{
      display: block !important;
  }
  //会员权益
  .vip_Equity {
    padding: 0;
    max-height: 300px;
    height: 250px;

    .vip_Equity_item {
      padding: 5px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 6px;
      float: left;
      border-radius: 4px;
      margin-right: 5px;
      margin-bottom: 10px;
      border: 1px solid #5b666d;
      position: relative;
      .lock{
          width: 20px;
          height: 20px;
          position: absolute;
          right: 6%;
          top: 11px;
          display: none;
        }
    }

    .vip_Equity_item:nth-child(3) {
      // margin-right: 0;
    }

    .vip_Equity_item:last-child {
      // margin-bottom: 73px;
    }

    .vip_Equity_item img {
      width: 32px;
      height: 32px;
    }

    .vip_Equity_item_info {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .vip_Equity_item_info div:nth-child(1) {
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
    }

    .vip_Equity_item_info div:nth-child(2) {
      font-family: PingFangSC-Regular;
      font-size: 10px;
      color: #666666;
      font-weight: 400;
    }
  }

  .no_vip_title {
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      color: #E52E00;
      font-weight: 600;
      margin: 25px 0;
  }

  .user_vip_info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        padding-left: 20px;
        div {
          // font-size: 16px;
          font-size: 14px !important;
          // font-family: PingFangSC, PingFangSC-Regular;
          // color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
          color: #6C431F;
          font-weight: 400;

          span {
            font-size: 16px;
            // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            color: #6C431F;
            line-height: 24px;
            display: inline-block;
            margin-right: -5px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
          }
          &:nth-child(1) span{
            margin-right: 0 !important;
          }
          &:nth-child(2) span{
            margin-right: 0 !important;
          }
          &:nth-child(4) span{
            margin-right: 0 !important;
          }
        }
      }


}

// mobile 结束 


.userContainer {
  padding: 16px 0;

  .user {
    display: flex;

    .avatarContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .avatar {
        // width: 87px;
        // height: 87px;
        border: 1px solid #e52e00;
        margin-right: 17px;
      }
    }

    .user2 {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      div {
        font-size: 16px;
        // font-family: PingFangSC, PingFangSC-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;

        span {
          font-size: 16px;
          // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          color: rgba(0, 0, 0, 0.65);
          line-height: 24px;
          display: inline-block;
          margin-right: -5px;
        }
      }
    }
  }

  /**
    会员相关开始
    */
  .vipby {
    background-size: 100%;
    background: url(../../assets/vip/baoyue_vip.png) no-repeat center;
  }

  .vipbn {
    background-size: 100%;
    background: url(../../assets/vip/baonian_vip.png) no-repeat center;
  }

  .novip {
    background: #FFFFFF;
  }

  .novip_mobile {
    background: #FFF !important;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  .bn_btn {
    background: #C17F42;
  }

  .by_btn {
    background: #4E4E4E;
    // border-radius: 31.5px;
    color: #FFF;
  }

  .novip_btn {
    background: #E52E00;
    padding: 7px 25px !important;
  }

  .novip_btn_mobile {
    background: #fff;
    border: 2px solid rgba(135, 68, 8, 1);
    border-radius: 31px;
    color: #874408 !important;
  }

  .user_vip_wrap {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-size: 100%;

    .user_vip_box {
      padding: 18px 18px 21px 21px;
      display: flex;
      align-items: center;

      .user_vip_logo {
        margin-right: 27px;
        width: 87px;
        height: 87px;
        line-height: 87px;
        font-size: 18px;
        .avatar{
          width: 100%;
          height: 100%;
        }
      }

      .user_vip_info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        padding-left: 20px;
        div {
          font-size: 16px;
          // font-family: PingFangSC, PingFangSC-Regular;
          // color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
          color: #6C431F;
          font-weight: 400;

          span {
            font-size: 16px;
            // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            color: #6C431F;
            line-height: 24px;
            display: inline-block;
            margin-right: -5px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
          }
          &:nth-child(1) span{
            margin-right: 32px;
          }
          &:nth-child(2) span{
            margin-right: 45px;
          }
          &:nth-child(4) span{
            margin-right: 64px;
          }
        }
      }

      .user_vip_open {
        // background: #E52E00;
        border-radius: 21px;
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 45px;
        // width: 170px;
        // height: 42px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
  .task_address{
    padding: 10px 0 20px 0;
  }

  //.moible start
  .user_vip_wrap_box {
    // padding: 0 24px;
  }

  .user_vip_wrap_mobile {
    /* width: 100%; */
    // height: 137px;
    // height: 120px;
    height: 68px;
    background-color: #d2d2d2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 0 20px;
    background-size: 100%;
  }

  .user_vip_wrap_lf {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  .user_vip_wrap_lf .user_vip_time {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #492607;
    font-weight: 500;
  }

  .user_vip_wrap_lf .user_vip_type {
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #492607;
    font-weight: 400;
  }

  .user_vip_wrap_lf .user_vip_time,
  .user_vip_wrap_lf .user_vip_type {
    display: flex;
    gap: 15px;
  }

  .user_vip_open {
    padding: 6px 24px;
    // background: #C17F42;
    border-radius: 31px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 400;
  }

  /**
    会员相关结束
    */
  .el-form-item {
    display: inline-block;
    width: 44%;
    font-size: 16px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;

    .el-input {
      width: 210px;
    }
  }

  .res {
    width: 100%;

    /deep/.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #b8b8b8;
        border-color: #b8b8b8;
      }
    }

    /deep/.ant-checkbox-checked::after {
      border: 1px solid #b8b8b8;
    }

    /deep/.ant-checkbox-wrapper:hover {
      .ant-checkbox-inner {
        border-color: #b8b8b8;
      }
    }
  }

  .el-button {
    background: #e52e00;
    border-radius: 4px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  }

  .update {
    width: 100%;
    display: flex;
    justify-content: center;

    .zhuxiao {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 24px;
      background: transparent;
      width: 100px;
      padding-bottom: 0;
    }
  }

  .xingming {
    width: 250px !important;
  }

  .linchuang {
    width: 178px !important;
  }

  .shenfen {
    width: 250px !important;
  }

  .keshi {
    width: 250px !important;
  }

  .xxxx {
    width: 178px !important;
  }
}

/deep/.el-form-item__label {
  padding-right: 0;
  font-size: 16px;
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  color: rgba(0, 0, 0, 0.65);
}

/deep/.el-input__inner {
  font-size: 14px;
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}</style>
