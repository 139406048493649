<template>
  <a-auto-complete
    ref="complete"
    placeholder="请输入想要搜索的内容"
    optionLabelProp="value"
    @select="onSearch"
    v-model="query"
    :defaultActiveFirstOption="false"
    style="font-size: 18px; font-weight: 400; color: rgba(0, 0, 0, 0.45)"
  >
    <template slot="dataSource">
      <a-select-option v-for="(item, i) of options" :key="item" :value="item">
        {{ item }}
        <a-button
          v-if="i === options.length - 1"
          @click.stop="clear"
          type="link"
          style="position: absolute; bottom: 0; right: 0"
        >
          清空记录
        </a-button>
      </a-select-option>
    </template>
    <a-input v-model="query" @pressEnter="onSearch()" size="large">
      <a-icon style="font-size: 20px; color: #999" slot="suffix" type="search" @click.stop="onSearch()" />
    </a-input>
  </a-auto-complete>
</template>

<script>
const prefix = 'nejm'

export default {
  name: 'BzSearch',
  data() {
    return {
      query: null,
      options: [],
    }
  },
  created() {
    this._cacheKey = `${prefix}_query`
    this.options = this._getCacheOptions()
    this.query = this.$route.query.query
  },
  methods: {
    hide() {
      // this.$refs.complete.blur()
      this.query = null
    },
    onSearch(query) {
      console.log(query, 'aa')
      if (query == null) {
        query = this.query
      }
      if (query == null || query === '') {
        return
      }
      if (!this.options.includes(query)) {
        this.options.unshift(query)
        if (this.options.length > 10) {
          this.options.length = 10
        }
      }
      this._setCacheOptions(this.options)
      // this.query = null
      this.$emit('search', query)
    },
    clear() {
      this.options = []
      this.query = null
      this._setCacheOptions(this.options)
    },
    _getCacheOptions() {
      let options = localStorage.getItem(this._cacheKey)
      if (options != null) {
        options = JSON.parse(options)
      }
      return options || []
    },
    _setCacheOptions(options) {
      if (options == null) {
        options = []
      }
      options = JSON.stringify(options)
      localStorage.setItem(this._cacheKey, options)
    },
  },
}
</script>

<style lang="less">
.ant-select-auto-complete.ant-select .ant-input {
  padding: 20px;
}
</style>
