<template>
  <div>
    <a-modal
      :class="status"
      :centered="false"
      :mask="false"
      :dialog-style="dialogStyle"
      v-model="visible"
      width="370px"
      footer=""
      @cancel="_close()"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <div class="wx_login" v-if="status === 'unLogin' && showWxImgcodeFlag">
        <a-button type="link" @click="handleReturn()"><a-icon type="left" /></a-button>
      </div>
      <div v-if="status === 'logined'">
        <div style="position: absolute; top: 0; left: 0; right: 0; height: 78px; background-color: #f2f2f2"></div>
        <div
          style="
            position: relative;
            top: -30px;
            z-index: 1000;
            margin-top: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div class="info-img">
            <div>
              <img
                class="defaultImg"
                v-if="info.avatar == null || info.avatar == '' || info.avatar == 'null'"
                :src="defaultImg"
                alt=""
              />
              <img class="avatar" v-else :src="info.avatar" alt="" />
            </div>
          </div>
          <div class="info-ct" style="padding-top: 50px">
            <div
              style="
                font-size: 20px;
                <!-- font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular; -->
                color: rgba(0, 0, 0, 0.85);
                width: 229px;
              "
              class="ellipsis"
            >
              {{ info.membername }}
            </div>
            <div style="margin-top: 12px">
              <el-tag
                type="info"
                :color="info.identity == '' ? '#fff' : ''"
                style="border-radius: 2px"
                :style="info.identity == '' ? 'border:none' : 'border:1px solid #e52e00'"
              >
                {{ info.identity == '0'?'其他': info.identity }}
              </el-tag>
            </div>
            <div style="margin-top: 16px">
              <div
                @click="toPersonalPage('flag2')"
                style="
                  cursor: pointer;
                  float: left;
                  color: #e52e00;
                  font-size: 14px;
                  <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
                  width: 80px;
                "
              >
                <i class="el-icon-star-off"></i>
                我的收藏
              </div>
              <div
                @click="toPersonalPage('flag3')"
                style="
                  cursor: pointer;
                  margin-left: 15px;
                  float: left;
                  font-size: 14px;
                  <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
                  color: rgba(0, 0, 0, 0.65);
                  width: 80px;
                "
              >
                <i class="el-icon-view"></i>
                阅读记录
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; display: flex; margin-top: 5px; border-top: 1px solid #eeeeee">
          <span @click="toPersonalPage('flag1')" class="optBtn1">个人中心</span>
          <span @click="logout" class="optBtn1">退出登录</span>
        </div>
      </div>
      <div v-if="status === 'unLogin'">
        <div style="margin-top: 0; display: flex; align-items: center; justify-content: center">
          <form style="width: 80%; margin-left: 10%; margin-right: 10%">
            <div class="loginLogo">
              <img class="logo" src="../../../assets/imgs/logo/logo.png" alt="NEJM医学前沿" />
            </div>
            <div class="ligin-title">用户登录</div>
            <div id="wechatimgCode" v-show="showWxImgcodeFlag"></div>
            <div v-show="!showWxImgcodeFlag">
              <div style="margin-top: 18px"><a-input v-model="accountName" placeholder="请输入用户名、手机或邮箱" /></div>
              <div style="margin-top: 16px">
                <a-input-password v-model="password" placeholder="请输入密码" @keyup.enter.native="login" />
              </div>
              <div style="margin-top: 8px">
                <!-- :style="!$isMobile ? 'width:17%' : 'width:20%'" -->
                <div
                  @click="forgetPwd"
                  style="
                    width: 50%;
                    text-align: left;
                    float: left;
                    font-size: 12px;
                    <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
                    color: rgba(0, 0, 0, 0.65);
                    cursor: pointer;
                  "
                >
                  忘记密码
                </div>
                <!-- <div
                  @click="showCodeLogin"
                  :style="!$isMobile ? 'width:33%' : 'width:30%'"
                  style="
                    text-align: left;
                    float: left;
                    font-size: 12px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
                    color: rgba(0, 0, 0, 0.65);
                    cursor: pointer;
                  "
                >
                  验证码登录
                </div> -->
                <div
                  @click="goReg"
                  style="
                    width: 50%;
                    text-align: right;
                    float: right;
                    font-size: 12px;
                    <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
                    color: rgba(0, 0, 0, 0.65);
                    cursor: pointer;
                  "
                >
                  注册
                </div>
              </div>
              <div style="margin-top: 47px">
                <a-button
                  @click="login"
                  style="
                    width: 100%;
                    height: 34px;
                    border-radius: 2px;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35);
                    font-size: 16px;
                    <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
                  "
                  type="primary"
                >
                  登录
                </a-button>
              </div>
              <div v-show="!$isMobile||($isMobile && this.isWeiXin())">
                <div class="otherLoginTitle">
                  <span class="leftLine"></span>
                  <span class="textDes">其他登录方式</span>
                  <span class="rightLine"></span>
                </div>
                <ul class="otherLoginMethods o_g js_otherLoginMethods">
                  <li>
                    <a :href="otherLoginUrlWeChat" @click="wxLoginBind($event)">
                      <img class="wechatimg" src="../../../assets/imgs/wechat.png" width="32" />
                    </a>
                  </li>
                </ul>
                
              </div>
            </div>
          </form>
        </div>
      </div>
    </a-modal>
    <!-- <a-modal :class="status"
      :centered="false"
      :mask="false"
      :dialog-style="{top: '75px', left: '455px'}"
      v-model="visible"
      width="455px"
      title=""
      footer=""
      @cancel="_close()"
      v-bind="$attrs"
      v-on="$listeners">
      <div id="wechatimgCode" style=""></div>
    </a-modal> -->
    <forget-pwd-dialog :visible.sync="forgetDialog" v-if="forgetDialog"></forget-pwd-dialog>
    <code-login v-if="codeVisible" :visible.sync="codeVisible" :codeParams="codeParams"></code-login>
  </div>
</template>

<script>
import { PATH_REGISTER } from '@/router/article/paths'
import { PATH_PERSONAL } from '@/router/personal/path'
import { login, userLogout } from '@/api/login'
import { setSessionUser, logoutLocal } from '@/utils/app-utils'
import ForgetPwdDialog from '@/components/home/components/ForgetPwdDialog'
import CodeLogin from '@/components/home/components/CodeLogin'
import { mapMutations } from 'vuex'
import { wechatJudgeBind } from '@/api/login'
import {SETTINGS} from './setting-wechatAppidSet';

const prefix = 'nejm'

export default {
  name: 'UserLogin',
  components: { ForgetPwdDialog, CodeLogin },
  props: {
    visible: {
      type: Boolean,
      default() {
        return false
      },
    },
    status: {
      type: String,
    },
    info: {
      type: Object,
    },
  },
  data() {
    return {
      // status: 'logined',
      // status: 'unLogin',
      // 微信开放平台
      appid: SETTINGS.appid,
      // 公众号
      gzAppid:SETTINGS.gzAppid,
      otherLoginUrlWeChat: '',
      // // 信开放平台/微信公众号授权登录的回调地址
      rect_host: SETTINGS.rect_host,
      showWxImgcode: false,
      accountName: null,
      password: null,
      forgetDialog: false,
      defaultImg: require('../../../assets/imgs/avatar.png'),
      codeVisible: false,
      codeParams: {},
      query: null,
      options: [],
    }
  },
  created() {
    console.log('created::', this.status, this.info)
    this._cacheKey = `${prefix}_query`
    // this.options = this._getCacheOptions()
    this.query = this.$route.query.query
    // this.accountName = null
    // this.password = null
  },
  mounted(){
    
    if (this.isWeiXin()) {
      this.showWxImgcode = false;
      var encodeUrl = encodeURIComponent(this.rect_host+'/wechat/wxlogin')
      // 公众号（公众平台）认证登录
      this.otherLoginUrlWeChat = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+this.gzAppid+'&redirect_uri='+encodeUrl+'&response_type=code&scope=snsapi_userinfo&state=1081627960967208960#wechat_redirect'
      // var html = '<script type="text/javascript" src="//res2.wx.qq.com/open/js/jweixin-1.4.0.js"><\/script>';
      // window.document.write(html);
    } else {
      // 微信开放平台 认证登录
      this.otherLoginUrlWeChat = 'https://open.weixin.qq.com/connect/qrconnect?appid='+this.appid+'&redirect_uri='+this.rect_host+'/wechat/wxlogin&response_type=code&scope=snsapi_login&state=1081627960967208960'
    }
    // this.creatImageCode()
  },
  computed: {
    dialogStyle() {
      // let style = { top: '75px', left: '400px' }
      let style = { top: '75px', left: '0px' }
      if (this.$isMobile) {
        style = null
      }
      return style
    },
    showWxImgcodeFlag(){
      return (this.visible && this.showWxImgcode)==true?true:false;
    }
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    handleReturn(){
      this.showWxImgcode = false;
    },
    // 生成二维码；
    creatImageCode(){
      var obj = new window.WxLogin({
        self_redirect:false,
        id:"wechatimgCode", 
        appid: this.appid, 
        scope: "snsapi_login", 
        redirect_uri: encodeURIComponent(this.rect_host+"/wechat/wxlogin"), 
        state: 1081627960967208960, 
        style: "black",
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCiN3eF9kZWZhdWx0X3RpcCBwOmxhc3QtY2hpbGQgew0KICBkaXNwbGF5OiBub25lOw0KfQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7DQogIHdpZHRoOiAxNjdweDsNCn0NCi5pbXBvd2VyQm94IC53cnBfY29kZSB7DQogIHdpZHRoOiAyNTdweDsNCn0NCi5pbXBvd2VyQm94IC5pbmZvIHsNCiAgICB3aWR0aDogMjc1cHg7DQogICAgbWFyZ2luOiAwOw0KfQ=="
      });
      console.log(obj);
    },
    //判断是否是微信浏览器
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    JudgeBind(code){
      var params = {
        code: code,
      }
      var vm =this;
      wechatJudgeBind(params).then((res) => {
        if(!res.result){
          this.setUser(res.tokenString)
          setSessionUser(res.tokenString)
          window.sessionStorage.setItem('memberId', res.tokenString.id)
          this.$message.success('登录成功')  
        }else {
          vm.$router.push(`/wxloginBindPhone?code=${code}`);
        }
      })
    },
    wxLoginBind(event){
      // var vm =this;
      if(this.isWeiXin()){
        console.log(2222);
        this.showWxImgcode = false;
        // event.preventDefault();
        // var time = Date.parse(new Date()).toString();
        // var times = time.substr(0, 10);
        // var APPID = 'wxe8711f0a5d7e59ed';
        // var params = {
        //   appId: APPID,
        //   noncestr: times,
        //   timestamp: times,
        //   url: window.location.href
        // }
        // wechatsignature(params).then(function(res){
        //   window.wx.config({
        //     debug: false,
        //     appId: APPID,
        //     timestamp: times,
        //     nonceStr: times,
        //     signature: res.jsSignature,
        //     jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"]
        //   });
        //   window.wx.ready(function(){
        //     // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        //   });
        //   window.wx.error(function(res){
        //     // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        //   });
        // })
      } else {
        event.preventDefault();
        this.showWxImgcode = true;
        this.creatImageCode();
        // console.log(11111111);
      }
    },
    _close() {
      console.log('close')
      this.showWxImgcode = false;
      this.$emit('update:visible', false)
    },
    goReg() {
      this._close()
      this.$router.push({ path: PATH_REGISTER })
    },
    login() {
      var params = {
        accountName: this.accountName,
        password: this.password,
      }
      console.log(params, 'params')
      if (this.accountName == null || this.accountName == '') {
        this.$message.warning('请输入用户名、手机或邮箱')
      } else if (this.password == null || this.password == '') {
        this.$message.warning('请输入密码')
      } else {
        login(params).then((res) => {
          this.setUser(res)
          setSessionUser(res)
          console.log(res,'=====登录成功')
          window.sessionStorage.setItem('memberId', res.id)
          // window.sessionStorage.setItem('uid', res.pcClientPo.uid)
          // window.sessionStorage.setItem('access_token', res.pcClientPo.accessToken)
          // window.sessionStorage.setItem('client_id', res.pcClientPo.clientId)
          // window.sessionStorage.setItem('pcClientPo',res.pcClientPo)
          // window.sessionStorage.setItem('membername',res.membername)
          // window.sessionStorage.setItem('token',res.token)
          this.$message.success('登录成功')
          if (res.ifNeedUpdate) {
            this.$notification.warning({
              message: '提示',
              description: '请及时更新用户资料',
              onClick: () => {
                console.log('Notification Clicked!')
              },
            })
          }
          this.$emit('update:visible', false)
        })
      }
    },
    async logout() {
      await userLogout()
      logoutLocal()
      this.setUser(null)
      // this.$message.success('退出成功')
      this.$emit('update:visible', false)
      localStorage.removeItem('token')
      window.sessionStorage.setItem('token',null)
      window.sessionStorage.setItem('memberId', null)
      if (this.$route.path === '/personal') {
        this.$router.push('/')
      }
      this.options = []
      this.query = null
      this._setCacheOptions(this.options)
    },
    _setCacheOptions(options) {
      if (options == null) {
        options = []
      }
      options = JSON.stringify(options)
      console.log(options+'33333333333333333333333333')
      localStorage.setItem(this._cacheKey, options)
      location.reload()
    },
    toPersonalPage(flag) {
      this.$router.push({ path: PATH_PERSONAL, query: { type: flag } })
      this.$emit('update:visible', false)
    },
    async forgetPwd() {
      this.forgetDialog = true
      this.$emit('update:visible', false)
    },
    showCodeLogin() {
      this.codeVisible = true
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="less" scoped>
.wx_login {
      height: 0;
      position: absolute;
    left: 4px;
    top: 14px;
}
.loginLogo {
  height: 50px;
  text-align: center;
  .logo {
    height: 46px;
  }
}
#wechatimgCode {
    text-align: center;
    width: 257px;
    height: 275px;
    overflow: hidden;
}
.otherLoginMethods {
  margin-top: 0.33rem;
  padding-bottom: 0.2rem;
  text-align: center;
}
.otherLoginTitle {
  margin: 20px 0 ;
  span.leftLine {
    display: inline-block;
    width: 40px;
    height: 2px;
    background-color: #ccc;
    vertical-align: middle;
    margin-right: 27px;
    margin-left: 23px;
  }
  span.textDes {
    color: #ccc;
  }

  span.rightLine {
    display: inline-block;
    width: 40px;
    height: 2px;
    background-color: #ccc;
    vertical-align: middle;
    margin-right: 0px;
    margin-left: 25px;
  }
}
.o_g {
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 0 !important;
  li {
    display: inline-block;
    width: 20%;
    text-align: center;
    a.iconweixin {
      color: #d9f2cb;
      font-size: 0.4rem;
      line-height: 1;
    }
  }
}
.info-img {
  width: 40%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    border-radius: 50%;
    background-color: #fff;
    width: 141px;
    height: 141px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.avatar {
  width: 129px;
  height: 129px;
  border-radius: 50%;
  border: solid 1px #e52e00;
}

.defaultImg {
  width: 129px;
  border-radius: 0;
  border: none;
}
.info-ct {
  width: 60%;
}
.ligin-title {
  font-size: 16px;
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  color: rgba(0, 0, 0, 1);
  font-weight: 900;
  width: 100%;
}
.optBtn {
  flex: 1;
  line-height: 30px;
}
.optBtn + .optBtn {
  margin-left: 10px;
}
</style>
<style>
.logined .ant-modal-body {
  padding: 0;
  padding-top: 20px;
}
.mobile .ant-modal-body .otherLoginTitle  span.leftLine {
    margin-right: 27px;
    margin-left: 23px;
  }
.mobile .ant-modal-body .otherLoginTitle  span.rightLine {
    margin-right: 0px;
    margin-left: 25px;
  }
.optBtn1 {
  text-align: center;
  flex: 1;
  font-size: 16px;
  /* font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; */
  line-height: 47px;
  cursor: pointer;
}
.optBtn1 + .optBtn1 {
  border-left: 1px solid #eeeeee;
  font-size: 16px;
  /* font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; */
  line-height: 47px;
  color: rgba(0, 0, 0, 0.65);
}
</style>
