<template>
  <a-form-model
    class="userInfoForm"
    :layout="$isMobile ? 'vertical' : null"
    ref="infoForm"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    :model="infoForm"
  >
    <div v-if="!$isMobile" style="margin: 0 0 10px 20px; font-weight: bold">请完善你的个人信息</div>
    <a-form-model-item prop="truename" label="姓名" :rules="[{ required: true, trigger: 'blur', message: '请输入姓名' }]">
      <a-input v-model="infoForm.truename" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item class="formModelItem" prop="identity" label="补全信息">
      <identity-select
        @change="handleTaget"
        style="width: 100%"
        v-model="infoForm.identity"
      ></identity-select>
    </a-form-model-item>
    <a-form-model-item v-if="!!infoForm.identity" class="formModelItem" prop="city" :rules="[{ required: true, trigger: 'change', message: '请选择城市' }]" label="地区">
      <el-cascader v-model="infoForm.city" v-if="!$isMobile" :options="cityOptions" ref="citycascader" :props="cityProps"
        :style="{width: '100%'}" placeholder="请选择城市：" @change="handleCityChange" clearable>
      </el-cascader>
      <template v-else>
        <el-input v-model="fieldValue" placeholder="请选择地区：" @click.native="show = true" readonly clearable :style="{width: '100%'}"></el-input>
        <van-popup v-model="show" round position="bottom">
          <van-cascader
            v-model="fieldCityIdValue"
            title="请选择所在地区"
            :options="cityOptions"
            @close="show = false"
            @finish="onFinish"
            :field-names="fieldNames"
          />
        </van-popup>
      </template>
    </a-form-model-item>

    <!-- 执业医师 -->
    <template v-if="infoForm.identity == '执业医师'">
      <a-form-model-item  class="formModelItem" prop="hospital" label="医院" :rules="[{ required: true, trigger: 'change', message: '请选择医院' }]">
        <el-select :style="{width: '100%'}" filterable v-model="infoForm.hospital" :disabled="!infoForm.city" placeholder="请选择">
          <el-option
            v-for="item in hospitalOptions"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </a-form-model-item>
      <a-form-model-item  class="formModelItem" prop="division" label="科室" :rules="[{ required: true, trigger: 'change', message: '请选择科室' }]">
        <el-cascader v-model="infoForm.division" ref="divisioncascader" :options="divisionOptions" :props="divisionProps"
          :style="{width: '100%'}" placeholder="请选择科室：" clearable></el-cascader>
      </a-form-model-item>
      <a-form-model-item  class="formModelItem" prop="joblevel" label="职称" :rules="[{ required: true, trigger: 'change', message: '请选择职称' }]">
        <joblevel-select style="width: 100%" v-model="infoForm.joblevel"></joblevel-select>
      </a-form-model-item>
      <a-form-model-item class="formModelItem" prop="jobname" label="职务" :rules="[{ required: true, trigger: 'change', message: '请选择职务' }]">
        <jobtitle-select style="width: 100%" v-model="infoForm.jobname"></jobtitle-select>
      </a-form-model-item>
    </template>

    <!-- 医学生 -->
    <template v-if="infoForm.identity == '医学生'">
      <a-form-model-item class="formModelItem" prop="university" label="学校" :rules="[{ required: true, trigger: 'change', message: '请选择学校' }]">
        <!-- <a-input v-model="infoForm.university" placeholder="请选择" /> -->
        <el-select :style="{width: '100%'}" v-model="infoForm.university" :disabled="!infoForm.city" placeholder="请选择">
          <el-option
            v-for="item in universityOptions"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </a-form-model-item>
      <a-form-model-item class="formModelItem" prop="majors" label="专业" :rules="[{ required: true, trigger: 'blur', message: '请输入专业' }]">
        <a-input v-model="infoForm.majors" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item class="formModelItem" prop="entranceYear" label="入学年份" :rules="[{ required: true, trigger: 'change', message: '请选择学年份' }]">
        <el-date-picker
        :style="{width: '100%'}"
        v-model="infoForm.entranceYear"
        :picker-options="pickerOptions"
        type="year"
        value-format="yyyy"
        placeholder="选择年">
      </el-date-picker>
      </a-form-model-item>
      <a-form-model-item class="formModelItem" prop="qualification" label="学历" :rules="[{ required: true, trigger: 'change', message: '请选择学历' }]">
        <!-- <a-input v-model="infoForm.joblevel" placeholder="请输入" /> -->
        <dataset-select style="width: 100%" v-model="infoForm.qualification"></dataset-select>
      </a-form-model-item>
    </template>
    <!-- 其他 -->
    <template v-if="infoForm.identity == '其他'">
      <a-form-model-item class="formModelItem" prop="otherCompany" label="单位" :rules="[{ required: true, trigger: 'blur', message: '请输入单位' }]">
        <a-input v-model="infoForm.otherCompany" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item class="formModelItem" prop="otherDepartment" label="部门" :rules="[{ required: true, trigger: 'blur', message: '请输入部门' }]">
        <a-input v-model="infoForm.otherDepartment" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item class="formModelItem" prop="otherJobname" label="职务" :rules="[{ required: true, trigger: 'blur', message: '请输入职务' }]">
        <a-input v-model="infoForm.otherJobname" placeholder="请输入" />
      </a-form-model-item>
    </template>
    <!-- <a-form-model-item class="formModelItem" prop="important" label="临床/研究重点">
      <a-input v-model="infoForm.important" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item class="formModelItem" prop="important" label="临床/研究重点">
      <a-input v-model="infoForm.important" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item class="formModelItem" prop="joblevel" label="职称">
      <a-input v-model="infoForm.joblevel" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item
      v-if="infoForm.identity == '医学生' || infoForm.identity == '其他'"
      class="formModelItem"
      prop="roleid"
      label="详细信息"
    >
      <roles-select :identity="infoForm.identity" style="width: 100%" v-model="infoForm.roleid"></roles-select>
    </a-form-model-item>
    <a-form-model-item v-else class="formModelItem" prop="division" label="科室">
      <division-select style="width: 100%" v-model="infoForm.division"></division-select>
    </a-form-model-item> -->
    <a-checkbox style="margin-left: 7px" @change="onChange1" :checked="infoForm.ifReceiveNejm">
      A. 我同意接收《NEJM医学前沿》发送的邮件或短信。
    </a-checkbox>
    <a-checkbox style="margin-left: 7px" @change="onChange2" :checked="infoForm.ifReceiveJh">
      B. 我同意接收嘉会医学研究和教育集团（J-Med）发送的邮件或短信。
    </a-checkbox>
    <a-checkbox style="margin-left: 7px" @change="onChange3" :checked="infoForm.ifReceiveDsf">
      C. 我同意接收《NEJM医学前沿》相关第三方发送的邮件或短信。
    </a-checkbox>
    <div style="margin-left: 7px; font-size: 12px; line-height: 30px">注：您可以随时更改信息接收选择</div>
    <div style="margin-top: 25px; text-align: center">
      <a-button @click="submitData('infoForm')" style="width: 50%" type="primary">提交</a-button>
    </div>
  </a-form-model>
</template>

<script>
import IdentitySelect from '@/components/select/IdentitySelect'
import JoblevelSelect from '@/components/select/JoblevelSelect'
import JobtitleSelect from '@/components/select/JobtitleSelect'
import DatasetSelect from '@/components/select/DatasetSelect'
import { completeUserInfo,get_city,get_hospital,get_division,get_university } from '@/api/login'
import { setSessionUser } from '@/utils/app-utils'
// const jueSeOf = {
//   1: '执业医师',
//   5: '医学生',
//   0: '其他',
// }
const jueSeOf1 = {
  '执业医师': 1,
  '医学生': 5,
  '其他': 0,
}
export default {
  name: 'userInfoForm',
  components: { IdentitySelect,JoblevelSelect,JobtitleSelect,DatasetSelect },
  props: {
    formData: {
      type: Object,
      default: function () {
        return null
      },
    },
  },
  data() {
    return {
      labelCol: this.$isMobile ? null : { span: 6 },
      wrapperCol: this.$isMobile ? null : { span: 18 },
      query: null,
      options: [],
      infoForm: {
        truename: null,
        identity: null,
        city: null,

        hospital: null,
        division: null,
        joblevel: null,
        jobname:null,//+

        university: null,//+
        majors: null,//+
        entranceYear: null,//+
        qualification:null,//+
        // 
        otherCompany:null,//+
        otherDepartment:null,//+
        otherJobname:null,//+

        emailUnsubscribe: false,
        
        important: null,
        
        roleid: null,
        ifReceiveNejm: true,
        ifReceiveJh: true,
        ifReceiveDsf: true,
        
      },
      // infoRules: {
      //   truename: [{ required: true, trigger: 'change', message: '请输入姓名' }],
      //   identity: [{ required: true, trigger: 'change', message: '请选择身份' }],

      //   hospital: [{ required: true, trigger: 'change', message: '请选择医院' }],
      //   division: [{ required: true, trigger: 'change', message: '请选择科室' }],
      //   joblevel: [{ required: true, trigger: 'change', message: '请选择职称' }],
      //   jobname: [{ required: true, trigger: 'change', message: '请选择职务' }],

      //   important: [{ required: true, trigger: 'change', message: '请输入研究重点' }],
      //   roleid: [{ required: true, trigger: 'change', message: '请选择详细信息' }],
      // },
      cityOptions: [],
      hospitalOptions:[],
      universityOptions:[],
      divisionOptions:[],
      // roleidFilter:[{roleid:1,label:'执业医师'},{roleid:5,label:'医学生'},{roleid:0,label:'其他'},],
      cityProps: {
        "multiple": false,
        value:'id',
        label:'name',
        children:'children'
      },
      divisionProps: {
        "multiple": false,
        value:'name',
        label:'name',
        children:'children'
      },
      pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
      },
      fieldValue: '',
      fieldCityIdValue:'',
      show:false,
      fieldNames: {
        text: 'name',
        value: 'id',
        children: 'children',
      },
    }
  },
  created() {},
  // watch:{
  //   'infoForm.identity':{
  //     handler(newVal,oldVal){
  //       if(newVal == '执业医师'){
  //         this.get_hospital();
  //         this.get_division();
  //       } else if(newVal == '医学生') {
  //         this.get_university();
  //       }
  //     }
  //   }
  // },
  mounted() {
    get_city().catch((res) => {
      this.cityOptions = res.children || [];
    })
    this.get_division();
  },
  methods: {
    onFinish({ selectedOptions }) {
      this.show = false;
      console.log(selectedOptions);
      // console.log(this.formData.field111);
      // console.log(this.fieldValue);
      if(!selectedOptions){
        return
      }
      this.fieldValue = selectedOptions.map((option) => option.name).join('/')
      this.infoForm.city = selectedOptions.map((option) => option.id)
      this.handleCityChange();
    },
    handleTaget(val){
      var trueName = this.infoForm.truename;
      var identity = val;
      var city = this.infoForm.city;
      var fieldValue = this.fieldValue;
      this.$refs['infoForm'].resetFields();
      // this.infoForm.city = null;
      this.infoForm.division = null;
      this.infoForm.city = city;
      this.infoForm.roleid = jueSeOf1[val];
      this.fieldValue =fieldValue;
      this.infoForm.truename = trueName;
      this.infoForm.identity = identity;
      this.handleCityChange()
    },
    handleCityChange(){
      if(this.infoForm.identity == '执业医师'){
          this.get_hospital();
        } else if(this.infoForm.identity == '医学生') {
          this.get_university();
        }
    },
    get_hospital(){
      if(this.infoForm.city == null){
        return
      }
      var params = {};
      params.p_code = this.infoForm.city[0]
      params.c_code = this.infoForm.city[1]
      params.a_code = this.infoForm.city[2]
      get_hospital(params).then((res) => {
        this.hospitalOptions = res;
      })
    },
    get_division(){
      var params = {};
      get_division(params).catch((res) => {
        console.log(res);
        this.divisionOptions = res.children;
      })
    },
    get_university(){
      var params = {};
      params.p_code = this.infoForm.city[0]
      params.c_code = this.infoForm.city[1]
      get_university(params).then((res) => {
        this.universityOptions = res;
      })
    },
    submitData(formName) {
      console.log(this.infoForm.division, 'infoForm.division')
      this.$refs[formName].validate((valid) => {
        if (valid) {
          
          this.infoForm.id = this.formData.id;
          console.log(this.infoForm)
          var params = { ...this.infoForm }
          // 将参数city转换格式
          if(this.infoForm.city && this.infoForm.city.length>0){
            params.cityidspliter  = this.infoForm.city.join(',');
            params.city  = this.$isMobile? this.fieldValue : this.$refs['citycascader'].getCheckedNodes()[0].pathLabels.join('/');
          }
          // 将参数科室division转换格式
          if (this.infoForm.division) {
            var length = this.infoForm.division.length
            params.division = this.infoForm.division[length - 1]
          }
          
          params.emailUnsubscribe = params.ifReceiveDsf || params.ifReceiveJh || params.ifReceiveNejm ? 0 : 1
          params.ifReceiveDsf = params.ifReceiveDsf ? 1 : 0
          params.ifReceiveJh = params.ifReceiveJh ? 1 : 0
          params.ifReceiveNejm = params.ifReceiveNejm ? 1 : 0
          completeUserInfo(params).then((res) => {
            console.log(res)
            setSessionUser(res)
            this.$router.push({ path: '/index' })
            this.$message.success('操作成功！')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onChange1(e) {
      // this.infoForm.emailUnsubscribe = e.target.checked
      this.infoForm.ifReceiveNejm = e.target.checked
    },
    onChange2(e) {
      // this.infoForm.emailUnsubscribe = e.target.checked
      this.infoForm.ifReceiveJh = e.target.checked
    },
    onChange3(e) {
      // this.infoForm.emailUnsubscribe = e.target.checked
      this.infoForm.ifReceiveDsf = e.target.checked
    },
  },
}
</script>

<style lang="less">
.formModelItem {
  margin-top: -10px !important;
}
</style>
