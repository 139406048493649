// 身份
export const shenFen = [
  {
    value: '医生',
    label: '医生',
  },
  {
    value: '住院医生',
    label: '住院医生',
  },
  {
    value: '其他医务工作者',
    label: '其他医务工作者',
  },
  {
    value: '医学院师生',
    label: '医学院师生',
  },
  {
    value: '其他',
    label: '其他',
  },
]
// 医学院师生
export const jueSe = [
  {
    value: 1,
    label: '教师',
  },
  {
    value: 2,
    label: '科研人员',
  },
  {
    value: 3,
    label: '医学生',
  },
]
// 其他
export const jueSe2 = [
  {
    value: 1,
    label: '医药企业',
  },
  {
    value: 2,
    label: '研究院所',
  },
  {
    value: 3,
    label: '其他',
  },
]

// 专业领域
export const zyly = [
  {
    value: 1,
    label: '其他',
  },
  {
    value: 2,
    label: '肿瘤',
  },
  {
    value: 3,
    label: '糖尿病',
  },
  {
    value: 4,
    label: '心脑血管疾病',
  },
  {
    value: 7,
    label: '呼吸系统疾病',
  },
  {
    value: 8,
    label: '妇产科和儿科',
  },
  {
    value: 9,
    label: '传染病',
  },
]
// 类型
export const leixing = [
  {
    value: 5,
    label: 'NEJM',
  },
  {
    value: 6,
    label: 'NEJM期刊荟萃',
  },
  {
    value: 7,
    label: '动画解读',
  },
  {
    value: 8,
    label: '音频',
  },
  {
    value: 9,
    label: '临床论文发表',
  },
  {
    value: 1,
    label: '述评',
  },
]
// 课程分组类型
export const kechengfenzu = [
  {
    value: 1,
    label: '单课程',
  },
  {
    value: 2,
    label: '多课程',
  },
  {
    value: 3,
    label: '会议',
  },
]
