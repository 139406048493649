import http from '@/http/index'

// 当前人员信息
export function getMemberInfo(params) {
  return http.post('/api/user/getMemberInfo', params)
}

// 完善当前用户信息
export function completeUserInfo(params) {
  return http.post('/api/user/completeUserInfo', params)
}

// 文章收藏记录
export function selectMyFavArticle(params) {
  return http.post('/api/user/selectMyFavArticle', params)
}

// 课程收藏记录
export function selectMyFavLesson(params) {
  return http.post('/api/user/selectMyFavLesson', params)
}

// 文章阅读记录
export function selectMyReadArticle(params) {
  return http.post('/api/user/selectMyReadArticle', params)
}

// 课程阅读记录
export function selectMyReadLesson(params) {
  return http.post('/api/user/selectMyReadLesson', params)
}
// 修改密码
export function updatePassword(params) {
  return http.post('/api/user/updatePassword', params)
}

// 发送验证码(修改信息用)
export function sendCode2(params) {
  return http.post('/api/base/sendCode2', params)
}

// 修改邮箱或手机号
export function updatePhoneNoOrEmail(params) {
  return http.post('/api/user/updatePhoneNoOrEmail', params)
}
