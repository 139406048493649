<template>
  <el-row style="margin-bottom:33px">
    <template v-for="(o, index) in options">
      <el-card class="subjectcarditems" :class="checkedList.includes(o.id)?'cardactive':''" v-if="(index<=6) || (index==7&&options.length==8) ||(index==7&&options.length>8&&dialogVisible)||(index>7&&dialogVisible)"  :key="index" @click.native="handleclick(o)">
      <!-- <el-card class="subjectcarditems" :class="checkedList.includes(o.id)?'cardactive':''" v-for="(o, index) in (options)" :key="index" @click.native="handleclick(o)"> -->
        <img :src="o.xcxIconSe" class="image">
        <div class="Subdesc">
          <span class="title">{{o.classname}}</span>
          <span class="content">{{o.num}}门课程</span>
          <!-- <span class="content">xx课程</span> -->
        </div>
      </el-card>
    </template>
      <el-card class="subjectcarditems" style="cursor: pointer;" v-if="options.length>8 && !dialogVisible" @click.native="openMore()">
        <div class="Subdesc">
          <span class="title">更多</span>
          <span class="content">展开显示更多...</span>
        </div>
      </el-card>
      <el-card class="subjectcarditems" style="cursor: pointer;" v-if="options.length>8 && dialogVisible" @click.native="openMore()">
        <div class="Subdesc">
          <span class="title">更多</span>
          <span class="content">展开显示更多...</span>
        </div>
      </el-card>
  </el-row>
</template>

<script>
import { subjectList } from '@/api/course'
// import { selectArticleClass } from '@/api/article'
export default {
  name: 'SubjectSelectCard',
  props: {
    options: {
      required: true,
    },
    checkList:{
      type: Array,
       default() {
        return []
      },
    }
    // params: {
    //   type: Object,
    //   default() {
    //     return {}
    //   },
    // },
  },
  data() {
    return {
      dialogVisible:false,
      checkedList:this.checkList,
      // allOpotions:[],
      // options: [
      //   {title:'传染病',content:'280课程'},
      //   {title:'传染病',content:'280课程'},
      //   {title:'传染病',content:'280课程'},
      //   {title:'传染病',content:'280课程'},
      //   {title:'传染病',content:'280课程'},
      //   {title:'传染病',content:'280课程'},
      //   {title:'传染病',content:'280课程'},
      // ],
    }
  },
  // computed:{
  //   checkedList(){
  //     return this.checkList
  // },
  mounted(){
    // var bgh = window.document.querySelector('.item2>form').offsetHeight+56
    // window.document.querySelector('.cr-img').style.backgroundSize = '1920px '+bgh+'px';
  },
  watch: {
    checkList: {
      handler() {
        this.checkedList = this.checkList
      },
      immediate: true,
    },
    dialogVisible(newVal,oldVal){
      if(newVal){
        console.log(oldVal);
                this.$nextTick(() => {
            var bgh = window.document.querySelector('.item2>form').offsetHeight+56
            console.log(111,bgh);
            window.document.querySelector('.cr-img').style.backgroundSize = '1920px '+bgh+'px';
    })
      } else {
        window.document.querySelector('.cr-img').style.backgroundSize = 'auto';
      }

    }
  },
  methods: {
    // async loadSubjects() {
    //   let res = await selectArticleClass({})
    //   // let arr = []
    //   // for (const temp of res) {
    //   //   arr.push(temp.classname)
    //   // }
    //   // this.columns = arr
    //   this.allOpotions = res
    //   // this.subjects.push(...res);// 复制一份创造一个假的多数据
    // },
    async _loadData() {
      if (this.params == null) {
        this.options = []
      } else {
        // this.options = [
        //   { value: 10, label: '儿科' },
        //   { value: 20, label: '妇产科' },
        //   { value: 30, label: '肿瘤' },
        //   { value: 40, label: '皮肤' },
        //   { value: 50, label: '整形美容' },
        //   { value: 60, label: '全科' },
        // ]
        let res = await subjectList({})
        console.log(res)
        this.options = res
      }
    },
    openMore(){
      this.dialogVisible = !this.dialogVisible;
    },
    handleclick(val){
      let index = this.checkedList.indexOf(val.id);
      if(index == -1){
        this.checkedList.push(val.id)
      }else {
        this.checkedList.splice(index,1)
      }
      this.$emit('changeCheckList',this.checkedList)
    }
  },
}
</script>

<style lang="less" scoped>

.subjectcarditems {
  cursor: pointer;
  display: inline-block;
  width: 276px;
  height: 80px;
  margin-right: 32px;
  margin-bottom: 16px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
  border-radius: 2px;
  overflow: hidden;

  /deep/.el-card__body {
    padding: 0;
  }
  
  img,.Subdesc {
    float: left;
  }
  img {
    width: 80px;
    height: 80px;
  }
  .Subdesc {
    padding-left: 15px;
    span {
      display: block;
      &.title {
        margin-top: 9px;
        height: 22px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 500;
        margin-bottom: 6px;
      }
      &.content {
        height: 20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
.moreSubject {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.moreSubject:hover {
  color: #5cb6ff;
}
.subjectcarditems.cardactive {
  box-shadow: 2px 1px 5px 4px rgba(20, 20 ,20 , 0.19);
}
.subjectcarditems:nth-child(4n){
    margin-right: 0;
  }
</style>
