import http from '@/http/index'

/**
 * 文章搜索列表
 * @param params：{
 *}
 */
export function selectArticleList(params) {
  return http.post('/api/article/selectArticleList', params)
}
/**
 * 相关文章
 * @param params：{
 *}
 */
export function getArticle(params) {
  return http.post('/api/article/getArticle', params)
}
/**
 * 文章相关视频
 * @param params：{
 *}
 */
export function getRelationVideo(params) {
  return http.post('/api/article/getRelationVideo', params)
}
/**
 * 文章详情
 * @param params：{
 *}
 */
export function getArticleDetail(params) {
  return http.post('/api/article/getArticleDetail', params)
}
export function getArticleDetail2(params) {
  return http.post('/api/article/getArticleDetail2', params)
}

/**
 * 文章详情
 * @param params：{
 *}
 */
export function getArticleDetailById(params) {
  return http.post('/api/article/getArticleDetailById', params)
}

/**
 * 文章专业领域
 * @param params：{
 *}
 */
export function selectArticleClass(params) {
  return http.post('/api/article/selectArticleClass', params)
}
/**
 * 文章类型
 * @param params：{
 *}
 */
export function selectArticleFilter(params) {
  return http.post('/api/article/selectArticleFilter', params)
}
/**
 * 学科下拉
 * @param params：{
 *}
 */
export function subject(params) {
  return http.post('/api/ConferenceAdvance/subject', params)
}
/**
 * 学科列表
 * @param params：{
 *}
 */
export function selectLessonList(params) {
  return http.post('/api/lesson/selectLessonList', params)
}
