import Vue from 'vue'

export default class EventBus {
  constructor() {
    this._bus = new Vue()
  }

  on(event, cb) {
    this._bus.$on(event, cb)
  }

  emit(event, ...payload) {
    this._bus.$emit(event, ...payload)
  }

  off(event, cb) {
    this._bus.$off(event, cb)
  }
}
