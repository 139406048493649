<template>
  <div class="">
    <div class="tit">
      <h3 :class="type == 'course' ? 'hover' : 'title'" @click="onType('course')">NEJM编辑课堂</h3>
      <!-- tab切换 -->
      <h3 :class="type == 'index' ? 'hover' : 'title'" @click="onType('index')">临床研究和实践</h3>
      <a-button type="link" size="small" class="mr-btn" v-if="type == 'course'" @click="onMoreClick('video')">
        更多
      </a-button>
      <a-button type="link" size="small" class="mr-btn" v-if="type == 'index'" @click="onMoreClick('img')">
        更多
      </a-button>
      <!-- <a-button type="link" size="small" class="mr-btn" v-if="total > 3" @click="onMoreClick('video')">更多</a-button> -->
    </div>
    <template>
      <div class="course">
        <CourseResourceCard1 v-if="type == 'course'" />
        <IndexCourseResourceCard1 v-if="type == 'index'" />
      </div>
    </template>
  </div>
</template>

<script>
import CourseResourceCard1 from '@/components/article/course-resources/CourseResourceCard1.vue'
import { PATH_COURSE_RESOURCES } from '@/router/article/paths'
import { selectLessonList } from '@/api/course'
import IndexCourseResourceCard1 from '@/components/article/course-resources/IndexCourseResourceCard1'

export default {
  name: 'NejmEdittingClass',
  components: { CourseResourceCard1, IndexCourseResourceCard1 },
  props: {
    showType: {
      type: String,
      required: true,
      default: 'course',
    },
  },
  data() {
    return {
      type: 'course',
      total: 0,
    }
  },
  async created() {
    await this.selectLessonList()
  },
  methods: {
    onMoreClick(e) {
      this.$router.push({ path: PATH_COURSE_RESOURCES, query: { type: e } })
    },
    onType(item) {
      this.type = item
    },
    async selectLessonList() {
      let res = await selectLessonList({
        lessonStatus: 1,
        pageNo: 1,
        pageSize: 3,
        subjectSerType: [1, 2],
        sortType: 3,
      })
      this.list = res.results
      this.total = res.page.total
      console.log(this.total + ' 11111')
    },
  },
}
</script>

<style lang="less" scoped>
.tit {
  padding-top: 40px;
  border-bottom: 1px solid #414347;
  height: 88px;
  .title {
    font-size: 20px;
    color: #b4ada3;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 44px;
    float: left;
    margin-right: 30px;
    cursor: pointer;
  }
  .hover {
    font-size: 20px;
    color: #e52e00;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 44px;
    float: left;
    margin-right: 30px;
    border-bottom: 4px solid #e52e00;
    cursor: pointer;
  }
  .mr-btn {
    float: right;
    font-size: 18px;
    color: #1e4ea6;
    letter-spacing: 0;
    text-align: right;
    line-height: 44px;
    height: 44px;
    font-weight: 400;
    padding: 0;
  }
}
.mobile {
  .tit {
    padding-top: 24px;
    border-bottom: 1px solid #414347;
    height: 62px;
    .title {
      font-size: 18px;
      margin-right: 10px;
      line-height: 34px;
    }
    .hover {
      font-size: 18px;
      margin-right: 10px;
      line-height: 34px;
    }
    .mr-btn {
      font-size: 16px;
      height: 34px;
      line-height: 34px;
    }
  }
}
</style>
