<template>
  <div>
    <div v-if="$isMobile" class="">
      <!--    <h1 class="title">最新期刊译文</h1>-->
      <h3 v-if="$isMobile" class="sub-title">{{ articleWeekly.title }}</h3>
      <PeriodicalBox
        :style="$isMobile ? '' : 'margin-top: 13px'"
        title="NEJM"
        :list="nejmList"
        :has-more="true"
        @more="onMoreClick"
        @item-click="onItemClick"
      />
      <!-- <a-divider style="margin: 8px 0" /> -->
      <PeriodicalBox1
        title="NEJM期刊荟萃"
        :list="nejmGatherList"
        :has-more="true"
        @more="onMoreClick"
        @item-click="onItemClick"
      />
      <CenterRightAnimation></CenterRightAnimation>
      <CenterRightReview></CenterRightReview>
    </div>
    <div v-if="!$isMobile" class="">
      <a-row :type="rowType" :gutter="50">
        <a-col flex="16 0 0">
          <PeriodicalBox
            :style="$isMobile ? '' : 'margin-top: 13px'"
            title="NEJM"
            :list="nejmList"
            :has-more="true"
            @more="onMoreClick"
            @item-click="onItemClick"
          />
          <PeriodicalBox1
            title="NEJM期刊荟萃"
            :list="nejmGatherList"
            :has-more="true"
            @more="onMoreClick"
            @item-click="onItemClick"
          />
        </a-col>
        <a-col flex="8 0 0">
          <CenterRightAnimation></CenterRightAnimation>
          <CenterRightReview></CenterRightReview>
        </a-col>
      </a-row>

      <!-- <a-divider style="margin: 8px 0" /> -->
      <!-- <a-row :type="rowType" :gutter="50">
        <a-col flex="16 0 0">
          <PeriodicalBox
            title="NEJM期刊荟萃"
            :list="nejmGatherList"
            :has-more="true"
            @more="onMoreClick"
            @item-click="onItemClick"
          />
        </a-col>
        <a-col flex="8 0 0">
          <CenterRightReview style="margin-top: 30px"></CenterRightReview>
        </a-col>
      </a-row> -->
    </div>
  </div>
</template>

<script>
import PeriodicalBox from '@/components/index/components/PeriodicalBox.vue'
import PeriodicalBox1 from '@/components/index/components/PeriodicalBox1.vue'
import { newestArticle } from '@/api/nejmCatalog'
import { PATH_ARTICLE_INFO, PATH_PERIOD_ARTICLE } from '@/router/article/paths'
import { format } from '@/utils/time-utils'
import CenterRightReview from '@/components/index/CenterRightReview1'
import CenterRightAnimation from '@/components/index/CenterRightAnimation'
export default {
  name: 'LatestPeriodicalTranslation',
  components: { PeriodicalBox, PeriodicalBox1, CenterRightReview, CenterRightAnimation },
  data() {
    return {
      nejmList: [],
      nejmGatherList: [],
      articleWeekly: null,
    }
  },
  computed: {
    rowType() {
      return this.$isMobile ? '' : 'flex'
    },
  },
  filters: {
    formatDateTxt(time) {
      return format(time, 'YYYY年MM月DD日')
    },
  },
  async created() {
    await this.getList()
  },
  methods: {
    onMoreClick() {
      let year = format(this.articleWeekly.thedate, 'YYYY')
      let month = format(this.articleWeekly.thedate, 'MM')
      this.$router.push({ path: PATH_PERIOD_ARTICLE + '?year=' + year + '&month=' + month })
    },
    onItemClick(item) {
      this.$router.push({ path: PATH_ARTICLE_INFO + '?permalinks=' + item.permalinks })
    },
    async getList() {
      let res = await newestArticle({})
      this.articleWeekly = res.articleWeeklyPo
      this.nejmList = res.articleMap[5]
      // this.nejmGatherList = res.articleMap[6]
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.title {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}
.sub-title {
  border-radius: 2px;
  background-color: @clr-primary;
  color: #fff;
  text-align: center;
  line-height: 2;
  font-size: 20px;
  font-weight: 400;
}
.mobile {
  .sub-title {
    font-size: 16px;
  }
}
</style>
