<template>
  <div class="bz-video">
    <img class="poster" v-show="!play && !!this.poster" :src="this.poster" @click="start" />
    <video-player
      v-show="play || !this.poster"
      ref="videoPlayer"
      class="video-player vjs-custom-skin"
      :class="{ 'show-play': !!showPlay }"
      :options="innerPlayerOptions"
      v-bind="$attrs"
      v-on="$listeners"
      @play="onPlay"
      @contextmenu.native.prevent="onContextmenu"
    />
  </div>
</template>

<script>
import { addVideoCount } from '@/api/user-api'

export default {
  name: 'BzVideoPlayer',
  props: {
    playerOptions: {
      type: Object,
    },
    src: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
    },
    showPlay: {
      type: Boolean,
      default() {
        return false
      },
    },
    relationId: {
      type: Number,
    },
    relationType: {
      type: Number, //10：文章 20：课程
    },
    subParam:{
      type: Object,
      default: () => ({
          is_subscribe:null,
          is_have_view_auth:null,
          is_month_chance:null
      }),
    }
  },
  data() {
    return {
      play: false,
      defaultPlayerOptions: {
        //播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: 'auto',
        language: 'zh-CN',
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        // aspectRatio: '4:3',
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          {
            //类型
            type: 'video/mp4',
            //url地址
            src: '',
          },
        ],
        //你的封面地址
        poster: '',
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true,
        },
      },
    }
  },
  computed: {
    innerPlayerOptions() {
      let options = this.playerOptions || {}
      options = {
        ...this.defaultPlayerOptions,
        ...options,
      }
      if (options.sources == null) {
        options.sources = []
      }
      options.sources[0].src = this.src
      if (this.poster) {
        options.poster = this.poster
      }
      return options
    },
  },
  methods: {
    onContextmenu() {},
    async start() {
      console.log('start video')
      if(this.subParam.is_month_chance ==1&&this.subParam.is_have_view_auth==2&& this.subParam.is_subscribe==1){
        console.log('无权限，没次数，弹没有次数')
        this.$refs.videoPlayer.$refs.video.pause()
        // this.$emit('paramFun')
        return
      }
      if(this.subParam.is_month_chance ==2&&this.subParam.is_have_view_auth==2&& this.subParam.is_subscribe==1){
      //  console.log('无权限，有一次次数，弹是否使用')
        this.$emit('paramFun',true)
        return
      }
      this.play = true
      this.$nextTick(() => {
        this.$refs.videoPlayer.$refs.video.play()
      })
    },
    async onPlay() {
      console.log('onPlay')
      if (this.relationId != null && this.relationType != null) {
        await addVideoCount({
          relationId: this.relationId,
          relationType: Number(this.relationType),
        })
      }
    },
  },
}
</script>

<style lang="less">
.bz-video {
  text-align: left;

  .poster {
    width: 100%;
    cursor: pointer;
  }
}

.video-player {
  .video-js .vjs-big-play-button {
    display: none;
    border: none;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    line-height: 2em;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  &.show-play {
    .vjs-has-started.video-js .vjs-big-play-button {
      display: none;
    }
    .video-js .vjs-big-play-button {
      display: block;
    }
  }
}
</style>
