<template>
  <el-dialog
    :width="!$isMobile ? '30%' : '90%'"
    :before-close="_handleClose"
    @close="_handleClose"
    v-bind="$attrs"
    v-on="$listeners"
    class="changeAccountNameDialog"
  >
    <div class="ligin-title">
      <img class="logo" src="../../../assets/imgs/logo/logo.png" alt="NEJM医学前沿" />
    </div>
    <el-form :model="memberNameParams" inline :rules="rules" ref="ruleForm" :label-width="!$isMobile ? '100px' : ''">
      <el-form-item prop="membername" label="用户名">
        <el-input
          v-model="memberNameParams.membername"
          :style="!$isMobile ? 'width: 250px' : ''"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>
      <el-form-item class="submitContainer">
        <el-button type="primary" style="width: 100px" @click="submit">确认</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { updateSignAccount } from '@/api/user-api'
export default {
  name: 'ChangeAccountNameDialog',
  props: {
    memberNameVisible: {
      type: Boolean,
      default() {
        return false
      },
    },
    memberNameParams: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      rules: {
        membername: [{ required: true, message: '请输入用户名' }],
      },
    }
  },
  methods: {
    _handleClose() {
      this.$emit('update:visible', false)
      this.$refs.ruleForm.resetFields()
    },
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          await updateSignAccount(this.memberNameParams)
          this.$emit('changeAccount')
          let user = JSON.parse(window.localStorage.getItem('app_user'))
          user.membername = this.memberNameParams.membername
          window.localStorage.setItem('app_user', JSON.stringify(user))
          this._handleClose()
          this.$message.success('修改成功')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.ligin-title {
  font-size: 16px;
  width: 100%;
  .logo {
    width: 100px;
  }
}
.el-form {
  margin-top: 40px;
  /deep/.el-form-item__label {
    padding-right: 15px;
  }
}
.submitContainer {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
</style>
<style lang="less">
.changeAccountNameDialog {
  .el-dialog__body {
    padding: 0px 40px 20px 40px;
  }
}
</style>
