<template>
  <div class="item">
    <a-list :grid="grid" :data-source="list" :loading="loading">
      <a-list-item slot="renderItem" slot-scope="item" @click.prevent="onClick(item)">
        <template v-if="item.subjectSerType === 3">
          <div class="nejmlist" @click.stop.prevent="onItemClick(item)">
            <div
              class="img"
              :style="{
                'background-image': `url('${
                  item.coverPc != null && item.coverPc != '' ? JSON.parse(item.coverPc)[0].path : ''
                }')`,
              }"
            ></div>
            <div style="padding: 8px 0">
              <div class="fr">
                <div class="title">{{ item.title }}</div>
                <div class="desc">{{ item.subhead }}</div>
              </div>
              <div class="fr">
                <div class="date">{{ item.publishdate | formatDateTxt }}</div>
                <div class="tagContainer">
                  <div v-for="(value, index) in item.classNames" :key="index">
                    <a-tag
                      color="#a1224e"
                      style="
                        font-size: 12px;
                        max-width: 100px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        cursor: pointer;
                      "
                    >
                      <a-tooltip @click.stop.prevent="onItemClick">
                        <template slot="title">{{ value }}</template>
                        {{ value }}
                      </a-tooltip>
                    </a-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="nejmlist" @click.stop.prevent="onItemClick(item)">
            <el-image
              v-if="item.subjectSerType == 5 || item.subjectSerType == 2"
              class="img"
              :src="item.coverMobile != null && item.coverMobile != '' ? JSON.parse(item.coverMobile)[0].path : ''"
            ></el-image>
            <el-image
              v-else
              class="img"
              :src="item.coverPc != null && item.coverPc != '' ? JSON.parse(item.coverPc)[0].path : ''"
            ></el-image>
            <a-tag v-if="item.subjectSerType == 5" class="tag" color="#a1224e">
              {{ item.subjectSerType == 5 ? '系列课' : item.subjectType == 2 ? '视频' : '音频' }}
            </a-tag>
            <a-tag v-if="item.subjectSerType == 2" class="tag" color="#a1224e">
              {{ item.subjectSerType == 2 ? '系列课' : item.subjectType == 2 ? '视频' : '音频' }}
            </a-tag>

            <!--<div v-if="item.subjectSerType == 5" style="padding: 16px; position: absolute; top: 15px; width: 78%">
              <div class="fr">
                <div class="ellipsis title" style="color: #ffffff">{{ item.title }}</div>
              </div>
              <div class="fr">
                <div class="desc" style="color: #ffffff">{{ item.subhead }}</div>
              </div>
              <div class="fr">
                <div class="date" style="color: #ffffff">{{ item.publishdate | formatDateTxt }}</div>
                <div style="color: #a1224e; font-size: 16px">
                  {{ item.ifViewAuth === 0 ? '' : '' }}
                </div>
              </div>
            </div>

            <div v-if="item.subjectSerType == 2" style="padding: 16px; position: absolute; top: 15px; width: 78%">
              <div class="fr">
                <div class="ellipsis title" style="color: #ffffff">{{ item.title }}</div>
              </div>
              <div class="fr">
                <div class="desc" style="color: #ffffff">{{ item.subhead }}</div>
              </div>
              <div class="fr">
                <div class="date" style="color: #ffffff">{{ item.publishdate | formatDateTxt }}</div>
                <div style="color: #a1224e; font-size: 16px">
                  {{ item.ifViewAuth === 0 ? '' : '' }}
                </div>
              </div>
            </div>-->

            <div style="padding: 16px 0">
              <div class="fr">
                <div class="ellipsis title">{{ item.title }}</div>
              </div>
              <div class="fr">
                <div class="desc">{{ item.subhead }}</div>
              </div>
              <div class="fr">
                <div class="date">{{ item.publishdate | formatDateTxt }}</div>
                <div style="color: #a1224e; font-size: 16px">
                  {{ item.ifViewAuth === 0 ? '' : '' }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import { format } from '@/utils/time-utils'
import { PATH_COURSE_VIDEO, PATH_SERIES_COURSES } from '@/router/article/paths'
import { outlinkwithUser } from '@/api/login'
import { getSessionUser } from '@/utils/app-utils'
import { selectLessonList } from '@/api/course'
// const types = {
//   心脑血管疾病: '10',
//   肿瘤: '20',
//   糖尿病: '30',
//   呼吸系统疾病: '40',
//   妇产科和儿科: '50',
//   围产医学和儿科: '50',
//   其他: '60',
//   传染病: '70',
// }
export default {
  name: 'CourseResourceCard',
  components: {},
  // props: {
  //   model: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  filters: {
    formatDateTxt(time) {
      return format(time, 'YYYY-MM-DD')
    },
  },
  data() {
    return {
      list: [],
      total: 0,
      loading: true,
    }
  },
  computed: {
    grid() {
      let column = 3
      if (this.$isMobile) {
        column = 1
      }
      return { gutter: 30, column, md: 2, lg: 3 }
    },
  },
  async created() {
    await this.selectLessonList()
  },
  methods: {
    // onItemClick(item) {
    //   this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    // },
    onItemClick(item) {
      let loginUser = getSessionUser()
      if (item.ifViewAuth == 1) {
        if (null == loginUser) {
          this.$message.warning('登录即可观看')
          this.$bus.emit('login')
          return
        }
        if (loginUser.truename == null) {
          this.$message.warning('需要资料补齐才能浏览')
          return
        }
      }
      if (item.ifViewAuth == 2) {
        if (null == loginUser) {
          this.$message.warning('登录即可观看')
          this.$bus.emit('login')
          return
        }
      }
      if (item.outsideChain) {
        outlinkwithUser().then(res=>{
          if(item.outsideChain.lastIndexOf('?')>-1){
            window.open(`${item.outsideChain}&nejmjmedssodata=${res.result}`)
          }else {
            window.open(`${item.outsideChain}?nejmjmedssodata=${res.result}`)
          }
        })
        return;
      }
      if (item.subjectSerType == 1) {
        //单课程
        this.$router.push({ path: PATH_COURSE_VIDEO + '?id=' + item.lessonResId })
      } else {
        //多课程、论坛
        this.$router.push({ path: PATH_SERIES_COURSES + '?id=' + item.lessonResId })
      }
    },
    async selectLessonList() {
      this.loading = true
      let res = await selectLessonList({
        lessonStatus: 1,
        pageNo: 1,
        pageSize: 3,
        subjectSerType: [1, 2],
        sortType: 3,
      })
      this.list = res.results
      this.total = res.page.total
      this.loading = false
    },
  },
}
</script>

<style lang="less" scoped>
.nejmlist {
  cursor: pointer;
}
.item {
  position: relative;
  overflow: hidden;
  // cursor: pointer;
  color: #000;
  padding-top: 24px;
  min-height: 400px;
  .img {
    width: 100%;
    height: 210px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    //width: 100%;
  }
  .title {
    line-height: 21px;
    font-size: 16px;
    color: rgba(32, 33, 33, 0.85);
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    color: rgb(51, 51, 51);
    letter-spacing: 0px;
    line-height: 24px;
    font-weight: 700;
  }
  .desc {
    margin-top: 15px;
    //height: 2em;
    line-height: 21px;
    height: 42px;
    margin-bottom: 4px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
  }
  .fr {
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    .date {
      color: rgba(0, 0, 0, 0.65);
      font-size: 16px;
      font-weight: 400;
      margin-top: 15px;
      line-height: 21px;
      margin-bottom: 4px;
    }
  }
  .tagContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .tag {
    position: absolute;
    top: 0;
    right: 15px;
    margin: 0;
  }
}

.mobile {
  .item {
    border: none;
    height: auto;
    // border-bottom: 1px solid #d4d4d4;
  }
  .img {
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    // width: 100%;
  }
}
</style>
