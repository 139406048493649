<template>
  <div class="container">
    <div class="catalogue">{{ catalogue }}</div>
    <RichText v-if="!$isMobile" :content="title | replaceHandle(`<br>`)" />
    <RichText v-else :content="title | replaceHandle(`<br>`)" class="titleFit" />
    <Share
      :isShow="{ navigation: false, collect: true, share: true, original: false }"
      :id="id"
      :ifCollect.sync="ifCollect"
      collectType="article"
      :outlink="outlink"
    ></Share>
    <RichText :content="keyword" id="rich" class="keyword" />
  </div>
</template>
<script>
// import { newestArticle } from '@/api/nejmCatalog'
import { getArticleDetail } from '@/api/article'
import RichText from '@/components/layout/RichText.vue'
import Share from '@/components/share/Share.vue'
import { mapMutations } from 'vuex'
import { getSessionUser, setSessionUser } from '@/utils/app-utils'
import { loginWithoutPwd } from '@/api/login'
import { getArticleDetail2 } from '@/api/serach'
export default {
  name: 'NejmCatalog',
  components: { RichText, Share },
  data() {
    return {
      catalogue: 'The New England Journal of Medicine目录',
      keyword: null,
      title: null,
      id: null,
      ifCollect: null,
      outlink: '',
      memberId: null,
      month: null,
      catalogIndex: null,
    }
  },
  async created() {
    this.dealOutLink(this.$route.query.sg)
    this.month = this.$route.query.month
    this.catalogIndex = this.$route.query.catalogIndex
    this.memberId = Number(window.sessionStorage.getItem('memberId'))
    await this.getSelectArticleList()
    this.$nextTick(() => {
      let rich = document.querySelector('#rich')
      let spanList = rich.querySelectorAll('p>span')
      let brList = rich.querySelectorAll('p>br')
      spanList = Array.prototype.slice.call(spanList)
      brList = Array.prototype.slice.call(brList)
      let richList = spanList.filter((item) => item.innerHTML == '&nbsp;')
      richList.forEach((item) => {
        item.parentNode.classList.add('divider')
      })
      brList.forEach((item) => {
        item.parentNode.classList.add('divider')
      })
    })
  },
  filters: {
    replaceHandle(str, query) {
      let res = new RegExp('(' + query + ')', 'g')
      str = str?.replace(res, '')
      str = str?.replace('　Vol', 'Vol')
      str = str?.replace('　No', 'No')
      str = str?.replace(' Vol', 'Vol')
      str = str?.replace(' No', 'No')
      str = str?.replace('Vol', '　Vol')
      str = str?.replace('No', '　No')
      return str
    },
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    async dealOutLink(sg) {
      let loginUser = getSessionUser()
      if (sg != null && sg == 'AbW1N' && loginUser == null) {
        this.setUser({})
        setSessionUser({})
        await loginWithoutPwd()
      }
    },
    async getSelectArticleList() {
      let res
      if (this.$route.query.prev == 'D1Co7866D71DC3Ao9B3B692DoA2o86B4') {
        res = await getArticleDetail2({ permalinks: this.$route.query.permalinks })
        // res = await getArticleDetail({ permalinks: this.$route.query.permalinks })
      } else {
        res = await getArticleDetail({ permalinks: this.$route.query.permalinks })
      }
      this.keyword = res.keyword
      this.id = res.id
      this.ifCollect = res.ifCollect
      this.outlink = res.outlink
      this.title = res.title
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  margin-top: 45px;
  .catalogue {
    font-size: 32px;
    // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    margin-bottom: 7px;
  }
  .catalogue::before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 30px;
    background-color: #e52e00;
    margin-right: 10px;
  }
  .btn {
    margin: 10px 0 20px 0;
    .share {
      margin-left: 34px;
    }
  }
  .keyword {
    margin: 39px 0 64px 0;
  }
}
// 移动端样式
.mobile {
  .container {
    margin-top: 5px;
    .catalogue {
      font-size: 18px;
      padding: 0 15px;
    }
    .titleFit {
      padding: 0 15px;
    }
    .catalogue::before {
      width: 3px;
      height: 18px;
      margin-right: 6px;
    }
    .keyword {
      padding: 0 15px;
    }
  }
}
</style>

<style lang="less">
.keyword {
  .rich-text {
    p > strong > span > span {
      font-size: 20px;
      font-weight: bold !important;
    }
    p > span > strong {
      font-size: 20px !important;
      font-weight: bold !important;
    }
    strong > span {
      font-size: 20px;
      font-weight: bold !important;
    }
    p > span {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.65);
    }
    .divider {
      height: 51.5px;
    }
  }
}
</style>
