<template>
  <el-select :value="value" placeholder="请选择职务" v-bind="$attrs" v-on="$listeners">
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'JobtitleSelect',
  props: {
    value: {
      required: true,
    },
    size: {
      type: String,
      default() {
        return 'medium'
      },
    },
    params: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: [],
    }
  },
  watch: {
    params: {
      handler() {
        this._loadData()
      },
      immediate: true,
    },
  },
  methods: {
    _loadData() {
      if (this.params == null) {
        this.options = []
      } else {
        this.options = [
          { label: '院长', value: '院长' },
          { label: '副院长', value: '副院长' },
          { label: '科室/部门主任', value: '科室/部门主任' },
          { label: '科室/副部门主任', value: '科室/副部门主任' },
          { label: '普通医师/护士/药师/职员', value: '普通医师/护士/药师/职员' },
          { label: '其他', value: '其他' },
        ]
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
