import http from '@/http/index'

/**
 * 广告列表
 * @param params：{
 *}
 */
export function selectAdvertList(params) {
  return http.post('/api/advert/selectAdvertList', params)
}

export function getModularSwitch(params) {
  return http.post('/api/base/getModularSwitch', params)
}
