<template>
  <a-config-provider :locale="locale">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
import { getSessionUser, setSessionUser } from '@/utils/app-utils'
import { mapMutations } from 'vuex'
import { loginWithoutPwdwithQueryload } from '@/api/login'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  name: 'App',
  data() {
    zhCN.Empty.description = '未搜索到相关内容'
    return {
      locale: zhCN,
    }
  },
  created() {
    // 页面入口，获取session中的用户，存入vuex
    let user = getSessionUser()
    this.setUser(user)
  },
  mounted() {
    if (this.$route.query.nejmjmedssodata) {
      console.log(this.$route.query.nejmjmedssodata);
      let vm = this;
      loginWithoutPwdwithQueryload({ data: this.$route.query.nejmjmedssodata }).then((res) => {
        console.log(res);
        if (res) {
          vm.setUser(res)
          console.log(res, '====用户信息')
          // vm.subscribe_info = res.subscribe_info
          // vm.subscribe_info.service_type = res.subscribe_info.service_type
          setSessionUser(res)
          window.sessionStorage.setItem('memberId', res.id)
        }
      }).catch((err) => {
        console.log(err);
        vm.$nextTick(() => {
          vm.$bus.emit('login')
        })
      })
    }
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
  },
}
</script>
