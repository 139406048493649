<template>
  <div class="box">
    <div class="fr">
      <h3 class="title">{{ title }}</h3>
      <a-button v-if="hasMore" type="link" size="small" class="mr-btn" @click="more">
        更多
        <a-icon type="right" />
      </a-button>
    </div>
    <a-divider style="margin: 16px 0 24px 0; background: #414347" />
    <a-list :grid="grid" :data-source="list">
      <a-list-item slot="renderItem" slot-scope="item">
        <slot v-bind:item="item"></slot>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
export default {
  name: 'TitleListBox',
  props: {
    title: {
      type: String,
    },
    list: {
      type: Array,
      required: true,
    },
    hasMore: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    grid() {
      let column = 3
      if (this.$isMobile) {
        column = 1
      }
      return { gutter: 30, column, md: 2, lg: 3 }
    },
  },
  methods: {
    more() {
      this.$emit('more')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.box {
  padding: 20px 0;
}

.fr {
  margin: 10px 0;
  align-items: baseline;
  justify-content: space-between;
  .title {
    // font-weight: 400;
    // font-size: 32px;
    // border-left: 4px solid @clr-primary;
    // padding-left: 12px;
    // color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
    color: #e52e00;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 700;
  }
}
.mr-btn {
  font-weight: 400;
  text-align: right;
  color: rgba(0, 0, 0, 0.65);
  line-height: 33px;
  font-size: 18px;
}
.mobile {
  .fr {
    .title {
      font-size: 18px;
    }
  }
}
</style>
