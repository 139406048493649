import Search from '@/views/search/Search.vue'

export default [
  {
    path: '/search',
    name: '查询',
    component: Search,
    meta: {
      keepAlive: true,
      isBack: false,
    },
  },
]
