<template>
  <!-- 专业领域列表 -->
  <MainCenter class="ProfessionalArticleList">
    <div class="header">
      <div class="header_main">
        <div class="txts">
          <span v-if="!$isMobile">{{ code | getProfessional }}</span>
          <span v-if="$isMobile">
            {{ code | getProfessional }}
            <span>/疾病领域</span>
            <span @click="show = true" style="margin-left: 33px; color: #e52e00">日期 {{ timeFit }}</span>
            <van-calendar :min-date="minDate" :max-date="maxDate" v-model="show" type="range" @confirm="change2" />
          </span>
          <span v-if="!$isMobile">{{ code | getProfessional2 }}</span>
        </div>
        <div class="header_main_bottom">
          <a-tabs v-model="activeKey" type="card" @change="tabChange">
            <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tab"></a-tab-pane>
          </a-tabs>
          <el-form v-if="!$isMobile" inline label-width="90px" :model="form">
            <!-- <el-form-item label="排序" prop="sortType">
              <SortSelect v-model="form.sortType" :params="params" @change="shaiXuanGetData" />
            </el-form-item> -->
            <el-form-item label="日期">
              <el-date-picker
                unlink-panels
                style="width: 268px"
                v-model="form.date"
                format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="change1"
              ></el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <!-- <div style="width: 100%; padding-bottom: 6px; padding-left: 27%" v-else>
          <MobileDateRangeSelect style="width: 260px" v-model="form.date" @change="getData"></MobileDateRangeSelect>
        </div> -->
      </div>
    </div>
    <ArticleList
      v-if="activeKey === '1'"
      :params="form"
      :pagination="flag === true ? pagination : null"
      :listData="listData"
      :loading="loading"
      @search="search"
    ></ArticleList>
    <AnimationList
      v-if="activeKey === '2'"
      :params="form"
      :listData="listData"
      :loading="loading"
      @search="search"
      :pagination="flag === true ? pagination : null"
    ></AnimationList>
    <AudioList
      v-if="activeKey === '3'"
      :params="form"
      :listData="listData"
      :loading="loading"
      :pagination="flag === true ? pagination : null"
    ></AudioList>
    <div v-if="!$isMobile" class="footer">
      <template  v-for="(item, index) in footList">
        <div class="item" @click="jumpZyly(item)" :key="index">
          <span>{{ item.title }}</span>
          <span>{{ item.titleEn }}</span>
        </div>
        <div :key="index + '1'" v-if="index !== footList.length - 1" class="line"></div>
      </template>
    </div>
  </MainCenter>
</template>

<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import ArticleList from './list/ArticleList.vue'
import AnimationList from './list/AnimationList.vue'
import AudioList from './list/AudioList.vue'
// import SortSelect from '@/components/form/select/SortSelect.vue'
import { getProfessional, getProfessional2, getArticleClassIds } from '@/filters'
import { selectArticleClass, selectArticleSource, selectArticleFilter, selectArticleList } from '@/api/article'
import moment from 'moment'
import { format } from '@/utils/time-utils.js'
import { PATH_PROFESSIONAL_ARTICLE_LIST } from '@/router/article/paths'
import { concatUrl } from '@/utils/file-utils'
import { getSessionUser } from '@/utils/app-utils'
// import MobileDateRangeSelect from '@/components/form/select/MobileDateRangeSelect'

export default {
  name: 'ProfessionalArticleList',
  components: { MainCenter, ArticleList, AnimationList, AudioList },
  data() {
    return {
      code: null,
      tabs: [
        { key: '1', tab: '文章' },
        { key: '2', tab: '动画解读' },
        // { key: '3', tab: '音频' },
      ],
      activeKey: '1',
      form: {
        // sortType: '',
        date: [],
        articleFilterIds: [],
        articleTypeIds: [],
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      },
      listData: [],
      pagination: {
        onChange: (page) => {
          this.getData(page)
        },
        current: 1,
        pageSize: 20,
        total: 0,
      },
      flag: false,
      zhiji: null,
      loading: true,
      timeFit: '',
      show: false,
      minDate: new Date(2010, 12, 1),
      maxDate: new Date(),
      // params: [
      //   { label: '最新在前', value: '0' },
      //   { label: '最早在前', value: '1' },
      //   { label: '阅读最多', value: '2' },
      // ],
    }
  },
  filters: { getProfessional, getProfessional2, getArticleClassIds },
  computed: {
    footList() {
      let list = [
        { code: 10, title: '心脑血管疾病', titleEn: 'Cardiovascular Disease' },
        { code: 20, title: '肿瘤', titleEn: 'Oncology' },
        { code: 30, title: '糖尿病', titleEn: 'Diabetes' },
        { code: 40, title: '呼吸系统疾病', titleEn: 'Pulmonary Disease' },
        { code: 50, title: '妇产科和儿科', titleEn: 'Ob/Gyn & Pediatrics' },
        { code: 70, title: '传染病', titleEn: 'Infectious Disease' },
        { code: 60, title: '其他', titleEn: 'Other Specialties' },
      ]
      return list.filter((v) => v.code !== Number(this.code))
    },
  },
  // watch: {
  //   '$route.query': {
  //     handler() {
  //       this.code = this.$route.query.code
  //       if(this.$route.query.startDate&&this.$route.query.endDate){
  //         this.$set(this.form,'date',[new Date(moment(this.$route.query.startDate).format('YYYY-MM-DD')),new Date(moment(this.$route.query.endDate).format('YYYY-MM-DD'))]);
  //         // this.form.date[0] =new Date(moment(this.$route.query.startDate).format('YYYY-MM-DD'));
  //         // this.form.date[1] =new Date(moment(this.$route.query.endDate).format('YYYY-MM-DD'))
  //         this.timeFit = `${this.formatDate(this.form.date[0])} - ${this.formatDate(this.form.date[1])}`
  //       }else {
  //         this.$set(this.form,'date',null)
  //       }
  //       this.change()
  //     },
  //     immediate: true,
  //   },
  // },
  created() {
    this.code = this.$route.query.code
    this.activeKey = this.$route.query.activeKey || '1'
    this.$bus.on('refresh-page', () => {
      this.getData(this.pagination.current)
    })
  },
  beforeDestroy() {
    this.$bus.off('refresh-page')
  },
  methods: {
    shaiXuanGetData() {
      this.getData(1)
    },
    jumpZyly(item) {
      this.$router.push({ path: `${PATH_PROFESSIONAL_ARTICLE_LIST}?code=${item.code}` })
    },
    selectArticleClass() {
      selectArticleClass().then(() => {})
    },
    selectArticleSource() {
      selectArticleSource().then(() => {
        selectArticleFilter({ id: 6 }).then(() => {})
      })
    },
    tabChange() {
      let query = {};
      this.form = {
        // sortType: '',
        date: [],
        articleFilterIds: [],
        articleTypeIds: [],
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      }
      this.timeFit = ''
      query.code = this.code;
      query.activeKey = this.activeKey;
      this.$router.push({
        path: '/professional-article-list',
        query: query,
      })
      this.change()
    },
    change() {
      this.pagination.current = 1
      this.getData(1)
    },
    change1(val){
      let query = {...this.$route.query};
      if(val && val.length > 0){
        query.startDate =  moment(val[0]).format('YYYYMMDD')
        query.endDate = moment(val[1]).format('YYYYMMDD')
      } else {
        Object.prototype.hasOwnProperty.call(query,'startDate') && delete query.startDate
        Object.prototype.hasOwnProperty.call(query,'endDate') && delete query.endDate
      }
      this.$router.push({
        path: '/professional-article-list',
        query: query,
      })
      // this.change();
    },
    change2(val) {
      let query = {...this.$route.query};
      console.log(val);
      const [start, end] = val
      query.startDate = format(start, 'YYYYMMDD')
      query.endDate = format(end, 'YYYYMMDD')
      this.form.date = [query.startDate,query.endDate]
      // this.$set(this.form,'date',[new Date(moment(query.startDate).format('YYYY-MM-DD')),new Date(moment(query.endDate).format('YYYY-MM-DD'))]);
      this.show = false
      this.timeFit = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.pagination.current = 1
      this.$router.push({
        path: '/professional-article-list',
        query: query,
      })
      this.getData2(1)
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    getSourceIds() {
      if (this.activeKey === '1') {
        return [5, 6, 9, 11]
      } else if (this.activeKey === '2') {
        return [7]
      } else if (this.activeKey === '3') {
        return [8]
      }
      return []
    },
    search(form) {
      console.log(this.form,form);
      // var datetime = null;
      // this.form.date && ( datetime = [this.form.date[0],this.form.date[1]])
      this.form = { ...this.form, ...form}
      this.$isMobile &&this.form.date&& (this.timeFit = `${this.formatDate(this.form.date[0])} - ${this.formatDate(this.form.date[1])}`)
      this.pagination.current = 1
      this.getData(1)
    },
    getData(pageNo) {
      this.loading = true
      this.listData = []
      let param = { ...this.form, pageNo, pageSize: this.pagination.pageSize }
      param.articleClassIds = [getArticleClassIds(this.code)]
      param.sourceIds = this.getSourceIds()
      param.startDate = param.date && param.date.length ? format(param.date[0], 'YYYYMMDD') : null
      param.endDate = param.date && param.date.length ? format(param.date[1], 'YYYYMMDD') : null
      delete param.date
      selectArticleList(param).then((res) => {
        if (res.results.length == 0) {
          this.flag = false
        } else {
          this.flag = true
        }
        res.results.forEach((v) => {
          // 切割作者
          v.authorText = this._sliceAuthor(v.author)
          v.postdate = v.postdate ? format(v.postdate, 'YYYY-MM-DD') : null
          v.videoUrl = v.videoUrl ? concatUrl(v.videoUrl) : null
          if (v.classNames != null) {
            v.classNames = v.classNames.split(',')
            let classNames = []
            v.classNames.forEach((item) => {
              if (item !== '') {
                classNames.push(item)
              }
            })
            v.classNames = classNames
          }
        })
        this.pagination.total = res.page.total
        this.pagination.current = pageNo
        this.listData = res.results
        this.loading = false
      })
    },
    getData2(pageNo) {
      this.loading = true
      this.listData = []
      console.log(this.listData)
      let param = { ...this.form, pageNo, pageSize: this.pagination.pageSize }
      param.articleClassIds = [getArticleClassIds(this.code)]
      param.sourceIds = this.getSourceIds()
      param.startDate = param.date && param.date.length ? format(param.date[0], 'YYYYMMDD') : null
      param.endDate = param.date && param.date.length ? format(param.date[1], 'YYYYMMDD') : null
      delete param.date
      selectArticleList(param).then((res) => {
        if (res.results.length == 0) {
          this.flag = false
        } else {
          this.flag = true
        }
        res.results.forEach((v) => {
          // 切割作者
          v.authorText = this._sliceAuthor(v.author)
          v.postdate = v.postdate ? format(v.postdate, 'YYYY-MM-DD') : null
          v.videoUrl = v.videoUrl ? concatUrl(v.videoUrl) : null
          if (v.classNames != null) {
            v.classNames = v.classNames.split(',')
            let classNames = []
            v.classNames.forEach((item) => {
              if (item !== '') {
                classNames.push(item)
              }
            })
            v.classNames = classNames
          }
        })
        this.pagination.total = res.page.total
        this.pagination.current = pageNo
        this.listData = res.results
        this.loading = false
      })
    },
    _sliceAuthor(author, limit = 3) {
      author = author.split(',')
      const postList = author.filter((item) => item.charAt(item.length - 1) === '.').map((item) => item.trim())
      let indexArr = []
      let count = 0
      let delLength = 0
      for (let i = 0; i < author.length; i++) {
        // 判断是否是职称
        // 如果是：当前字符串放入indexArr，continue
        // 如果否：说明是作者姓名，判断字符串长度是否超过限制,否：count+1，当前字符串放入indexArr，continue，是：删除上一次添加的名字，中断循环；如果数组长度是否超出limit，否：count+1，当前字符串放入indexArr，continue，是：中断循环
        let index = author[i]
        if (!postList.includes(index.trim())) {
          // 判断字符串长度是否超过限制或数组的长度是否满足limit
          if (indexArr.join(',').length >= 100) {
            indexArr.splice(indexArr.length - 1 - delLength, delLength + 1)
            break
          }
          if (count >= limit) {
            break
          }
          count++
          delLength = 0
          indexArr.push(index)
        } else {
          delLength++
          indexArr.push(index)
        }
      }
      if (indexArr.length < author.length) {
        return indexArr.join(',') + '...'
      }
      return indexArr.join(',')
    },
  },
}
</script>

<style lang="less">
@import '~@/assets/less/variables.less';
.qrCode_con {
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    background-color: #ffffff;
  }
  span {
    margin-top: 6px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
  }
}
.home {
  .ProfessionalArticleList {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }
  .AnimationList {
    margin: auto;
    width: 100%;
    max-width: 1200px;
  }
}
.ProfessionalArticleList {
  .header {
    position: relative;
    // left: calc((1200px - 100vw) / 2);
    // width: 100vw;
    // height: 248px;
    height: 196px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #d9d9d9;
    padding-left: 10px;
    .qrCode {
      position: absolute;
      right: 42px;
      bottom: 63px;
      width: 50px;
      height: 50px;
      padding: 2px;
      background-color: #ffffff;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.12), 0px 3px 8px 0px rgba(0, 0, 0, 0.08),
        0px 5px 14px 0px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      box-sizing: border-box;
      .icon {
        width: 100%;
        height: 100%;
      }
    }
    .header_main {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 32px calc((100vw - 1200px) / 2) 0px calc((100vw - 1200px) / 2);
      box-sizing: border-box;
      .ant-tabs-bar {
        margin-bottom: 0;
        border-bottom: none;
      }
      .txts {
        display: flex;
        flex-direction: column;
        span:nth-child(1) {
          font-size: 42px;
          // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
          line-height: 50px;
        }
        span:nth-child(2) {
          margin-top: 8px;
          font-size: 24px;
          // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
          line-height: 32px;
        }
      }
      .header_main_bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 24px;
        .ant-tabs-tab {
          // width: 180px;
          height: 50px;
          font-size: 18px;
          // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          color: rgba(0, 0, 0, 0.85);
          line-height: 50px;
          text-align: center;
          background-color: #ececec;
          border-radius: 2px 2px 0px 0px;
          margin-right: 4px;
        }
        .ant-tabs-tab-active {
          color: #e52e00;
          background-color: #fff;
        }
        .el-form-item {
          margin: 0 0 4px 0;
        }
      }
    }
  }
  .footer {
    // width: 100vw;
    height: 164px;
    position: relative;
    // left: calc((1200px - 100vw) / 2);
    color: #ffffff;
    background-color: #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 calc((100vw - 1200px) / 2);
    box-sizing: border-box;
    .item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgba(0, 0, 0, 0.85);
      span:nth-child(1) {
        font-size: 18px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        line-height: 26px;
      }
      span:nth-child(2) {
        margin-top: 4px;
        font-size: 14px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        line-height: 22px;
      }
    }
    .line {
      width: 1px;
      height: 50px;
      background-color: @clr-divider;
    }
  }
  @media screen and (max-width: 1200px) {
    .header {
      left: 0;
    }
    .footer {
      left: 0;
    }
  }
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 54px;
}
.el-form-item__label {
  font-size: 16px;
  // font-family: PingFangSC, PingFangSC-Regular;
  color: rgba(0, 0, 0, 0.85);
  padding-right: 15px;
}
.mobile {
  .ProfessionalArticleList {
    .ArticleList {
      width: 100%;
    }
    .AnimationList {
      width: 100%;
    }
    .header {
      padding: 0 15px;
      position: relative;
      left: calc((1200px - 100vw) / 2);
      width: 100%;
      height: 100px;
      color: rgba(0, 0, 0, 0.65);
      background-color: #d9d9d9;
      .qrCode {
        position: absolute;
        right: 42px;
        bottom: 63px;
        width: 50px;
        height: 50px;
        padding: 2px;
        background-color: #ffffff;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.12), 0px 3px 8px 0px rgba(0, 0, 0, 0.08),
          0px 5px 14px 0px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        box-sizing: border-box;
        .icon {
          width: 100%;
          height: 100%;
        }
      }
      .header_main {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px calc((100vw - 1200px) / 2) 0px calc((100vw - 1200px) / 2);
        box-sizing: border-box;
        .ant-tabs-bar {
          margin-bottom: 0;
          border-bottom: none;
        }
        .txts {
          display: flex;
          flex-direction: column;
          span:nth-child(1) {
            font-size: 20px;
            // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
            line-height: 50px;
            span {
              font-size: 10px;
              margin-top: -20px;
              display: inline-block;
            }
          }
          span:nth-child(2) {
            margin-top: 8px;
            font-size: 16px;
            // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
            line-height: 32px;
          }
          .el-button {
            float: right;
            height: 32px;
            margin-top: 10px;
          }
        }
        .header_main_bottom {
          justify-content: center;
          margin-top: 0px;
          .ant-tabs-tab {
            width: 121.08px !important;
            height: 24px !important;
            font-size: 12px !important;
            line-height: 22px !important;
          }
          .ant-tabs-tab-active {
            background-color: #e52e00 !important;
            color: #fff !important;
          }
        }
      }
    }
    .footer {
      width: 100vw;
      height: 164px;
      position: relative;
      left: calc((1200px - 100vw) / 2);
      color: #ffffff;
      background-color: #f2f2f2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 calc((100vw - 1200px) / 2);
      box-sizing: border-box;
      .item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: rgba(0, 0, 0, 0.85);
        span:nth-child(1) {
          font-size: 6px;
          // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
          line-height: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span:nth-child(2) {
          margin-top: 4px;
          font-size: 6px;
          // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
          line-height: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .line {
        width: 1px;
        height: 50px;
        background-color: @clr-divider;
      }
    }
    @media screen and (max-width: 1200px) {
      .header {
        left: 0;
      }
      .footer {
        left: 0;
      }
    }
  }
}
</style>
<style lang="less" scoped></style>
