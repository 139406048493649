/**
 * 字符串转DOM
 * @param str
 * @returns {NodeListOf<ChildNode>}
 */
export function parseDOM(str) {
  if (!str) {
    return null
  }
  let div = document.createElement('div')
  div.innerHTML = str
  return div.childNodes
}
