<template>
  <!-- 动画解读 -->
  <div class="AnimationList">
    <a-list
      :split="false"
      size="large"
      :pagination="loading === false ? pagination : null"
      :loading="loading"
      :data-source="listData"
      class="zhuanYeQuickTakes"
      :grid="!$isMobile ? { gutter: 58, column: 4 } : { gutter: 0, column: 1 }"
    >
      <a-list-item class="item" slot="renderItem" :key="index" slot-scope="item, index">
        <a :href="'/animation-particulars' + generate(item.permalinks)" @click.prevent="onItemClick(item)">
          <el-image :src="item.thumb" fit="cover"></el-image>
        </a>
        <div class="title">
          <a @click.prevent="onItemClick(item)">{{ item.title }}</a>
        </div>
        <div class="time">{{ item.postdate }}</div>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import { generateHref } from '@/utils/utils'
import moment from 'moment'

export default {
  name: 'AnimationList',
  props: ['params', 'pagination', 'listData', 'loading'],
  components: {},
  data() {
    return {
      form:{},
      code:null
    }
  },
  mounted() {
    this.form = { ...this.params }
  },
  watch: {
    '$route.query': {
      handler() {
        if (this.$route.query.code) {
          this.code = this.$route.query.code
        }
        if(this.$route.query.startDate&&this.$route.query.endDate){
          this.$set(this.form,'date',[new Date(moment(this.$route.query.startDate).format('YYYY-MM-DD')),new Date(moment(this.$route.query.endDate).format('YYYY-MM-DD'))]);
          // this.timeFit = `${this.formatDate(this.form.date[0])} - ${this.formatDate(this.form.date[1])}`
        }else {
          this.$set(this.form,'date',null)
        }
        this.$emit('search', this.form)
      },
      immediate: true,
    },
  },
  methods: {
    generate(item) {
      return generateHref('permalinks', item)
    },
    onItemClick(item) {
      this.$router.push({ path: '/animation-particulars', query: { permalinks: item.permalinks } })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';
/deep/.ant-row {
  margin: 0 !important;
  padding: 0 10px;
}
.AnimationList {
  padding: 64px 0;
  .item {
    height: 212px;
    // margin-bottom: 40px;
    // margin-bottom: 102px;
    a {
      .el-image {
        width: 100% !important;
        height: 180px !important;
      }
    }
    .time {
      width: 256px;
      text-align: left;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      line-height: 42px;
      border: none; //需求更改
      margin-top: -5px;
    }
    .title {
      margin-top: 8px;
      // 双行...
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      > a {
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
      a:hover {
        color: #e52e00;
      }
    }
  }
}
.ant-list-pagination {
  margin-top: 0px;
  text-align: center;
}
.mobile {
  .AnimationList {
    padding: 15px 15px 0 15px;
    .item {
      margin-bottom: 0px !important;
      height: auto;
      a {
        .el-image {
          width: 100% !important;
          height: 100% !important;
        }
      }
      .time {
        width: 100%;
        font-size: 18px;
        text-align: left;
      }
      .title {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        a {
          font-size: 18px;
        }
      }
    }
    /deep/.ant-list-pagination {
      margin-bottom: 24px;
    }
  }
}
</style>
<style lang="less">
.zhuanYeQuickTakes {
  .ant-col-6 {
    border-right: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    height: 330px !important;
    padding-top: 30px;
  }
  .item {
    margin-bottom: 0 !important;
  }
  .ant-col-6:nth-of-type(4n-4) {
    border-right: none;
  }
  .ant-col-6:nth-of-type(17) {
    border-bottom: none;
  }
  .ant-col-6:nth-of-type(18) {
    border-bottom: none;
  }
  .ant-col-6:nth-of-type(19) {
    border-bottom: none;
  }
  .ant-col-6:nth-of-type(20) {
    border-bottom: none;
  }
  .ant-col-6:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.2) inset;
  }
  .ant-list-pagination {
    margin-top: 30px;
  }
}
.mobile {
  .zhuanYeQuickTakes {
    .ant-list-pagination {
      margin-top: 0px;
    }
  }
}
</style>
