import Vue from 'vue'
import { mapState } from 'vuex'

Vue.mixin({
  computed: {
    ...mapState({
      $isMobile: (state) => state.isMobile,
      $isMobile1: (state) => state.isMobile1,
      $isMobile2: (state) => state.isMobile2,
    }),
  },
})
