<template>
  <div class="">
    <h3 v-if="reviewList.length > 0" class="title">述评</h3>
    <div v-for="(item, i) of reviewList" :key="i" style="padding: 5px 0">
      <a
        :href="'/article-info' + generate(item.permalinks)"
        @click.prevent="onReviewClick(item)"
        style="font-size: 16px; color: #666666; letter-spacing: 0; line-height: 24px; font-weight: 400"
      >
        {{ item.wantsayTitle | formatHtmlTxt }}
      </a>
    </div>
    <!-- <div class="nejm" @click="onNejmClick">
      <h3 class="title">NEJM目录</h3>
      <a class="nejm-txt" v-for="(item, i) of mulvList" :key="i" v-html="replaceHandle(item.title, `<br>`)"></a>
    </div> -->
  </div>
</template>

<script>
import { newestArticle } from '@/api/nejmCatalog'
import { PATH_ARTICLE_INFO, PATH_NEJM_CATALOG } from '@/router/article/paths'
import { getModularSwitch, selectAdvertList } from '@/api/adv'
import { generateHref } from '@/utils/utils'
import { format } from '@/utils/time-utils'

export default {
  name: 'CenterRightReview',
  data() {
    return {
      adList: [],
      reviewList: [],
      mulvList: [],
      adv: null,
      advImg: '',
      switchValue: null,
    }
  },
  filters: {
    formatDateTxt(time) {
      return format(time, 'YYYY-MM-DD')
    },
    formatHtmlTxt(str) {
      return str.replace(/<\/?.+?>/g, '')
    },
  },
  async created() {
    await this.getList()
    await this.getModularSwitch()
  },
  methods: {
    generate(item) {
      return generateHref('permalinks', item)
    },
    onAnimationClick(item) {
      console.log(item)
      this.$router.push({ path: '/animation-particulars', query: { permalinks: item.permalinks } })
    },
    onReviewClick(item) {
      console.log(item)
      this.$router.push({ path: PATH_ARTICLE_INFO, query: { permalinks: item.permalinks } })
    },
    onNejmClick() {
      this.$router.push({ path: PATH_NEJM_CATALOG, query: { permalinks: this.mulvList[0].permalinks } })
      // this.$router.push({ path: PATH_NEJM_CATALOG })
    },
    replaceHandle(str, query) {
      let res = new RegExp('(' + query + ')', 'g')
      str = str?.replace(res, '')
      str = str?.replace('　Vol', 'Vol')
      str = str?.replace('　No', 'No')
      str = str?.replace(' Vol', 'Vol')
      str = str?.replace(' No', 'No')
      str = str?.replace('Vol', '　Vol')
      str = str?.replace('No', '　No')
      return str
    },
    async getList() {
      let res = await newestArticle({})
      this.mulvList = res.articleMap[0]
      let arr = []
      for (const item of res.articleMap[5]) {
        if ('' != item.wantsay) {
          arr.push(item)
        }
      }
      // for (const item of res.articleMap[6]) {
      //   if ('' != item.wantsay) {
      //     arr.push(item)
      //   }
      // }
      if (res.articleMap[7]) {
        this.adList = res.articleMap[7]
        console.log(2)
        this.adList.splice(1, 1)
        for (const item of res.articleMap[7]) {
          if ('' != item.wantsay) {
            arr.push(item)
          }
        }
      }
      // arr.push(
      //   '<p style="text-align: center;"><strong>强化降压降低老年高血压… Hypertension. N Engl J Med 2021;385:1268-79.</p >'
      // )
      this.reviewList = arr
      console.log('reviewList::', this.adList)
    },
    async getModularSwitch() {
      let res = await getModularSwitch({ moduleName: 'advert' })
      this.switchValue = res.switchValue
      if (this.switchValue == 1) {
        await this.getAdv()
      }
    },
    async getAdv() {
      let res = await selectAdvertList({
        pageNo: 1,
        pageSize: 1,
        ifPublish: '是',
      })
      console.log(this.adv, 'ccc')
      this.adv = res.results[0]
      if (this.adv != null && (this.adv.advertPosition == null || this.adv.advertPosition == 0)) {
        this.advImg = JSON.parse(this.adv.imgUrl)[0].url
      }
    },
    showAdv() {
      window.open(this.adv.adUrl)
      // window.open('https://www.qq.com')
      // downloadFile(item.adUrl)
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.ad {
  width: 100%;
  height: 250px;
  margin-bottom: 30px;
  background-color: #ccc;
}
.title {
  margin: 12px 0;
  font-size: 18px;
  font-weight: 400;
  color: #e83b19;
  font-size: 18px;
  color: #e52e00;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 700;
  border-bottom: 1px solid #414347;
  padding-bottom: 16px;
}
.nejm {
  margin: 20px 0;
  // padding: 5px 20px;
  // line-height: 1.5;
  // border-left: 4px solid @clr-primary;
  //background: rgba(0, 0, 0, 0.04);
  // background: #f9e093;
  // cursor: pointer;
  .title {
    font-size: 18px;
    color: #e52e00;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 700;
    border-bottom: 1px solid #414347;
    padding-bottom: 16px;
  }
}
.card-css {
  cursor: pointer;
  border: none;
  //div:nth-child(2) {
  //  padding-left: 0px;
  //  padding-right: 0px;
  //}
}
.card-css :nth-child(2) {
  zoom: 1;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.ant-row div:nth-child(2) {
  margin-left: 29px;
}
.nejm-txt {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  color: #1e4ea6;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 400;
  //color: white;
}
.mobile {
  .ant-row div:nth-child(2) {
    margin-left: 0px;
  }
  .title {
    font-size: 18px;
  }
  .nejm {
    .title {
      font-size: 18px;
    }
  }
}
</style>
