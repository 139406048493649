/**
 * 支付宝开发包
 */

/** 检查是否是支付宝浏览器 */
export const isAli = function () {
    let ua = window.navigator.userAgent.toLowerCase();
    //判断是不是支付宝
    if (ua.match(/AlipayClient/i) == "alipayclient") {
        return true;
    } else {
        return false;
    }
}