import http from '@/http/index'

/**
 * 会议预告详情
 * @param params：{
 *}
 */
export function conferenceAdvanceDetail(params) {
  return http.post('/api/ConferenceAdvance/detail', params)
}
/**
 * 会议预告列表
 * @param params：{
 *}
 */
export function conferenceAdvanceList(params) {
  return http.post('/api/ConferenceAdvance/list', params)
}
/**
 * 学科列表
 * @param params：{
 *}
 */
export function subjectList(params) {
  return http.post('/api/ConferenceAdvance/subject', params)
}

/**
 * 课程详情
 * @param params
 * @returns {undefined}
 */
export function selectLessonDetail(params) {
  return http.post('/api/lesson/selectLessonDetail', params)
}
/**
 * 课程列表
 * @param params
 * @returns {undefined}
 */
export function selectLessonList(params) {
  return http.post('/api/lesson/selectLessonList', params)
}

/**
 * 课程类别列表
 * @param params 按照subjectSerType查询所属类别下的视频数目
 * @returns {undefined}
 */
 export function selectLessonListCountUsingPOST(params) {
  return http.post('/api/lesson/selectLessonCount', params)
}

