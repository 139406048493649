var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('MainCenter',{staticClass:"mainCenter"},[(!_vm.$isMobile)?_c('a-anchor',{attrs:{"offsetTop":90}},[_c('div',{staticClass:"sidebar"},[_c('a-menu',{staticClass:"cebianlan",staticStyle:{"width":"294px"},attrs:{"mode":"inline"},on:{"click":_vm.handleClick}},[_c('a-sub-menu',{key:"our",staticStyle:{"width":"294px"},on:{"titleClick":_vm.titleClick}},[_c('div',{staticClass:"menu-title",class:_vm.key === 'our' ||
                _vm.key === 'gywm' ||
                _vm.key === 'team' ||
                _vm.key === 'translation' ||
                _vm.key === 'medical' ||
                _vm.key === 'quote' ||
                _vm.key === 'corrigendum' ||
                _vm.key === 'guide' ||
                _vm.key === 'consult'
                  ? 'menu-title2'
                  : null,attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"one",class:_vm.key === 'our' ||
                  _vm.key === 'gywm' ||
                  _vm.key === 'team' ||
                  _vm.key === 'translation' ||
                  _vm.key === 'medical' ||
                  _vm.key === 'quote' ||
                  _vm.key === 'corrigendum' ||
                  _vm.key === 'guide' ||
                  _vm.key === 'consult'
                    ? 'one1'
                    : null},[_vm._v(" 关于我们 ")])]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#gywm',
                offset: !_vm.$isMobile ? -105 : -55,
              }),expression:"{\n                el: '#gywm',\n                offset: !$isMobile ? -105 : -55,\n              }"}],key:"gywm",staticClass:"gywm",class:_vm.key === 'gywm' ? 'yangshi2' : null},[_vm._v(" 关于我们 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#team',
                offset: !_vm.$isMobile ? -105 : -55,
              }),expression:"{\n                el: '#team',\n                offset: !$isMobile ? -105 : -55,\n              }"}],key:"team",staticClass:"gywm",class:_vm.key === 'team' ? 'yangshi2' : null},[_vm._v(" 编辑团队 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#translation',
                offset: !_vm.$isMobile ? -105 : -55,
              }),expression:"{\n                el: '#translation',\n                offset: !$isMobile ? -105 : -55,\n              }"}],key:"translation",staticClass:"gywm",class:_vm.key === 'translation' ? 'yangshi2' : null},[_vm._v(" 译文 免责声明 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#medical',
                offset: !_vm.$isMobile ? -105 : -55,
              }),expression:"{\n                el: '#medical',\n                offset: !$isMobile ? -105 : -55,\n              }"}],key:"medical",staticClass:"gywm",class:_vm.key === 'medical' ? 'yangshi2' : null},[_vm._v(" 医疗和药物 免责声明 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#quote',
                offset: !_vm.$isMobile ? -105 : -55,
              }),expression:"{\n                el: '#quote',\n                offset: !$isMobile ? -105 : -55,\n              }"}],key:"quote",staticClass:"gywm",class:_vm.key === 'quote' ? 'yangshi2' : null},[_vm._v(" 引用 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#corrigendum',
                offset: !_vm.$isMobile ? -105 : -55,
              }),expression:"{\n                el: '#corrigendum',\n                offset: !$isMobile ? -105 : -55,\n              }"}],key:"corrigendum",staticClass:"gywm",class:_vm.key === 'corrigendum' ? 'yangshi2' : null},[_vm._v(" 勘误和更正 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#guide',
                offset: !_vm.$isMobile ? -105 : -55,
              }),expression:"{\n                el: '#guide',\n                offset: !$isMobile ? -105 : -55,\n              }"}],key:"guide",staticClass:"gywm",class:_vm.key === 'guide' ? 'yangshi2' : null},[_vm._v(" NEJM统计学报告指南 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#consult',
                offset: !_vm.$isMobile ? -105 : -55,
              }),expression:"{\n                el: '#consult',\n                offset: !$isMobile ? -105 : -55,\n              }"}],key:"consult",staticClass:"gywm",class:_vm.key === 'consult' ? 'yangshi2' : null},[_vm._v(" 《新英格兰医学杂志》投稿咨询 ")])],1),_c('a-menu-item',{key:"2",staticClass:"two",class:_vm.key === '2' ? 'yangshi1' : null},[_vm._v("服务条款")]),_c('a-menu-item',{key:"9",staticClass:"six",class:_vm.key === '9' ? 'yangshi1' : null},[_vm._v("用户协议")]),_c('a-menu-item',{key:"3",staticClass:"three",class:_vm.key === '3' ? 'yangshi1' : null},[_vm._v("隐私政策")]),_c('a-menu-item',{key:"4",staticClass:"four",class:_vm.key === '4' ? 'yangshi1' : null},[_vm._v("联系我们")]),_c('a-menu-item',{key:"10",staticClass:"five",class:_vm.key === '10' ? 'yangshi1' : null},[_vm._v("下载App")])],1)],1)]):_c('div',{staticClass:"anchorFit"},[_c('a-menu',{attrs:{"mode":"horizontal"},on:{"click":_vm.handleClick}},[_c('a-sub-menu',{key:"our",on:{"titleClick":_vm.titleClick}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v("关于我们")])]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#gywm',
              offset: !_vm.$isMobile ? -105 : -55,
            }),expression:"{\n              el: '#gywm',\n              offset: !$isMobile ? -105 : -55,\n            }"}],key:"gywm"},[_vm._v(" 关于我们 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#team',
              offset: !_vm.$isMobile ? -105 : -55,
            }),expression:"{\n              el: '#team',\n              offset: !$isMobile ? -105 : -55,\n            }"}],key:"team"},[_vm._v(" 编辑团队 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#translation',
              offset: !_vm.$isMobile ? -105 : -55,
            }),expression:"{\n              el: '#translation',\n              offset: !$isMobile ? -105 : -55,\n            }"}],key:"translation"},[_vm._v(" 译文 免责声明 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#medical',
              offset: !_vm.$isMobile ? -105 : -55,
            }),expression:"{\n              el: '#medical',\n              offset: !$isMobile ? -105 : -55,\n            }"}],key:"medical"},[_vm._v(" 医疗和药物 免责声明 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#quote',
              offset: !_vm.$isMobile ? -105 : -55,
            }),expression:"{\n              el: '#quote',\n              offset: !$isMobile ? -105 : -55,\n            }"}],key:"quote"},[_vm._v(" 引用 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#corrigendum',
              offset: !_vm.$isMobile ? -105 : -55,
            }),expression:"{\n              el: '#corrigendum',\n              offset: !$isMobile ? -105 : -55,\n            }"}],key:"corrigendum"},[_vm._v(" 勘误和更正 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#guide',
              offset: !_vm.$isMobile ? -105 : -55,
            }),expression:"{\n              el: '#guide',\n              offset: !$isMobile ? -105 : -55,\n            }"}],key:"guide"},[_vm._v(" NEJM统计学报告指南 ")]),_c('a-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#consult',
              offset: !_vm.$isMobile ? -105 : -55,
            }),expression:"{\n              el: '#consult',\n              offset: !$isMobile ? -105 : -55,\n            }"}],key:"consult"},[_vm._v(" 《新英格兰医学杂志》投稿咨询 ")])],1),_c('a-menu-item',{key:"2"},[_vm._v("服务条款")]),_c('a-menu-item',{key:"9"},[_vm._v("用户协议")]),_c('a-menu-item',{key:"3"},[_vm._v("隐私政策")]),_c('a-menu-item',{key:"4"},[_vm._v("联系我们")]),_c('a-menu-item',{key:"10"},[_vm._v("下载App")])],1)],1),_c('div',{ref:"main1",staticClass:"main1"},[_c('div',[(!_vm.$isMobile)?_c('div',{attrs:{"id":"our"}},[_vm._v(_vm._s(_vm.our))]):_vm._e(),_c('span',{attrs:{"id":"gywm"},domProps:{"innerHTML":_vm._s(_vm.gywm)}}),_c('span',{attrs:{"id":"team"},domProps:{"innerHTML":_vm._s(_vm.team)}}),_c('span',{attrs:{"id":"translation"},domProps:{"innerHTML":_vm._s(_vm.translation)}}),_c('span',{attrs:{"id":"medical"},domProps:{"innerHTML":_vm._s(_vm.medical)}}),_c('span',{attrs:{"id":"quote"},domProps:{"innerHTML":_vm._s(_vm.quote)}}),_c('span',{attrs:{"id":"corrigendum"},domProps:{"innerHTML":_vm._s(_vm.corrigendum)}}),_c('span',{attrs:{"id":"guide"},domProps:{"innerHTML":_vm._s(_vm.guide)}}),_c('span',{attrs:{"id":"consult"},domProps:{"innerHTML":_vm._s(_vm.consult)}})])]),_c('div',{ref:"main2",staticClass:"main2"},[_c('div',[(_vm.key != '10' && !_vm.$isMobile)?_c('div',{staticClass:"ourTitle"},[_vm._v(_vm._s(_vm.obj.title))]):_vm._e(),(_vm.key != '10')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.obj.content)}}):_vm._e(),(_vm.key == '10')?_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('el-image',{class:!_vm.$isMobile ? 'pcImage' : 'pcimageFit',attrs:{"src":_vm.downloadPng,"fit":"cover"}})],1):_vm._e()])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }