<template>
  <div></div>
</template>

<script>
import { wechatJudgeBind } from '@/api/login'
import { setSessionUser } from '@/utils/app-utils'
import { mapMutations } from 'vuex'
export default {
  name: '',
  data() {
    return {
      // locale: zhCN,
      code:'',
    }
  },
  created() {
    // 页面入口，获取session中的用户，存入vuex
    // let user = getSessionUser()
    // this.setUser(user)
    console.log(this.$route.query.code);
    this.code = this.$route.query.code;
    this.JudgeBind();
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    //判断是否是微信浏览器
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    JudgeBind(){
      var params = {
        code: this.code,
        iswx: this.isWeiXin()
      }
      var vm =this;
      wechatJudgeBind(params).then((res) => {
        if(!res.result){
          this.setUser(res.tokenString)
          setSessionUser(res.tokenString)
          window.sessionStorage.setItem('memberId', res.tokenString.id)
          this.$message.success('登录成功')  
          vm.$router.back();
        }else {
          vm.$router.replace(`/wxloginBindPhone?code=${vm.code}`);
        }
      })
    }
  },
}
</script>
