<template>
  <el-dialog title="分享到微信" v-bind="$attrs" v-on="$listeners" width="300px">
    <div class="fr">
      <qrcode-vue :value="codeUrl" :size="250" />
    </div>
  </el-dialog>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'CodeDialog',
  components: {
    QrcodeVue,
  },
  props: {
    codeUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.fr {
  align-items: center;
  justify-content: center;
}
</style>
