<template>
  <div class="container">
    <div class="qianYanTitle">{{ title }}</div>
    <a-divider v-if="!$isMobile" style="background-color: #d4d4d4" />
    <div class="fenLan">
      <div class="fenLanItem">
        <div :class="$isMobile ? 'NEJMContainer' : ''"><div class="NEJM">NEJM</div></div>
        <div v-for="(item, index) in list1" :key="index" class="module">
          <a :href="'/article-info' + generate(item.permalinks)" @click.prevent="onItemClick(item)" class="chaolianjie">
            {{ item.title }}
          </a>
          <div class="leixing">{{ item.typeName }}</div>
        </div>
      </div>
      <div style="width: 1px; background-color: #d4d4d4"></div>
      <div class="fenLanItem">
        <div :class="$isMobile ? 'NEJMContainer' : ''"><div class="NEJM">NEJM期刊荟萃</div></div>
        <div v-for="(item, index) in list2" :key="index" class="module">
          <a :href="'/article-info' + generate(item.permalinks)" @click.prevent="onItemClick(item)" class="chaolianjie">
            {{ item.title }}
          </a>
          <div class="leixing">{{ item.typeName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newestArticle } from '@/api/nejmCatalog'
import { generateHref } from '@/utils/utils.js'
export default {
  name: 'LatestPeriodical',
  data() {
    return {
      title: null,
      list1: [],
      list2: [],
    }
  },
  mounted() {
    this.getSelectArticleList()
  },
  methods: {
    generate(item) {
      return generateHref('permalinks', item)
    },
    onItemClick(item) {
      this.$router.push({ path: '/article-info', query: { permalinks: item.permalinks } })
    },
    async getSelectArticleList() {
      let res = await newestArticle()
      this.title = res.articleWeeklyPo.title
      this.list1 = res.articleMap[5]
      this.list2 = res.articleMap[6]
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  margin-top: 56px;
  .qianYanTitle {
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    // font-family: PingFangSC, PingFangSC-Medium;
    margin-bottom: 12px;
  }
  .qianYanTitle::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 30px;
    background-color: #e52e00;
    margin-right: 9px;
  }
  .fenLan {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    .fenLanItem {
      width: 44.35%;
    }
    .chaolianjie {
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
      line-height: 24px;
      // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
    }
    .leixing {
      margin-top: 8px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      line-height: 20px;
    }
    .NEJM {
      width: 532px;
      height: 34px;
      font-size: 18px;
      line-height: 34px;
      text-align: center;
      background-color: #e52e00;
      color: #fff;
      border-radius: 2px;
      // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
      margin-bottom: 32px;
    }
    .module {
      margin-bottom: 32px;
      a:hover {
        color: #e52e00;
      }
    }
  }
}
// 移动端样式
.mobile {
  .container {
    margin-top: 5px;
    .qianYanTitle {
      font-size: 20px;
      // font-family: PingFangSC, PingFangSC-Medium;
      margin-bottom: 12px;
      padding: 0 15px;
    }
  }
  .qianYanTitle::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 20px;
    background-color: #e52e00;
    margin-right: 6px;
  }

  .fenLan {
    margin-top: 0px;
    display: block;
    .NEJMContainer {
      padding: 0 15px;
      .NEJM {
        width: 100%;
      }
    }
    .fenLanItem {
      width: 100%;
    }
    .module {
      padding: 0 15px;
    }
  }
}
</style>
