<template>
  <el-dialog
    title="用户登录"
    width="500px"
    :before-close="_handleClose"
    @close="_handleClose"
    append-to-body
    v-bind="$attrs"
    v-on="$listeners"
  >
    <el-tabs v-model="activeName">
      <el-tab-pane label="手机验证" name="first">
        <el-input placeholder="输入手机号码" v-model="mobileParams.phone" style="margin: 10px 0 20px 0"></el-input>
        <div style="margin-bottom: 20px">
          <el-input placeholder="输入验证码" v-model="mobileParams.code"></el-input>
          <el-button type="primary" class="send">
            发送验证码
            <span>60</span>
          </el-button>
        </div>
        <el-button type="primary" class="login">登录</el-button>
      </el-tab-pane>
      <el-tab-pane label="邮箱验证" name="second">
        <el-input placeholder="输入手机号码" v-model="emailParams.phone" style="margin: 10px 0 20px 0"></el-input>
        <div style="margin-bottom: 20px">
          <el-input placeholder="输入验证码" v-model="emailParams.code"></el-input>
          <el-button type="primary" class="send">
            发送验证码
            <span>60</span>
          </el-button>
        </div>
        <el-button type="primary" class="login">登录</el-button>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
export default {
  name: 'CodeLogin',
  props: {
    codeVisible: {
      type: Boolean,
      default() {
        return false
      },
    },
    codeParams: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      activeName: 'first',
      mobileParams: {
        phone: '',
        code: '',
      },
      emailParams: {
        phone: '',
        code: '',
      },
    }
  },
  methods: {
    _handleClose() {
      this.$emit('update:visible', false)
    },
  },
}
</script>
<style lang="less" scoped>
.el-tab-pane {
  display: flex;
  flex-direction: column;
  .el-input {
    width: 330px;
  }
  .send {
    margin-left: 15px;
    width: 109.64px;
  }
  .login {
    width: 100px;
  }
}
</style>
