<template>
    <div>
      <div ref="rt" class="rich-text" v-html="content"></div>
      <a-drawer
        title="参考文献"
        placement="right"
        width="300px"
        :closable="true"
        :visible="visible"
        @close="visible = false"
      >
        <div class="about" v-html="activeAbout"></div>
      </a-drawer>
    </div>
  </template>
  
  <script>
  import { parseDOM } from '@/utils/dom-utils.js'
  
  const reg1 = /^(\d+)[-|-](\d+)$/
  // const reg2 = /^(\d+)[,|，](\d+)$/
  const reg3 = /[,|，]/
  
  export default {
    name: 'RichText',
    props: {
      content: {
        required: true,
      },
      about: {
        type: String,
      },
    },
    data() {
      return {
        visible: false,
        active: null,
      }
    },
    watch: {
      content: {
        handler(val) {
          if (val) {
            this.$nextTick(() => {
              let vm = this
              this.$refs.rt?.querySelectorAll('a[href]').forEach((it) => {
                it.addEventListener('click', function (e) {
                  let href = it.getAttribute('href')
                  if (href.startsWith('http://#')) {
                    e.preventDefault()
                    vm.active = this.innerText
                    vm.visible = true
                  }
                })
              })
            })
          }
        },
        immediate: true,
      },
    },
    computed: {
      aboutList() {
        return parseDOM(this.about)
      },
      activeAbout() {
        // active可能的格式: 1   1,3   1，3   1,3,5   1，3，5   1-3   1-4,8-11
        let { active } = this
        if (!active) {
          return null
        }
        let arr = active.split(reg3)
  
        let res = []
        arr.forEach((it) => {
          let start,
            end = 0
          let matchs = it.match(reg1)
          if (matchs?.length > 1) {
            start = parseInt(matchs[1])
            end = parseInt(matchs[2])
          } else {
            start = end = parseInt(it)
          }
          res.push(...this._getAbouts(start, end))
        })
  
        return res.join('<br/>')
      },
    },
    methods: {
      _getAbouts(start, end) {
        start = parseInt(start)
        end = parseInt(end)
  
        let res = []
        if (end == null && end === start) {
          res = [this.aboutList[start - 1]]?.outerHTML
        } else {
          for (let i = start; i <= end; i++) {
            res.push(this.aboutList[i - 1]?.outerHTML)
          }
        }
        return res
      },
    },
  }
  </script>
  
  <style lang="less">
  .about {
    padding: 10px 18px;
    font-size: 12px;
  }
  .rich-text {
    text-align: justify;
    font-size: 14px !important;
  }
  </style>
  