<template>
  <el-checkbox-group :value="value" v-bind="$attrs" v-on="$listeners">
    <el-checkbox v-for="(item, index) in options" :key="index" :label="item.id">{{ item.filtername }}</el-checkbox>
  </el-checkbox-group>
</template>

<script>
import { selectArticleFilter } from '@/api/article'
export default {
  name: 'ArticleFilterCheckbox',
  props: {
    value: {
      required: true,
    },
    params: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: [],
    }
  },
  watch: {
    params: {
      handler() {
        this._loadData()
      },
      immediate: true,
    },
  },
  methods: {
    _loadData() {
      if (this.params == null) {
        this.options = []
      } else {
        selectArticleFilter(this.params).then((res) => {
          this.options = res
        })
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
