<template>
  <!-- 我的订阅-->
  <div class="myVipSubsribe">
    <div class="have_vip">
      <div class="myVip_wp">
        <div class="myVip" @click="showOrderFun()" v-if="!$isMobile">
          我的订阅
          <span></span>
        </div>
        <a
          target="_blank"
          href="https://nejmqianyan.cn/nejm-api/static-service/20231117/23111714fbb7eb047dd34fcdb63d0687324443ec.jpeg"
        >
          <div class="ask">如有疑问，请咨询学术助理</div>
        </a>
        <div class="showOrderBtn" @click="showOrderFun()">{{ showOrder ? '收起' : '展开' }}</div>
      </div>
      <div class="dyvip_list" v-show="showOrder" v-for="(item, index) in vipOrderList" :key="index">
        <div class="dyvip_title">
          <div class="dyvip_info">
            <span>下单时间:{{ item.ordertime }}</span>
            <span>订阅编号:{{ item.ordersn }}</span>
          </div>
          <div @click="deleteVipOrder(item.id)" class="iconfont icon-shanchu"></div>
        </div>
        <div class="dyvip_cont">
          <div class="dyvip_img">
            <img :src="item.service_mobile_img" v-if="$isMobile" />
            <img :src="item.service_pc_img" v-if="!$isMobile" />
          </div>
          <div class="dyvip_viptype_info">
            <!-- <span v-if="item.service_title!='连续包月'&&item.service_title!='连续包年'">{{ item.service_title }}</span> -->
            <span>{{ item.service_title }}</span>
            <span class="dyvip_vipmoney">
              <b>{{ item.price }}</b>
            </span>
            <!-- <span class="dyvip_vipmoney"><b>{{ item.price }}</b> <b v-if="item.service_title!='连续包月' &&item.service_title!='连续包年'">* 1</b></span>   -->
            <!--个数，如需要可以放出来-->
          </div>
        </div>
      </div>
      <div class="dyvip_list_page">
        <el-pagination
          v-show="showOrder"
          background
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[3, 10]"
          :page-size="pageSize"
          :total="totalCount"
          layout="sizes, prev, pager, next"
        ></el-pagination>
      </div>
      <div class="clearBoth"></div>
    </div>
    <div class="clearBoth"></div>
    <div class="no_vip">
      <div class="no_vip_title">会员服务订阅</div>
      <div class="no_vip_list">
        <div
          v-infinite-scroll="load"
          :infinite-scroll-disabled="isInfiniteScrollDisabled"
          style="margin-bottom: 20px; overflow-y: auto"
        >
          <div class="viplist">
            <div
              class="vip_item"
              v-for="(item, index) in vipList"
              :key="index"
              @click="checkVip(item.price, index, item.id, item)"
            >
              <div class="vip_pic">
                <img :src="item.pc_img" />
              </div>
              <div class="new_vip_box">
                <div class="vip_money">
                  <span>￥</span>
                  {{ item.price }}
                </div>
                <template v-if="subscribe_info.service_type < 3">
                  <div class="vip_check" v-if="isChek == index">
                    <img src="../../assets/vip/check_true.png" />
                  </div>
                  <div class="vip_check_no" v-else>
                    <!-- <img src="../../assets/vip/check_false.png"/> -->
                  </div>
                </template>
              </div>
            </div>
            <!-- 	  <view class="vip_item">
                            <view class="vip_pic">
                                <image src="https://yxqyoss.judianseo.com/data/upload/20230710/202307101688971193277080.png"></image>
                            </view>
                            <text class="vip_money">￥25</text>
                        </view>
                        <view class="vip_item">
                            <view class="vip_pic">
                                <image src="https://yxqyoss.judianseo.com/data/upload/20230710/202307101688971193277080.png"></image>
                            </view>
                            <text class="vip_money">￥25</text>
                        </view> -->
          </div>
        </div>
      </div>
      <div class="no_vip_title">{{ equity_title }}权益</div>
      <div class="vip_Equity" id="vip_Equitylist">
        <div class="vip_Equity_item" v-for="(item, index) in equitylist" :key="index" :data-id="item.id">
          <!-- <img :src="item.pc_qyimg_on" />  -->
          <img :src="item.icon" />
          <div class="vip_Equity_item_info">
            <div>{{ item.title }}</div>
            <div>{{ item.infos }}</div>
          </div>
          <img class="lock" :data-id="item.id" src="../../assets/vip/lock_close.png" alt="lock" />
        </div>
      </div>
      <div class="clearBoth"></div>
      <!--支付相关 start-->
      <template v-if="!$isMobile && (subscribe_info['service_type'] == 3 || subscribe_info['service_type'] == 4)">
        <div class="no_can_order_box">
          <div class="txt">
            <template v-if="subscribe_info['service_type'] == 3">当前已是连续包月用户，无需购买</template>
            <template v-if="subscribe_info['service_type'] == 4">当前已是连续包年用户，无需购买</template>
          </div>
        </div>
      </template>
      <div class="openPay" v-else-if="!$isMobile && isChek != null">
        <div class="openPayWrap">
          <div class="qrCode">
            <div class="img" v-if="payType === 'wx'">
              <img :src="payImg" alt="" />
              <div class="name">微信支付</div>
            </div>
            <div class="img" v-else-if="payType === 'ali'">
              <img :src="aliPayImg" alt="" @click="createAliPayPcQrcode" />
              <div class="name">支付宝</div>
            </div>
          </div>
          <div class="payInfo">
            <div class="payMoney">
              <span>{{ orderStatus == '1' ? '已支付' : '待支付' }}:</span>
              <span>
                <i>¥</i>
                {{ pay_Money }}
              </span>
            </div>
            <div class="weChatPay">使用{{ payTypeList[payType].name }}扫码轻松支付</div>
            <div class="weChat_icon">
              <template v-for="(v, k) in payTypeList">
                <div class="icon_span" :key="k" @click="changePayType(v, k)" v-if="payTypeOptions.indexOf(k) !== -1">
                  <div class="check" :class="{ checked: payType === k }">
                    <van-icon name="success" size="20" color="#FFFFFF" v-if="payType === k" />
                  </div>
                  <div class="icon"><img :src="v.icon" /></div>
                  <div class="name">{{ v.name }}</div>
                </div>
              </template>
            </div>
            <div class="payAgrement">
              请阅读
              <span @click="readAgrement(1)">《会员服务协议》</span>
              及
              <span @click="readAgrement(2)">《自动续费服务规则》</span>
            </div>
          </div>
        </div>
      </div>
      <!--支付end-->
      <template v-if="$isMobile && (subscribe_info['service_type'] == 3 || subscribe_info['service_type'] == 4)">
        <div class="no_can_order_box">
          <div class="txt">
            <template v-if="subscribe_info['service_type'] == 3">当前已是连续包月用户，无需购买</template>
            <template v-if="subscribe_info['service_type'] == 4">当前已是连续包年用户，无需购买</template>
          </div>
        </div>
      </template>
      <template v-else-if="$isMobile">
        <div class="clearBoth"></div>
        <div class="this_line_space"></div>
        <div class="no_vip_title">支付方式</div>
        <div class="mobile_paytype_select_list">
          <template v-for="(v, k) in payTypeList">
            <div class="item_line" :key="k" @click="changePayType(v, k)" v-if="payTypeOptions.indexOf(k) !== -1">
              <div class="name_span">
                <div class="icon"><img :src="v.icon" /></div>
                <div class="name">{{ v.name }}支付</div>
              </div>
              <div class="check" :class="{ checked: payType === k }">
                <van-icon name="success" size="16" color="#FFFFFF" v-if="payType === k" />
              </div>
            </div>
          </template>
        </div>
        <div class="openvip_warp">
          <div class="openvip" @click="MobilePayVip">确认并支付</div>
        </div>
        <div class="vip_agreement">
          请阅读
          <span @click="readAgrement(1)">《会员服务协议》</span>
          <template v-if="!isWeiXin">
            及
            <span @click="readAgrement(2)">《自动续费服务规则》</span>
          </template>
        </div>
      </template>
      <div class="vip_ask">常见问题</div>
      <div class="vip_ask_list">
        <!-- <div v-for="(item, index) in asklist" :key="index" @click="askquestion(item)">
                    {{ item.title }}
                </div> -->
        <div v-for="(item, index) in asklist" :key="index">
          <div class="title">
            <!-- {{ item.title }}     -->
          </div>
          <!-- <div class="cont" :v-html="item.content"></div> -->
          <RichText :content="item.content" :about="item.content" class="mainContent" />
        </div>

        <!-- <div>
                    2，常见问题文案常见问题文案常见问题文案常见问题文案常见问题
                </div>
                <div>
                    3，常见问题文案常见问题文案常见问题文案常见问题文案常见
                </div> -->
      </div>
    </div>

    <div v-html="aliWapPayForm"></div>

    <askPop :param="askItem" v-show="isshow" @close="close"></askPop>
    <agrment :param="agrement" v-show="isshowAgrement" @close="closeAgrment"></agrment>
  </div>
</template>
<!-- <script src="https://cdn.jsdelivr.net/npm/swiper@10/swiper-element-bundle.min.js"></script> -->
<!-- <script src="https://3.swiper.com.cn/dist/js/swiper.min.js"></script> -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.1/js/swiper.min.js"></script>
<script>
var swiper = new Swiper('.swiper-container', {
  direction: 'vertical',
  slidesPerView: 'auto',
  mousewheel: true,
  freeMode: true,
})
</script>
<!-- <script src="@/assets/css/touchslider.js"></script> -->
<script>
//vip列表
import RichText from '@/components/layout/RichText-ask.vue'
import pc_img1 from '@/assets/vip/by_vip.png'
import pc_img2 from '@/assets/vip/bn_vip.png'
import pc_img3 from '@/assets/vip/lxby_vip.png'
import pc_img4 from '@/assets/vip/lxbn_vip.png'
//权益图片
import pc_qyimg1_on from '@/assets/vip/7.png'
import pc_qyimg1_off from '@/assets/vip/7_1.png'
import pc_qyimg2_on from '@/assets/vip/8.png'
import pc_qyimg2_off from '@/assets/vip/8_1.png'
import pc_qyimg3_on from '@/assets/vip/9.png'
import pc_qyimg3_off from '@/assets/vip/9_1.png'
import pc_qyimg4_on from '@/assets/vip/10.png'
import pc_qyimg4_off from '@/assets/vip/10_1.png'
import pc_qyimg5_on from '@/assets/vip/11.png'
import pc_qyimg5_off from '@/assets/vip/11_1.png'
import check_true from '@/assets/vip/check_true.png'
import check_false from '@/assets/vip/check_false.png'
import merge from 'webpack-merge'
import qrcode from 'qrcode-generator'
import axios from 'axios'
import { getMemberInfo } from '@/api/personal'
import { MessageBox } from 'element-ui'
import askPop from '@/components/vip/pop'
import agrment from '@/components/vip/pop'

import { mapState } from 'vuex'
import { mapMutations } from 'vuex'

import * as wxUtils from '@/utils/wx-utils'
import * as aliUtils from '@/utils/ali-utils'
import * as dialogUtils from '../../utils/dialog-utils'

export default {
  data() {
    return {
      showOrder: false,
      isInfiniteScrollDisabled: false,
      agrement: {},
      vipOrderList: [
        // {
        //     ordertime: '2023年05月30日 16:50:35',
        //     ordersn: 'CP128282192891291891273636',
        //     service_title: '连续包月用户',
        //     price: '25',
        //     service_type: '1',
        //     service_mobile_img: '/img/by_vip.93170645.png'
        // },
        // {
        //     ordertime: '2023年05月30日 16:50:35',
        //     ordersn: 'CP128282192891291891273636',
        //     service_title: '连续包月用户',
        //     price: '25',
        //     service_type: '1',
        //     service_mobile_img: '/img/by_vip.93170645.png'
        // }
      ],
      isChek: 0,
      opacity: 'opacity',
      vipList: [
        // {
        //     pc_img: pc_img1,
        //     price: 25,
        //     member_equity_id: "5,4,3,2,1"
        // },
        // {
        //     pc_img: pc_img2,
        //     price: 200,
        //     member_equity_id: "5,4,3,2,1"
        // },
        // {
        //     pc_img: pc_img3,
        //     price: 35,
        //     member_equity_id: "4,3,2,1"
        // },
        // {
        //     pc_img: pc_img4,
        //     price: 300,
        //     member_equity_id: "5,4,3,2,1"
        // }
      ],
      equitylist: [
        // {
        //     id:"1",
        //     pc_qyimg_on: pc_qyimg1_on,
        //     pc_qyimg_off: pc_qyimg1_off,
        //     price: 25,
        //     title: 'NEJM 中译全文',
        //     infos: '每周同步上新，官方授权'
        // },
        // {
        //     id:"2",
        //     pc_qyimg_on: pc_qyimg2_on,
        //     pc_qyimg_off: pc_qyimg2_off,
        //     price: 200,
        //     title: 'NEJM Journal Watch中译全文',
        //     infos: '每周同步上新，官方授权'
        // }, {
        //     id:"3",
        //     pc_qyimg_on: pc_qyimg3_on,
        //     pc_qyimg_off: pc_qyimg3_off,
        //     price: 35,
        //     title: 'NEJM 动画解读',
        //     infos: '2分钟了解NEJM 最新论著'
        // },
        // {
        //     id:"4",
        //     pc_qyimg_on: pc_qyimg4_on,
        //     pc_qyimg_off: pc_qyimg4_off,
        //     price: 300,
        //     title: '线下研讨会入场券',
        //     infos: '品牌主办会议，全年参会资格'
        // },
        // {
        //     id:"5",
        //     pc_qyimg_on: pc_qyimg5_on,
        //     pc_qyimg_off: pc_qyimg5_off,
        //     price: 300,
        //     title: '品牌定制文创礼品',
        //     infos: '定制帆布袋文件夹，医学图书等'
        // }
      ],
      pay_Money: '', //选中金额
      subscribe_info: {},
      asklist: [],
      service_id: '', //服务ID
      service_type: '', //1：包月；2：包年；3：连续包月；4：连续包年
      payImg: '',
      aliPayImg: '',
      ordersn: '',
      orderStatus: null, // 存储订单状态
      timer: null, // 存储定时器
      isshow: false,
      isshowAgrement: false,
      askItem: {}, //常见问题
      mobilePayurl: '',
      noEquitylist: [], //未拥有权益
      opacityClass: '',
      equity_title: '',
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      checkVipTypeTitle: '',

      aliPayQrcodeInfo: '', // 支付宝二维码记录
      aliPayQrcodeInfoTimer: null, // 支付宝二维码记录轮询id
      aliWapPayForm: '', // 支付宝h5支付调起form表单

      isWeiXin: false, // 是否是微信
      // 支付类型
      payTypeList: {
        wx: { name: '微信', icon: require('../../assets/vip/weChatPay.png') },
        ali: { name: '支付宝', icon: require('../../assets/vip/zfbPay.png') },
      },
      payTypeOptions: ['wx', 'ali'], // 当前可选支付
      payType: 'wx', // 当前支付方式
    }
  },
  components: {
    askPop,
    RichText,
    agrment,
  },
  onLoad() {},
  watch: {
    '$route.query': {
      handler() {
        var type = this.$route.query.type
        if (type != 'flag4') {
          // clearInterval(this.timer);
          // this.orderStatus = '1'
        } else {
        }
      },
      immediate: true,
    },
    isShow: function (newValue, oldValue) {
      console.log(newValue, oldValue)
      console.log(this.isShow, 'isShow')
      if (this.isShow) {
        this.getUserINfo()
      } else {
        this.memberId = null
        window.sessionStorage.setItem('memberId', null)
        this.subscribe_info.service_type = 0
      }
    },
    // orderStatus: {
    //     handler (newVal, oldVal) {
    //         console.log('data变化了')
    //         console.log(newVal, oldVal)
    //         if (newVal) {
    //         // 当orderStatus有值时，停止定时器
    //             clearInterval(this.timer);
    //         } else {
    //             // 当orderStatus没有值时，开始定时器并执行getOrderStatus方法
    //             this.timer = setInterval(this.getOrderStatus, 5000);
    //         }
    //     },
    //     deep: true
    // },
  },
  created() {
    this.getViplist()

    this.isWeiXin = wxUtils.isWeiXin()
    if (this.isWeiXin) this.checkMobileWxAuth()
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isShow() {
      return !!this.user
    },
  },
  mounted() {
    this.getUserINfo()
    this.getViplist()
    this.getVipAsklist()
    this.getVipOrderlist()
  },
  beforeDestroy() {
    this.clearCheckAliPayPcQrcode()
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    load() {},
    close(e) {
      this.isshow = e
    },
    closeAgrment(e) {
      this.isshowAgrement = e
    },
    readAgrement(type) {
      console.log('协议')
      if (type == 1) {
        window.open('https://www.nejmqianyan.cn/xieyi.html', '_blank')
      }
      if (type == 2) {
        window.open('https://www.nejmqianyan.cn/xufeixieyi.html', '_blank')
      }
      // this.isshowAgrement = true
    },
    askquestion(item) {
      console.log('文章')
      this.askItem = item
      this.isshow = true
    },
    async getUserINfo() {
      let res = (await getMemberInfo()) || {}
      console.log(res)
      this.subscribe_info = res.subscribe_info
      if (this.$isMobile) {
        // this.showOrder = true
      }
      // if(this.subscribe_info&&this.subscribe_info.service_type ==2 || this.subscribe_info&&this.subscribe_info.service_type ==4){
      //     MessageBox.confirm('尊敬的年费会员用户,您还没有填写收货地址，是否前往?', '提示', {
      //         confirmButtonText: '确定前往',
      //         cancelButtonText: '稍后编辑',
      //         // type: 'success',
      //     }).then((ec) => {
      //         console.log(ec)
      //         this.$router.push({
      //             query: merge(this.$route.query, { type: 'flag1' }),
      //         }).catch((err) => {
      //             console.log('输出报错', err)
      //         })
      //     }).catch((err) => {
      //         console.log(err)
      //     });
      // }
      //买了连续包月的，另外包月和包年的就灰掉，不给点  //1包月，2包年，3连续包月 4连续包年
      // if(this.subscribe_info.service_type ==3){
      //     let list = this.vipList.filter(item =>item.title == '包月' || item.title == '包年')
      //     console.log(list,'list')
      // }
    },
    getVipAsklist() {
      axios.get('https://www.nejmqianyan.cn/index.php?c=program&m=getsubscribeQaList').then((res) => {
        console.log(res)
        if (res.data.result == '1') {
          this.asklist = res.data.qa_lsit
          this.asklist = this.asklist.map((item) => {
            return {
              title: item.title,
              content: item.content.replace(/\"/g, '&quot;'),
            }
          })
          console.log(typeof this.asklist[0].content)
          this.agrement = res.data.xieyi
          return
        }
        this.$message.error(res.msg)
      })
    },
    /****
     *
     * 会员服务列表  vipList
     *
     * VIP权益列表  equitylist
     *
     */
    getViplist() {
      console.log('VIP权益列表')
      let token = window.sessionStorage.getItem('token')
      console.log(token, 'token')
      axios
        .get('https://www.nejmqianyan.cn/index.php?c=program&m=getServiceList&ut=1&sf=pc' + '&token=' + token)
        .then((res) => {
          if (res.data.result == '1') {
            // 过滤哪些VIP档位需要显示
            let vipList = []
            let isWeiXin = wxUtils.isWeiXin()
            for (let service of res.data.servicelsit) {
              // 微信内不显示包月包年
              if (isWeiXin && (service.type == 3 || service.type == 4)) continue

              vipList.push(service)
            }
            this.vipList = vipList

            this.equitylist = res.data.equitylist
            if (this.vipList.length > 0) {
              // console.log('默认选中包月')
              this.pay_Money = this.vipList[0].price || ''
              let index = 0
              let service_id = this.vipList[0].id
              let price = this.vipList[0].price
              let item = this.vipList[0]
              this.checkVip(price, index, service_id, item)
            }
            return
          }
          this.$message.error(res.msg)
        })
    },
    getVipOrderlist() {
      console.log('VIP订单列表')
      let token = window.sessionStorage.getItem('token')
      // console.log(token,'token');
      axios
        .get(
          'https://www.nejmqianyan.cn/index.php?c=program&m=getServiceOrderList&page=' +
            this.pageIndex +
            '&per_page=' +
            this.pageSize +
            '&sf=' +
            'pc' +
            '&token=' +
            token
        )
        .then((res) => {
          console.log(res)
          if (res.data.result == '1') {
            this.vipOrderList = res.data.items
            this.totalCount = Number(res.data.total_count)
            this.pageIndex = res.data.page
          } else {
            this.vipOrderList = []
            this.totalCount = 0
          }
        })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getVipOrderlist()
    },
    //分页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getVipOrderlist()
    },
    /***
     * 获取订单状态
     */
    async getOrderStatus() {
      let token = window.sessionStorage.getItem('token')
      // console.log('状态');
      const res = await axios.get(
        'https://www.nejmqianyan.cn/index.php?c=pay&m=notifypay' +
          '&sf=' +
          'pc' +
          '&token=' +
          token +
          '&ordersn=' +
          this.ordersn
      )
      if (res.data.result == '1') {
        // 如果获取到result为1，停止定时器并更新orderStatus
        // clearInterval(this.timer);
        clearTimeout()
        this.orderStatus = '1'
        this.$message.success(res.data.msg)
        setTimeout(() => {
          this.$notify({
            title: '成功',
            message: '支付成功',
            type: 'success',
          })
          //   ===去掉了，需要的时候可以放开
          // if(this.checkVipTypeTitle =='包年'){
          //     //感谢您购买NEJM医学前沿年度学术会员，请及时补充收货地址，方便我们寄送定制文创礼品
          //     this.$notify({
          //         title: '提示消息',
          //         message: '感谢您购买NEJM医学前沿年度学术会员，请及时补充收货地址，方便我们寄送定制文创礼品',
          //         type: 'success',
          //         offset:200
          //     });
          // }
          if (this.$route.query.herfUrl) {
            window.location.href = this.$route.query.herfUrl
          } else {
            this.$router.push({ path: '/personal', query: { type: 'flag1' } }).catch((err) => {
              console.log('输出报错', err)
            })
          }
        }, 1000)
        return
      } else {
        setTimeout(() => {
          this.getOrderStatus()
        }, 5000)
      }
      // this.orderStatus = ''
      // this.$message.error(res.data.msg)
    },
    deleteVipOrder(id) {
      console.log(id)
      if (id == undefined || !id) {
        this.$message.error('您要删除的订单id不存在!')
        return
      }
      // let md5 = require('@/util/md5.js')
      // let timestamp = Math.round(new Date() / 1000);
      // let access_token = window.sessionStorage.getItem('access_token');
      // let client_id = window.sessionStorage.getItem('client_id');
      // let md5Data = md5.hexMD5(access_token + timestamp + 'gm8Pyx3sbuCdqsspYylv3rhh9Bt40vn7' + client_id)
      let token = window.sessionStorage.getItem('token')
      // console.log(token,'token');
      MessageBox.confirm('此操作将删除该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then((ec) => {
          console.log(ec)
          axios
            .get(
              'https://www.nejmqianyan.cn/index.php?c=program&m=deleteServiceOrder&id=' +
                id +
                '&sf=pc' +
                '&token=' +
                token
            )
            .then((res) => {
              console.log(res)
              if (res.data.result == '1') {
                this.$message.success('删除成功')
                this.getVipOrderlist()
                return
              }
              this.$message.error('删除失败')
            })
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    /****
     * 选择会员权益
     * @param {price}  选中会员金额
     * @param {index}  选中会员item index下标
     * @param {service_id}  选中会员服务id
     * @param {token sf=pc} //pc会员信息
     * @param {sf=pc} //pc会员信息
     *
     */
    checkVip(price, index, service_id, item) {
      let that = this
      if (!that.isShow) {
        this.$bus.emit('login')
        return
      }
      if (this.subscribe_info.service_type == 3 || this.subscribe_info.service_type == 4) {
        // 暂定连续包年/包月会员，不允许购买任何产品，页面上取消选择按钮，因此这里直接屏蔽掉点击事件，跳出即可
        return
      }
      // let chushiItem = document.querySelector('.vip_Equity_item');
      // chushiItem.style.opacity = '1';
      that.isChek = index
      that.equity_title = item.title
      // console.log(that.isChek)
      that.pay_Money = price
      that.service_id = service_id
      that.service_type = item.type
      let vipitem = that.vipList[index].member_equity_id
      this.checkVipTypeTitle = that.vipList[index].title
      // console.log(vipitem,'vipitem')
      let list = vipitem.split(',') //包含的权益
      // console.log(list,'list')
      let nolist = that.equitylist.filter((item) => !list.includes(item.id))
      // console.log(nolist,'===不包含的权益')
      that.noEquitylist = nolist || []
      let classlist = document.getElementById('vip_Equitylist')
      // console.log(classlist,'classlist')

      list.forEach((item) => {
        let listItemss = classlist.querySelector('.vip_Equity_item[data-id="' + item + '"]')
        let lockItem = classlist.querySelector('.lock[data-id="' + item + '"]')
        // console.log(listItemss,'listItemss')
        // console.log(lockItem,'lockItem1')
        listItemss.classList.remove('opacity')
        lockItem.classList.remove('display_block')
      })
      that.noEquitylist.forEach((n) => {
        let listItem = classlist.querySelector('.vip_Equity_item[data-id="' + n.id + '"]')
        let lockItem = classlist.querySelector('.lock[data-id="' + n.id + '"]')
        listItem.classList.add('opacity')
        lockItem.classList.add('display_block')
      })
      that.$forceUpdate()

      // 判断可用支付方式
      let payTypeOptions = []
      let payType = ''
      for (let type of ['ali', 'wx']) {
        if (type === 'wx') {
          if (aliUtils.isAli()) continue // 支付宝内不显示微信支付
          if (this.service_type == 3 || this.service_type == 4) continue // 连续包月/包年不显示微信支付

          payTypeOptions.push('wx')
          payType = 'wx'
        } else if (type === 'ali') {
          if (wxUtils.isWeiXin()) continue // 微信内不显示微信

          payTypeOptions.push('ali')
          payType = 'ali'
        }
      }
      this.payTypeOptions = payTypeOptions
      this.payType = payType

      if (that.$isMobile) {
        console.log('走h5支付')
        return
      }
      let token = window.sessionStorage.getItem('token')
      // console.log(token,'token');
      axios
        .get(
          'https://www.nejmqianyan.cn/index.php?c=pay&m=index&service_id=' +
            that.service_id +
            '&sf=pc' +
            '&token=' +
            token
        )
        .then((res) => {
          // console.log(res);
          if (res.data.result == '1') {
            that.payImg = res.data.paycode
            that.mobilePayurl = res.data.code_url
            that.ordersn = res.data.ordersn
            clearTimeout()
            setTimeout(() => {
              that.getOrderStatus()
            }, 5000)
            return
          }
          that.$message.error(res.data.msg)
        })
      // 生成支付宝二维码
      this.createAliPayPcQrcode()
    },
    //h5支付
    MobilePayVip() {
      if (!this.isShow) {
        this.$bus.emit('login')
        return
      }

      if (this.payType === 'wx') {
        if (wxUtils.isWeiXin()) {
          console.log('jssdk支付')
          this.wxJssdkPay()
        } else {
          console.log('h5支付')
          let token = window.sessionStorage.getItem('token')
          console.log(token, 'token')
          axios
            .get(
              'https://www.nejmqianyan.cn/index.php?c=pay&m=h5index&service_id=' +
                this.service_id +
                '&sf=pc' +
                '&token=' +
                token
            )
            .then((res) => {
              console.log(res)
              if (res.data.result == '1') {
                this.mobilePayurl = res.data.h5_url
                this.ordersn = res.data.ordersn
                setTimeout(() => {
                  var referrer = document.referrer
                  console.log(referrer, 'document.referrer')
                  // window.location.href=this.mobilePayurl
                  //window.location.href=this.mobilePayurl+'&redirect_url=https://dev.nejmqianyan.com&referer=https://dev.nejmqianyan.com/&urlencode=https://dev.nejmqianyan.com'
                  window.location.href = this.mobilePayurl
                }, 3000)
                // window.open(this.mobilePayurl,'_blank');
                clearTimeout()
                setTimeout(() => {
                  this.getOrderStatus()
                }, 5000)
                return
              }
              this.$message.error(res.data.msg)
            })
        }
      } else if (this.payType === 'ali') {
        console.log('h5支付')
        this.aliWapPay()
      }
    },
    showOrderFun() {
      this.showOrder = !this.showOrder
      this.$forceUpdate()
      this.getVipOrderlist()
      console.log(this.showOrder, 'd')
    },
    getVipOrder() {
      this.$router
        .push({
          query: merge(this.$route.query, { type: 'flag4' }),
        })
        .catch((err) => {
          console.log('输出报错', err)
        })
    },

    /** 切换支付方式 */
    changePayType(info, key) {
      this.payType = key
    },

    /** 用于检查微信浏览器内是否已授权获取了openId */
    checkMobileWxAuth() {
      // 不在微信浏览器，或已经获取openId，则跳出
      if (!wxUtils.isWeiXin()) return

      let openId = wxUtils.getOpenId()
      if (openId) {
        // 载入jsApi权限
        wxUtils.initJsApi()
        return
      }

      let code = this.$route.query.code
      if (code) {
        let url = this.$Config.API_PATH_PHP + '?c=pay&m=getOpenId'
        url += '&code=' + code
        axios.get(url).then((res) => {
          if (res.data.result == 1) {
            // 存储openId
            wxUtils.setOpenId(res.data.openid)
            wxUtils.setUnionid(res.data.unionid)

            // 载入jsApi权限
            wxUtils.initJsApi()
          } else {
            // 报错
            this.$toast('获取openId失败')
          }
        })
      } else {
        wxUtils.goGetCode()
      }
    },
    /** 微信JSSDK支付 */
    wxJssdkPay() {
      let token = window.sessionStorage.getItem('token')
      let url = this.$Config.API_PATH_PHP + '?c=pay&m=orderJsApiPay'
      url += '&openid=' + wxUtils.getOpenId()
      url += '&service_id=' + this.service_id
      url += '&sf=' + 'pc'
      url += '&token=' + token
      axios.get(url).then((res) => {
        console.log(res)
        if (res.data.result == 1) {
          let data = res.data
          window.wx.chooseWXPay({
            timestamp: data.timestamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
            paySign: data.paySign,
            success: () => {
              // 调起成功
              this.paySuccess()
            },
            cancel: () => {
              // 取消了支付
              this.$toast('您取消的支付')
            },
            error: () => {
              // 支付失败
              this.$toast('支付失败')
            },
          })
        } else {
          // 报错
          this.$toast('支付失败')
        }
      })
    },
    paySuccess() {
      setTimeout(() => {
        this.$notify({
          title: '成功',
          message: '支付成功',
          type: 'success',
        })
        if (this.checkVipTypeTitle == '包年') {
          //感谢您购买NEJM医学前沿年度学术会员，请及时补充收货地址，方便我们寄送定制文创礼品
          this.$notify({
            title: '提示消息',
            message: '感谢您购买NEJM医学前沿年度学术会员，请及时补充收货地址，方便我们寄送定制文创礼品',
            type: 'success',
            offset: 200,
          })
        }
        if (this.$route.query.herfUrl) {
          window.location.href = this.$route.query.herfUrl
        } else {
          this.$router.push({ path: '/personal', query: { type: 'flag1' } }).catch((err) => {
            console.log('输出报错', err)
          })
        }
      }, 1000)
    },

    /** 创建支付宝支付二维码(PC) */
    createAliPayPcQrcode() {
      let token = window.sessionStorage.getItem('token')
      let form = new FormData()
      form.append('result_data', JSON.stringify({ service_id: this.service_id, token }))
      axios.post(this.$Config.API_PATH_PHP + '?c=qrcode&m=createInfo', form).then((res) => {
        this.aliPayQrcodeInfo = res.data.data
        let qr = qrcode(6, 'M')
        qr.addData(this.$Config.DOMAIN + '/qrcode-member-ali-pay/' + this.aliPayQrcodeInfo.id + '/' + token)
        qr.make()
        this.aliPayImg = qr.createDataURL(4, 6)

        this.startCheckAliPayPcQrcode()
      })
    },
    /** 轮询检查支付宝二维码 */
    rollCheckAliPayPcQrcode() {
      if (!this.aliPayQrcodeInfo || !this.aliPayQrcodeInfo.id) return

      axios.get(this.$Config.API_PATH_PHP + '?c=qrcode&m=getInfo&id=' + this.aliPayQrcodeInfo.id).then((res) => {
        let info = res.data.data
        if (info.status > 0) {
          this.clearCheckAliPayPcQrcode()
          dialogUtils
            .confirmPC('支付宝扫码支付中，请确认是否完成支付', {
              title: '提示',
              confirmButtonText: '支付成功',
              cancelButtonText: '暂未支付',
            })
            .then(() => {
              if (this.$route.query.herfUrl) {
                window.location.href = this.$route.query.herfUrl
              } else {
                this.$router.push({ path: '/personal', query: { type: 'flag1' } }).catch((err) => {
                  console.log('输出报错', err)
                })
              }
            })
            .catch(() => {
              window.location.reload()
            })
        }
      })
    },
    /** 轮询启动/停止 */
    startCheckAliPayPcQrcode() {
      this.clearCheckAliPayPcQrcode()
      this.aliPayQrcodeInfoTimer = setInterval(this.rollCheckAliPayPcQrcode, 5000)
    },
    clearCheckAliPayPcQrcode() {
      if (this.aliPayQrcodeInfoTimer !== null) {
        clearInterval(this.aliPayQrcodeInfoTimer)
        this.aliPayQrcodeInfoTimer = null
      }
    },

    /** 支付寶H5支付 */
    aliWapPay() {
      let model = 'memberOrderWapPay'
      if (this.service_type == 3 || this.service_type == 4) model = 'memberOrderSignPay'

      let token = window.sessionStorage.getItem('token')
      let url = this.$Config.API_PATH_PHP + '?c=alipay&m=' + model
      url += '&service_id=' + this.service_id
      url += '&sf=' + 'pc'
      url += '&token=' + token
      axios.get(url).then((res) => {
        if (res.data.result == 1) {
          this.aliWapPayForm = res.data.form
          this.$nextTick(() => {
            document.forms['alipaysubmit'].submit()
          })
        } else {
          // 报错
          this.$toast(res.data.msg)
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import url(https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.1/css/swiper.min.css);

.opacity {
  opacity: 0.5;
}
//禁止点击
.pointer-none {
  pointer-events: none;
}
//默认
.pointer-auto {
  pointer-events: auto;
}

.display_block {
  display: block !important;
}
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.myVipSubsribe {
  padding: 16px 0;

  .have_vip {
    .myVip_wp {
      display: flex;
      align-items: center;
      gap: 32px;
      margin-bottom: 29px;
      a {
        flex: 1;
      }
      .myVip {
        width: 150px;
        height: 42px;
        background: #e52e00;
        border-radius: 2px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.85);
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .ask {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #1a55a7;
        font-weight: 400;
        cursor: pointer;
      }
      .showOrderBtn {
        padding: 7px 10px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        border-radius: 10px;
        border: 1px solid #5b666d;
        cursor: pointer;
      }
    }

    .dyvip_list {
      width: 100%;
      margin-bottom: 21px;

      .dyvip_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgba(151, 151, 151, 0.5);
        border-radius: 2px;
        padding: 11px 16px;
      }

      .dyvip_info {
        display: flex;
        gap: 53px;
        align-items: center;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
      }

      .dyvip_cont {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        /* align-items: center; */
        padding: 21px 0;
        gap: 24px;
      }

      .dyvip_img {
        width: 250px;
        height: 130px;
        /* background: #f00; */
        border-radius: 20px;
      }

      .dyvip_img img {
        width: 100%;
        height: 100%;
      }

      .dyvip_viptype_info {
        display: flex;
        gap: 48px;
        padding: 12px 0 0 0;
        .dyvip_vipmoney {
          display: flex;
          gap: 20px;
        }
      }
    }
    .dyvip_list_page {
      float: right;
    }
  }

  .no_vip {
    .no_vip_title {
      font-family: PingFangSC-Semibold;
      font-size: 20px;
      color: #e52e00;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .no_vip_list {
      height: 200px;
      width: 100%;
      overflow: hidden;
      margin-bottom: 40px;
    }
  }
}

.page-section-spacing {
  margin-top: 60px;
}

.scroll-view_H {
  white-space: nowrap;
}

.scroll-view-item {
  height: 300px;
}

.scroll-view-item_H {
  display: inline-block;
  width: 100%;
  height: 300px;
}

.viplist {
  /* 	width: auto;
	overflow: hidden; */
  display: flex;
  justify-content: flex-start;
  height: 100%;
}

.vip_item {
  /* 	display: flex;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column; */
  width: 250px;
  /* height: 300rpx; */
  // border: 1px solid rgba(91, 102, 109, 1);  // mobile  //去掉边框
  border-radius: 20px;
  // margin-left: 24px; 4权益用
  margin-left: 16px;
  position: relative;
  cursor: pointer;
}

.vip_item .vip_check {
  // position: absolute;
  width: 16px;
  height: 16px;
  // background: #E52E00;
  color: #fff;
  // right: 28px;
  // bottom: 10px;
  right: 5%;
  bottom: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}

.vip_item .vip_check_no {
  // position: absolute;
  width: 16px;
  height: 16px;
  // background: #E52E00;
  color: #fff;
  // right: 28px;
  // bottom: 10px;
  right: 5%;
  bottom: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #a0a0a0;
  img {
    width: 100%;
    height: 100%;
  }
}

.vip_pic {
  // width: 252px;
  width: 248px;
  height: 140px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
}
.new_vip_box {
  display: flex;
  align-items: center;
  background: #fbf9ed;
  border-radius: 15px;
  width: 96%;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 37px;
  box-shadow: 0.4px 0.5px 0.5px rgba(0, 0, 0, 0.022), 1px 1.4px 1.3px rgba(0, 0, 0, 0.031),
    2.1px 2.8px 2.7px rgba(0, 0, 0, 0.039), 4.4px 5.8px 5.5px rgba(0, 0, 0, 0.048);
}

.vip_money {
  font-size: 28px;
  color: #e52e00;
  font-weight: bold;
  font-family: PingFangSC-Medium;
  display: block;
  // text-align: center;
  padding: 8px 0 6px 0;
  width: 94%;
  // margin: auto;
  // border-radius: 15px;
  // margin-bottom: 5px;
  // background: #FBF9ED;
  display: flex;
  align-items: center;
  span {
    font-family: AlibabaPuHuiTiH;
    font-size: 22px;
    color: #e52e00;
    font-weight: bold;
    margin-right: 6px;
  }
}

.vip_tips {
  padding: 30px 24px;
  font-family: PingFangSC-Medium;
  font-size: 28px;
  color: #e52e00;
  font-weight: 500;
}

/**
 * vip权益列表
 */
.vip_Equity {
  padding: 8px 12px;
  max-height: 300px;
  height: 250px;

  .vip_Equity_item {
    padding: 10px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    float: left;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 16px;
    border: 1px solid rgba(91, 102, 109, 1);
    position: relative;
    .lock {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 6%;
      top: 11px;
      display: none;
    }
  }

  .vip_Equity_item:nth-child(3) {
    // margin-right: 0;
  }

  .vip_Equity_item:last-child {
    // margin-bottom: 73px;
  }

  .vip_Equity_item img {
    width: 54px;
    height: 54px;
  }

  .vip_Equity_item_info {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .vip_Equity_item_info div:nth-child(1) {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    margin-bottom: 5px;
  }

  .vip_Equity_item_info div:nth-child(2) {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #666666;
    font-weight: 400;
  }
}

.openvip_warp {
  padding: 0 51px;
  margin-top: 73px;

  .openvip {
    width: 100%;
    height: 80px;
    background: #e52e00;
    border-radius: 2px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.vip_agreement {
  font-family: PingFangSC-Regular;
  font-size: 24px;
  color: #1a55a7;
  text-align: center;
  font-weight: 400;
  text-align: center;
  padding: 16px 0 60px 0;
}

.clearBoth {
  clear: both;
}

//支付相关 start
.openPay {
  padding: 20px 0;
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 40px 0;
  border: 1px solid rgba(151, 151, 151, 0.5);
  border-radius: 8px;

  .openPayWrap {
    display: flex;
    align-content: center;
    gap: 32px;

    .qrCode {
      display: flex;

      .img {
        .name {
          width: 100%;
          // height: 36px;
          // line-height: 36px;
          padding-top: 18px;
          text-align: center;
        }

        + .img {
          margin-left: 16px;
        }
      }
      img {
        width: 160px;
        height: 160px;
        border: 1px solid rgba(151, 151, 151, 1);
        border-radius: 4px;
      }
    }

    .payInfo {
      .payMoney {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 19px;

        span {
          &:nth-child(1) {
            font-family: PingFangSC-Semibold;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 600;
          }

          &:nth-child(2) {
            font-family: AlibabaPuHuiTiH;
            font-size: 40px;
            color: #e52e00;
            font-weight: 400;
          }

          i {
            font-family: AlibabaPuHuiTiH;
            font-size: 20px;
            color: #e52e00;
            font-weight: bold;
          }
        }
      }

      .weChatPay {
        margin-bottom: 8px;
      }

      .weChat_icon {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .icon_span {
          display: flex;
          align-items: center;

          .check {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 28px;
            // height: 28px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid #999999;
            cursor: pointer;

            &.checked {
              background-color: #e52e00;
              border: 1px solid #e52e00;
            }
          }
          .icon {
            margin-left: 5px;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }
          .name {
            margin-left: 5px;
          }

          + .icon_span {
            margin-left: 100px;
          }
        }
      }

      .payAgrement {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #1a55a7;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
}

//支付相关 end

.vip_ask {
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 13px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 32px;
}

.vip_ask_list {
  .mainContent {
    font-size: 14px !important;
    .rich-text {
      font-size: 14px !important;
    }
  }
  // padding: 0 24px;
  div {
    font-family: PingFangSC-Regular;
    font-size: 14px !important;
    color: #1a55a7;
    font-weight: 400;
    margin-bottom: 12px;
    display: inline-block;
    width: 100%;
    // white-space: nowrap;  //展开
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    .title {
      display: block;
    }
    .cont {
      display: block;
    }
  }
}

.no_can_order_box {
  padding: 20px 0;
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 40px 0;
  border: 1px solid rgba(151, 151, 151, 0.5);
  border-radius: 8px;

  .txt {
  }
}

//手机样式
html {
  font-size: 10px;
}

.mobile {
  /* 调整iPhone X 的刘海屏 */
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .myVipSubsribe .dyvip_list .dyvip_img {
      width: 190px !important;
      height: 120px !important;
      border-radius: 20px;
    }
    .user_vip_wrap_box {
      .user_vip_wrap_lf {
        gap: 18px !important;
      }
    }

    .dyvip_list {
      .dyvip_title {
        .dyvip_info {
          font-size: 12px;
          span {
            font-size: 12px !important;
            width: 60% !important;
            &:nth-child(2) {
              width: 38% !important;
            }
          }
        }
      }
    }
    .myVipSubsribe {
      .dyvip_info {
        width: 90%;
      }
    }
    .myVipSubsribe .vip_Equity .vip_Equity_item {
      &:nth-child(4) {
        width: 48%;
        margin-right: 5px;
      }
      &:nth-child(5) {
        width: 49%;
        margin-right: 0;
      }
    }
    .new_vip_box {
      padding: 0 20px;
    }
  }
  @media only screen and (device-width: 375px) {
    .new_vip_box {
      padding: 0 20px;
    }
    .myVipSubsribe .dyvip_list .dyvip_img {
      width: 190px !important;
      height: 120px !important;
      border-radius: 20px;
    }
    .user_vip_wrap_box {
      .user_vip_wrap_lf {
        gap: 18px !important;
      }
    }

    .dyvip_list {
      .dyvip_title {
        .dyvip_info {
          font-size: 12px;
          span {
            font-size: 12px !important;
            width: 60% !important;
            &:nth-child(2) {
              width: 38% !important;
            }
          }
        }
      }
    }
    .myVipSubsribe {
      .dyvip_info {
        width: 90%;
      }
    }
    .myVipSubsribe .vip_Equity .vip_Equity_item {
      &:nth-child(4) {
        width: 48%;
        margin-right: 5px;
      }
      &:nth-child(5) {
        width: 49%;
        margin-right: 0;
      }
    }
  }
  @media only screen and (device-width: 390px) and (device-height: 844px) {
    .new_vip_box {
      padding: 0 20px;
    }
    .myVipSubsribe {
      .dyvip_info {
        width: 90%;
      }
    }
    .dyvip_list {
      .dyvip_title {
        .dyvip_info {
          font-size: 12px;
          span {
            font-size: 12px !important;
            width: 60% !important;
            &:nth-child(2) {
              width: 38% !important;
            }
          }
        }
      }
    }
  }
  .myVipSubsribe {
    padding: 16px 0;

    .have_vip {
      .myVip_wp {
        display: flex;
        align-items: center;
        gap: 32px;
        margin-bottom: 15px;
        justify-content: flex-end;
        a {
          flex: none;
        }
        .ask {
          border: 1px solid rgba(26, 85, 167, 1);
          border-radius: 20px;
          font-size: 12px;
          padding: 4px 23px;
        }
      }
    }

    .dyvip_list {
      width: 100%;
      margin-bottom: 5px;

      .dyvip_title {
        display: flex;
        justify-content: start;
        align-items: center;
        border: 1px solid rgba(151, 151, 151, 0.5);
        border-radius: 2px;
        padding: 11px 0px;
      }

      .dyvip_info {
        display: flex;
        gap: 0;
        align-items: center;
        width: 90%;
        span {
          font-family: PingFangSC-Medium;
          // font-size: 20%;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 60%;
          &:nth-child(2) {
            width: 36%;
          }
        }
      }

      .dyvip_cont {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        /* align-items: center; */
        padding: 21px 0;
        gap: 12px;
      }

      .dyvip_img {
        width: 215px;
        height: 130px;
        /* background: #f00; */
        border-radius: 20px;
      }

      .dyvip_img img {
        width: 100%;
        height: 100%;
      }

      .dyvip_viptype_info {
        display: flex;
        gap: 20px;
        padding: 12px 0 0 0;

        span {
          font-family: PingFangSC-Medium;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: bold;
        }
        .dyvip_vipmoney {
          gap: 10px;
        }
      }
    }

    //没有会员
    .no_vip_list {
      height: 140px;
      margin-bottom: 0;
    }

    .no_vip_title {
      font-size: 18px;
    }

    .viplist {
      height: auto;
    }

    .vip_item {
      width: 127px;
      // border: 1px solid rgba(91, 102, 109, 1);   //去掉边框
      border-radius: 10px;
      margin-left: 12px;
      position: relative;
    }

    .vip_item .vip_check {
      position: absolute;
      width: 16px;
      height: 16px;
      // background: #E52E00;
      color: #fff;
      right: 10%;
      bottom: 11px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .vip_item .vip_check_no {
      position: absolute;
      right: 10% !important;
      bottom: 11px !important;
    }
    .vip_pic {
      width: 125px;
      height: 70px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }

    .vip_money {
      font-size: 16px;
      color: #e52e00;
      font-weight: bold;
      font-family: PingFangSC-Medium;
      display: block;
      text-align: left;
      // padding: 8px 0 6px 20px;

      span {
        font-family: AlibabaPuHuiTiH;
        font-size: 16px;
        color: #e52e00;
        font-weight: bold;
        margin-right: 0;
      }
    }

    //会员权益 mobile
    .vip_Equity {
      padding: 0;
      max-height: 300px;
      // height: 250px;
      height: auto;

      .vip_Equity_item {
        padding: 5px 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 6px;
        float: left;
        border-radius: 4px;
        margin-right: 5px;
        margin-bottom: 10px;
        border: 1px solid #5b666d;
        .lock {
          width: 16px;
          height: 16px;
          position: absolute;
          right: 6%;
          top: 11px;
        }
      }

      .vip_Equity_item:nth-child(1),
      .vip_Equity_item:nth-child(2) {
        width: 33%;
      }

      .vip_Equity_item:nth-child(3) {
        width: 31%;
        margin-right: 0;
      }

      .vip_Equity_item:last-child {
        // margin-bottom: 73px;
      }

      .vip_Equity_item img {
        width: 32px;
        height: 32px;
      }

      .vip_Equity_item_info {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .vip_Equity_item_info div:nth-child(1) {
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        margin-bottom: 5px;
      }

      .vip_Equity_item_info div:nth-child(2) {
        font-family: PingFangSC-Regular;
        font-size: 10px;
        color: #666666;
        font-weight: 400;
      }
    }

    //支付相关 start
    .openPay {
      position: relative;
      padding: 20px 0;
      display: flex;
      align-content: center;
      justify-content: center;
      margin: 40px 0;
      border: 1px solid rgba(151, 151, 151, 0.5);
      border-radius: 8px;

      .openPayWrap {
        display: flex;
        align-content: center;
        gap: 32px;

        .qrCode {
          width: 160px;
          height: 160px;
          border: 1px solid rgba(151, 151, 151, 1);
          border-radius: 4px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .payInfo {
          padding: 20px 0;
          .payMoney {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 19px;

            span {
              &:nth-child(1) {
                font-family: PingFangSC-Semibold;
                font-size: 18px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: 600;
              }

              &:nth-child(2) {
                font-family: AlibabaPuHuiTiH;
                font-size: 28px;
                color: #e52e00;
                font-weight: 400;
              }

              i {
                font-family: AlibabaPuHuiTiH;
                font-size: 20px;
                color: #e52e00;
                font-weight: bold;
              }
            }
          }

          .weChatPay {
            margin-bottom: 8px;
          }

          .weChat_icon {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-bottom: 20px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .payAgrement {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #1a55a7;
            font-weight: 400;
            cursor: pointer;
          }
        }
      }
    }

    //支付相关 end
    .this_line_space {
      width: 100%;
      height: 10px;
    }
    .mobile_paytype_select_list {
      .item_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        .name_span {
          display: flex;
          align-items: center;

          .icon {
            margin-right: 6px;

            img {
              width: 24px;
              height: 24px;
            }
          }
          .name {
          }
        }
        .check {
          display: flex;
          align-items: center;
          justify-content: center;
          // width: 24px;
          // height: 24px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          border: 1px solid #999999;
          cursor: pointer;

          &.checked {
            background-color: #e52e00;
            border: 1px solid #e52e00;
          }
        }

        + .item_line {
          border-top: 1px solid #cccccc;
        }
      }
    }

    .openvip_warp {
      padding: 0 51px;
      margin-top: 36px;

      .openvip {
        width: 100%;
        height: 40px;
        background: #e52e00;
        border-radius: 2px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .vip_agreement {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #1a55a7;
      font-weight: 400;
      text-align: center;
      padding: 16px 0 60px 0;
    }
  }
}
.item-ct-detail-txt {
  margin-bottom: 15px;
}
</style>
