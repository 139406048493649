<!-- APP下载 -->
<template>
  <div class="container">
    <a v-if="falg" href="javascript:;" @click="download()">Android手机APP下载</a>
    <a v-else href="javascript:;" @click="download()">苹果手机APP下载</a>
  </div>
</template>
<script>
export default {
  name: 'Download',
  components: {},
  data() {
    return {
      falg: false,
    }
  },
  beforeCreate() {},
  created() {
    // this.ios = !!this.u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    // if (!this.ios) {
    //   window.location.href = 'https://www.baidu.com/'
    // } else {
    //   window.location.href = this.url
    // }
    this.download()
  },
  methods: {
    download() {
      const u = navigator.userAgent
      const ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (!ios) {
        this.falg = true
        window.location.href = 'https://www.nejmqianyan.cn/NEJM医学前沿.apk'
      } else {
        window.location.href = 'https://itunes.apple.com/cn/app/id1148469879'
        // window.location.href = 'dcep://uniwallet/openWallet?sign=83BAF9E6899CF260DD032A3A5DE6AF5909607004AB763C7430BA58EDA6AA13A2B3DE6A56744F844D434BCA364E60836EDAE16693A3EBEA5FDBB5CAB7AF55B0FB'
      }
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  max-width: 1200px;
  margin: auto;
  text-align: center;
  font-size: 30px;
  padding: 100px 0;
}
</style>
