<template>
    <div class="pop">
        <div class="popbox">
            <div class="popupwarp">
                <div style="padding: 40px 32px;">
                    <div class="pop_title">
                        <img src="@/assets/imgs/logo/logo.png" />
                    </div>
                    <div class="pop_user">{{ param.title }}:</div>
                    <div class="pop_cont" v-html="formattedData" v-infinite-scroll="load" :infinite-scroll-disabled="isInfiniteScrollDisabled" style="margin-bottom: 20px;overflow-y:auto;height: 150px;" >
                        {{ param.content }}
                    </div>
                    <div class="pop_btn_wrap">
                        <div class="pop_btn" @click="close">我知晓了</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'FreeReadPopup',
    data() {
        return {
            userInfo: {
                truename: ""
            },
            isInfiniteScrollDisabled: false,
        }
    },
    computed: {
        formattedData() {
        // 去掉p标签并添加换行符
        return this.param.content.replace(/<(\/)?p>/g, '\n');
        },
    },
    props: {
        param: {
            type: Object,
            default: () => ({

            }),
        },
        // type:{
        //     type:String,
        //     default:''
        // },
        islogin: {
            type: Boolean,
            default: true,
        },
        isFree: {
            type: Boolean,
            default: true,
        },
        // is_subscribe: {
        //     type: Number,
        //     default: 1
        // },
        // is_have_view_auth: {
        //     type: Number,
        //     default: 2
        // },
        // is_month_chance: {
        //     type: Number,
        //     default: 2
        // },
    },
    created() {
        // this.userInfo.truename = window.sessionStorage.getItem('membername') || ''
    },
    mounted() {

    },
    methods: {
        close() {
            this.$emit('close', false)
        },
        chooseFree() {
            console.log('使用免费次数')
            let token = window.sessionStorage.getItem('token');
            console.log(token,'token');    
            axios.get('https://www.nejmqianyan.cn/index.php?c=program&m=articleUseMonthChance&id='+this.subParam.articleId + '&sf='+'pc'+'&token='+ token).then(res => {
                console.log(res);
                if(res.result == '1'){
                    this.$message.success('使用成功')
                    this.$emit('close', false)
                    this.$emit('getArticle')
                    return
                }
                this.$message.error(res.data.msg || '您本月免费次数已经使用了!' )
            })
        },
    },

}
</script>
<style lang="less" scoped>
.pop {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.30);
}

.popbox {
    // margin-top: 20%;
    // height: 500px;
    // background: #FFFFFF;
    box-shadow: 0px 4px 16px 2px rgba(200, 201, 204, 0.5);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popupwarp {
    z-index: 999;
    width: 400px;
    // height: 500px;
    background: #FFF;
    box-shadow: 0px 4px 16px 2px rgba(200, 201, 204, 0.5);
    border-radius: 10px;
    margin-top: 10%;

}

.pop_title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px;
}

.pop_title img {
    width: 150px;
    height: 58.12px;
}

.pop_user {
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 40px;
}

.pop_cont {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    margin-bottom: 44px;
}

.pop_btn_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop_btn_wrap .pop_btn {
    width: 144px;
    height: 50px;
    border: 1px solid rgba(51, 51, 51, 1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    cursor: pointer;
    // padding: 10px 50px;
}

.pop_btn_wrap .pop_btn:nth-child(2) {
    background: #E52E00;
    border-radius: 2px;
    color: #fff;
    border: none;
}

// 移动端样式
.mobile {
    .popupwarp {
        width: 80%;
        z-index: 999;
        // height: 380px;
        background: #FFF;
        box-shadow: 0px 4px 16px 2px rgba(200, 201, 204, 0.5);
        border-radius: 10px;
        margin-top: 20%;
    }

    .pop_btn_wrap .pop_btn {
        width: 144px;
        height: 35px;
        border: 1px solid #333333;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #333333;
        font-weight: 400;
    }

    .pop_btn_wrap .pop_btn:nth-child(2) {
        background: #E52E00;
        border-radius: 2px;
        color: #fff;
        border: none;
    }
}
</style>
  