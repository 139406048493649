<template>
  <div v-if="list != null && list.length > 0 && switchValue" class="hot-articles">
    <!-- <img class="hot-pic" :src="item.typepic" /> -->
    <div class="hot-title">热门阅读</div>
    <a-list :grid="grid" :data-source="list" :pagination="$isMobile1 ? pagination2 : pagination">
      <a-list-item slot="renderItem" slot-scope="item">
        <div class="article">
          <a :href="'' + generate(item)">
            <img
              v-if="item.thumb != '' || item.thumb != null"
              class="img"
              style="width: 100%; height: auto; object-fit: cover"
              :src="item.thumb"
              alt=""
            />
            <img
              v-else
              class="img"
              style="width: 100%; height: auto; object-fit: cover"
              src="../../assets/imgs/huge.png"
              alt=""
            />
            <div class="title">{{ item.sourceName }}</div>
            <div class="content">{{ item.title }}</div>
          </a>
          <div class="fr">
            <a :href="'' + generate(item)">
              <div class="date">{{ item.pubdate | formatDateTxt }}</div>
            </a>
            <div class="type">{{ item.typeName ? item.typeName : item.filterName }}</div>
          </div>
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import { selectHotReadList, getModularSwitch } from '@/api/hotRead'
import {
  PATH_ARTICLE_INFO,
  PATH_COURSE_VIDEO,
  PATH_MEETING_NOTICE,
  PATH_MEETING_REVIEW,
  PATH_SERIES_COURSES,
} from '@/router/article/paths'
import { format } from '@/utils/time-utils'
import { selectLessonDetail } from '@/api/course'
import { getSessionUser } from '@/utils/app-utils'

export default {
  name: 'HotArticles',
  data() {
    return {
      list: [],
      switchValue: null,
      pagination: {
        onChange: (page, pageSize) => {
          console.log(page, pageSize)
        },
        showSizeChanger: false,
        simple: true,
        size: 'small',
        pageSize: 4,
        position: 'top',
        total: 0,
      },
      pagination2: {
        onChange: (page, pageSize) => {
          console.log(page, pageSize)
        },
        showSizeChanger: false,
        simple: true,
        size: 'small',
        pageSize: 2,
        position: 'top',
        total: 0,
      },
    }
  },
  computed: {
    grid() {
      let column = 4
      if (this.$isMobile1) {
        column = 2
      }
      if (this.$isMobile2) {
        column = 1
      }
      return { gutter: 54, column, md: 2, lg: 4 }
    },
  },
  filters: {
    formatContent(str) {
      if (str == null) {
        return ''
      }
      return str.replace(/<[^>]+>/g, ' ')
    },
    formatDateTxt(time) {
      return format(time, 'YYYY-MM-DD')
    },
  },
  async created() {
    await this.getList()
  },
  methods: {
    generate(item) {
      let path = ''
      //文章
      if (item.relationType == 40) {
        path = PATH_ARTICLE_INFO + '?permalinks=' + item.permalinks
      }
      //论坛
      if (item.relationType == 30) {
        path = PATH_MEETING_REVIEW + '?id=' + item.id
      }
      //会议预告
      if (item.relationType == 20) {
        path = PATH_MEETING_NOTICE + '?id=' + item.id
      }
      return path
    },
    async onClick(item) {
      console.log(item)
      //文章
      if (item.relationType == 40) {
        this.$router.push({ path: PATH_ARTICLE_INFO + '?permalinks=' + item.permalinks })
      }
      //论坛
      if (item.relationType == 30) {
        this.$router.push({ path: PATH_MEETING_REVIEW + '?id=' + item.id })
      }
      //会议预告
      if (item.relationType == 20) {
        this.$router.push({ path: PATH_MEETING_NOTICE + '?id=' + item.id })
      }
      //编辑课堂
      if (item.relationType == 10) {
        let loginUser = getSessionUser()
        let res = await selectLessonDetail({
          id: item.id,
          memberId: null != loginUser ? loginUser.id : null,
        })
        if (res.ifViewAuth == 1) {
          if (null == loginUser) {
            this.$message.warning('登录即可观看')
            this.$bus.emit('login')
            return
          }
          if (loginUser.roleid == null) {
            this.$message.warning('需要资料补齐才能浏览')
            return
          }
        }
        if (res.ifViewAuth == 2) {
          if (null == loginUser) {
            this.$message.warning('登录即可阅读')
            this.$bus.emit('login')
            return
          }
        }
        if (res.subjectSerType == 1) {
          //单课程
          this.$router.push({ path: PATH_COURSE_VIDEO + '?id=' + item.id })
        } else {
          //多课程、论坛
          this.$router.push({ path: PATH_SERIES_COURSES + '?id=' + item.id })
        }
      }
    },
    async getList() {
      let res2 = await getModularSwitch({
        id: 5,
        moduleName: 'hot_read',
        switchValue: null,
      })
      this.switchValue = res2.switchValue
      let res = await selectHotReadList({})
      let arr = []
      let i = 0
      for (const temp of res.results) {
        let item = {
          ...temp,
          index: i,
        }
        arr.push(item)

        i++
      }
      this.list = arr
      // this.list = [{}]
      this.pagination.total = res.results.length
      this.pagination2.total = res.results.length
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.hot-articles {
  position: relative;
  .hot-title {
    position: absolute;
    top: 0;
    font-weight: bold;
    font-size: 20px;
    color: #e52e00;
    height: 32px;
    line-height: 32px;
  }
}
.mobile {
  .hot-articles {
    height: auto;
  }
}
.article {
  border-radius: 4px;
  //background: rgba(0, 0, 0, 0.04);
  //background: #f9e093;
  // background: #f6f2ef;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  //margin-right: 54px;
  .title {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    color: #333333;
    padding-top: 8px;
  }
  .content {
    margin: 6px 0 0px 0;
    //color: rgba(0, 0, 0, 0.85);
    color: #333333;
    height: 60px;
    line-height: 30px;
    font-size: 16px;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
    word-break: break-word;
  }
  .fr {
    padding: 5px 0;
    justify-content: space-between;
    cursor: default;
    //border-top: 1px solid @clr-primary;
  }
  .date {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    //color: white;
  }
  .type {
    font-size: 16px;
    font-weight: 400;
    color: #1e4ea6;
    //color: rgba(0, 0, 0, 0.65);
    //color: white;
  }
}
// 移动端样式
.mobile {
  .hot-articles {
    .hot-title {
      // left: 10px;
    }
  }
  .hot-articles {
    .hot-title {
      font-size: 18px;
    }
  }
  .article {
    border-radius: 4px;
    //background: rgba(0, 0, 0, 0.04);
    //background: #f9e093;
    // background: #f6f2ef;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
    //margin-right: 54px;
    .title {
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 18px;
      color: #333333;
    }
    .content {
      margin: 6px 0 0px 0;
      //color: rgba(0, 0, 0, 0.85);
      color: #333333;
      height: 48px;
      line-height: 24px;
      font-size: 16px;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-weight: 400;
      word-break: break-word;
    }
    .fr {
      padding: 5px 0;
      justify-content: space-between;
      //border-top: 1px solid @clr-primary;
    }
    .date {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      //color: white;
    }
    .type {
      font-size: 16px;
      font-weight: 400;
      color: #1e4ea6;
      //color: rgba(0, 0, 0, 0.65);
      //color: white;
    }
  }
}
</style>

<style lang="less">
.hot-articles {
  .ant-list-pagination {
    margin: 40px 0 24px 0;
    height: 48px;
    text-align: right;
    padding-bottom: 16px;
    border-bottom: 1px solid #414347;
  }
  .ant-pagination {
    font-size: 16px;
  }
}
.mobile {
  .hot-articles {
    .ant-list-pagination {
      margin: 20px 0 12px 0;
      height: 48px;
      text-align: right;
      padding-bottom: 8px;
      border-bottom: 1px solid #414347;
    }
    .ant-pagination {
      font-size: 16px;
    }
  }
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
  margin: 0 10px;
  line-height: 30px;
  border-radius: 2px;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  border-radius: 2px;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 30px;
  border: 0;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 32px;
  line-height: 32px;
  margin: 0;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 32px;
  margin-right: 8px;
  width: 32px;
  padding: 0;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e52e00;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
</style>
