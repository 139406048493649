<template>
  <!-- 动画解读 -->
  <div class="AnimationList">
    <a-list
      :split="false"
      size="large"
      :pagination="pagination"
      :data-source="listData"
      :grid="!$isMobile ? { gutter: 85, column: 4 } : { gutter: 0, column: 1 }"
    >
      <a-list-item class="item" slot="renderItem" :key="index" slot-scope="item, index">
        <a @click.prevent="onItemClick(item)">
          <el-image :src="item.thumb" fit="cover"></el-image>
        </a>
        <div class="time">{{ item.postdate }}</div>
        <div class="title">
          <a @click.prevent="onItemClick(item)">{{ item.title }}</a>
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
export default {
  name: 'AudioList',
  props: ['params', 'pagination', 'listData', 'loading'],
  components: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {
    onItemClick(item) {
      this.$router.push({ path: '/audio-particulars', query: { permalinks: item.permalinks } })
    },
  },
}
</script>

<style lang="less">
@import '~@/assets/less/variables.less';
.AnimationList {
  padding: 64px 0;
  .item {
    // margin-bottom: 40px;
    margin-bottom: 102px;
    height: 212px;
    // a {
    //   .el-image {
    //     width: 256px !important;
    //     height: 180px !important;
    //   }
    // }
    .time {
      width: 256px;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      line-height: 42px;
      border-left: 1px solid #d4d4d4;
      border-right: 1px solid #d4d4d4;
      border-bottom: 1px solid #d4d4d4;
      margin-top: -5px;
    }
    .title {
      margin-top: 8px;
      // 三行...
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      > a {
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
      a:hover {
        color: #e52e00;
      }
    }
  }
}
.ant-list-pagination {
  margin-top: 0px;
  text-align: center;
}
.mobile {
  .AnimationList {
    padding: 15px 15px 0 15px;
    .item {
      margin-bottom: 248px;
      a {
        .el-image {
          width: 100% !important;
          height: 100% !important;
        }
      }
      .time {
        width: 100%;
        font-size: 25px;
      }
      .title {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        a {
          font-size: 20px;
        }
      }
    }
    /deep/.ant-list-pagination {
      margin-bottom: 24px;
    }
  }
}
</style>
