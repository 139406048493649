<template>
  <div class="title-nav">
    <div class="title">{{ title }}</div>
    <div class="navs">
      <div class="fr">
        <nav v-for="(item, i) of navs.slice(0, 5)" :key="i" @click="$emit('nav-click', item)">
          <a-divider v-if="i !== 0" type="vertical" />
          <span class="nav-item">
            {{ item.text }}
          </span>
        </nav>
      </div>
      <div class="nav-item nav-action">
        <slot name="action">
          <span v-if="title == '按期浏览'" @click="$emit('nav-click', navs[5])">其他</span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleNav',
  props: {
    title: {
      type: String,
      required: true,
    },
    navs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.title-nav {
  + .title-nav {
    margin-top: 18px;
  }
  .title {
    margin-bottom: 8px;
    font-weight: bold;
  }

  .navs {
    display: flex;
    justify-content: space-between;
    .nav-item {
      flex-shrink: 0;
      cursor: pointer;
      &:hover {
        color: @clr-primary;
      }
    }
    .nav-action {
      margin-left: 30px;
    }
  }
}
</style>
