<template>
  <div class="">
    <div class="swiper" :style="!$isMobile ? 'width: 375px;margin-bottom: 40px;' : 'width: 100%;margin-bottom: 40px;'">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, i) in adv" :key="i">
          <a :href="item.adUrl" target="_blank">
            <img :src="item.imgUrl" :alt="item.name" style="width: 100%" />
          </a>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination swiper-pagination2" slot="pagination"></div>
      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
    </div>
    <template>
      <div v-if="adList.length != 0">
        <h3 class="title">
          动画解读
          <!--        {{ adList[0].postdate | formatDateTxt }}-->
        </h3>
        <a-list :grid="{ gutter: 10, column: 1 }" :data-source="adList">
          <a-list-item slot="renderItem" slot-scope="item" style="width: 70%">
            <a :href="'/animation-particulars' + generate(item.permalinks)">
              <a-card class="card-css" @click.prevent="onAnimationClick(item)">
                <img slot="cover" :src="item.thumb" height="" />
                <a-card-meta style="padding: 0px">
                  <div
                    slot="description"
                    style="
                      height: 48px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      font-size: 16px;
                      color: #666666;
                      letter-spacing: 0;
                      line-height: 24px;
                      font-weight: 400;
                      margin-top: 10px;
                    "
                  >
                    {{ item.title }}
                  </div>
                </a-card-meta>
              </a-card>
            </a>
          </a-list-item>
        </a-list>
      </div>
    </template>
  </div>
</template>

<script>
import { newestArticle } from '@/api/nejmCatalog'
import { PATH_ARTICLE_INFO, PATH_NEJM_CATALOG } from '@/router/article/paths'
import { getModularSwitch, selectAdvertList } from '@/api/adv'
import { generateHref } from '@/utils/utils'
import { format } from '@/utils/time-utils'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'CenterRightAnimation',
  components: { Swiper, SwiperSlide },
  data() {
    return {
      adList: [],
      reviewList: [],
      mulvList: [],
      adv: [],
      advImg: '',
      switchValue: null,
      swiperOptions: {
        loop: true, // 循环模式选项
        pagination: {
          // 如果需要分页器
          el: '.swiper-pagination2',
          clickable: true,
        },
        autoplay: {
          delay: 10000, //自动滚动的间隔时间
          stopOnLastSlide: false, //如果设置为true，当切换到最后一个slide时停止自动切换（loop模式下无效）。
          disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。默认为true
        },
        // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
      },
    }
  },
  filters: {
    formatDateTxt(time) {
      return format(time, 'YYYY-MM-DD')
    },
    formatHtmlTxt(str) {
      return str.replace(/<\/?.+?>/g, '')
    },
  },
  async created() {
    await this.getList()
    await this.getModularSwitch()
  },
  methods: {
    generate(item) {
      return generateHref('permalinks', item)
    },
    onAnimationClick(item) {
      console.log(item)
      this.$router.push({ path: '/animation-particulars', query: { permalinks: item.permalinks } })
    },
    onReviewClick(item) {
      console.log(item)
      this.$router.push({ path: PATH_ARTICLE_INFO, query: { permalinks: item.permalinks } })
    },
    onNejmClick() {
      this.$router.push({ path: PATH_NEJM_CATALOG, query: { permalinks: this.mulvList[0].permalinks } })
      // this.$router.push({ path: PATH_NEJM_CATALOG })
    },
    async getList() {
      let res = await newestArticle({})
      this.mulvList = res.articleMap[0]
      let arr = []
      for (const item of res.articleMap[5]) {
        if ('' != item.wantsay) {
          arr.push(item)
        }
      }
      // for (const item of res.articleMap[6]) {
      //   if ('' != item.wantsay) {
      //     arr.push(item)
      //   }
      // }
      if (res.articleMap[7]) {
        this.adList = res.articleMap[7]
        this.adList.splice(1, 1)
        for (const item of res.articleMap[7]) {
          if ('' != item.wantsay) {
            arr.push(item)
          }
        }
      }
      console.log(this.adList.length)
      // arr.push(
      //   '<p style="text-align: center;"><strong>强化降压降低老年高血压… Hypertension. N Engl J Med 2021;385:1268-79.</p >'
      // )
      this.reviewList = arr
      console.log('reviewList::', this.adList)
    },
    async getModularSwitch() {
      let res = await getModularSwitch({ moduleName: 'advert' })
      this.switchValue = res.switchValue
      if (this.switchValue == 1) {
        await this.getAdv()
      }
    },
    async getAdv() {
      let res = await selectAdvertList({
        pageNo: 1,
        pageSize: 10,
        ifPublish: '是',
      })
      for (var i = 0; i < res.results.length; i++) {
        if (res.results[i].advertPosition == null || res.results[i].advertPosition == '右一') {
          res.results[i].imgUrl = JSON.parse(res.results[i].imgUrl)[0].url
          this.adv.push(res.results[i])
          console.log(res.results[i] + '右一')
        }
      }
    },
    // showAdv(url) {
    //   window.open(url)
    //   // window.open('https://www.qq.com')
    //   // downloadFile(item.adUrl)
    // },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.ad {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  background-color: #ccc;
  img {
    width: 100%;
  }
}
.title {
  margin: 13px 0 20px 0;
  font-size: 18px;
  font-weight: 400;
  color: #e52e00;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 700;
  border-bottom: 1px solid #414347;
  padding-bottom: 16px;
}
.nejm {
  margin: 20px 0;
  padding: 5px 20px;
  line-height: 1.5;
  border-left: 4px solid @clr-primary;
  //background: rgba(0, 0, 0, 0.04);
  background: #f9e093;
  cursor: pointer;
}
.card-css {
  cursor: pointer;
  border: none;
  //div:nth-child(2) {
  //  padding-left: 0px;
  //  padding-right: 0px;
  //}
}
.card-css :nth-child(2) {
  zoom: 1;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.ant-row div:nth-child(2) {
  margin-left: 20px;
}
.nejm-txt {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
  //color: white;
}
.mobile {
  .ant-row div:nth-child(2) {
    margin-left: 0px;
  }
  .title {
    font-size: 18px;
  }
}
</style>
