<template>
  <el-dialog :before-close="_handleClose" @close="_handleClose" v-bind="$attrs" v-on="$listeners">
    <el-form :model="ruleForm" inline status-icon :rules="rules" ref="ruleForm" label-width="125px">
      <el-form-item label="原密码" prop="oldPassword">
        <el-input type="password" v-model="ruleForm.oldPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" v-model="ruleForm.newPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="checknewPassword">
        <el-input type="password" v-model="ruleForm.checknewPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="_submitForm('ruleForm')">提交</el-button>
        <el-button @click="_resetPwd">重置</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { updatePassword } from '@/api/personal'
export default {
  name: 'ChangePwdDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false
      },
    },
    ruleForm: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    let validateNewPwd = (rule, value, callback) => {
      if (this.ruleForm.checknewPassword !== '') {
        this.$refs.ruleForm.validateField('checknewPassword')
      }
      callback()
    }
    let validateCheckNewPwd = (rule, value, callback) => {
      if (value !== this.ruleForm.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { min: 6, message: '密码至少6位', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码至少6位', trigger: 'blur' },
          { required: true, validator: validateNewPwd, trigger: 'blur' },
        ],
        checknewPassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { min: 6, message: '密码至少6位', trigger: 'blur' },
          { required: true, validator: validateCheckNewPwd, trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    _handleClose() {
      this.$emit('update:visible', false)
      this._resetPwd()
    },
    _submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await updatePassword(this.ruleForm)
          if (res) {
            this.$message.success('修改密码成功')
            this._handleClose()
          } else {
            this.$message.error('修改密码失败')
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    _resetPwd() {
      this.$refs.ruleForm.resetFields()
    },
  },
}
</script>
<style lang="less" scoped>
.el-input {
  width: 220px;
}
.el-form-item:nth-last-of-type(1) {
  display: flex;
  justify-content: center;
}
.el-form {
  /deep/.el-form-item__label {
    padding-right: 15px;
  }
}
</style>
