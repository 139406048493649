<template>
  <div class="search">
    <el-drawer :visible.sync="drawer" :with-header="false" size="80%" class="searchDrawer">
      <div>
        <p
          style="
            margin-bottom: 8px;
            font-size: 16px;
            <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
          "
        >
          排序
        </p>
        <el-select v-model="requestMsg.sortType" placeholder="请选择" style="width: 100%" @change="sort">
          <el-option label="最新在前" value="0"></el-option>
          <el-option label="最早在前" value="1"></el-option>
          <el-option label="阅读最多" value="2"></el-option>
        </el-select>
      </div>
      <div v-if="key == '7' || key == '5' || key == '6' || key == '1'">
        <p
          style="
            margin-bottom: 8px;
            font-size: 16px;
            <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
          "
        >
          专业领域
        </p>
        <el-select
          v-model="requestMsg.articleClassIds"
          placeholder="请选择"
          style="width: 100%"
          @change="professionalField"
          multiple
          clearable
        >
          <el-option v-for="item in option" :key="item.id" :label="item.classname" :value="item.id"></el-option>
        </el-select>
      </div>
      <div v-if="key == '11'">
        <p
          style="
            margin-bottom: 8px;
            font-size: 16px;
            <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
          "
        >
          学科
        </p>
        <el-select v-model="coursexk.articalClassId" placeholder="请选择" style="width: 100%" @change="course">
          <el-option v-for="item in optionxk" :key="item.id" :label="item.classname" :value="item.id"></el-option>
        </el-select>
      </div>
      <div v-if="key == '5' || key == '6'">
        <p
          style="
            margin-bottom: 8px;
            font-size: 16px;
            <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
          "
        >
          类型
        </p>
        <el-select
          v-model="requestMsg.articleFilterIds"
          placeholder="请选择"
          style="width: 100%"
          @change="getArticleFilter"
          multiple
          clearable
        >
          <el-option v-for="item in options" :key="item.id" :label="item.filtername" :value="item.id"></el-option>
        </el-select>
      </div>
      <div>
        <van-cell title="日期" :value="timeFit" @click="show = true" />
        <van-calendar :min-date="minDate" :max-date="maxDate" v-model="show" type="range" @confirm="onChange2" />
      </div>
    </el-drawer>
    <div class="header">
      <BzSearch v-if="!$isMobile" @search="onSearch" style="width: 1002px; margin: 16px auto; display: block" />
      <div v-else class="shaixuanContainer">
        <BzSearch @search="onSearch" style="width: 85%; margin: 20px 0 0 5%; display: block; float: left" />
        <!-- 手机版筛选 -->
        <div v-if="$isMobile" class="iconfont icon-shaixuan tuBiaoShaiXuan" @click="drawer = true">
          <img src="../../assets/imgs/search.png" />
        </div>
        <div class="section"></div>
        <van-tabs v-model="current2" @click="getMenuItem">
          <van-tab v-for="item in data" :key="item.key" :name="item.key" :title="item.title"></van-tab>
        </van-tabs>
      </div>
      <ul v-if="!$isMobile" class="header_text">
        <li>共{{ msg ? msg.page.total : 0 }}篇</li>
      </ul>
    </div>
    <MainCenter class="MainCenter">
      <a-menu v-if="!$isMobile" v-model="current" mode="horizontal" @select="getMenuItem" style="margin-top: 10px">
        <a-menu-item
          v-for="item in data"
          :key="item.key"
          style="
            border-radius: 2px 2px 0px 0px;
            margin-right: 4px;
            background-color: #ececec;
            height: 50px;
            line-height: 50px;
            font-size: 18px;
            <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
            color: rgba(0, 0, 0, 0.85);
            border-bottom: none;
          "
          :class="key == item.key ? 'menu-item' : ''"
        >
          {{ item.title }}
        </a-menu-item>
      </a-menu>
      <div class="main">
        <div v-if="!$isMobile" class="main_left">
          <a-anchor :offsetTop="!$isMobile ? 112 : 62">
            <div>
              <p
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
                  color: rgba(0, 0, 0, 0.85);
                  line-height: 24px;
                "
              >
                排序
              </p>
              <el-select v-model="requestMsg.sortType" placeholder="请选择" style="width: 100%" @change="sort">
                <el-option label="最新在前" value="0"></el-option>
                <el-option label="最早在前" value="1"></el-option>
                <el-option label="阅读最多" value="2"></el-option>
              </el-select>
            </div>
            <div v-if="key == '7' || key == '5' || key == '6' || key == '1'">
              <p
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
                  color: rgba(0, 0, 0, 0.85);
                  line-height: 24px;
                "
              >
                专业领域
              </p>
              <el-select
                v-model="requestMsg.articleClassIds"
                placeholder="请选择"
                style="width: 100%"
                @change="professionalField"
                multiple
                clearable
              >
                <el-option v-for="item in option" :key="item.id" :label="item.classname" :value="item.id"></el-option>
              </el-select>
            </div>
            <div v-if="key == '11'">
              <p
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
                  color: rgba(0, 0, 0, 0.85);
                  line-height: 24px;
                "
              >
                学科
              </p>
              <el-select v-model="coursexk.articalClassId" placeholder="请选择" style="width: 100%" @change="course">
                <el-option v-for="item in optionxk" :key="item.id" :label="item.classname" :value="item.id"></el-option>
              </el-select>
            </div>
            <div v-if="key == '5' || key == '6'">
              <p
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
                  color: rgba(0, 0, 0, 0.85);
                  line-height: 24px;
                "
              >
                类型
              </p>
              <el-select
                v-model="requestMsg.articleFilterIds"
                placeholder="请选择"
                style="width: 100%"
                @change="getArticleFilter"
                multiple
                clearable
              >
                <el-option v-for="item in options" :key="item.id" :label="item.filtername" :value="item.id"></el-option>
              </el-select>
            </div>
            <div>
              <p
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  <!-- font-family: MicrosoftYaHei, MicrosoftYaHei-Regular; -->
                  color: rgba(0, 0, 0, 0.85);
                  line-height: 24px;
                "
              >
                日期
              </p>
              <el-date-picker
                unlink-panels
                v-model="getTime"
                format="yyyy-MM-dd"
                style="width: 100%"
                @change="onChange"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
          </a-anchor>
        </div>
        <component
          v-if="!$isMobile"
          :is="component"
          :msg="msg"
          :query="query"
          :pageNo="pageNo"
          style="float: left; width: 74%"
          @paging="paging"
          :loading="loading"
          :keyId="key"
        ></component>
        <component
          v-else
          :is="component"
          :msg="msg"
          :query="query"
          :pageNo="pageNo"
          style="float: left; width: 100%"
          @paging="paging"
          :loading="loading"
          :keyId="key"
        ></component>
      </div>
    </MainCenter>
    <!-- <div class="bottom">
      <MainCenter>
        <ul>
          <li>
            <p>呼吸系统疾病</p>
            <p>Pulmonary Disease</p>
          </li>
          <li>
            <p>传染病</p>
            <p>Infectious Disease</p>
          </li>
          <li>
            <p>肿瘤</p>
            <p>Oncology</p>
          </li>
          <li>
            <p>妇产科和儿科</p>
            <p>Ob/Gyn & Pediatrics</p>
          </li>
          <li>
            <p>其他</p>
            <p>Other Specialties</p>
          </li>
        </ul>
      </MainCenter>
    </div> -->
  </div>
</template>

<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import SearchAnimation from '@/views/search/SearchAnimation.vue'
import SearchArticle from '@/views/search/SearchArticle.vue'
import SearchResource from '@/views/search/SearchResource.vue'
import { selectArticleFilter, selectArticleClass, selectArticleList, subject, selectLessonList } from '@/api/serach'
import moment from 'moment'
import { format } from '@/utils/time-utils.js'
import BzSearch from '@/components/form/BzSearch.vue'
import { getSessionUser } from '@/utils/app-utils'
// import { PATH_SEARCH } from '@/router/search/paths.js'

const componentOf = {
  1: SearchArticle,
  2: SearchAnimation,
  4: SearchResource,
}
export default {
  name: 'Search',
  components: { MainCenter, BzSearch },
  data() {
    return {
      getTime: [],
      current: ['5'],
      current2: '5',
      key: '5',
      pageNo: 1,
      pageSize: 20,
      data: [
        { title: 'NEJM', key: '5' },
        // { title: 'NEJM期刊荟萃', key: '6' },
        { title: '临床研究论文撰写', key: '9' },
        { title: '述评', key: '1' },
        { title: '动画解读', key: '7' },
        // { title: '音频', key: '8' },
        { title: '课程', key: '11' },
      ],
      options: [],
      option: [],
      optionxk: [],
      requestMsg: {
        articleClassIds: [], //专业领域
        articleFilterIds: [], //文章类型
        articleIds: [], //文章id
        articleTypeIds: [],
        startDate: '', //开始时间
        endDate: '', //结束时间
        isweek: 0, //是否是一周的概述
        month: '', //按月浏览
        // pageNo: 1,
        // pageSize: 20,
        searchInfo: '', //查询信息 文章
        sortType: '', //排序方式
        sourceIds: ['5'], //文章来源id
        status: 0, //译文状态（1：审核 0：发布 9：提交）
        wantsayInfo: '', //查询信息  评述
        year: '', //按年浏览
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      },
      requestMsg2: {
        articleClassIds: [], //专业领域
        articleFilterIds: [], //文章类型
        articleIds: [], //文章id
        articleTypeIds: [],
        startDate: '', //开始时间
        endDate: '', //结束时间
        isweek: 0, //是否是一周的概述
        month: '', //按月浏览
        // pageNo: 1,
        // pageSize: 20,
        searchInfo: '', //查询信息 文章
        sortType: '', //排序方式
        // sourceIds: ['5'], //文章来源id
        status: 0, //译文状态（1：审核 0：发布 9：提交）
        wantsayInfo: '', //查询信息  评述
        year: '', //按年浏览
        haswantsay: 1, //显示述评
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      },
      coursexk: {
        articalClassId: null, //学科主键id
        lessonStatus: 1, //@课程状态0=待发布1=已发布2=已下架
        // startDate: '', //开始时间
        // endDate: '', //结束时间
        // month: '', //查询月
        // sortType: '0', //排序方式
        // pageNo: 1,
        // pageSize: 21,
        // perlink: '', //课程键
        searchInfo: '', //关键字
        // searchType: 0, //查询类型(与课程键连用)0：非关联数据1：关联数据
        subjectSerType: [1, 2, 3], //课程分组类型1=single 单课程2=multi 多课程3=meeting 会议
        // subjectType: 2, //	integer($int32)@课程类型1=audio 音频2=video 视频
        sortType: 3,
      },
      msg: null,
      obj: null,
      objcourse: null,
      arr: ['7', '8'],
      setShake: null,
      shakeTime: 800, //防抖时间
      query: null,
      flag: false,
      loading: true,
      drawer: false,
      timeFit: '',
      show: false,
      minDate: new Date(2010, 12, 1),
      maxDate: new Date(),
    }
  },
  created() {
    this.query = this.$route.query.query
    this.requestMsg.searchInfo = this.$route.query.query
    this.coursexk.searchInfo = this.$route.query.query
    this.requestMsg2.wantsayInfo = this.$route.query.query
    if(this.$route.query.startDate&&this.$route.query.endDate){
      this.getTime = [  new Date(moment(this.$route.query.startDate).format('YYYY-MM-DD')) ,new Date(moment(this.$route.query.endDate).format('YYYY-MM-DD'))];
      this.timeFit = `${this.formatDate(this.getTime[0])} - ${this.formatDate(this.getTime[1])}`
    }
    // this.articleFilter()
    // this.articleClass()
    // this.articleList()
    if (this.$route.query.key) {
          this.key  = this.$route.query.key;
          this.current = [this.$route.query.key];
          this.current2 = this.$route.query.key
    }
    if (this.key !== '11' && this.key !== '1') {
        this.articleFilter()
        this.articleClass()
        this.requestMsg.sourceIds = []
        this.requestMsg.sourceIds.push(this.key)
        if (this.$route.query.sortType) {
          this.requestMsg.sortType  = this.$route.query.sortType;
        }
        if (this.$route.query.articleClassIds) {
          for (let i in this.$route.query.articleClassIds.split(',')) {
            var articleClassIdsitemNum = parseInt(this.$route.query.articleClassIds.split(',')[i])
            this.requestMsg.articleClassIds.indexOf(articleClassIdsitemNum)==-1 && this.requestMsg.articleClassIds.push(articleClassIdsitemNum)
          }
        }
        if (this.$route.query.articleFilterIds) {
          for (let i in this.$route.query.articleFilterIds.split(',')) {
            var articleFilterIdsitemNum = parseInt(this.$route.query.articleFilterIds.split(',')[i])
            this.requestMsg.articleFilterIds.indexOf(articleFilterIdsitemNum)==-1 && this.requestMsg.articleFilterIds.push(articleFilterIdsitemNum)
          }
        }
        this.requestMsg.startDate = this.$route.query.startDate || ''
        this.requestMsg.endDate = this.$route.query.endDate || ''
        this.articleList()
      } else if (this.key === '11') {
        if (this.$route.query.sortType) {
          this.requestMsg.sortType = this.coursexk.sortType  = this.$route.query.sortType;
        }
        if (this.$route.query.articalClassId) {
          this.coursexk.articalClassId  = this.$route.query.articalClassId;
          // for (let i in this.$route.query.articalClassId.split(',')) {
          //   var articalClassIditemNum = parseInt(this.$route.query.articalClassId.split(',')[i])
          //   !!this.coursexk.articalClassId && this.coursexk.articalClassId.indexOf(articalClassIditemNum)==-1 && this.coursexk.articalClassId.push(articalClassIditemNum)
          // }
        }
        this.coursexk.startDate = this.$route.query.startDate || ''
        this.coursexk.endDate = this.$route.query.endDate || ''
        this.coursexk.pageSize = 20
        this.getSubject()
        this.selectLessonList()
      } else if (this.key === '1') {
        if (this.$route.query.sortType) {
          this.requestMsg.sortType = this.requestMsg2.sortType  = this.$route.query.sortType;
        }
        if (this.$route.query.articleClassIds) {
          for (let i in this.$route.query.articleClassIds.split(',')) {
            var articleClassIdsitemNum1 = parseInt(this.$route.query.articleClassIds.split(',')[i])
            this.requestMsg2.articleClassIds.indexOf(articleClassIdsitemNum1)==-1 && this.requestMsg2.articleClassIds.push(articleClassIdsitemNum1)
            this.requestMsg.articleClassIds.indexOf(articleClassIdsitemNum1)==-1 && this.requestMsg.articleClassIds.push(articleClassIdsitemNum1)
          }
        }
        this.requestMsg2.startDate = this.$route.query.startDate || ''
        this.requestMsg2.endDate = this.$route.query.endDate || ''
        this.articleFilter()
        this.articleClass()
        this.articleList2()
      }
  },
  methods: {
    getMenuItem(item) {
      this.timeFit = ''
      this.requestMsg = {
        articleClassIds: [], //专业领域
        articleFilterIds: [], //文章类型
        articleIds: [], //文章id
        articleTypeIds: [],
        startDate: '', //开始时间
        endDate: '', //结束时间
        isweek: 0, //是否是一周的概述
        month: '', //按月浏览
        // pageNo: 1,
        // pageSize: 20,
        searchInfo: this.query, //查询信息 文章
        sortType: '', //排序方式
        sourceIds: ['5'], //文章来源id
        status: 0, //译文状态（1：审核 0：发布 9：提交）
        wantsayInfo: '', //查询信息  评述
        year: '', //按年浏览
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      }
      this.requestMsg2 = {
        articleClassIds: [], //专业领域
        articleFilterIds: [], //文章类型
        articleIds: [], //文章id
        articleTypeIds: [],
        startDate: '', //开始时间
        endDate: '', //结束时间
        isweek: 0, //是否是一周的概述
        month: '', //按月浏览
        // pageNo: 1,
        // pageSize: 20,
        searchInfo: '', //查询信息 文章
        sortType: '', //排序方式
        // sourceIds: ['5'], //文章来源id
        status: 0, //译文状态（1：审核 0：发布 9：提交）
        wantsayInfo: '', //查询信息  评述
        year: '', //按年浏览
        haswantsay: 1, //显示述评
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      }
      this.loading = true
      this.pageNo = 1
      this.msg = { page: {}, results: [] }
      this.getTime = []
      this.requestMsg.articleClassIds = []
      this.requestMsg.sortType = ''
      this.requestMsg.articleFilterIds = []
      if (this.$isMobile) {
        this.key = item
      } else {
        this.key = item.key
      }
      let query = {query:this.$route.query.query,key:this.key}
      this.$router.push({
        path: '/search',
        query: query,
      })
      if (this.arr.indexOf(this.key) !== -1) {
        this.requestMsg.pageSize = 20
      } else {
        this.requestMsg.pageSize = 20
      }
      if (this.key !== '11' && this.key !== '1') {
        this.articleFilter()
        this.articleClass()
        this.requestMsg.sourceIds = []
        this.requestMsg.sourceIds.push(this.key)
        this.articleList()
      } else if (this.key === '11') {
        this.coursexk.pageSize = 20
        this.coursexk.pageSize = 20
        this.getSubject()
        this.selectLessonList()
      } else if (this.key === '1') {
        this.requestMsg2.wantsayInfo = this.query
        this.articleFilter()
        this.articleClass()
        this.articleList2()
      }
    },
    paging(pageNo, pageSize) {
      this.flag = true
      this.pageNo = pageNo
      this.pageSize = pageSize
      if (this.key === '11') {
        this.selectLessonList()
      } else if (this.key === '1') {
        this.articleList2()
      } else {
        this.articleList()
      }
      console.log(this.msg, 'this.msg')
    },
    sort() {
      //排序
      this.flag = false
      let query = {...this.$route.query};
      if (this.requestMsg.sortType) {
        query.sortType = this.requestMsg.sortType
      } else {
        delete query.sortType
      }
      this.$router.push({
        path: '/search',
        query: query,
      })
      if (this.key === '11') {
        this.coursexk.sortType = this.requestMsg.sortType
        this.selectLessonList()
      } else if (this.key === '1') {
        this.requestMsg2.sortType = this.requestMsg.sortType;
        this.articleList2()
      } else {
        this.articleList()
      }
    },
    professionalField() {
      //专业领域
      this.flag = false
      let query = {...this.$route.query};
      if (this.requestMsg.articleClassIds.toString()) {
        query.articleClassIds = this.requestMsg.articleClassIds.toString()
      } else {
        delete query.articleClassIds
      }
      this.$router.push({
        path: '/search',
        query: query,
      })
      if(this.key==='1'){
        this.requestMsg2.articleClassIds = this.requestMsg.articleClassIds;
        this.shake(this.articleList2, this.shakeTime)
      }else {
        this.shake(this.articleList, this.shakeTime)
      }
      // this.articleList()
    },
    course() {
      //课程类型
      // this.shake(this.selectLessonList, this.shakeTime)
      this.flag = false
      let query = {...this.$route.query};
      if (this.coursexk.articalClassId.toString()) {
        query.articalClassId = this.coursexk.articalClassId.toString()
      } else {
        delete query.articalClassId
      }
      this.$router.push({
        path: '/search',
        query: query,
      })
      this.selectLessonList()
    },
    onChange() {
      this.flag = false
      let query = {...this.$route.query};
      if (this.getTime !== null) {
        query.startDate = this.requestMsg.startDate = moment(this.getTime[0]).format('YYYYMMDD')
        query.endDate = this.requestMsg.endDate = moment(this.getTime[1]).format('YYYYMMDD')
      } else {
        this.requestMsg.startDate = ''
        Object.prototype.hasOwnProperty.call(query,'startDate') && delete query.startDate
        this.requestMsg.endDate = ''
        Object.prototype.hasOwnProperty.call(query,'endDate') && delete query.endDate
      }
      if (this.getTime !== null && this.key === '1') {
        this.requestMsg2.startDate = moment(this.getTime[0]).format('YYYYMMDD')
        this.requestMsg2.endDate = moment(this.getTime[1]).format('YYYYMMDD')
      } else {
        this.requestMsg2.startDate = ''
        this.requestMsg2.endDate = ''
      }
      if (this.key === '11') {
        this.coursexk.startDate = this.requestMsg.startDate
        this.coursexk.endDate = this.requestMsg.endDate
        this.$router.push({
          path: '/search',
          query: query,
        })
        this.selectLessonList()
      } else if (this.key === '1') {
        this.$router.push({
          path: '/search',
          query: query,
        })
        this.articleList2()
      } else {
        this.$router.push({
          path: '/search',
          query: query,
        })
        this.articleList()
      }
    },
    onChange2(val) {
      this.getTime = val
      const [start, end] = val
      this.show = false
      this.timeFit = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.onChange()
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    getArticleFilter() {
      //文章类型
      this.flag = false
      let query = {...this.$route.query};
      if (this.requestMsg.articleFilterIds.toString()) {
        query.articleFilterIds = this.requestMsg.articleFilterIds.toString()
      } else {
        delete query.articleFilterIds
      }
      this.$router.push({
        path: '/search',
        query: query,
      })
      this.shake(this.articleList, this.shakeTime)
      // this.articleList()
    },
    articleFilter() {
      //文章类型
      selectArticleFilter({ id: this.key }).then((res) => {
        this.options = res
      })
    },
    articleClass() {
      //专业领域
      selectArticleClass().then((res) => {
        this.option = res
      })
    },
    getSubject() {
      //学科下拉
      subject().then((res) => {
        this.optionxk = res
      })
    },
    articleList() {
      //搜索文章
      if (!this.flag) {
        this.pageNo = 1
      }
      selectArticleList({ ...this.requestMsg, pageNo: this.pageNo, pageSize: this.pageSize }).then((res) => {
        res.results.forEach((element) => {
          // 切割作者
          element.authorText = this._sliceAuthor(element.author)
          element.postdate = format(element.postdate, 'YYYY-MM-DD')
          if (element.classNames != null) {
            element.classNames = element.classNames.split(',')
            let classNames = []
            element.classNames.forEach((item) => {
              if (item !== '') {
                classNames.push(item)
              }
            })
            element.classNames = classNames
          }
        })
        this.msg = res
        this.loading = false
        this.$bus.emit('selectArticleListAmount', res.results)
      })
    },
    articleList2() {
      //搜索述评
      if (!this.flag) {
        this.pageNo = 1
      }
      selectArticleList({ ...this.requestMsg2, pageNo: this.pageNo, pageSize: this.pageSize }).then((res) => {
        res.results.forEach((element) => {
          // 切割作者
          element.authorText = this._sliceAuthor(element.author)
          element.postdate = format(element.postdate, 'YYYY-MM-DD')
          if (element.classNames != null) {
            element.classNames = element.classNames.split(',')
            let classNames = []
            element.classNames.forEach((item) => {
              if (item !== '') {
                classNames.push(item)
              }
            })
            element.classNames = classNames
          }
        })
        this.msg = res
        this.loading = false
        this.$bus.emit('selectArticleListAmount', res.results)
      })
    },
    selectLessonList() {
      //搜索课程
      if (!this.flag) {
        this.pageNo = 1
      }
      selectLessonList({ ...this.coursexk, pageNo: this.pageNo, pageSize: this.pageSize }).then((res) => {
        res.results.forEach((element) => {
          element.postdate = format(element.postdate, 'YYYY-MM-DD')
          element.createTime = format(element.createTime, 'YYYY-MM-DD')
          // element.classnames = element.classnames.split(',')
          // let classnames = []
          // element.classnames.forEach((item) => {
          //   if (item !== '') {
          //     classnames.push(item)
          //   }
          // })
          // element.classnames = classnames
          if (element.coverPc !== '') {
            element.coverPc = JSON.parse(element.coverPc)
          }
        })
        this.msg = res
        this.loading = false
        this.$bus.emit('selectLessonListAmount', res.results)
      })
    },
    _sliceAuthor(author, limit = 3) {
      author = author.split(',')
      const postList = author.filter((item) => item.charAt(item.length - 1) === '.').map((item) => item.trim())
      let indexArr = []
      let count = 0
      let delLength = 0
      for (let i = 0; i < author.length; i++) {
        // 判断是否是职称
        // 如果是：当前字符串放入indexArr，continue
        // 如果否：说明是作者姓名，判断字符串长度是否超过限制,否：count+1，当前字符串放入indexArr，continue，是：删除上一次添加的名字，中断循环；如果数组长度是否超出limit，否：count+1，当前字符串放入indexArr，continue，是：中断循环
        let index = author[i]
        if (!postList.includes(index.trim())) {
          // 判断字符串长度是否超过限制或数组的长度是否满足limit
          if (indexArr.join(',').length >= 100) {
            indexArr.splice(indexArr.length - 1 - delLength, delLength + 1)
            break
          }
          if (count >= limit) {
            break
          }
          count++
          // console.log(delLength, 'ss')
          delLength = 0
          indexArr.push(index)
        } else {
          delLength++
          indexArr.push(index)
        }
      }
      if (indexArr.length < author.length) {
        return indexArr.join(',') + '...'
      }
      return indexArr.join(',')
    },
    //防抖函数
    shake(fn, time) {
      if (this.setShake) {
        clearTimeout(this.setShake)
        this.setShake = null
      }
      this.setShake = setTimeout(() => {
        fn()
      }, time)
    },
    onSearch(query) {
      let queryObj = {}
      if (query.toString()) {
        queryObj.query = query.toString()
      }
      if(this.key){
        queryObj.key = this.key;
      }
      // 改为内查询重新跳
      this.$router.push({
        path: '/search',
        query: queryObj,
      })
      // this.current= ['5'],
      // this.current2= '5',
      // this.key= '5',
      // window.location.reload();
      // this.getMenuItem(this.data[0]);
      // this.$forceUpdate();
      this.pageNo = 1
      this.timeFit = ''
      this.requestMsg = {
        articleClassIds: [], //专业领域
        articleFilterIds: [], //文章类型
        articleIds: [], //文章id
        articleTypeIds: [],
        startDate: '', //开始时间
        endDate: '', //结束时间
        isweek: 0, //是否是一周的概述
        month: '', //按月浏览
        // pageNo: 1,
        // pageSize: 20,
        searchInfo: '', //查询信息 文章
        sortType: '', //排序方式
        sourceIds: ['5'], //文章来源id
        status: 0, //译文状态（1：审核 0：发布 9：提交）
        wantsayInfo: '', //查询信息  评述
        year: '', //按年浏览
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      }
      this.requestMsg2 = {
        articleClassIds: [], //专业领域
        articleFilterIds: [], //文章类型
        articleIds: [], //文章id
        articleTypeIds: [],
        startDate: '', //开始时间
        endDate: '', //结束时间
        isweek: 0, //是否是一周的概述
        month: '', //按月浏览
        // pageNo: 1,
        // pageSize: 20,
        searchInfo: '', //查询信息 文章
        sortType: '', //排序方式
        // sourceIds: ['5'], //文章来源id
        status: 0, //译文状态（1：审核 0：发布 9：提交）
        wantsayInfo: '', //查询信息  评述
        year: '', //按年浏览
        haswantsay: 1, //显示述评
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      }
      this.query = query
      this.requestMsg2.wantsayInfo = query
      this.coursexk.searchInfo = query
      this.requestMsg.searchInfo = query
      if (this.key === '1') {
        this.articleList2()
      } else if (this.key === '11') {
        this.selectLessonList()
      } else {
        this.requestMsg.sourceIds = []
        this.requestMsg.sourceIds.push(this.key)
        this.articleList()
      }
    },
  },
  computed: {
    component() {
      if (this.key === '5' || this.key === '6' || this.key === '9' || this.key === '1') {
        var msg = '1'
        return componentOf[msg]
      } else if (this.key === '11') {
        var msga = '4'
        return componentOf[msga]
      } else if (this.key === '7' || this.key === '8') {
        var msgb = '2'
        return componentOf[msgb]
      }
      return ''
    },
  },
}
</script>

<style lang="less" scoped>
.shaixuanContainer {
  background-color: #fff;
  // opacity: 0.9;
}
.search {
  position: relative;
  overflow: hidden;
  .tuBiaoShaiXuan {
    margin-top: 25px;
    padding-left: 5px;
    z-index: 1;
    float: left;
    width: 10%;
  }
}

.header {
  width: 100%;
  height: 196px;
  background: #d9d9d9;
  position: absolute;
}
.MainCenter {
  margin-top: 146px;
}
.mobile {
  .header {
    width: 100%;
    height: 0;
    position: absolute;
    /deep/.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
      height: 40px;
    }
    /deep/.ant-select-auto-complete.ant-select .ant-input {
      height: 40px;
    }
  }
  .MainCenter {
    margin-top: 135px;
  }
}
.section::after {
  content: '';
  display: table;
  clear: both;
}
.section {
  margin: 0;
}
.header_text {
  position: absolute;
  right: 202px;
  bottom: -18px;
  font-size: 18px;
  color: #000;
  line-height: 26px;
}
.menu-item {
  background: rgb(255, 255, 255) !important;
  color: #e52e00 !important;
}
.main {
  width: 100%;
  margin: 64px 0;
  // overflow: hidden;
}
.main_left {
  width: 26%;
  height: 100vh;
  border-right: 1px solid #d4d4d4;
  padding: 0 24px 0 0;
  float: left;
  /deep/.ant-anchor-wrapper {
    overflow: visible;
  }
  /deep/.ant-anchor-ink::before {
    width: 0px;
  }
}
// .main_left > div {
//   margin-bottom: 16px !important;
// }
// .bottom {
//   width: 100%;
//   height: 100px;
//   background: rgb(243, 240, 240);
//   margin-top: 20px;
//   ul {
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//     li {
//       float: left;
//       width: 20%;
//       height: 50px;
//       text-align: center;
//       margin-top: 30px;
//       font-size: 14px;
//       line-height: 25px;
//       border-right: 0.5px solid rgb(116, 116, 116);
//       &:nth-of-type(5) {
//         border-right: none;
//       }
//     }
//   }
// }
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input {
  margin-bottom: 9px;
}
/deep/.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  height: 42px;
  // height: 64px;
  // margin-right: 0;
  // margin-left: 0;
}
/deep/.ant-select-auto-complete.ant-select .ant-input {
  // height: 64px;
  // line-height: 1.5;
  // background: transparent;
  // border-width: 1px;
}
.ant-menu-horizontal {
  border-bottom: none;
}
</style>
<style lang="less">
.searchDrawer {
  .el-drawer__body {
    padding: 10px;
  }
  .van-cell {
    padding: 0;
    .van-cell__title {
      span {
        font-size: 16px;
      }
    }
  }
}
</style>
