<template>
  <div class="">
    <h3 class="sub-title">{{ weeklyTitle }}</h3>
    <el-divider></el-divider>
    <PeriodicalBox
      title="NEJM"
      :list="nejmList"
      :has-more="false"
      @more="onMoreClick('nejm')"
      @item-click="onItemClick"
    />
    <a-divider />
    <!-- <PeriodicalBox
      title="NEJM期刊荟萃"
      :list="nejmGatherList"
      :has-more="false"
      @more="onMoreClick('nejmGather')"
      @item-click="onItemClick"
    /> -->
  </div>
</template>

<script>
import PeriodicalBox from '@/components/index/components/PeriodicalBox.vue'
export default {
  name: 'ArticleDetail',
  components: { PeriodicalBox },
  props: {
    list: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      nejmList: [],
      nejmGatherList: [],
      weeklyTitle: null,
    }
  },
  created() {
    console.log(this.list, 'listlistlistlist')
    this.nejmList = this.list.NEJMList
    this.nejmGatherList = this.list.NEJMArticleList
    this.weeklyTitle = this.list.weeklyTitle
  },
  methods: {
    onMoreClick() {
      this.$router.push('latest-periodical')
    },
    onItemClick(item) {
      console.log(item.permalinks)
      this.$router.push({ path: '/article-info', query: { permalinks: item.permalinks } })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.title {
  margin-bottom: 14px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.sub-title {
  border-radius: 2px;
  border-left: 5px solid @clr-primary;
  color: #333333;
  /*text-align: center;*/
  /*line-height: 2;*/
  padding-left: 10px;
}
</style>
