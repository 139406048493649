<template>
  <div class="container">
    <MainCenter class="mainCenter">
      <div v-if="!$isMobile" class="left">
        <div @click="changeAvatar" style="cursor: pointer">
          <a-avatar class="avatar" :size="124" :src="res.avatar" />
        </div>
        <div>{{ res.truename }}</div>
        <div class="user_vip_info" :class="res.subscribe_info.service_type == '1'&&res.subscribe_info.service_type == '3'?'bg_4E4E4E':'bg_C17F42'" v-if="res.subscribe_info.service_type !='0'"  @click="getVipOrder">
          <div class="user_vip_info_title" >立即续订</div>
          <div class="user_vip_info_time">{{res.subscribe_info.service_end_date }}到期</div>
        </div>
        <div style="width: 220px; height: 1px; background-color: #d4d4d4; margin: 24px 0"></div>
        <div class="userInfo">
          <div @click="changeMobile" style="cursor: pointer;display: flex;align-items: center;">
            <!-- <a-avatar :size="28" style="background-color: #fff; color: #000; border: 1px solid #000; margin-right: 8px"  class="iconfont icon-shouji" /> -->
              <!-- -->
            <img src="../../assets/vip/phone.png" style="width: 30px;height: 30px;margin-right: 8px;"  alt="">
            <span class="telAndEmail">{{ res.mobile }}</span>
          </div>
          <div @click="changeEmail" style="cursor: pointer;display: flex;align-items: center;" class="email">
            <!-- <a-avatar :size="28" style="background-color: #fff; color: #000; border: 1px solid #000; margin-right: 8px" class="iconfont icon-youxiang"
            /> -->
            <!--   -->
              <img src="../../assets/vip/email.png"  style="width: 30px;height: 30px;margin-right: 8px;"  alt="">
            <span class="telAndEmail">{{ res.email }}</span>
          </div>
        </div>
        <el-button class="zhuxiao" type="text" @click="showChangePwd">修改密码</el-button>
        <el-button class="zhuxiao" type="text" style="margin-left: 0" @click="logout">退出登录</el-button>
        <div style="width: 100%; height: 8px; background-color: #f0f2f5; margin-top: 15px"></div>
        <div :class="flag1 === true ? 'btn1' : null">
          <el-button type="text" @click="getData" :class="flag1 === true ? 'btnA' : null" class="btn">
            个人资料
          </el-button>
        </div>
        <div :class="flag2 === true ? 'btn2' : null">
          <el-button type="text" @click="getCollection" :class="flag2 === true ? 'btnB' : null" class="btn">
            我的收藏
          </el-button>
        </div>
        <div :class="flag3 === true ? 'btn3' : null">
          <el-button type="text" @click="getRecord" :class="flag3 === true ? 'btnC' : null" class="btn">
            阅读记录
          </el-button>
        </div>
        <div :class="flag4 === true ? 'btn4' : null">
          <el-button type="text" @click="getVipOrder" :class="flag4 === true ? 'btnD' : null" class="btn">
            订阅记录
          </el-button>
        </div>
      </div>

      <div class="btnContainer" v-else>
        <el-button type="text" @click="getData" :class="flag1 === true ? 'btn10' : null" class="btnFix">
          个人资料
        </el-button>
        <el-button type="text" @click="getCollection" :class="flag2 === true ? 'btn20' : null" class="btnFix">
          我的收藏
        </el-button>
        <el-button type="text" @click="getRecord" :class="flag3 === true ? 'btn30' : null" class="btnFix">
          阅读记录
        </el-button>
        <el-button type="text" @click="getVipOrder" :class="flag4 === true ? 'btn40' : null" class="btnFix">
          订阅记录
        </el-button>
      </div>
      <div :class="$isMobile ? 'rightFix' : 'right'">
        <component :is="component" @editAvatar="editAvatar"></component>
      </div>
      <ChangePwdDialogVue v-if="dialogVisible" :visible.sync="dialogVisible" :ruleForm="ruleForm"></ChangePwdDialogVue>
      <ChangeMobileDialog v-if="mobileVisible" :visible.sync="mobileVisible" :mobileParams="mobileParams"
        @editAvatar="editAvatar"></ChangeMobileDialog>
      <ChangeEmailDialog v-if="emailVisible" :visible.sync="emailVisible" :emailParams="emailParams"
        @editAvatar="editAvatar"></ChangeEmailDialog>
    </MainCenter>
  </div>
</template>
<script>
import MainCenter from '@/components/layout/MainCenter.vue';
import PersonalData from './PersonalData';
import PersonalCollection from './PersonalCollection';
import ReadRecord from './ReadRecord';
import MyVipOrderSubscribe from './MyVipOrderSubscribe';
import ChangePwdDialogVue from '@/components/home/components/ChangePwdDialog.vue'
import ChangeMobileDialog from '@/components/home/components/ChangeMobileDialog.vue'
import ChangeEmailDialog from '@/components/home/components/ChangeEmailDialog.vue'
import { getMemberInfo } from '@/api/personal'
import mrtx from '@/assets/imgs/mrtx.png'
import { userLogout } from '@/api/login'
import { setSessionUser ,logoutLocal} from '@/utils/app-utils'
import { mapMutations } from 'vuex'
import merge from 'webpack-merge'

const componentOf = {
  10: PersonalData,
  20: PersonalCollection,
  30: ReadRecord,
  40: MyVipOrderSubscribe
}

export default {
  name: 'Personal',
  components: { MainCenter, ChangePwdDialogVue, ChangeMobileDialog, ChangeEmailDialog },
  data() {
    return {
      index: 10,
      res: {
        avatar: '',
      }, //返回参数
      flag1: false,
      flag2: false,
      flag3: false,
      flag4: false,
      dialogVisible: false,
      mobileVisible: false,
      emailVisible: false,
      ruleForm: {
        oldPassword: null,
        newPassword: null,
        checknewPassword: null,
      },
      mobileParams: {
        mobile: null,
        code: null,
      },
      emailParams: {
        email: null,
        code: null,
      },
    }
  },
  computed: {
    component() {
      return componentOf[this.index]
    },
  },
  watch: {
    '$route.query': {
      handler() {
        var type = this.$route.query.type
        if (type == 'flag3') {
          this.getRecord()
        } else if (type == 'flag2') {
          this.getCollection()
        } else if (type == 'flag4') {
          this.getVipOrder()
        } else {
          this.getData()
        }
      },
      immediate: true,
    },
  },
  created() {
    this.initialize()
    this.$bus.on('user-change', (user) => {
      this.res = user
    })
  },
  destroyed() {
    this.$bus.off('user-change')
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    async initialize() {
      let res = await getMemberInfo()
      console.log(res,'====用户信息')
      if (res.avatar == null) {
        res.avatar = mrtx
      }
      this.res = res
      // 获取当前时间  
      let now = new Date();  
      // 将指定的日期字符串转换为 Date 对象  
      let endDate = res.subscribePo.serviceEndDate
     if(endDate){
      let specifiedDate = new Date(endDate);  
      // 比较两个日期  
      if (now > specifiedDate) {  
          console.log("会员日期已过期");  
          this.$notify({
              title: '提示消息',
              message: '会员有效期已过期,请及时续费~',
              type: 'warning'
          });
      } else {  
          console.log("会员日期未过期");  
      }
     }
      setSessionUser(res)
    },
    async logout() {
      window.sessionStorage.clear()
      await userLogout({})
      
      logoutLocal()
      // this.$message.success('退出成功')
      this.$emit('update:visible', false)
      localStorage.removeItem('token')
      window.sessionStorage.setItem('token',null)

      this.setUser(null)
      this.$router.push('/index')
    },
    // 个人资料
    getData() {
      //修改原有参数
      this.$router
        .push({
          query: merge(this.$route.query, { type: 'flag1' }),
        })
        .catch((err) => {
          console.log('输出报错', err)
        })
      this.flag1 = true
      this.flag2 = false
      this.flag3 = false
      this.flag4 = false
      this.index = 10
    },
    // 我的收藏
    getCollection() {
      //修改原有参数
      this.$router
        .push({
          query: merge(this.$route.query, { type: 'flag2' }),
        })
        .catch((err) => {
          console.log('输出报错', err)
        })
      this.flag1 = false
      this.flag2 = true
      this.flag3 = false
      this.flag4 = false
      this.index = 20
    },
    // 阅读记录
    getRecord() {
      //修改原有参数
      this.$router
        .push({
          query: merge(this.$route.query, { type: 'flag3' }),
        })
        .catch((err) => {
          console.log('输出报错', err)
        })
      this.flag1 = false
      this.flag2 = false
      this.flag3 = true
      this.flag4 = false
      this.index = 30
    },
    //订阅记录
    getVipOrder() {
      this.$router
        .push({
          query: merge(this.$route.query, { type: 'flag4' }),
        })
        .catch((err) => {
          console.log('输出报错', err)
        })
      this.flag1 = false
      this.flag2 = false
      this.flag3 = false
      this.flag4 = true
      this.index = 40
    },
    editAvatar() {
      this.initialize()
    },
    changeAvatar() {
      this.$bus.emit('change-avatar')
    },
    showChangePwd() {
      this.dialogVisible = true
    },
    changeMobile() {
      this.mobileVisible = true
    },
    changeEmail() {
      this.emailVisible = true
    },
  },
}
</script>
<style lang="less" scoped>
.el-message-box {
  width: 420px;
}

.mobile {
  .container {
    .mainCenter {
      display: block;
    }
  }

  .btnContainer {
    // margin-top: 20px;
    width: 100%;
    // position: fixed;
    display: flex;
    justify-content: center;

    // z-index: 999;
    // opacity: 0.9;
    .btnFix {
      font-size: 16px;
      width: 100px;
      color: #6f6f6f;
      background-color: #f4f4f4;
      // background-color: #fff;
    }

    .btn10,
    .btn20,
    .btn30,
    .btn40 {
      color: #e52e00;
    }
  }
}

.container {
  background-color: #f0f2f5;
  padding: 8px 0px;

  .mainCenter {
    display: flex;
    padding: 0;

    .left {
      width: 288px;
      background-color: #fff;
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 24px;

      .avatar {
        // width: 124px;
        // height: 124px;
        border: 1px solid #e52e00;
        margin-bottom: 16px;
      }
      .bg_4E4E4E{
        background: #4E4E4E;
      }
      .bg_C17F42{
        background: #C17F42;
      }
      .user_vip_info{
        width: 150px;
        height: 36px;
        background: #C17F42;
        border-radius: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 8px;
        gap: 2px;
        cursor: pointer;
        .user_vip_info_title{
          font-family: PingFangSC-Medium;
          font-size: 12px;
          color: #FFF;
          text-align: center;
          font-weight: 500;
        }
        .user_vip_info_time{
          font-family: PingFangSC-Regular;
          font-size: 10px;
          color: #FFF;
          text-align: center;
          font-weight: 400;
        }
      }
      .userInfo {
        display: flex;
        flex-direction: column;

        .telAndEmail {
          font-size: 16px;
          // font-family: PingFangSC, PingFangSC-Regular;
          color: rgba(0, 0, 0, 0.85);
          line-height: 32px;
        }

        .email {
          margin: 16px 0 40px 0;
        }
      }

      .zhuxiao {
        font-size: 16px;
        // font-family: PingFangSC, PingFangSC-Regular;
        color: rgba(0, 0, 0, 0.65);
        line-height: 24px;
      }

      .btn {
        font-size: 20px;
        // font-family: PingFangSC, PingFangSC-Medium;
        color: rgba(0, 0, 0, 0.65);
        line-height: 28px;
      }

      .btnA,
      .btnB,
      .btnC,
      .btnD {
        color: #e52e00;
      }
    }

    .right {
      width: 904px;
      background-color: #fff;
      padding: 24px 53px;
    }

    .rightFix {
      width: 100%;
      background-color: #fff;
      padding: 2px 15px 10px 15px;
    }
  }
}

.btn1 {
  width: 100%;
  border-left: 5px solid red;
  border-right: 5px solid#fceae5;
  text-align: center;
  background-color: rgba(229, 46, 0, 0.1);
}

.btn2 {
  width: 100%;
  border-left: 5px solid red;
  border-right: 5px solid#fceae5;
  text-align: center;
  background-color: rgba(229, 46, 0, 0.1);
}

.btn3 {
  width: 100%;
  border-left: 5px solid red;
  border-right: 5px solid#fceae5;
  text-align: center;
  background-color: rgba(229, 46, 0, 0.1);
}
.btn4 {
  width: 100%;
  border-left: 5px solid red;
  border-right: 5px solid#fceae5;
  text-align: center;
  background-color: rgba(229, 46, 0, 0.1);
}
</style>
