<template>
  <div class="item2">
    <el-form v-if="!$isMobile" :inline="true" :model="form">
      <el-form-item v-if="!$isMobile" class="cardsel">
        <div class="active-card">
          <!-- tab切换 -->
          <!-- <a :href="'/course-resources?type=index'">
            <div :class="chType == 'index' ? 'active-card-2-ck' : 'active-card-2'" class="active-card-2">全部</div>
          </a> -->
          <a @click.prevent="targetSubject('video')">
            <div :class="chType == 'video' ? 'active-card-2-ck' : 'active-card-2'" class="active-card-2">
              NEJM编辑课堂
            </div>
            <!-- <div class="active-card-2 active-card-2-ck">NEJM编辑课堂</div> -->
          </a>
          <a @click.prevent="targetSubject('img')">
            <div :class="chType == 'img' ? 'active-card-2-ck' : 'active-card-2'" class="active-card-2">
              临床研究和实践
            </div>
          </a>
        </div>
      </el-form-item>
      <el-form-item prop="period" label="日期筛选：" class="pcTimeSel">
        <el-date-picker
          size="medium"
          unlink-panels
          v-model="form.period"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyyMMdd"
          format="yyyy-MM-dd"
          @change="onParamChange"
        />
      </el-form-item>
      <div class="bottomLine"></div>
      <!-- <el-form-item prop="subject">
      
      </el-form-item> -->
      <SubjectSelectCard
        :options="chType === 'img' ? subjectLists1 : subjectLists"
        :checkList="checkList"
        @changeCheckList="changeCheckList"
      />
    </el-form>

    <div
      style="
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;
        margin-top: -20px;
      "
      v-else
    >
      <div class="mobileMenuSel">
        <a-button type="link" @click="goback()">
          <a-icon type="left" />
          返回
        </a-button>
        <span class="mobileMenuSeltitle">课程</span>
        <a-button type="link" @click="drawer = true">
          课程筛选
          <a-icon type="right" />
        </a-button>
      </div>
      <el-drawer :visible.sync="drawer" :with-header="false" size="100%">
        <div class="drawerContainer">
          <div class="mobileMenuSel">
            <a-button type="link" @click="drawer = false">
              <a-icon type="left" />
              返回
            </a-button>
            <span class="mobileMenuSeltitle">课程选择</span>
            <a-button type="link">&nbsp;</a-button>
          </div>
          <div class="subjectselect">
            <!-- <a :href="'/course-resources?type=video'"> -->
            <span :class="chType == 'img' ? '' : 'chosedSub'" @click="targetSubject('video')">NEJM编辑课堂</span>
            <!-- </a> -->
            <!-- <a :href="'/course-resources?type=img'"> -->
            <span :class="chType == 'img' ? 'chosedSub' : ''" @click="targetSubject('img')">临床研究和实践</span>
            <!-- </a> -->
            <img src="../../../assets/imgs/mobileselect.jpg" @click="show = true" class="mobileTimer" />
          </div>
          <!-- <van-popup v-model="show" position="bottom" style="height: 350px">
          <van-picker
            title="标题"
            show-toolbar
            :columns="columns"
            :default-index="defaultIndex"
            @confirm="onConfirm"
            @cancel="onCancel"
            @change="onChange"
          />
        </van-popup> -->
          <!-- <van-cell title="日期" :value="timeFit" @click="show = true" /> -->
          <van-calendar :min-date="minDate" :max-date="maxDate" v-model="show" type="range" @confirm="onParamChange" />
          <!-- <a-list bordered :data-source="columns">
        <a-list-item slot="renderItem" slot-scope="item, index">
              <img src="../../../assets/imgs/duihao.png" class="duigou" /> 
              {{ item }}
            </a-list-item>
      </a-list> -->
          <el-checkbox-group v-model="checkList" class="kechensubject" @change="changeCheckList">
            <el-checkbox
              :label="item.id"
              v-for="(item, index) in chType === 'img' ? subjectLists1 : subjectLists"
              :key="index"
            >
              {{ item.classname }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-drawer>
      <!-- <div class="active-card">
        <a :href="'/course-resources?type=index'">
          <div :class="chType == 'index' ? 'active-card-2-ck' : 'active-card-2'" class="active-card-2">全部</div>
        </a>
        <a :href="'/course-resources?type=video'">
          <div :class="chType == 'video' ? 'active-card-2-ck' : 'active-card-2'" class="active-card-2">
            NEJM编辑课堂
          </div>
        </a>
        <a :href="'/course-resources?type=img'">
          <div :class="chType == 'img' ? 'active-card-2-ck' : 'active-card-2'" class="active-card-2">
            临床研究和实践
          </div>
        </a>
      </div> -->
    </div>

    <a-list
      :grid="{ gutter: $isMobile ? 0 : 20, column: $isMobile ? 1 : 3, md: 2, lg: 2, xl: 4 }"
      :data-source="list"
      :pagination="pagination"
      :loading="loadFlag"
    >
      <a-list-item
        :style="
          $isMobile
            ? ''
            : item.index % 3 == 0
            ? 'width: 380px;padding-left: 0px; padding-right: 0px; '
            : 'width: 380px;padding-left: 0px; padding-right: 0px; margin-right: 30px; '
        "
        slot="renderItem"
        slot-scope="item"
      >
        <CourseResourceCard :model="item" @click.native="onItemClick(item)" class="card" />
      </a-list-item>
    </a-list>
    <FreeReadPop
      :subParam="subParam"
      :islogin="islogin"
      v-show="isShow && isFree"
      @close="close"
      @showContent="showContent"
      @content="getContent"
    ></FreeReadPop>
    <noFreeReadPop
      :subParam="subParam"
      :islogin="islogin"
      v-if="isShow && subscribe_info.service_type == 0"
    ></noFreeReadPop>
    <!--没有免费次数-->
    <!-- <nologinPop v-if="!isShow" @login="login"></nologinPop> -->
  </div>
</template>

<script>
import CourseResourceCard from '@/components/article/course-resources/CourseResourceCard.vue'
import SubjectSelectCard from '@/components/form/select/SubjectSelectCard.vue'
import { selectLessonList, selectLessonListCountUsingPOST } from '@/api/course'
import { selectArticleClass } from '@/api/article'
import { outlinkwithUser } from '@/api/login'
import { PATH_COURSE_VIDEO, PATH_MEETING_REVIEW, PATH_SERIES_COURSES } from '@/router/article/paths'
import { getSessionUser, setSessionUser } from '@/utils/app-utils'
import { format } from '@/utils/time-utils'
import moment from 'moment'
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'
import { loginWithoutPwdwithQueryload } from '@/api/login'
import { getMemberInfo } from '@/api/personal'
import FreeReadPop from '@/components/article/popup/FreeReadPopup_video.vue'
// import nologinPop from '@/components/article/popup/nologinPopup.vue';
import noFreeReadPop from '@/components/article/popup/noFreeReadPopup.vue'

export default {
  name: 'CourseResourceCardList',
  components: {
    CourseResourceCard,
    SubjectSelectCard,
    FreeReadPop,
    // nologinPop,
    noFreeReadPop,
  },
  props: {
    params: {
      type: Object,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFree: false,
      isShowFree: false,
      noFree: false,
      islogin: false,
      usefreeCont: false, ///////使用免费次数之后
      subParam: {
        is_subscribe: '', //是否付费文章（1：是；2：否）
        is_have_view_auth: '', //1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
        is_month_chance: '', //未付费或者已付费但是会员到期的用户，每个用户每个月有一次免费的机会 1：表示无机会；2：表示有机会
      }, //会员信息
      subscribe_info: {
        service_type: '',
        service_end_date: '',
      }, //会员信息
      videoparam: {
        is_use: false,
      },
      isClose: false,
      loadFlag: true,
      dialogVisible: false,
      checkList: [],
      drawer: false,
      active: 'first',
      list: [],
      pagination: {
        onChange: this.onPageChange,
        showSizeChanger: false,
        size: 'small',
        page: 1,
        pageSize: 9,
        total: 0,
      },
      form: {
        period: null,
        subject: null,
      },
      show: false,
      columns: [],
      subjects: [],
      subjectsAllow: [],
      subjectsAllow1: [],
      subjectLists: [],
      subjectLists1: [],
      defaultIndex: 1,
      subjectName: '选择学科',
      periodTxt: null,
      periodTxt2: null,
      dateShow: false,
      currentDate: null,
      dateShowType: 1,
      subjectSerType: [1, 2],
      chType: this.type,
      minDate: new Date(2010, 12, 1),
      maxDate: new Date(),
      timeFit: '',
      memberId: null,
      VideoData: {},
    }
  },
  filters: {
    formatDateTxt(time, type) {
      if (time == null) {
        if (type == 1) {
          return '起始月份'
        } else {
          return '结束月份'
        }
      }

      return format(time, 'YYYY年MM月')
    },
  },
  watch: {
    type: {
      handler() {
        this.pagination.page = 1
        // this.selectLessonList()
      },
      immediate: true,
    },
    '$route.query': {
      handler() {
        if (this.$route.query.startDate && this.$route.query.endDate) {
          this.form.period = [this.$route.query.startDate, this.$route.query.endDate]
          this.timeFit = `${this.formatDate(
            new Date(moment(this.$route.query.startDate).format('YYYY-MM-DD'))
          )} - ${this.formatDate(new Date(moment(this.$route.query.endDate).format('YYYY-MM-DD')))}`
        }
        if (this.$route.query.articalClassIds) {
          for (let i in this.$route.query.articalClassIds.split(',')) {
            !this.checkList.includes(parseInt(this.$route.query.articalClassIds.split(',')[i])) &&
              this.checkList.push(parseInt(this.$route.query.articalClassIds.split(',')[i]))
          }
        }
      },
      immediate: true,
    },
    isShow: function (newValue, oldValue) {
      console.log(newValue, oldValue)
      console.log(this.isShow, 'isShow')
      if (this.isShow) {
        this.getUseinfo()
        // this.getArticleInfo()
      }
    },
  },
  async created() {
    this.memberId = Number(window.sessionStorage.getItem('memberId'))
    if (this.isShow) {
      this.islogin = true
      console.log('1')
    } else {
      this.islogin = false
    }
    this.selectLessonList()
    await this.loadSubjects()
    await this.loadSubjectsAllow()
  },
  mounted() {
    if (this.$route.query.nejmjmedssodata) {
      console.log(this.$route.query.nejmjmedssodata)
      let vm = this
      loginWithoutPwdwithQueryload({ data: this.$route.query.nejmjmedssodata })
        .then((res) => {
          console.log(res)
          if (res) {
            vm.setUser(res)
            console.log(res, '====用户信息')
            vm.subscribe_info = res.subscribe_info
            vm.subscribe_info.service_type = res.subscribe_info.service_type
            setSessionUser(res)
            window.sessionStorage.setItem('memberId', res.id)
          }
        })
        .catch((err) => {
          console.log(err)
          vm.$nextTick(() => {
            vm.$bus.emit('login')
          })
        })
    }
    //是否登录。登录查询用户信息
    if (this.isShow) {
      this.initialize()
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isShow() {
      return !!this.user
    },
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    async initialize() {
      let res = await getMemberInfo()
      // console.log(res,'====用户信息')
      this.subscribe_info = res.subscribe_info || {}
      this.subscribe_info.service_type = res.subscribe_info.service_type || ''
    },
    showContent(e) {
      this.usefreeCont = e
    },
    getArticleInfo() {
      console.log(111)
    },
    getContent(e) {
      console.log(e, '文章content')
      this.data.content = e
      this.videoparam.content = e
      this.videoparam.is_use = true
    },
    openVip() {
      this.$router.push({ path: '/personal', query: { type: 'flag4' } }).catch((err) => {
        console.log('输出报错', err)
      })
    },
    close(e) {
      console.log(e, '=====e')
      this.flag2 = false
      this.isFree = e.a
      //点击使用后
      if (e.b == '1') {
        if (this.VideoData.outsideChain) {
          outlinkwithUser().then((res) => {
            if (this.VideoData.outsideChain.lastIndexOf('?') > -1) {
              window.open(`${this.VideoData.outsideChain}&nejmjmedssodata=${res.result}`)
            } else {
              window.open(`${this.VideoData.outsideChain}?nejmjmedssodata=${res.result}`)
            }
          })
          return
        }
        if (this.VideoData.subjectSerType == 1) {
          //单课程
          this.$router.push({
            path: PATH_COURSE_VIDEO + '?id=' + this.VideoData.lessonResId + '&param=' + this.videoparam,
          })
          // this.$router.push({
          //   name: '课程视频',
          //   params: { id: row.id },
          // })
        } else if (this.VideoData.subjectSerType == 3) {
          //会议回顾
          this.$router.push({
            path: PATH_MEETING_REVIEW + '?id=' + this.VideoData.lessonResId + '&param=' + this.videoparam,
          })
        } else if (this.VideoData.subjectSerType == 2) {
          //多课程、论坛
          this.$router.push({
            path: PATH_SERIES_COURSES + '?id=' + this.VideoData.lessonResId + '&param=' + this.videoparam,
          })
        } else if (this.VideoData.subjectSerType == 4) {
          //临床研究单课程
          this.$router.push({
            path: PATH_COURSE_VIDEO + '?id=' + this.VideoData.lessonResId + '&param=' + this.videoparam,
          })
        } else if (this.VideoData.subjectSerType == 5) {
          //临床研究系列课程
          this.$router.push({
            path: PATH_SERIES_COURSES + '?id=' + this.VideoData.lessonResId + '&param=' + this.videoparam,
          })
        }
      }
      // this.isClose = e.b
    },
    //关闭弹窗
    closePop(e) {
      console.log(e, '使用次数后')
      this.isClose = e
    },
    login() {
      // this.$emit('login')
      this.$bus.emit('login')
    },
    async getUseinfo() {
      let res = await getMemberInfo()
      console.log(res, '====用户信息')
      window.sessionStorage.setItem('memberId', res.id)
      this.memberId = res.id
      this.userInfo.truename = res.membername
      this.subscribe_info.service_type = res.subscribe_info.service_type
    },
    closeDialog() {
      this.dialogVisible = false
    },
    openDialog() {
      this.dialogVisible = true
    },
    goback() {
      this.$router.push({ path: '/' })
    },
    changeCheckList(val) {
      let query = { ...this.$route.query }
      this.checkList = val
      if (this.checkList.toString()) {
        query.articalClassIds = this.checkList.toString()
      } else {
        delete query.articalClassIds
      }
      this.$router.push({
        path: '/course-resources',
        query: query,
      })
      this.selectLessonList()
    },
    changeType(type) {
      this.chType = type
      // tab切换
      let query = {}
      query.type = this.chType
      this.$router.replace({ path: '/course-resources', query: query })
      this.$router.push({ path: '/course-resources', query: query, replace: true })
    },
    onItemClick(item) {
      console.log(item, '====视频文章item')
      this.VideoData = item
      let loginUser = getSessionUser()
      // this.flag2 =  item.is_have_view_auth == '1'?true:false
      if (item.ifViewAuth == 1) {
        if (null == loginUser) {
          this.$message.warning('登录即可观看')
          this.$bus.emit('login')
          return
        }
        if (loginUser.truename == null) {
          this.$message.warning('需要资料补齐才能浏览')
          return
        }
      }
      if (item.ifViewAuth == 2) {
        if (null == loginUser) {
          this.$message.warning('登录即可观看')
          this.$bus.emit('login')
          return
        }
      }
      if (item.ifViewAuth == 2) {
        if (null == loginUser) {
          this.$message.warning('登录即可观看')
          this.$bus.emit('login')
          return
        }
      }
      // this.subParam.articleId = item.lessonResId
      // this.noFree = item.is_subscribe == '1' ? true:false
      // this.isFree = item.is_subscribe == '1' ? true:false
      // this.subParam.is_subscribe = item.is_subscribe//是否付费文章（1：是；2：否）
      // this.subParam.is_have_view_auth= item.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
      // this.subParam.is_month_chance = item.is_month_chance
      this.$forceUpdate()
      if (item.outsideChain) {
        outlinkwithUser().then((res) => {
          if (item.outsideChain.lastIndexOf('?') > -1) {
            window.open(`${item.outsideChain}&nejmjmedssodata=${res.result}`)
          } else {
            window.open(`${item.outsideChain}?nejmjmedssodata=${res.result}`)
          }
        })
        return
      }
      if (item.subjectSerType == 1) {
        //单课程
        this.$router.push({ path: PATH_COURSE_VIDEO + '?id=' + item.lessonResId })
      } else if (item.subjectSerType == 3) {
        //会议回顾
        this.$router.push({ path: PATH_MEETING_REVIEW + '?id=' + item.lessonResId })
      } else if (item.subjectSerType == 2) {
        //多课程、论坛
        this.$router.push({ path: PATH_SERIES_COURSES + '?id=' + item.lessonResId })
      } else if (item.subjectSerType == 4) {
        //临床研究单课程
        this.$router.push({ path: PATH_COURSE_VIDEO + '?id=' + item.lessonResId })
      } else if (item.subjectSerType == 5) {
        //临床研究系列课程
        this.$router.push({ path: PATH_SERIES_COURSES + '?id=' + item.lessonResId })
      }
    },
    async onParamChange(val) {
      console.log(val, 'val')
      let query = { ...this.$route.query }
      // if(this.form.period && this.form.period.length > 0){
      //   query.startDate =  moment(this.form.period[0]).format('YYYYMMDD')
      //   query.endDate = moment(this.form.period[1]).format('YYYYMMDD')
      // }
      if (this.form.period == null) {
        this.form.period = []
      }
      if (val != null) {
        query.startDate = this.form.period[0] = format(val[0], 'YYYYMMDD')
        query.endDate = this.form.period[1] = format(val[1], 'YYYYMMDD')
      } else {
        this.form.period[0] = ''
        this.form.period[1] = ''
        delete query.startDate
        delete query.endDate
      }
      // if (this.$isMobile) {
      //   const [start, end] = val
      //   this.show = false
      //   this.timeFit = `${this.formatDate(start)} - ${this.formatDate(end)}`
      // }
      this.$router.push({
        path: '/course-resources',
        query: query,
      })
      await this.selectLessonList()
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    onPageChange(page) {
      this.pagination.page = page
      this.onParamChange()
    },
    targetSubject(val) {
      console.log(val)
      this.checkList = []
      let query = { ...this.$route.query }
      this.chType = val
      delete query.articalClassIds
      query.type = this.chType
      this.$router.push({
        path: '/course-resources',
        query: query,
      })
      // this.loadSubjectsAllow();
      this.selectLessonList()
    },
    async selectLessonList() {
      // tab切换
      if (this.chType === 'img') {
        this.subjectSerType = [4, 5]
      } else if (this.chType === 'video') {
        this.subjectSerType = [1, 2]
      } else {
        this.subjectSerType = [1, 2, 4, 5]
      }
      let res = await selectLessonList({
        endDate: this.form.period != null ? this.form.period[1] : null,
        //todo 暂时注释
        lessonStatus: 1,
        pageNo: this.pagination.page,
        pageSize: this.pagination.pageSize,
        startDate: this.form.period != null ? this.form.period[0] : null,
        //todo 暂时注释
        subjectSerType: this.subjectSerType,
        articalClassId: this.form.subject,
        sortType: 3,
        articalClassIds: this.checkList,
        memberId: this.memberId,
      })
      let arr = []
      let index = 1
      for (const temp of res.results) {
        arr.push({
          ...temp,
          index: index,
        })
        index++
      }
      this.list = arr
      this.pagination.total = res.page.total
      this.loadFlag = false
    },
    showPopup() {
      this.show = true
    },
    async onConfirm(value, index) {
      this.defaultIndex = index
      this.subjectName = this.subjects[index].classname
      this.form.subject = this.subjects[index].id
      await this.selectLessonList()
      this.show = false
    },
    onChange(picker, value, index) {
      console.log(`当前值：${value}, 当前索引：${index}`)
    },
    onCancel() {
      this.show = false
    },
    async loadSubjects() {
      let res = await selectArticleClass({})
      // let arr = []
      // for (const temp of res) {
      //   arr.push(temp.classname)
      // }
      // this.columns = arr
      this.subjects = res

      // this.subjects.push(...res);// 复制一份创造一个假的多数据
    },
    async loadSubjectsAllow() {
      let res = await selectLessonListCountUsingPOST({ lessonStatus: 1, sortType: 3, subjectSerType: [1, 2] })
      this.subjectsAllow = res
      if (this.subjectsAllow.length > 0 && this.subjects.length > 0) {
        var newArr = []
        this.subjects.filter((item) => {
          var index = this.subjectsAllow.findIndex((i) => {
            return i.articalClassId == item.id
          })
          if (index >= 0) {
            newArr.push({ ...item, ...this.subjectsAllow[index] })
          }
        })
        this.subjectLists = newArr
      } else {
        this.subjectLists = []
      }
      let res1 = await selectLessonListCountUsingPOST({ lessonStatus: 1, sortType: 3, subjectSerType: [4, 5] })
      this.subjectsAllow1 = res1
      if (this.subjectsAllow1.length > 0 && this.subjects.length > 0) {
        var newArr1 = []
        this.subjects.filter((item) => {
          var index = this.subjectsAllow1.findIndex((i) => {
            return i.articalClassId == item.id
          })
          if (index >= 0) {
            newArr1.push({ ...item, ...this.subjectsAllow1[index] })
          }
        })
        this.subjectLists1 = newArr1
      } else {
        this.subjectLists1 = []
      }
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    async dateOnConfirm() {
      if (this.form.period == null) {
        this.form.period = []
      }
      if (this.dateShowType == 1) {
        this.periodTxt = this.currentDate
        this.form.period[0] = format(this.currentDate, 'YYYYMMDD')
      } else {
        this.periodTxt2 = this.currentDate
        this.form.period[1] = format(this.currentDate, 'YYYYMMDD')
      }
      await this.selectLessonList()
      this.dateShow = false
    },
    showDatePick(type) {
      this.dateShowType = type
      this.dateShow = true
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';
/deep/.ant-row {
  margin: 0 !important;
  padding: 0px;
}
.card {
  // border: 1px solid @clr-border;
}
.active-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  height: 33px;
  margin-top: -1px;
}
.cardsel {
  height: 46px;
  margin-bottom: 0 !important;
}
.bottomLine {
  width: 1200px;
  height: 1px;
  background-color: #ccc;
  margin-bottom: 16px;
  margin-top: -6px;
}
.item2 /deep/.ant-list-pagination {
  text-align: right;
}
.item2 > form > .el-row {
  min-height: 196px;
}
.pcTimeSel {
  height: 46px;
  float: right;
  margin-bottom: 0 !important;

  /deep/label {
    line-height: 42px;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
  }
  /deep/.el-form-item__content {
    margin-top: 4px;
  }
  /deep/.el-range-editor--small.el-input__inner {
    height: 50px;
  }
  /deep/.el-range-editor--small .el-range-separator {
    line-height: 48px;
  }
  /deep/.el-input__inner {
    border: 1px solid rgba(102, 102, 102, 1);
  }
  /deep/.el-range-editor--small .el-range-input {
    font-size: 20px;
    font-weight: 400;
    color: #999999;
  }
}
.active-card-1 {
  width: 50%;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  border: 1px solid rgba(0, 0, 0, 0.45);
  border-right: none;
  border-radius: 4px 0px 0px 4px;
}
.active-card-1-ck {
  width: 50%;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border: 1px solid #a1224e;
  border-right: none;
  border-radius: 4px 0px 0px 4px;
  color: #a1224e;
}
.active-card-2 {
  cursor: pointer;
  width: 160px;
  height: 42px;
  line-height: 42px;
  // margin-right: 64px;
  background: #ffffff;
  border: 1px solid rgba(102, 102, 102, 1);
  border-radius: 4px;
  font-family: MicrosoftYaHei;
  font-size: 20px;
  text-align: center;
  color: #333333;
  font-weight: 400;
}
.active-card-2-ck {
  cursor: pointer;
  width: 160px;
  height: 42px;
  line-height: 42px;
  background: #e52e00;
  border-radius: 4px;
  border: none;
  text-align: center;
  font-family: MicrosoftYaHei-Bold;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
}
.mobile {
  .mobileMenuSel {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 44px;
    line-height: 44px;
    font-size: 18px;
    border-bottom: 1px solid #ccc;
    .mobileMenuSeltitle {
      font-weight: 500;
      font-family: PingFangSC-Medium;
    }
    /deep/.ant-btn-link {
      height: 44px;
      font-size: 16px;
      color: #1e4ea6;
    }
    /deep/.ant-btn > .anticon + span,
    .ant-btn > span + .anticon {
      margin: 0;
    }
  }
  .subjectselect {
    height: 42px;
    line-height: 42px;
    padding-left: 15px;
    padding-right: 24px;
    position: relative;
    span {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      font-weight: 400;
      margin-right: 15px;
      display: inline-block;
    }
    .mobileTimer {
      display: inline-block;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 15px;
      right: 14px;
    }
  }
  .chosedSub {
    color: #1e4ea6;
    border-bottom: 2px solid #1e4ea6;
  }
  .kechensubject {
    border-top: 1px solid #dddddd;
    /deep/.el-checkbox {
      width: 100%;
      height: 45px;
      line-height: 45px;
      margin-right: 0px;
      padding-left: 15px;
      border-bottom: 1px solid #dddddd;
      .el-checkbox__inner {
        width: 22px;
        height: 22px;
        border-radius: 50%;
      }
      .el-checkbox__inner::after {
        border-width: 2px;
        height: 12px;
        left: 6px;
        top: 1px;
        width: 6px;
      }
      .el-checkbox__label {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        font-weight: 400;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #1e4ea6;
        border-color: #1e4ea6;
      }
      .el-checkbox__inner:hover {
        border-color: #1e4ea6;
      }
      .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #1e4ea6;
      }
    }
  }
  .duigou {
    width: 22px;
    height: 22px;
  }
  .item2 {
    margin-top: 40px;
    /deep/.ant-list-pagination {
      text-align: center;
    }
  }
  .active-card {
    margin-bottom: 20px;
    .active-card-2 {
      line-height: 32px;
      padding: 0 15px;
      margin-right: 10px;
    }
  }
}
</style>
