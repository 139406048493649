const KEY_TOKEN = 'app_token'
const KEY_USER = 'app_user'

/**
 * 获取session中保存的token信息
 * @returns {string}  token信息
 */
export function getSessionToken() {
  let expireTime = localStorage.getItem('EXPIRETIME')
  // console.log('expireTime:', expireTime)
  let nowTime = new Date().getTime()
  if (nowTime - expireTime > 6 * 60 * 60 * 1000) {
    //超时6小时过期
    return null
  }
  return localStorage.getItem(KEY_TOKEN)
}

/**
 * 设置token信息到session
 * @param token
 */
export function setSessionToken(token) {
  localStorage.setItem('EXPIRETIME', new Date().getTime())
  localStorage.setItem(KEY_TOKEN, token)
}

/**
 * 获取session中保存的用户信息
 * @returns {any} 用户信息
 */
export function getSessionUser() {
  let expireTime = localStorage.getItem('EXPIRETIME')
  // console.log('expireTime:', expireTime)
  let nowTime = new Date().getTime()
  if (nowTime - expireTime > 6 * 60 * 60 * 1000) {
    //超时6小时过期
    return null
  }
  return JSON.parse(localStorage.getItem(KEY_USER))
}

/**
 * 设置用户信息到session
 * @param user  用户信息
 */
export function setSessionUser(user) {
  localStorage.setItem('EXPIRETIME', new Date().getTime())
  localStorage.setItem(KEY_USER, JSON.stringify(user))
}

/**
 * 本地注销
 */
export function logoutLocal() {
  localStorage.removeItem('EXPIRETIME')
  localStorage.removeItem(KEY_TOKEN)
  localStorage.removeItem(KEY_USER)
}
