import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // 登录账号
    user: null,
    isMobile: false,
    isMobile1: false,
    isMobile2: false,
  },
  getters: {},
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setIsMobile(state, payload) {
      state.isMobile = payload
    },
    setIsMobile1(state, payload) {
      state.isMobile1 = payload
    },
    setIsMobile2(state, payload) {
      state.isMobile2 = payload
    },
  },
  actions: {},
})

export default store

resize()

window.addEventListener('resize', resize)

function resize() {
  let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  if (width < 1080) {
    document.body.classList.add('mobile')
    store.commit('setIsMobile', true)
  } else {
    document.body.classList.remove('mobile')
    store.commit('setIsMobile', false)
  }
  if (width < 992) {
    // document.body.classList.add('mobile')
    store.commit('setIsMobile1', true)
  } else {
    // document.body.classList.remove('mobile')
    store.commit('setIsMobile1', false)
  }
  if (width < 768) {
    // document.body.classList.add('mobile')
    store.commit('setIsMobile2', true)
  } else {
    // document.body.classList.remove('mobile')
    store.commit('setIsMobile2', false)
  }
}
