import http from '@/http/index'

/**
 * 登录
 * @param params：{
 *}
 */
export function login(params) {
  return http.post('/api/login/login', params)
}
/**
 * 外部链接登录
 * @param params：{
 *}
 */
export function loginWithoutPwd(params) {
  return http.post('/api/login/loginWithoutPwd', params)
}

/**
 * 外部链接登录2,用地址传参获取用户信息；上个外部链接无用户信息登录为空用户
 * @param params：{
 *}
 */
export function loginWithoutPwdwithQueryload(params) {
  const encodedUrl = encodeURIComponent(params.data);
  params.data = encodeURIComponent(params.data)
  return http.post(`/api/authLogin/login?data=${encodedUrl}`, params)
}
export function outlinkwithUser(params) {
  console.log(params);
  return http.get(`/api/authLogin/getToken`, params)
}

/**
 * 发送图片验证码
 * @param params：{
 *}
 */
export function sendimgCode(params) {
  return http.get('/api/captchaImage', params)
}
/**
 * 注销
 * @param params：{
 *}
 */
export function userLogout(params) {
  return http.post('/api/login/userLogout', params)
}
/**
 * 发送验证码
 * @param params：{
 *}
 */
export function sendCode(params) {
  return http.post('/api/base/sendCode', params)
}
/**
 * 发送验证码3
 * @param params：{
 *}
 */
export function sendCode3(params) {
  return http.post('/api/base/sendCode3', params)
}
/**
 * 发送验证码
 * @param params：{
 *}
 */
export function checkCode(params) {
  return http.post('/api/base/checkCode', params)
}
/**
 * 忘记密码(邮箱)
 * @param params：{
 *}
 */
export function forgetPasswordEmail(params) {
  return http.post('/api/user/forgetPasswordEmail', params)
}
/**
 * 忘记密码(手机号)
 * @param params：{
 *}
 */
export function forgetPasswordPhone(params) {
  return http.post('/api/user/forgetPasswordPhone', params)
}
/**
 * 注册用户
 * @param params：{
 *}
 */
export function register(params) {
  return http.post('/api/user/register', params)
}
/**
 * 完善用户信息
 * @param params：{
 *}
 */
export function completeUserInfo(params) {
  return http.post('/api/user/completeUserInfo', params)
}

/**
 * 微信打开获取签名
 * @param params：{
 *}
 */
export function wechatsignature(params) {
  return http.post(`/api/wechat/getSignature`, params)
  // return http.get(`/api/wechat/getJsapiTicket`, params)
}
/**
 * 微信跳转判断是否有手机号绑定
 * @param params：{
 *}
 */
export function wechatJudgeBind(params) {
  return http.get(`/api/wechat/checkCode?code=${params.code}&iswx=${params.iswx}`, params)
}

/**
 * 微信手机号绑定
 * @param params：{
 *}
 */
export function wxlogin(params) {
  return http.get(`/api/wechat/wxlogin?code=${params.code}&phone=${params.phone}&verifyCode=${params.verifyCode}&iswx=${params.iswx}`, params)
}

/**
 * 获取城市信息
 * @param params：{
 *}
 */
export function get_city(params) {
  return http.get(`/api/jhRegion/getTree`, params, false)
}

// 无code返回的请求,用catch捕获
/**
 * 获取医院信息
 * @param params：{
 *}
 */
export function get_hospital(params) {
  return http.post(`/api/jhHospital/selectAll?p_code=${params.p_code}&c_code=${params.c_code}&a_code=${params.a_code}`, params)
}

// 无code返回的请求,用catch捕获
/**
 * 获取科室信息
 * @param params：{
 *}
 */
export function get_division(params) {
  return http.get(`/api/jhDepartment/getTree`, params, false)
  // return http.post(`/api/jhDepartment/get?p_code=${params.p_code}&c_code=${params.c_code}&a_code=${params.a_code}`, params)
}

/**
 * 获取学校信息
 * @param params：{
 *}
 */
export function get_university(params) {
  return http.post(`/api/jhUniversity/selectAll?p_code=${params.p_code}&c_code=${params.c_code}`, params)
}
