// 专业领域
export function getProfessional(code) {
  let types = {
    10: '心脑血管疾病',
    20: '肿瘤',
    30: '糖尿病',
    40: '呼吸系统疾病',
    50: '妇产科和儿科',
    60: '其他',
    70: '传染病',
  }
  return types[code]
}

export function getProfessional2(code) {
  let types = {
    10: 'Cardiovascular & Cerebrovascular Disease',
    20: 'Oncology',
    30: 'Diabetes',
    40: 'Pulmonary Disease',
    50: 'Ob/Gyn & Pediatrics',
    60: 'Other Medical Specialties',
    70: 'Infectious Diseases',
  }
  return types[code]
}

// 文章分类Id
export function getArticleClassIds(code) {
  let types = {
    10: 4,
    20: 2,
    30: 3,
    40: 7,
    50: 8,
    60: 1,
    70: 9,
  }
  return types[code]
}

// 改变匹配字符串并修改颜色
export function replaceHandle(str, query) {
  let res = new RegExp('(' + query + ')', 'g')
  str = str.replace(res, "<span style='color:#e52e00'>" + query + '</span>')
  return str
}
