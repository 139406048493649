<template>
  <MainCenter>
    <Article :article="{ type: 12 }"></Article>
  </MainCenter>
</template>

<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import Article from '@/components/article/Article.vue'
export default {
  name: 'LatestPeriodical',
  components: { Article, MainCenter },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped></style>
