import { logoutLocal, setSessionToken } from '@/utils/app-utils.js'
import axios from 'axios'
import { Message } from 'element-ui'
import Vue from 'vue'
import { configInterceptors } from './config-interceptors'
import { saveAs } from 'file-saver'

const axiosInstance = axios.create({})
configInterceptors(axiosInstance)

const index = {}

index.request = (config, interceptError) => request('request', null, null, config, interceptError)
index.get = (url, config, interceptError) => request('get', url, null, config, interceptError)
index.delete = (url, config, interceptError) => request('delete', url, null, config, interceptError)
index.head = (url, config, interceptError) => request('head', url, null, config, interceptError)
index.options = (url, config, interceptError) => request('options', url, null, config, interceptError)
index.post = (url, data, config, interceptError) => request('post', url, data, config, interceptError)
index.put = (url, data, config, interceptError) => request('put', url, data, config, interceptError)
index.patch = (url, data, config, interceptError) => request('patch', url, data, config, interceptError)
index.download = (url, data, config, interceptError) =>
  request('post', url, data, { ...config, responseType: 'blob', isDownload: true }, interceptError)
index.all = axios.all
index.spread = axios.spread

function request(reqMethod, url, data, config, interceptError = true) {
  let req

  config = { ...config, interceptError }

  switch (reqMethod) {
    case 'request':
      req = axiosInstance[reqMethod](config)
      break
    case 'get':
    case 'delete':
    case 'head':
    case 'options':
      req = axiosInstance[reqMethod](url, config)
      break
    case 'post':
    case 'put':
    case 'patch':
      req = axiosInstance[reqMethod](url, data, config)
      break
    default:
      Message.error('请求方式不支持！')
      return
  }
  return req
    .catch((e) => {
      if (e && e.message === 'Network Error') {
        Message.error('网络错误！')
      } else {
        console.log(e)
        Message.error('网络繁忙，请稍后！')
      }
    })
    .then(async (res) => {
      // 请求配置
      let reqConfig = res.config
      // 后端返回的消息体
      let serverRes
      if (res && String(res.status).startsWith('2')) {
        let contentType = res.headers['content-type']
        if (reqConfig.isDownload) {
          let blobData = new Blob([res.data])
          if (contentType.toUpperCase().indexOf('STREAM') !== -1) {
            // 返回的是流
            let filename = res.headers['content-disposition'].replace(/\w+;filename=(.*)/i, '$1')
            saveAs(blobData, decodeURI(filename))
            return res
          } else {
            res.data = JSON.parse(await blobData.text())
          }
        }
        serverRes = res.data
        if (serverRes) {
          let code = serverRes.code
          if (code === 0) {
            // 请求成功，并且后端响应正确，直接取出业务数据
            if (serverRes.token) {
              // console.log('serverRes.token',serverRes.token)
              window.sessionStorage.setItem('token', serverRes.token)
              window.localStorage.setItem('token', serverRes.token)
              setSessionToken(serverRes.token)
            }
          } else if (code === 10002) {
            // token过期，重新登录
            Message.error('登录超时，请重新登录')
            // 本地注销
            logoutLocal()
            // 跳转到登录页面
            Vue.prototype.$bus.emit('login')
            return Promise.reject(serverRes)
          } else if (code !== 0 && reqConfig.interceptError) {
            // 统一处理业务错误
            Message.error(serverRes.message || serverRes.data || '网络繁忙，请稍后！')
            return Promise.reject(serverRes)
          } else {
            // 其他情况，进入错误处理
            return Promise.reject(serverRes)
          }
        }
      }
      return serverRes.data
    })
}

export default index
