<template>
  <!-- 课程 -->
  <div>
    <a-spin :spinning="loading">
      <div class="main">
        <div class="main2">
          <div class="content" v-for="item in msg ? msg.results : []" :key="item.id">
            <el-tag color="#e52e00" class="elTag">视频</el-tag>
            <!-- <a @click="resource(item.lessonResId)"><el-image :src="item.thumb" fit="cover"></el-image></a> -->
            <a @click="resource(item.lessonResId)">
              <el-image
                :src="
                  item.coverPc != ''
                    ? item.coverPc[0].url
                    : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg08.viwik.com%2Fimages%2F20180820%2Ftooopen_sy_154340434039264.jpg&refer=http%3A%2F%2Fimg08.viwik.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1634519796&t=256bfdfa3d04ce6d27214dd3de65664c'
                "
                fit="cover"
              ></el-image>
            </a>
            <div class="bianKuangContainer">
              <a class="title" @click="resource(item.lessonResId)">
                <!-- {{ item.title }} -->
                <RichText :content="item.title | replaceHandle(query)" />
              </a>
              <div class="xueKeTime">
                <div class="createTime">{{ item.createTime }}</div>
                <div class="className" v-for="(className, index) in item.classNames" :key="index">
                  <a @click.prevent="onItemClick" class="biaoqian">{{ className }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="length == '0' && !loading" style="display: flex; justify-content: center">
        <el-image :src="nulldata" fit="cover"></el-image>
      </div>
    </a-spin>
    <div class="footer" v-if="length != '0' && !loading">
      <div style="display: flex">
        <a-button type="link" @click="onShowSizeChange(1)">首页</a-button>
        <a-pagination
          v-model="pageNo"
          :page-size="20"
          show-size-changer
          :page-size-options="['10', '20', '50', '100']"
          :total="msg ? msg.page.total : 0"
          show-less-items
          @change="onShowSizeChange"
        />
        <a-button type="link" @click="onShowSizeChange(msg.page.pages)">尾页</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import nulldata from '@/assets/imgs/nulldata.png'
import RichText from '@/components/layout/RichText.vue'
import { replaceHandle } from '@/filters'
const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}
export default {
  name: 'SearchResource',
  components: { RichText },
  props: {
    msg: {
      type: Object,
      default: function () {
        return null
      },
    },
    query: {
      type: String,
      default() {
        return ''
      },
    },
    pageNo: {
      type: Number,
      default() {
        return 1
      },
    },
    loading: {},
  },
  data() {
    return {
      // pageNo: 1,
      length: 0,
      nulldata,
    }
  },
  created() {
    this.$bus.on('selectLessonListAmount', (res) => {
      console.log(res.length)
      this.length = res.length
    })
  },
  filters: { replaceHandle },
  methods: {
    onItemClick(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    onShowSizeChange(page) {
      this.pageNo = page
      this.$emit('paging', page)
    },
    resource(id) {
      this.$router.push({ path: '/course-video', query: { id: id } })
    },
  },
}
</script>

<style lang="less" scoped>
.mobile {
  .main {
    padding: 0 15px;
    .main2 {
      .content {
        width: 46%;
        margin: 2% !important;
        .el-image {
          width: 100%;
          height: 216.33px;
        }
        .bianKuangContainer {
          .title {
          }
          .xueKeTime {
            .createTime {
            }
            .className {
              .biaoqian {
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .mobile {
    .main {
      padding: 0 15px;
      .main2 {
        .content {
          width: 100%;
          margin-bottom: 198px;
          .el-image {
            width: 100%;
            height: 216.33px;
          }
          .bianKuangContainer {
            .title {
            }
            .xueKeTime {
              .createTime {
              }
              .className {
                .biaoqian {
                }
              }
            }
          }
        }
      }
    }
  }
}
.main {
  width: 100%;
  padding: 0 0 24px 24px;
  .main2 {
    .content {
      float: left;
      width: 30%;
      margin: 0 1.6% 36px 1.6% !important;
      border: 1px solid #d4d4d4;
      position: relative;
      .elTag {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        color: #fff;
        border: #e52e00;
      }
      .el-image {
        width: 100%;
        height: 139.48px;
      }
      .bianKuangContainer {
        // border-left: 1px solid #d4d4d4;
        // border-right: 1px solid #d4d4d4;
        // border-bottom: 1px solid #d4d4d4;
        height: 128px;
        margin-top: -2px;
        padding: 12px 16px;
        .title {
          height: 48px;
          display: block;
          font-size: 14px;
          // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
          /deep/.rich-text {
            display: block;
            font-size: 14px;
            // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
          }
          /deep/.rich-text:hover {
            color: #e52e00;
          }
        }
        .xueKeTime {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .createTime {
            font-size: 14px;
            // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            color: rgba(0, 0, 0, 0.6);
            line-height: 22px;
          }
          .className {
            display: flex;
            flex-wrap: wrap;
            .biaoqian {
              font-size: 16px;
              // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
              color: #e52e00;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
.footer {
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>
<style lang="less">
.main .main2 .content .bianKuangContainer .title[data-v-18d3cd13] .rich-text {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
