<template>
  <MainCenter>
    <el-button v-if="$isMobile" @click="drawer = true" class="shaiXuanMore" type="primary">筛选</el-button>
    <el-drawer :visible.sync="drawer" :with-header="false" size="50%">
      <div class="drawerContainer">
        <div class="drawerPaixuContainer">
          <div>排序</div>
          <SortSelect class="drawerPaixu" v-model="form.sortType" :params="params" @change="shaiXuanGetData" />
        </div>
        <div class="drawer1">
          <van-cell title="日期" :value="timeFit" @click="show = true" />
          <van-calendar :min-date="minDate" :max-date="maxDate" v-model="show" type="range" @confirm="onParamChange" />
        </div>
        <div class="drawer2">
          <div>专业领域</div>
          <ArticleClassCheckbox v-model="form.articleClassIds" @change="shaiXuanGetData"></ArticleClassCheckbox>
        </div>
      </div>
    </el-drawer>

    <TitleFormList
      title="动画解读"
      sub-title="Quick Takes"
      :data-source="list"
      :pagination="flag === true ? pagination : null"
      :grid="{ gutter: 48, column: $isMobile ? 1 : 3, md: 2, lg: 3, xl: 3 }"
      :loading="loading"
      class="quickTakes"
    >
      <el-form v-if="!$isMobile" :model="form" class="form" slot="form" label-position="top">
        <a-anchor :offsetTop="!$isMobile ? 112 : 62" style="width: 260px">
          <el-form-item label="排序" prop="sort">
            <SortSelect class="paixu" v-model="form.sortType" :params="params" @change="shaiXuanGetData" />
          </el-form-item>
          <el-form-item label="日期" prop="date">
            <el-date-picker
              unlink-panels
              v-model="form.date"
              type="daterange"
              format="yyyy-MM-dd"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 240px"
              @change="onParamChange"
            />
          </el-form-item>
          <el-form-item>
            <span class="label" slot="label">专业领域</span>
            <ArticleClassCheckbox v-model="form.articleClassIds" @change="shaiXuanGetData"></ArticleClassCheckbox>
          </el-form-item>
        </a-anchor>
      </el-form>
      <div v-if="!$isMobile" class="item" slot-scope="{ item }">
        <a :href="'/animation-particulars' + generate(item.permalinks)" @click.prevent="onItemClick(item)">
          <el-image
            style="width: 100%; height: auto"
            :src="
              item.thumb != ''
                ? item.thumb
                : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg08.viwik.com%2Fimages%2F20180820%2Ftooopen_sy_154340434039264.jpg&refer=http%3A%2F%2Fimg08.viwik.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1634519796&t=256bfdfa3d04ce6d27214dd3de65664c'
            "
            fit="cover"
          ></el-image>
        </a>
        <div class="item-title">
          <a :href="'/animation-particulars' + generate(item.permalinks)" @click.prevent="onItemClick(item)">
            {{ item.title }}
          </a>
        </div>
        <div class="item-date ellipsis">
          <a @click.prevent="onItemClick2" class="item-date" v-for="(value, index) in item.classNames" :key="index">
            {{ value }}
          </a>
          <span v-if="item.classNames == null"></span>
          <span v-else>|</span>
          <!-- <span v-if="item.classNames.length != 0">|</span> -->
          {{ item.postdate }}
        </div>
      </div>
      <div v-else class="item" slot-scope="{ item }">
        <a @click.prevent="onItemClick(item)">
          <el-image
            :src="
              item.thumb != ''
                ? item.thumb
                : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg08.viwik.com%2Fimages%2F20180820%2Ftooopen_sy_154340434039264.jpg&refer=http%3A%2F%2Fimg08.viwik.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1634519796&t=256bfdfa3d04ce6d27214dd3de65664c'
            "
            fit="cover"
          ></el-image>
        </a>
        <div class="item-title">
          <a @click.prevent="onItemClick(item)">{{ item.title }}</a>
        </div>
        <div class="item-date ellipsis">
          <a @click.prevent="onItemClick2" class="item-date" v-for="(value, index) in item.classNames" :key="index">
            {{ value }}
          </a>
          <span v-if="item.classNames == null"></span>
          <span v-else>|</span>
          <!-- <span v-if="item.classNames.length != 0">|</span> -->
          {{ item.postdate }}
        </div>
      </div>
    </TitleFormList>
  </MainCenter>
</template>

<script>
import SortSelect from '@/components/form/select/SortSelect.vue'
import MainCenter from '@/components/layout/MainCenter.vue'
import TitleFormList from '@/components/layout/TitleFormList.vue'
import ArticleClassCheckbox from '@/components/select/ArticleClassCheckbox.vue'
import { selectArticleList } from '@/api/serach'
import moment from 'moment'
import { format } from '@/utils/time-utils.js'
import { generateHref } from '@/utils/utils'

const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}

export default {
  name: 'AnimationList',
  components: { ArticleClassCheckbox, SortSelect, TitleFormList, MainCenter },
  data() {
    return {
      list: [],
      pagination: {
        onChange: this.onPageChange,
        showSizeChanger: false,
        // size: 'small',
        pageNo: 1,
        pageSize: 9,
        total: 0,
        current: 1,
        sourceIds: ['7'],
      },
      form: {
        sortType: '',
        date: [],
        startDate: '',
        endDate: '',
        articleClassIds: [],
      },
      params: [
        { label: '最新在前', value: '0' },
        { label: '最早在前', value: '1' },
        { label: '阅读最多', value: '2' },
      ],
      flag: false,
      flag2: true,
      drawer: false,
      timeFit: '',
      show: false,
      minDate: new Date(2010, 12, 1),
      maxDate: new Date(),
      loading: true,
    }
  },
  created() {
    this.getData()
  },
  watch: {
    '$route.query': {
      handler() {
        this.form.articleClassIds = []
        if (this.$route.query.sortType) {
          this.form.sortType  = this.$route.query.sortType;
        }
        if (this.$route.query.articleClassIds) {
          for (let i in this.$route.query.articleClassIds.split(',')) {
            this.form.articleClassIds.push(parseInt(this.$route.query.articleClassIds.split(',')[i]))
          }
        }
        if(this.$route.query.startDate&&this.$route.query.endDate){
          this.form.startDate = this.$route.query.startDate
          this.form.endDate = this.$route.query.endDate 
          this.form.date = [  new Date(moment(this.$route.query.startDate).format('YYYY-MM-DD')) ,new Date(moment(this.$route.query.endDate).format('YYYY-MM-DD'))];
          this.timeFit = `${this.formatDate(this.form.date[0])} - ${this.formatDate(this.form.date[1])}`
        }
        // this.change()
      },
      immediate: true,
    },
  },
  methods: {
    shaiXuanGetData() {
      let query = {}
      if (this.form.sortType) {
        query.sortType = this.form.sortType
      }
      if (this.form.articleClassIds.toString()) {
        query.articleClassIds = this.form.articleClassIds.toString()
      }
      if(this.form.date && this.form.date.length > 0){
        query.startDate =  moment(this.form.startDate).format('YYYYMMDD')
        query.endDate = moment(this.form.endDate).format('YYYYMMDD')
      }
      this.$router.push({
        path: '/animation-list',
        query: query,
      })
      this.pagination.pageNo = 1
      this.getData()
    },
    getData() {
      //搜索文章
      selectArticleList({ ...this.form, ...this.pagination }).then((res) => {
        if (res.results.length == 0) {
          this.flag = false
        } else {
          this.flag = true
        }
        console.log(res.results.length)
        res.results.forEach((element) => {
          element.postdate = format(element.postdate, 'YYYY-MM-DD')
          if (element.classNames != null) {
            element.classNames = element.classNames.split(',')
            let classNames = []
            element.classNames.forEach((item) => {
              if (item !== '') {
                classNames.push(item)
              }
            })
            element.classNames = classNames
          }
        })
        this.pagination.total = res.page.total
        this.pagination.current = this.pagination.pageNo
        this.list = res.results
        this.loading = false
      })
    },
    generate(item) {
      return generateHref('permalinks', item)
    },
    onItemClick(item) {
      this.$router.push({ path: '/animation-particulars', query: { permalinks: item.permalinks } })
    },
    onItemClick2(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    onParamChange(val) {
      let query = {}
      if (this.form.sortType) {
        query.sortType = this.form.sortType
      }
      if (this.form.articleClassIds.toString()) {
        query.articleClassIds = this.form.articleClassIds.toString()
      }
      if (this.flag2) {
        this.pagination.pageNo = 1
      }
      console.log(val, 'val')
      if (val != null) {
        query.startDate =  this.form.startDate = moment(val[0]).format('YYYYMMDD')
        query.endDate = this.form.endDate = moment(val[1]).format('YYYYMMDD')
      } else {
        this.form.startDate = ''
        this.form.endDate = ''
      }
      this.$router.push({
        path: '/animation-list',
        query: query,
      })
      this.getData()
      if (this.$isMobile) {
        const [start, end] = val
        this.show = false
        this.timeFit = `${this.formatDate(start)} - ${this.formatDate(end)}`
      }
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    onPageChange(page) {
      this.flag2 = false
      console.log(this.form)
      this.pagination.pageNo = page
      this.onParamChange()
      this.flag2 = true
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';
.drawerContainer {
  padding: 20px 10px;
  .drawerPaixuContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .drawerPaixu {
      margin: 8px 0 16px 0;
    }
  }
  .drawer1 {
    .van-cell {
      padding: 0;
    }
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .drawer2 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    div {
      margin: 16px 0 8px 0;
    }
    /deep/.el-checkbox-group {
      margin: 0px;
      .el-checkbox {
        width: 100%;
        margin: 4px 0;
      }
    }
  }
}
.form {
  /deep/.ant-anchor-ink::before {
    width: 0px;
  }
  .el-form-item {
    /deep/.el-form-item__label {
      font-size: 16px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
    }
    .paixu {
      width: 240px;
    }
  }
}
.item {
  // width: 236px;
  width: 100%;
  height: 290px;
  // margin-bottom: 43px;
  .item-title {
    font-size: 16px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    // height: 63px;
    height: auto;
    margin-top: 5px;
    // 三行...
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .item-date {
    margin-top: 8px;
    font-size: 12px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    a:hover {
      color: #e52e00;
    }
  }
}
.mobile {
  .item {
    width: 100%;
    height: auto;
    margin-bottom: 60px;
    .item-title {
      font-size: 16px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.85);
      line-height: 20px;
      height: auto;
      margin-top: 5px;
      // 三行...
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .item-date {
      margin-top: 2px;
      margin-bottom: 6px;
      font-size: 14px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      a:hover {
        color: #e52e00;
      }
    }
  }
}
@media (min-width: 992px) {
  .mobile {
    .item {
      height: 290px;
    }
  }
}
/deep/.ant-list-pagination {
  margin: 30px 0 14px 0;
  text-align: center;
}
.shaiXuanMore {
  position: absolute;
  right: 15px;
  top: 85px;
}
</style>
<style lang="less">
.quickTakes {
  .ant-list-item {
    margin-bottom: 0 !important;
  }
  .ant-col-8 {
    border-right: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    padding: 24px 24px 24px 24px;
  }
  .ant-col-8:nth-of-type(3n-3) {
    border-right: none;
  }
  .ant-col-8:nth-of-type(7) {
    border-bottom: none;
  }
  .ant-col-8:nth-of-type(8) {
    border-bottom: none;
  }
  .ant-col-8:nth-of-type(9) {
    border-bottom: none;
  }
  .ant-col-8:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.2) inset;
  }
}
</style>
