import AnimationList from '@/views/article/animation/AnimationList.vue'
import AnimationParticulars from '@/views/article/animation/animationParticulars/AnimationParticulars.vue'
import ArticleInfo from '@/views/article/ArticleInfo.vue'
import AudioList from '@/views/article/audio/AudioList.vue'
import AudioParticulars from '@/views/article/audio/audioParticulars/AudioParticulars.vue'
import ClinicalResearchList from '@/views/article/clinical-research/ClinicalResearchList.vue'
import CourseResources from '@/views/article/course-resouces/CourseResources.vue'
import CourseVideo from '@/views/article/course-resouces/CourseVideo.vue'
import MeetingNotice from '@/views/article/course-resouces/MeetingNotice.vue'
import MeetingReview from '@/views/article/course-resouces/MeetingReview.vue'
import SeriesCourses from '@/views/article/course-resouces/SeriesCourses.vue'
import PeriodArticle from '@/views/article/PeriodArticle.vue'
import LatestPeriodical from '@/views/article/periodical/LatestPeriodical.vue'
import NejmCatalog from '@/views/article/periodical/NejmCatalog.vue'
import NejmList from '@/views/article/periodical/NejmList.vue'
import NejmCatalystList from '@/views/article/periodical/NejmCatalystList.vue'
import NejmPeriodicalList from '@/views/article/periodical/NejmPeriodicalList.vue'
import ProfessionalArticleList from '@/views/article/professional/ProfessionalArticleList.vue'
import ProfessionalMore from '@/views/article/professional/ProfessionalMore.vue'
import Transfer from '@/views/article/Transfer.vue'
import Commentary from '@/views/commentary/Commentary.vue'
export default [
  {
    path: '/animation-list',
    name: '动画解读',
    component: AnimationList,
  },
  {
    path: '/animation-particulars',
    name: '动画解读详情',
    component: AnimationParticulars,
  },
  {
    path: '/article-info',
    name: '文章详情',
    component: ArticleInfo,
  },
  {
    path: '/audio-list',
    name: '音频',
    component: AudioList,
  },
  {
    path: '/audio-particulars',
    name: '音频详情',
    component: AudioParticulars,
  },
  {
    path: '/clinical-research-list',
    name: '临床研究论文撰写',
    component: ClinicalResearchList,
  },
  {
    path: '/course-resources',
    name: '课程',
    component: CourseResources,
  },
  {
    path: '/course-video',
    name: '课程视频',
    component: CourseVideo,
  },
  {
    path: '/meeting-notice',
    name: '会议预告',
    component: MeetingNotice,
    meta: {
      theme2: true,
    },
  },
  {
    path: '/meeting-review',
    name: '会议回顾',
    component: MeetingReview,
    meta: {
      theme2: true,
    },
  },
  {
    path: '/series-courses',
    name: '系列课',
    component: SeriesCourses,
  },
  {
    path: '/period-article',
    name: '按期浏览',
    component: PeriodArticle,
  },
  {
    path: '/latest-periodical',
    name: '最新期刊',
    component: LatestPeriodical,
  },
  {
    path: '/nejm-catalog',
    name: 'NEJM目录',
    component: NejmCatalog,
  },
  {
    path: '/nejm-list',
    name: 'NEJM类型文章',
    component: NejmList,
  },
  {
    path: '/nejm-catalyst-list',
    name: 'Catalyst类型文章',
    component: NejmCatalystList,
  },
  {
    path: '/nejm-periodical-list',
    name: 'NEJM期刊荟萃',
    component: NejmPeriodicalList,
  },
  {
    path: '/professional-article-list',
    name: '专业领域',
    component: ProfessionalArticleList,
  },
  {
    path: '/professional-more',
    name: '专业领域更多',
    component: ProfessionalMore,
  },
  {
    path: '/article-transfer',
    name: '路由冲突中转',
    component: Transfer,
  },
  {
    path: '/commentary',
    name: '述评',
    component: Commentary,
  },
]
