export const SETTINGS = {
  // 微信开放平台
  appid: 'wxad1bfe701882c4fb',
  // 微信公众号
  gzAppid:'wxe8711f0a5d7e59ed',
  // 信开放平台/微信公众号授权登录的回调地址
  // rect_host: 'https://dev.nejmqianyan.com',//测试
  rect_host: 'https://www.nejmqianyan.cn',//测试
  // rect_host: process.env.NODE_ENV === 'production'?'https://www.nejmqianyan.cn':'https://dev.nejmqianyan.com',//生产
}
