import EventBus from '@/event-bus'
import '@/init-ui'
import '@/mixins/reactive.js'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import moment from 'moment'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'video.js/dist/video-js.css'
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import VideoPlayer from 'vue-video-player'
import App from './App.vue'
import './assets/less/main.less'
import './override-ui.js'
import * as Config from './config/index'
import router from './router'
import store from './store'

// import "swiper/swiper-bundle.css"
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

Vue.use(Vant)
Vue.use(Antd)
Vue.use(VideoPlayer)
Vue.use(VueScrollTo, {
  container: 'body', // 滚动元素
  duration: 1000, // 动画时长
  easing: 'ease', // 动画曲线
  offset: -5, // 滚动终点距离父元素顶部距离
  force: true, // 强制立即执行，即便元素是可见的
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false, // x 轴禁止滚动
  y: true,
})
moment.locale('zh-cn')

Vue.prototype.$bus = new EventBus()
Vue.prototype.$Config = Config

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
