<template>
  <div class="cr-img" :style="$isMobile ? 'padding-left: 15px; padding-right: 15px' : ''">
    <div class="head-title">{{ detail.title }}</div>
    <div class="head-title2">{{ detail.subhead }}</div>
    <div class="head-title2">
      主办方： {{ detail.sponsor }} / {{ detail.beginTime | formatDateTxt }} - {{ detail.endTime | formatDateTxt }}
    </div>
    <div class="share">
      <Share
        :isShow="{ navigation: false, collect: true, share: true, original: false }"
        :id="detail.lessonAdvId"
        collectType="meeting"
        v-bind:ifCollect.sync="detail.ifFav"
      ></Share>
    </div>

    <div :style="$isMobile ? 'margin: 0 auto; overflow: auto;' : 'margin: 0 auto; overflow: auto; width: 1200px'">
      <RichText :content="detail.content" :about="detail.aboutcontent" />
    </div>
  </div>
</template>

<script>
import { conferenceAdvanceDetail } from '@/api/course'
import RichText from '@/components/layout/RichText.vue'
import Share from '@/components/share/Share'
import { getSessionUser } from '@/utils/app-utils'
import { format } from '@/utils/time-utils'

export default {
  name: 'MeetingNotice',
  components: { RichText, Share },
  data() {
    return {
      list: [1, 2],
      detail: {},
    }
  },
  filters: {
    formatDateTxt(time) {
      return format(time, 'YYYY年MM月DD日 HH:mm')
    },
  },
  created() {
    console.log(this.$route.query.id)
    this.detail.lessonAdvId = this.$route.query.id
    this.getDetail()
  },
  methods: {
    async getDetail() {
      let loginUser = getSessionUser()
      let res = await conferenceAdvanceDetail({
        id: Number(this.detail.lessonAdvId),
        memberId: null != loginUser ? loginUser.id : null,
      })
      this.detail = res
    },
  },
}
</script>

<style lang="less" scoped>
.cr-img {
  // background-image: url('../../../assets/imgs/cr-bg.jpeg');
  background-repeat: no-repeat;
}
share-box {
  padding-left: 40%;
}
.item {
  height: 60px;
  line-height: 60px;
  font-size: 24px;
}
.sub-title {
  font-weight: bolder;
}
.head-title {
  color: #a1224e;
  font-size: 32px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  margin-top: 50px;
  font-weight: 400;
}
.head-title2 {
  height: 26px;
  line-height: 26px;
  font-size: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  font-weight: 400;
}

.ct-head-left {
  width: 33%;
  text-align: center;
}
.ct-head-center {
  width: 33%;
  color: #a1224e;
  font-size: 18px;
  text-align: center;
}
.ct-head-right {
  width: 33%;
  font-size: 18px;
  color: #a1224e;
  text-align: center;
}
.ct-head-block {
  border: #a1224e solid 1px;
  border-radius: 2px;
  width: 306px;
  display: flex;
  align-items: center;
  height: 42px;
  flex-direction: row;
}
.ct-end-block {
  border: #a1224e solid 1px;
  border-radius: 4px;
  width: 306px;
  height: 36px;
  line-height: 36px;
  background-color: #a1224e;
  color: white;
  font-size: 12px;
  text-align: center;
  margin-bottom: 32px;
}
.ct-block {
  margin-top: 30px;
  margin-left: 39%;
}

.ct-dv {
  width: 10%;
  border: solid 1px #a1124e;
}
.ct-dv-txt {
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}
.ct-item-info {
  width: 70%;
}
.share {
  width: 1200px;
  margin: 0 auto;
}

.mobile {
  .head-title {
    color: #a1224e;
    font-size: 20px;
    //height: 40px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
  }
  .head-title2 {
    height: 46px;
    line-height: 18px;
    font-size: 14px;
    text-align: center;
  }
  .share {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 27%;
  }
  .rich-text p img {
  }
}
</style>
