<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" width="100%">
    <div class="fr">
      <!-- <img class="img" src="../../assets/imgs/fengxiang.png" /> -->
      <span class="span">请使用浏览器的分享功能分享本文</span>
    </div>
  </el-dialog>
</template>

<script>
// import QrcodeVue from 'qrcode.vue'

export default {
  name: 'FengXiang',
  components: {},
  props: {
    isweixin: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.fr {
  align-items: center;
  justify-content: center;
  .span {
    color: #ffffff;
    font-size: 24px;
    line-height: 48px;
    margin: 0;
    width: 80%;
    top: 150px;
    position: absolute;
    border: 1px dashed #ffffff;
    border-radius: 20px;
    padding: 24px;
    text-align: center;
  }
  .img {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
  }
  .img1 {
    position: absolute;
    top: 500px;
    right: 20px;
    width: 120px;
  }
  .img2 {
    position: absolute;
    top: 500px;
    right: 100px;
    width: 120px;
  }
}
/deep/.el-dialog {
  background: #ffffff00;
  margin-top: 0 !important;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    margin: 0;
    padding: 0;
  }
}
</style>
