import Index from '@/views/index/Index.vue'
import OtherLogin from '@/views/index/otherLogin/otherLogin.vue'
import WxloginBindPhone from '@/views/index/otherLogin/wxloginBindPhone.vue'
// import Test from '@/views/personal/testUserForm.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import routesAbout from './about/routes.js'
import routesArticle from './article/routes.js'
import routesIndex from './index/routes.js'
import routesPersonal from './personal/routes.js'
import routesSearch from './search/routes.js'
import routesQrcode from './qrcode/routes.js'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '',
        name: '首页',
        component: Index,
      },
      ...routesIndex,
      ...routesAbout,
      ...routesArticle,
      ...routesSearch,
      ...routesPersonal,
      ...routesQrcode,
    ],
  },
  {
    path: '/wechat/wxlogin',
    name: '微信跳转',
    component: OtherLogin,
  },
  {
    path: '/wxloginBindPhone',
    name: '微信跳转绑定手机',
    component: WxloginBindPhone,
  },
  // {
  //   path: '/test',
  //   name: 'ces',
  //   component: Test,
  // },
]

// routes[0].children.forEach((it) => {
//   if (it.path) {
//     console.log(`// ${it.name}`)
//     let pathVar = it.path.replaceAll('/', '').replaceAll('-', '_').toUpperCase()
//     console.log(`const PATH_${pathVar} = '${it.path}'`)
//     console.log()
//   }
// })

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  // if (!to.query?._t) {
  //   to.query._t = Date.now()
  // }
  // 其他情况，跳转路由
  next()
})

export default router
