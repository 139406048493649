<template>
  <div class="cr-img" :style="$isMobile ? 'padding-left: 15px; padding-right: 15px' : ''">
    <div id="box">
      <div class="item">{{ detail.title }}</div>
    </div>
    <div class="ct-au">
      <div class="item-au">
        {{ detail.subhead }}
      </div>
    </div>
    <div class="ct-au">
      <div class="item-au">主办方： {{ detail.sponsor }}</div>
    </div>
    <div class="share">
      <Share
        :isShow="{ navigation: false, collect: true, share: true, original: false }"
        :id="detail.lessonResId"
        collectType="lesson"
        v-bind:ifCollect.sync="detail.ifFav"
      ></Share>
    </div>

    <div class="item-content">
      <BzVideoPlayer
        v-if="
          detail.media != '' &&
          detail.media != '[]' &&
          detail.media != 'null' &&
          JSON.parse(detail.media)[0].fileType != 1
        "
        style="width: 700px; height: 100%"
        :src="
          detail.media != '' && detail.media != '[]' && detail.media != 'null' ? JSON.parse(detail.media)[0].url : ''
        "
        :player-options="{ loop: true }"
        :showPlay="true"
      />
      <img
        v-else
        style="width: 1200px; height: 677px"
        :src="
          detail.media != '' && detail.media != '[]' && detail.media != 'null' ? JSON.parse(detail.media)[0].url : ''
        "
        alt=""
      />
    </div>
    <div class="item-content">
      <RichText
        :content="detail.content"
        :about="detail.aboutcontent"
        class="item-content-txt"
        style="overflow: auto"
      />
    </div>
  </div>
</template>

<script>
import { selectLessonDetail } from '@/api/course'
import RichText from '@/components/layout/RichText.vue'
import Share from '@/components/share/Share'
import BzVideoPlayer from '@/components/video/BzVideoPlayer.vue'
import { getSessionUser } from '@/utils/app-utils'
import { format } from '@/utils/time-utils'

export default {
  name: 'MeetingReview',
  components: { RichText, BzVideoPlayer, Share },
  data() {
    return {
      detail: {},
    }
  },
  filters: {
    formatDateTxt(time) {
      return format(time, 'YYYY年MM月DD日 HH:mm')
    },
  },
  created() {
    console.log(this.$route.query.id)
    this.detail.lessonResId = this.$route.query.id
    this.getDetail()
  },
  methods: {
    async getDetail() {
      let loginUser = getSessionUser()
      let res = await selectLessonDetail({
        id: this.detail.lessonResId,
        memberId: null != loginUser ? loginUser.id : null,
      })
      this.detail = res
    },
  },
}
</script>

<style lang="less" scoped>
.cr-img {
  background-image: url('../../../assets/imgs/cr-bg.jpeg');
  background-repeat: no-repeat;
}
#box {
  display: flex;
  display: -webkit-flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.item {
  height: 44px;
  line-height: 44px;
  font-size: 32px;
  color: #a1224e;
  text-align: center;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
}
.ct-au {
  display: flex;
  display: -webkit-flex;
  height: 26px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.item-au {
  height: 26px;
  line-height: 26px;
  font-size: 18px;
  padding-right: 12px;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  font-weight: 400;
}
.item-content {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.item-content-txt {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.share {
  width: 1200px;
  margin: 0 auto;
}

.mobile {
  #box {
    display: flex;
    display: -webkit-flex;
    height: 60px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .item {
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    color: #a1224e;
    text-align: center;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ct-au {
    display: flex;
    display: -webkit-flex;
    height: 30px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .item-au {
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    padding-right: 12px;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
  }

  .share {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 27%;
  }
}
</style>
