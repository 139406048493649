/**
 * 微信开发包
 */

import {generateQuery} from "@/utils/utils";
import * as Config from "@/config/index";
import axios from "axios";
window.wx = require('weixin-js-sdk');

let appId = "wxe8711f0a5d7e59ed";


/**
 * 加载JS
 * @param src
 * @returns {Promise<unknown>}
 */
export const loadJs = function(src) {
    return new Promise((resolve, reject)=>{
        let script = document.createElement('script');
        script.type = "text/javascript";
        script.src= src;
        document.body.appendChild(script);

        script.onload = ()=>{
            resolve();
        }
        script.onerror = ()=>{
            reject();
        }
    })
}

/** 检查是否是微信浏览器 */
export const isWeiXin = function () {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
    } else {
        return false;
    }
}

/** openId get/set */
export const getOpenId = function () {
    return localStorage.getItem('nejmAppId');
}
export const setOpenId = function (value) {
    localStorage.setItem('nejmAppId', value);
}
/** unionId get/set */
export const getUnionid = function () {
    return localStorage.getItem('nejmUnionId');
}
export const setUnionid = function (value) {
    localStorage.setItem('nejmUnionId', value);
}

/** 初始化JSSDK Config */
export const initJsApi = function (callback) {
    let url = Config.API_PATH_PHP + '?c=pay&m=getJssdkConfig';
    url += '&url=' + encodeURIComponent(window.location.href);
    axios.get(url).then(res => {
        let config = res.data;
        window.wx.config({
            debug: false,
            appId: config.appId,
            timestamp: config.timestamp,
            nonceStr: config.nonceStr,
            signature: config.signature,
            jsApiList: config.jsApiList
        });
        window.wx.ready(()=>{
            callback && callback();
        })
        window.wx.error((res)=>{
            console.log('wx.error', res)
        })
    });
}

/** 发起微信授权跳转，获取code凭证 */
export const goGetCode = function () {
    let base = 'https://open.weixin.qq.com/connect/oauth2/authorize';
    let params = {
        appid: appId,
        redirect_uri: encodeURIComponent(location.href),
        response_type: 'code',
        scope: 'snsapi_userinfo',
        state: "",
    }
    window.location.href = base + generateQuery(params) + '#wechat_redirect';
}
