<template>
    <div class="pop"
        v-if="islogin && isFree && subParam.is_month_chance == '2' && subParam.is_have_view_auth == '2' && subParam.is_subscribe == '1'&&subscribe_info.service_type==0">
        <div class="popbox">
            <div class="popupwarp">
                <div style="padding: 40px 32px;">
                    <div class="pop_title">
                        <img src="../../../assets/imgs/logo/logo.png" />
                    </div>
                    <div class="pop_user">亲爱的{{ userInfo.truename }}:</div>
                    <div class="pop_cont">您本月还有1次免费浏览权益,是否使用？</div>
                    <div class="pop_btn_wrap">
                        <div class="pop_btn" @click="close">不使用</div>
                        <div class="pop_btn" @click="chooseFree">使用</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import {
//     useFree
// } from '@/api/newPhp'
import { getMemberInfo } from '@/api/personal'
import axios from 'axios'
// import request from '@/api/request'
// const base = 'https://yxqyoss.judianseo.com'
export default {
    name: 'FreeReadPopup',
    data() {
        return {
            userInfo: {
                truename: ""
            },
            subscribe_info:{
                service_type:null
            }
        }
    },
    props: {
        subParam: {
            type: Object,
            default: () => ({
                is_subscribe: '1',
                is_have_view_auth: '2',
                is_month_chance: '2'
            }),
        },
        islogin: {
            type: Boolean,
            default: false,
        },
        isFree: {
            type: Boolean,
            default: true,
        },
        // is_subscribe: {
        //     type: Number,
        //     default: 1
        // },
        // is_have_view_auth: {
        //     type: Number,
        //     default: 2
        // },
        // is_month_chance: {
        //     type: Number,
        //     default: 2
        // },
    },
    created() {
        // this.userInfo.truename = window.sessionStorage.getItem('membername') || ''
    },
    mounted() {
        console.log('使用弹窗',this.subParam)
        if(this.islogin){
            this.getUseinfo()
        }
    },
    methods: {
        async getUseinfo(){
            let res = await getMemberInfo()
            console.log(res,'====用户信息')
            this.userInfo.truename = res.membername
            this.subscribe_info.service_type= res.subscribe_info.service_type
        },
        close() {
            this.$emit('close', {a:false,b:true})
        },
        chooseFree() {
            // this.$emit('close', false)
            console.log('使用免费次数')
            // await useFree({ id: this.subParam.articleId }).then((res) => {
            //     console.log(res, 'res')
            // })
            // this.$axios.get('https://yxqyoss.judianseo.com/index.php?c=program&m=articleUseMonthChance&id='+this.subParam.articleId)
            //     .then(function(res){
            //         console.log(res, 'res')
            //     })
            //     .catch(function(err){
            //         console.log(err, 'err')
            //     })
                // request({
                //     url:"/index.php?c=program&m=articleUseMonthChance&id="+this.subParam.articleId
                // }).then(res => {
                //     console.log(res)
                // })
            // let md5 = require('@/util/md5.js')
            // let timestamp = Math.round(new Date() / 1000);
            // let access_token = window.sessionStorage.getItem('access_token');
            // let client_id = window.sessionStorage.getItem('client_id');
            // let md5Data = md5.hexMD5(access_token + timestamp + 'gm8Pyx3sbuCdqsspYylv3rhh9Bt40vn7' + client_id)  
            // let token = window.sessionStorage.getItem('token');  
            let token = window.sessionStorage.getItem('token');
            console.log(token,'token');    
            axios.get('https://www.nejmqianyan.cn/index.php?c=program&m=articleUseMonthChance&type=1&id='+this.subParam.articleId + '&sf='+'pc'+'&token='+ token).then(res => {
                console.log(res);
                if(res.data.result == '1'){
                    this.$message.success('使用成功')
                    this.$emit('close', false)
                    this.$emit('content',res.data.article_content)
                    this.$emit('showContent',true)
                    // this.$emit('getArticle')
                    return
                }
                this.$message.error(res.data.msg || '您本月免费次数已经使用了!' )
            })
        },
    },

}
</script>
<style lang="less" scoped>
.pop {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.30);
}

.popbox {
    // margin-top: 20%;
    // height: 500px;
    // background: #FFFFFF;
    box-shadow: 0px 4px 16px 2px rgba(200, 201, 204, 0.5);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popupwarp {
    z-index: 999;
    width: 400px;
    // height: 500px;
    background: #FFF;
    box-shadow: 0px 4px 16px 2px rgba(200, 201, 204, 0.5);
    border-radius: 10px;
    margin-top: 10%;

}

.pop_title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px;
}

.pop_title img {
    width: 150px;
    height: 58.12px;
}

.pop_user {
    font-family: PingFangSC-Semibold;
    font-size: 20px;
    color: #000;
    font-weight: 600;
    margin-bottom: 40px;
}

.pop_cont {
    font-family: PingFangSC-Regular;
    font-size:20px;
    color: #000;
    font-weight: 400;
    margin-bottom: 44px;
}

.pop_btn_wrap {
    display: flex;
    align-items: center;
    gap: 32px;
}

.pop_btn_wrap .pop_btn {
    width: 144px;
    height: 50px;
    border: 1px solid rgba(51, 51, 51, 1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    cursor: pointer;
    // padding: 10px 50px;
}

.pop_btn_wrap .pop_btn:nth-child(2) {
    background: #E52E00;
    border-radius: 2px;
    color: #fff;
    border: none;
}

// 移动端样式
.mobile {
    .popupwarp {
        width: 80%;
        z-index: 999;
        height: 380px;
        background: #FFF;
        box-shadow: 0px 4px 16px 2px rgba(200, 201, 204, 0.5);
        border-radius: 10px;
        margin-top: 20%;
    }

    .pop_btn_wrap .pop_btn {
        width: 144px;
        height: 35px;
        border: 1px solid #333333;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #333333;
        font-weight: 400;
    }

    .pop_btn_wrap .pop_btn:nth-child(2) {
        background: #E52E00;
        border-radius: 2px;
        color: #fff;
        border: none;
    }
}
</style>
  