<template>
  <!-- 动画搜索 -->
  <div>
    <a-spin :spinning="loading">
      <div class="main">
        <div class="main2">
          <div class="content" v-for="item in msg ? msg.results : []" :key="item.id">
            <a :href="'/animation-particulars' + generate(item.permalinks)">
              <el-image :src="item.thumb" fit="cover"></el-image>

              <a class="explain" @click="animation(item.permalinks)">
                <RichText :content="item.title | replaceHandle(query)" />
              </a>
              <div class="video-date ellipsis">
                <a
                  :href="'/animation-particulars' + generate(item.permalinks)"
                  style="margin-right: 5px"
                  class="video-date"
                  v-for="(value, index) in item.classNames"
                  :key="index"
                >
                  {{ value }}
                </a>
                <span v-if="item.classNames == null"></span>
                <span v-else>|</span>
                <!-- <span v-if="item.classNames.length != 0">|</span> -->
                {{ item.postdate }}
              </div>
            </a>
          </div>
        </div>
      </div>
      <div v-if="length == '0' && !loading" style="display: flex; justify-content: center">
        <el-image :src="nulldata" fit="cover"></el-image>
      </div>
    </a-spin>
    <div class="footer" v-if="length != '0' && !loading">
      <div style="display: flex">
        <a-button type="link" @click="onShowSizeChange(1, pageSize)">首页</a-button>
        <a-pagination
          v-model="pageNo"
          :page-size="pageSize"
          show-size-changer
          :page-size-options="['10', '20', '50', '100']"
          :total="msg ? msg.page.total : 0"
          show-less-items
          @change="onShowSizeChange"
          @showSizeChange="onShowSizeChange"
        />
        <a-button type="link" @click="onShowSizeChange(msg.page.pages, pageSize)">尾页</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import nulldata from '@/assets/imgs/nulldata.png'
import RichText from '@/components/layout/RichText.vue'
import { replaceHandle } from '@/filters'
import { generateHref } from '@/utils/utils'
const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}
export default {
  name: 'SearchAnimation',
  components: { RichText },
  props: {
    msg: {
      type: Object,
      default: function () {
        return null
      },
    },
    query: {
      type: String,
      default() {
        return ''
      },
    },
    pageNo: {
      type: Number,
      default() {
        return 1
      },
    },
    loading: {},
  },
  data() {
    return {
      // pageNo: 1,
      pageSize: 20,
      length: 0,
      nulldata,
    }
  },
  filters: { replaceHandle },
  created() {
    this.$bus.on('selectArticleListAmount', (res) => {
      this.length = res.length
    })
  },
  methods: {
    onShowSizeChange(page, size) {
      console.log(page, size)
      this.pageNo = page
      this.pageSize = size
      this.$emit('paging', page, size)
    },
    animation(id) {
      this.$router.push({ path: '/animation-particulars', query: { permalinks: id } })
    },
    onItemClick(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    generate(item) {
      return generateHref('permalinks', item)
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  margin-left: 18px;
  .main2 {
    .content {
      border-right: 1px solid #f5f5f5;
      border-bottom: 1px solid #f5f5f5;
      padding: 22px;
      float: left;
      width: 33%;
      height: 319px;
      // padding: 30px;
      .el-image {
        width: 100%;
        height: 180px;
      }
      .explain {
        width: 100%;
        display: block;
        // font-size: 18px;
        font-size: 16px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.85);
        height: auto;
        line-height: 20px;
        margin: 5px 0 8px 0;
        // 三行...
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        /deep/.rich-text:hover {
          color: #e52e00;
        }
      }
      /deep/.rich-text {
        font-size: 16px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.85);
        // height: 42px;
        line-height: 20px;
      }
      .video-date {
        display: inline-block;
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
      a:hover {
        color: #e52e00;
      }
    }
    .content:nth-of-type(3n-3) {
      border-right: none;
    }
    .content:nth-of-type(19) {
      border-bottom: none;
    }
    .content:nth-of-type(20) {
      border-bottom: none;
    }
    .content:nth-of-type(21) {
      border-bottom: none;
    }
    .content:hover {
      transition: all 0.3s ease-in-out;
      box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.2) inset;
    }
  }
}
.mobile {
  .main {
    margin-left: 0px;
    padding: 0 15px;
    .main2 {
      .content {
        padding: 0px;
        border: none;
        float: left;
        margin-right: 38px;
        width: 100%;
        height: auto;
        margin-bottom: 24px;
        .el-image {
          width: 100%;
          height: 100%;
        }
        .explain {
          width: 100%;
          font-size: 16px;
          // height: 70px;
          // height: 100px;
          // line-height: 32px;
        }
        /deep/.rich-text {
          font-size: 16px;
          // height: 70px;
          // line-height: 32px;
        }
        .video-date {
          // font-size: 20px;
        }
      }
    }
  }
  .footer {
    padding: 0;
  }
}
.footer {
  padding: 30px 0 30px 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>
