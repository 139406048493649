<template>
  <MainCenter>
    <el-button v-if="$isMobile" @click="drawer = true" class="shaiXuanMore" type="primary">筛选</el-button>
    <el-drawer :visible.sync="drawer" :with-header="false" size="50%">
      <div class="drawer">
        <div>专业领域</div>
        <ArticleClassCheckbox v-model="params.articleClassIds" @change="getData(1)"></ArticleClassCheckbox>
      </div>
    </el-drawer>
    <TitleFormList
      title="述评"
      sub-title="Commentary"
      :data-source="list"
      :pagination="flag === true ? pagination : null"
      :loading="loading"
    >
      <div v-if="!$isMobile" slot="form">
        <a-anchor :offsetTop="!$isMobile ? 112 : 62">
          <div class="lingyu">专业领域</div>
          <!-- <div style="width: 92%; height: 1px; margin-top: 8px; background-color: #d4d4d4"></div> -->
          <ArticleClassCheckbox v-model="params.articleClassIds" @change="getData(1)"></ArticleClassCheckbox>
        </a-anchor>
      </div>
      <div slot-scope="{ item }" :class="$isMobile ? 'itemFit' : 'item'">
        <div class="buju">
          <div>
            <div class="shuPing">
              专家述评
              <i v-if="item.ifCollect != 0" class="iconfont icon-shanchu" @click="collect(item)"></i>
            </div>
            <a
              :href="'/article-info' + generate(item.permalinks)"
              @click.prevent="onItemClick(item)"
              class="shupingTitle"
            >
              {{ item.wantsayTitle }}
            </a>
            <div v-if="item.sourceName != null" href="javascript:;" class="title">
              {{ item.sourceName + '：' }}{{ item.typeName ? item.typeName : item.filterName }}
            </div>
            <a
              v-if="item.wantsayTitle !== item.title"
              :href="'/article-info' + generate(item.permalinks)"
              @click.prevent="onItemClick(item)"
              class="title_s"
            >
              {{ item.title }}
            </a>
            <div class="other">
              <a @click.prevent="onItemClick2" class="other1" v-for="(value, index) in item.classNames" :key="index">
                {{ value }}
              </a>
              <span v-if="item.classNames == null"></span>
              <span v-else>|</span>
              <!-- <span v-if="item.classNames.length != 0">|</span> -->
              {{ item.postdate }}
            </div>
          </div>
          <div v-if="!$isMobile && item.thumb != ''" class="tupian">
            <a @click.prevent="onItemClick(item)">
              <el-image style="width: 120px; height: 120px" :src="item.thumb" fit="cover"></el-image>
            </a>
          </div>
        </div>
      </div>
    </TitleFormList>
  </MainCenter>
</template>
<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import TitleFormList from '@/components/layout/TitleFormList.vue'
import ArticleClassCheckbox from '@/components/select/ArticleClassCheckbox.vue'
import { selectArticleList } from '@/api/article'
import { format } from '@/utils/time-utils.js'
import { generateHref } from '@/utils/utils.js'
import { mapState } from 'vuex'
import { collectArticle, cancelCollect } from '@/api/user-api.js'
import { getSessionUser } from '@/utils/app-utils'

const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}
export default {
  name: 'Commentary',
  components: { MainCenter, TitleFormList, ArticleClassCheckbox },
  data() {
    return {
      params: {
        articleClassIds: [],
        haswantsay: 1,
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      },
      list: [],
      pagination: {
        onChange: (page) => {
          this.getData(page)
        },
        current: 1,
        pageSize: 20,
        total: 0,
      },
      flag: false,
      drawer: false,
      loading: true,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    this.getData(1)
  },
  methods: {
    generate(item) {
      return generateHref('permalinks', item)
    },
    onItemClick(item) {
      this.$router.push({ path: '/article-info', query: { permalinks: item.permalinks } })
    },
    onItemClick2(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    async getData(pageNo) {
      let param = { ...this.params, pageNo, pageSize: this.pagination.pageSize }
      let res = await selectArticleList(param)
      if (res.results.length == 0) {
        this.flag = false
      } else {
        this.flag = true
      }
      res.results.forEach((v) => {
        v.postdate = v.postdate ? format(v.postdate, 'YYYY-MM-DD') : null
        if (v.classNames != null) {
          v.classNames = v.classNames.split(',')
          let classNames = []
          v.classNames.forEach((item) => {
            if (item !== '') {
              classNames.push(item)
            }
          })
          v.classNames = classNames
        }
      })
      this.pagination.total = res.page.total
      this.pagination.current = pageNo
      this.list = res.results
      this.loading = false
    },
    async collect(item) {
      if (this.user) {
        if (item.ifCollect === 0) {
          //收藏
          await collectArticle({ id: item.id })
          this.getData(this.pagination.current)
        } else {
          //取消收藏
          let res = await cancelCollect({ id: item.id })
          if (res) {
            this.getData(this.pagination.current)
          }
        }
      } else {
        this.$bus.emit('login')
      }
    },
  },
}
</script>
<style lang="less" scoped>
.drawer {
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  div {
    margin-bottom: 8px;
  }
  /deep/.el-checkbox-group {
    margin: 0px;
    .el-checkbox {
      width: 100%;
      margin: 4px 0;
    }
  }
}
/deep/.ant-anchor-ink::before {
  width: 0px;
}
.lingyu {
  font-size: 16px;
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
}
.item {
  width: 100%;
  margin-bottom: 32px;
  .buju {
    display: flex;
    justify-content: space-between;
    .shuPing {
      font-size: 14px;
      // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
      line-height: 30px;
      color: #e52e00;
      i {
        cursor: pointer;
        display: none;
      }
    }
    .shupingTitle {
      margin: 8px 0;
      display: block;
      font-size: 14px;
      // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
      color: rgba(0, 0, 0, 0.85);
      line-height: 26px;
    }
    .title {
      font-size: 14px;
      // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
      line-height: 30px;
      color: #e52e00;
    }
    .title_s {
      margin: 8px 0;
      display: block;
      font-size: 14px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.6);
      line-height: 26px;
    }
    .other {
      font-size: 14px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.6);
      line-height: 26px;
    }
    .other1 {
      font-size: 14px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.6);
      line-height: 26px;
    }
    .tupian {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    a:hover {
      color: #e52e00;
    }
  }
}
.itemFit {
  width: 100%;
  margin-bottom: 32px;
  .buju {
    display: flex;
    justify-content: space-between;
    .shuPing {
      display: block;
      font-size: 14px;
      // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
      line-height: 30px;
      color: #e52e00;
      i {
        cursor: pointer;
        display: none;
      }
    }
    .shupingTitle {
      margin: 8px 0;
      display: block;
      font-size: 14px;
      // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
      color: rgba(0, 0, 0, 0.85);
      line-height: 26px;
    }
    .title {
      display: block;
      font-size: 14px;
      // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
      line-height: 30px;
      color: #e52e00;
    }
    .title_s {
      margin: 8px 0;
      display: block;
      font-size: 14px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.6);
      line-height: 26px;
    }
    .other {
      font-size: 14px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.6);
      line-height: 26px;
    }
    .other1 {
      font-size: 14px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: rgba(0, 0, 0, 0.6);
      line-height: 26px;
    }
    a:hover {
      color: #e52e00;
    }
  }
}
/deep/.el-checkbox {
  margin-top: 8px;
}
/deep/.ant-list-item {
  padding: 0;
}
/deep/.ant-list-pagination {
  margin: 24px 0;
}
.shaiXuanMore {
  position: absolute;
  right: 15px;
  top: 85px;
}
</style>
