import moment from 'moment'

/**
 * 日期格式化
 * @param date    时间
 * @returns {string}
 */
export function formatDate(date) {
  return format(date, 'YYYY-MM-DD')
}

/**
 * 日期时间格式化
 * @param date    时间
 * @returns {string}
 */
export function formatDateTime(date) {
  return format(date, 'YYYY-MM-DD HH:mm:ss')
}

/**
 * 时间格式化
 * @param date    时间
 * @returns {string}
 */
export function formatTime(date) {
  return format(date, 'HH:mm:ss')
}

/**
 * 自定义日期、时间格式化
 * @param date    时间
 * @param format  格式
 * @returns {string|null}
 */
export function format(date, format) {
  if (date == null) {
    return null
  }
  if (typeof date === 'number' && date <= 9000000000) {
    date *= 1000
  }
  return moment(date).format(format)
}
