<template>
  <div class="header">
    <el-button type="text" :class="flag1 === true ? 'btn1' : null" class="btn" @click="articleAnimationAudio">
      文章/动画
    </el-button>
    <a-divider
      type="vertical"
      style="width: 2px; height: 24px; margin-top: -4px; background-color: rgba(0, 0, 0, 0.85)"
    />
    <el-button type="text" :class="flag2 === true ? 'btn2' : null" class="btn" @click="curriculumResources">
      课程
    </el-button>
    <component :is="component"></component>
  </div>
</template>
<script>
import ArticleAnimationAudio from './ArticleAnimationAudio'
import CurriculumResources from './CurriculumResources'
const componentOf = {
  10: ArticleAnimationAudio,
  20: CurriculumResources,
}

export default {
  name: 'PersonalCollection',
  data() {
    return {
      index: 10,
      flag1: false,
      flag2: false,
    }
  },
  computed: {
    component() {
      return componentOf[this.index]
    },
  },
  created() {
    this.flag1 = true
  },
  methods: {
    // 文章/动画/音频
    articleAnimationAudio() {
      this.index = 10
      this.flag1 = true
      this.flag2 = false
    },
    // 课程
    curriculumResources() {
      this.index = 20
      this.flag1 = false
      this.flag2 = true
    },
  },
}
</script>
<style lang="less" scoped>
.header {
  .btn {
    font-size: 20px;
    // font-family: PingFangSC, PingFangSC-Regular;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
  }
  .btn1 {
    color: #e83b19;
    /deep/span {
      font-weight: bold;
    }
  }
  .btn2 {
    color: #e83b19;
    /deep/span {
      font-weight: bold;
    }
  }
}
</style>
