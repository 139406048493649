<template>
    <!-- 免费次数已用完弹窗 start -->
    <div class="no_vip_warp" v-if="islogin && subParam.is_subscribe == '1' && subParam.is_have_view_auth == '2' && subParam.is_month_chance == '1'">
        <div class="no_vip_title">您本月的免费浏览次数已用完，请付费观看</div>
        <div class="no_vip_wrap">
            <div class="no_vip_tips">
                <div>NEJM 医学前沿</div>
                <div>订阅会员可继续浏览</div>
            </div>
            <div class="no_vip_line"></div>
            <div class="open_vip" @click="openVip">
                立即订阅
            </div>
        </div>
    </div>
    <!-- 免费次数已用完弹窗 end -->
</template>
<script>
// import {
//     useFreeRead
// } from '@/api/article'
// import merge from 'webpack-merge'
export default {
    name: 'FreeReadPopup',
    data() {
        return {
            userInfo: {
                truename: "张学友"
            }
        }
    },
    props: {
        subParam:{
            type: Object,
            default: () => ({
                is_subscribe:null,
                is_have_view_auth:null,
                is_month_chance:null
            }),
        },
        islogin: {
            type: Boolean,
            default: false,
        },
        isFree: {
            type: Boolean,
            default: true,
        },
        is_subscribe: {
            type: Number,
            default: 1
        },
        is_have_view_auth: {
            type: Number,
            default: 2
        },
        is_month_chance: {
            type: Number,
            default: 1
        },
    },
    mounted() {
        console.log('没有次数弹窗')
    },
    methods: {
        close() {
            this.$emit('close', false)
        },
        openVip(){
            // this.$router
            // .push({
            //     query: merge(this.$route.query, { type: 'flag4' }),
            // })
            this.$router.push({ path:'/personal', query:{ type: 'flag4',herfUrl:window.location.href } })
            .catch((err) => {
                console.log('输出报错', err)
            })
        }
    },

}
</script>
<style lang="less" scoped>
/**
 * 没有会员
 */
.NoFree_cont {
    position: fixed;
}
.no_vip_warp{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 215px;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.53) 1%, #FFFFFF 40%, #FFFFFF 100%);
}
.no_vip {
    width: 100%;
    /* height: 279px; */
    /** 底部小块白色*/
    // height: 100%;
    /** 整个页面白色*/
    height: 215px;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.53) 1%, #FFFFFF 40%, #FFFFFF 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    z-index: 9999;
    gap: 20px;
    left: 0;
    flex-direction: column;
}

.no_vip_title {
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: bold;
    display: block;
}

.no_vip_wrap {
    display: flex;
    align-items: center;
    gap: 28px;
}

.no_vip_tips {
    display: flex;
    gap: 6px;
    flex-direction: column;
}

.no_vip_tips div {
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: #E52E00;
    font-weight: 400;
}

.no_vip_line {
    width: 4px;
    height: 56px;
    background: #E52E00;
}

.open_vip {
    padding: 11px 80px;
    background: #E52E00;
    border-radius: 28px;
    color: #fff;
    font-family: PingFangSC-Medium;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

// 移动端样式
.mobile {
    .no_vip {
        width: 100%;
        // /* height: 279rpx; */
        /** 底部小块白色*/
        // height: 100%;
        height: 300px;
        /** 整个页面白色*/
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.53) 1%, #FFFFFF 40%, #FFFFFF 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 0;
        z-index: 9999;
        gap: 20px;
        flex-direction: column;
    }

    .no_vip_title {
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #000;
        text-align: center;
        font-weight: bold;
        display: block;
    }

    .no_vip_wrap {
        display: flex;
        align-items: center;
        gap: 28px;
    }

    .no_vip_tips {
        display: flex;
        gap: 6px;
        flex-direction: column;
    }

    .no_vip_tips div {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #E52E00;
        font-weight: 400;
    }

    .no_vip_line {
        width: 2px;
        height: 50px;
        background: #E52E00;
    }

    .open_vip {
        padding: 12px 50px;
        background: #E52E00;
        border-radius: 28px;
        color: #fff;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
    }
}
</style>
  