<template>
  <div class="item" style="position: relative">
    <template v-if="model.subjectSerType === 3">
      <div
        class="img"
        :style="{
          'background-image': `url('${
            model.coverPc != null && model.coverPc != '' ? JSON.parse(model.coverPc)[0].path : ''
          }')`,
        }"
      ></div>
      <!--      <img :src="model.coverPc != null ? JSON.parse(model.coverPc)[0].path : ''" class="img" alt="" />-->
      <div style="padding: 8px">
        <div class="title">{{ model.title }}</div>
        <div class="desc">
          {{ model.subhead }}
        </div>
        <div class="fr">
          <div class="tagContainer">
            <div v-for="(value, index) in model.classNames" :key="index">
              <a-tag
                color="#a1224e"
                style="
                  font-size: 12px;
                  max-width: 100px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  cursor: pointer;
                "
              >
                <a-tooltip @click.stop.prevent="onItemClick">
                  <template slot="title">{{ value }}</template>
                  {{ value }}
                </a-tooltip>
              </a-tag>
            </div>
          </div>

          <!-- <a-tag
            color="#a1224e"
            style="font-size: 12px; max-width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
          >
            <a-tooltip>
              <template slot="title">{{ model.classNames.join(',') }}</template>
              {{ model.classNames.join(',') }}
            </a-tooltip>
          </a-tag> -->
          <div class="date">{{ model.publishdate | formatDateTxt }}</div>
        </div>
      </div>
    </template>
    <template v-else>
      <!--            <div-->
      <!--              class="img"-->
      <!--              :style="{-->
      <!--                'background-image': `url('${-->
      <!--                  model.coverPc != null && model.coverPc != '' ? JSON.parse(model.coverPc)[0].path : ''-->
      <!--                }')`,-->
      <!--              }"-->
      <!--            ></div>-->
      <!--      <img-->
      <!--        class="img"-->
      <!--        :src="model.coverPc != null && model.coverPc != '' ? JSON.parse(model.coverPc)[0].path : ''"-->
      <!--        alt=""-->
      <!--      />-->
      <el-image
        v-if="model.subjectSerType == 5 || model.subjectSerType == 2"
        class="img"
        :src="model.coverMobile != null && model.coverMobile != '' ? JSON.parse(model.coverMobile)[0].path : ''"
      ></el-image>
      <el-image
        v-else
        class="img"
        :src="model.coverPc != null && model.coverPc != '' ? JSON.parse(model.coverPc)[0].path : ''"
      ></el-image>
      <a-tag v-if="model.subjectSerType == 5" class="tag" >
        {{ model.subjectSerType == 5 ? '系列课' : model.subjectType == 2 ? '视频' : '音频' }}
      </a-tag>
      <a-tag v-if="model.subjectSerType == 2" class="tag" >
        {{ model.subjectSerType == 2 ? '系列课' : model.subjectType == 2 ? '视频' : '音频' }}
      </a-tag>
      <div class="audioplay"></div>
      <!--<div v-if="model.subjectSerType == 5" style="padding: 16px; position: absolute; top: 30px; width: 78%">
        <div class="title">{{ model.title }}</div>
        <div class="desc">{{ model.subhead }}</div>
        <div class="fr">
          <div class="date">{{ model.publishdate | formatDateTxt }}</div>
          <div style="color: #a1224e; font-size: 16px">
            {{ model.ifViewAuth === 0 ? '' : '' }}
          </div>
        </div>
      </div>
      <div v-if="model.subjectSerType == 2" style="padding: 16px; position: absolute; top: 30px; width: 78%">
        <div class="title" style="color: #ffffff">{{ model.title }}</div>
        <div class="desc" style="color: #ffffff">{{ model.subhead }}</div>
        <div class="fr">
          <div class="date" style="color: #ffffff">{{ model.publishdate | formatDateTxt }}</div>
          <div style="color: #ffffff; font-size: 16px">
            {{ model.ifViewAuth === 0 ? '' : '' }}
          </div>
        </div>
      </div>-->
      <div class="audiotext">
        <div class="title">{{ model.title }}</div>
        <div style="display:flex">
          <div class="desc" :title="model.subhead">{{ model.subhead }}</div>
          <div class="date">{{ model.publishdate | formatDateTxt }}</div>
        </div>
        <!--<div class="fr">
          <div class="date">{{ model.publishdate | formatDateTxt }}</div>
          <div style="color: #a1224e; font-size: 16px">
                        免费-->
            <!--{{ model.ifViewAuth === 0 ? '' : '' }}
          </div>
        </div>-->
      </div>
    </template>
  </div>
</template>

<script>
import { format } from '@/utils/time-utils'
const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}
export default {
  name: 'CourseResourceCard',
  components: {},
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  filters: {
    formatDateTxt(time) {
      return format(time, 'YYYY-MM-DD')
    },
  },
  data() {
    return {}
  },
  methods: {
    onItemClick(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
  },
}
</script>

<style lang="less" scoped>
.item {
  position: relative;
  // overflow: hidden;
  cursor: pointer;
  color: #000;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  .img {
    // width: 100%;
    // height: 208px;
    width: 378px;
    height: 223px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    //width: 100%;
  }
  .audiotext {
    padding-top: 18px;
    padding-bottom:16px
  }
  .title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 34px;
    font-family: MicrosoftYaHei-Bold;
    padding: 0 5px;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 700;
  }
  .desc {
    //height: 2em;
    width: 278px;
    height: 24px;
    line-height: 24px;
    // margin-bottom: 4px;
    // margin-top: 4px;
    font-size: 14px;
    margin-left: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #666666;
    font-weight: 400;
  }
  .date {
      // color: rgba(0, 0, 0, 0.65);
      // font-size: 12px;
      // font-weight: 400;
      width: 88px;
      height: 24px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      margin-right: 5px;
      color: #999999;
      letter-spacing: 0;
      text-align: right;
      line-height: 24px;
      font-weight: 400;
    }
    .audioplay {
      position: absolute;
      width: 44px;
      height: 43px;
      top: 89px;
      left: 168px;
      background-image: url('../../../assets/imgs/audioplay.png');
    }
  .fr {
    margin-top: 5px;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    .date {
      // color: rgba(0, 0, 0, 0.65);
      // font-size: 12px;
      // font-weight: 400;
      width: 89px;
      height: 24px;
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #999999;
      letter-spacing: 0;
      text-align: right;
      line-height: 24px;
      font-weight: 400;
    }
  }
  .tagContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .tag {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    width: 72px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    background: #E52E00;
    border-radius: 0px 8px 0px 0px;
    border: none;
  }
}

.mobile {
  .item {

    border: none;
    height: auto;
    border-bottom: 1px solid #d4d4d4;
    border-radius: 0;
    padding-bottom: 12px;
    // border-bottom: 1px solid #d4d4d4;
    .tag {
      width: 48px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 12px;
    }
  }
  .ant-list-grid .ant-col > .ant-list-item {
    margin-bottom: 12px;
  }
  .img {
    width: 360px;
    height: 178px;
    background-repeat: no-repeat;
    background-size: cover;
    // height: auto;
    //width: 100%;
  }
  .audioplay {
      width: 21px;
      height: 21px;
      top: 74px;
      left: 168px;
      background-image: url('../../../assets/imgs/audioplay2.png');
    }
  .title {
    height: auto;
    font-size: 14px;
  }
  .desc {
    //height: 2em;
    width: 290px;
    height: auto;
    line-height: 12px;
    // margin-bottom: 4px;
    // margin-top: 4px;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #666666;
    font-weight: 400;
  }
    .date {
      // color: rgba(0, 0, 0, 0.65);
      // font-size: 12px;
      // font-weight: 400;
      width: 89px;
      height: auto;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      line-height: 12px;
    }
  .audiotext {
    padding-top: 12px;
    padding-bottom:0
  }
}
</style>
