import { render, staticRenderFns } from "./Commentary.vue?vue&type=template&id=9ceb2abc&scoped=true"
import script from "./Commentary.vue?vue&type=script&lang=js"
export * from "./Commentary.vue?vue&type=script&lang=js"
import style0 from "./Commentary.vue?vue&type=style&index=0&id=9ceb2abc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ceb2abc",
  null
  
)

export default component.exports