<template>
  <!-- <div class="cr-img" :style="{'background-size':'1920px '+pxdata_height+'px'}"> -->
  <div :class="active=='video'? 'cr-img':'cr-img1'">
    <!--    <img class="logo" src="../../../assets/imgs/cr-bg.jpeg" alt="" />-->
    <a-col v-if="$isMobile && bannners.pic != null && bannners.pic.length > 0" :span="$isMobile ? 24 : 11">
      <div>
        <img
          :style="$isMobile ? 'height: 230px; width: 100%' : 'height: 267px; width: 516px'"
          :src="bannners.pic"
          alt=""
        />
      </div>
    </a-col>
    <MainCenter :class="$isMobile ? 'box-mb' : 'box'">
      <a-row :gutter="20">
        <a-col v-if="!$isMobile && bannners.pic != null && bannners.pic.length > 0" :span="$isMobile ? 24 : 11">
          <div>
            <img
              :style="$isMobile ? 'height: 230px; width: 100%' : 'height: 267px; width: 516px'"
              :src="bannners.pic"
              alt=""
            />
          </div>
        </a-col>
        <a-col
          v-if="list != null && list.length > 0"
          :span="$isMobile ? 24 : 12"
          :style="
            $isMobile ? ('height: ' + list.length > 1 ? '330' : '230' + 'px;margin-top: 20px; ') : 'height: 276px'
          "
        >
          <template>
            <div class="title">NEJM医学前沿会议预告</div>
            <a
              v-for="(item, i) of list"
              :key="i"
              :href="'/meeting-notice' + generate(item.lessonAdvId)"
              @click.prevent="goDetailInfo(item)"
            >
              <div class="item" style="cursor: pointer">
                <a-row :gutter="18">
                  <a-col :span="$isMobile ? 12 : 7">
                    <img class="item-img" :src="item.imgPc" alt="" width="163" height="112" />
                  </a-col>
                  <a-col :span="$isMobile ? 11 : 16" :style="!$isMobile ? 'margin-left:10px' : ''">
                    <div class="item-title">{{ item.title }}</div>
                    <div class="tagContainer">
                      <div v-for="(value, index) in item.articelClassName.split(',')" :key="index">
                        <a-tag
                          color="#a1224e"
                          style="
                            font-size: 12px;
                            max-width: 100px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            cursor: pointer;
                            font-weight: 400;
                          "
                        >
                          <a-tooltip @click.stop.prevent="onItemClick">
                            <template slot="title">{{ value }}</template>
                            {{ value }}
                          </a-tooltip>
                        </a-tag>
                      </div>
                    </div>
                    <div class="item-date">
                      {{ item.beginTime | formatDateTxt }} - {{ item.endTime | formatDateTxt }}
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a>
          </template>
        </a-col>
      </a-row>
      <!--      <van-button type="primary">主要按钮</van-button>-->
      <!-- <div v-if="$isMobile" class="ft-block" style="width: 100%"> -->
      <!--        <div :class="active == 'luntan' ? 'ft-lc ft-ck' : 'ft-lc'" @click="changeTab('luntan')">临床研究论坛</div>-->
      <!--        <div class="ft-line"></div>-->
      <!-- <div :class="active == 'ketang' ? 'ft-kc ft-ck' : 'ft-kc'" @click="changeTab('ketang')">NEJM编辑课堂</div>
      </div> -->

      <a-divider
        v-if="(bannners.pic != null && bannners.pic.length > 0) || (list != null && list.length > 0)"
        :style="$isMobile ? 'margin-top: 16px' : 'margin-top: 30px'"
      />
      <CourseResourceCardList v-if="$isMobile" :type="active" />
      <CourseResourceCardList v-if="!$isMobile" :type="active" />

      <!-- <FreeReadPop :subParam="subParam" :islogin="islogin" v-show="isShow&&isFree" @close="close" @getArticle="getArticleInfo"></FreeReadPop> -->
      <!-- <noFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow&&subscribe_info.service_type == 0" ></noFreeReadPop> -->
      <!--没有免费次数-->
      <!-- <noUseFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow && isClose && subscribe_info.service_type == 0  && subParam.is_month_chance =='2'" @noclose="closePop" @getArticle="getArticleInfo" @content="getContent"></noUseFreeReadPop>点击不使用免费次数后，显示可以使用一次免费次数 -->
      <!-- <nologinPop v-if="!isShow" @login="login"></nologinPop> -->

    </MainCenter>
  </div>
</template>

<script>
import CourseResourceCardList from '@/components/article/course-resources/CourseResourceCardList.vue'
import MainCenter from '@/components/layout/MainCenter.vue'
import { conferenceAdvanceList } from '@/api/course'
import { getBanner } from '@/api/base'
import { PATH_MEETING_NOTICE } from '@/router/article/paths'
import { format } from '@/utils/time-utils'
import { generateHref } from '@/utils/utils'

import { mapMutations } from 'vuex'
// import { loginWithoutPwd,loginWithoutPwdwithQueryload } from '@/api/login'
import { mapState } from 'vuex'
// import FreeReadPop from '@/components/article/popup/FreeReadPopup.vue';
// import noFreeReadPop from '@/components/article/popup/noFreeReadPopup.vue';
// import nologinPop from '@/components/article/popup/nologinPopup.vue';
// import nologinPop from '@/components/article/popup/nologinPop.vue';

// import noUseFreeReadPop from '@/components/article/popup/noUseFreePopup.vue';
import { getMemberInfo } from '@/api/personal'
const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}
export default {
  name: 'CourseResources',
  components: { CourseResourceCardList, MainCenter,
    // nologinPop,
    // FreeReadPop,
    // noFreeReadPop,
    // noUseFreeReadPop
   },
  data() {
    return {
      //新增功能 start
      isFree:false,
      isShowFree:false,
      noFree:false,
      islogin:false,
      subParam:{
        is_subscribe:null,//是否付费文章（1：是；2：否）
        is_have_view_auth:null,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
        is_month_chance:null//未付费或者已付费但是会员到期的用户，每个用户每个月有一次免费的机会 1：表示无机会；2：表示有机会
      },//会员信息
      subscribe_info:{
        service_type:"",
        service_end_date:""
      },//会员信息
      isClose:false,
      //新增功能 end
      list: [],
      bannners: [],
      active: 'video', // ketang
      usefreeCont:false,///////使用免费次数之后
      data:{}
    }
  },
  filters: {
    formatDateTxt(time) {
      return format(time, 'YYYY-MM-DD')
    },
  },
  watch: {
    '$route.query': {
      handler() {
        if (this.$route.query.type != null) {
          if (this.$route.query.type == 'video' || this.$route.query.type == 'img') {
            this.active = this.$route.query.type
          }
        }
      },
      immediate: true,
    },
  },
  // computed:{
  //   pxdata_height(){
  //     return window.document.querySelector('.item2>form').offsetHeight+56
  //   }
  // },
  async created() {
    console.log('created')
    await this.conferenceAdvanceList()
    await this.getBanner()
    if(this.isShow){
      this.islogin = true
      console.log('1',this.islogin)
    }else{
      this.islogin = false
      this.memberId = null
      window.sessionStorage.setItem('memberId', null)
    }
       //是否登录。登录查询用户信息
    if(this.isShow){
      this.initialize()
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isShow() {
      return !!this.user
    },
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    login() {
      // this.$emit('login')
      this.$bus.emit('login')
    },
    showContent(e){
      this.usefreeCont = e
    },
    getContent(e){
      console.log(e,'文章content')
      this.data.content = e
    },
    async initialize() {
      let res = await getMemberInfo()
      console.log(res,'====用户信息') 
      this.subscribe_info = res.subscribe_info || {}
      this.subscribe_info.service_type= res.subscribe_info.service_type || ''
    },
    onItemClick(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    changeTab(tab) {
      this.active = tab
      console.log(this.active + 'ceshiceshiceshiceshiceshiceshiceshiceshiceshi')
    },
    goDetailInfo(item) {
      console.log('goDetailInfo::', item)
      this.$router.push({ path: PATH_MEETING_NOTICE + '?id=' + item.lessonAdvId })
    },
    generate(item) {
      return generateHref('id', JSON.stringify(item))
    },
    async getBanner() {
      let res = await getBanner({ code: 'classroom' })
      if (null != res.content) {
        this.bannners = JSON.parse(res.content)
      }
      console.log('banner::', this.bannners)
    },
    async conferenceAdvanceList() {
      let res = await conferenceAdvanceList({
        pageNo: 1,
        pageSize: 2,
        lessAdvStatus: 2,
      })
      console.log(res, 'aaa')
      let arr = res.results
      this.list = arr
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.cr-img {
  background-image: url('../../../assets/imgs/cr-bg1.jpg');
  background-repeat: no-repeat;
}
.cr-img1 {
  background-image: url('../../../assets/imgs/cr-bg.png');
  background-repeat: no-repeat;
}
.box {
  padding: 56px 0;
}
.box-mb {
  padding-left: 7px !important;
  padding-right: 8px !important;
  padding-bottom: 30px;
}
.title {
  color: #a1224e;
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 8px;
}

.item {
  margin: 15px 0;
  .item-img {
    object-fit: cover;
  }
  .item-title {
    height: 2em;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
    overflow: hidden;
  }
  .tagContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .item-date {
    margin-top: 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
  }
}
.ft-block {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
}
.ft-lc {
  width: 180px;
  font-size: 28px;
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  line-height: 36px;
  cursor: pointer;
}

.ft-line {
  width: 3px;
  height: 28px;
  border-left: solid 3px rgba(0, 0, 0, 0.85);
}
.ft-kc {
  width: 220px;
  font-size: 28px;
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  line-height: 36px;
  padding-left: 10px;
  cursor: pointer;
}

.ft-ck {
  color: #a1224e;
}
.mobile {
  .box {
    padding: 0;
  }
  .cr-img {
    background-image: none;
  }
  .cr-img1 {
    background-image: none;
  }
  .item {
    margin: 15px 0;
    .item-title {
      height: 2em;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 400;
      overflow: hidden;
      margin-bottom: 5px;
      text-overflow: ellipsis;
    }
  }
  .item-date {
    margin-top: 12px;
    font-size: 10px;
  }
  .ft-lc {
    width: 120px;
    font-size: 18px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    line-height: 18px;
    cursor: pointer;
  }

  .ft-line {
    width: 2px;
    height: 18px;
    border-left: solid 3px rgba(0, 0, 0, 0.85);
  }
  .ft-kc {
    width: 140px;
    font-size: 18px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    line-height: 18px;
    padding-left: 10px;
    cursor: pointer;
  }

  .ft-ck {
    color: #a1224e;
  }
}
</style>
