<template>
  <div class=""></div>
</template>

<script>
export default {
  name: 'ArticleVideo',
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped></style>
