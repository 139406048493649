<template>
  <div>
    <div class="topContent">
      <div class="topCenter">
        <div v-if="!$isMobile" class="year">{{ year }}年{{ month }}</div>
        <div v-if="!$isMobile" class="month">{{ transMap[month] }}</div>
        <div v-if="$isMobile" class="dateFit">
          {{ month }}
          <span>/{{ year }}</span>
        </div>
        <div v-if="!$isMobile" style="float: left; bottom: 0; position: absolute">
          <el-select v-model="year" placeholder="请选择" @change="changeYear">
            <el-option v-for="item in yearArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div :class="!$isMobile ? 'tabsContainer' : 'tabsContainerFit'">
          <el-tabs v-if="!$isMobile" v-model="activeName" @tab-click="handleClick" type="border-card" class="tabList">
            <el-tab-pane
              v-for="(item, index) in monthArr"
              :key="index"
              :name="item.value"
              :label="item.label"
            ></el-tab-pane>
          </el-tabs>
          <van-tabs v-else v-model="activeName" type="card" @click="handleClick">
            <van-tab v-for="(item, index) in monthArr" :key="index" :name="item.value" :title="item.label"></van-tab>
          </van-tabs>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <MainCenter class="articleList">
      <div style="margin: 20px 0" v-for="(item, index) in this.dataList" :key="index">
        <a-row v-if="!$isMobile" class="contentFitContainer" :gutter="50">
          <a-col :span="16">
            <ArticleDetail :list="item" />
          </a-col>
          <a-col :span="8">
            <ArticleComment :list="item" :catalogIndex="index" :month="params.month" />
          </a-col>
        </a-row>
        <a-row v-else class="contentFitContainer">
          <a-col>
            <ArticleDetail :list="item" />
            <ArticleComment :list="item" />
          </a-col>
        </a-row>
      </div>
    </MainCenter>
  </div>
</template>

<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import ArticleDetail from '@/components/article/PeriodArticle/ArticleDetail.vue'
import ArticleComment from '@/components/article/PeriodArticle/ArticleComment.vue'
import { selectQiKanList, getMonthOfQiKanYear } from '@/api/article'
export default {
  name: 'PeriodArticle',
  components: { MainCenter, ArticleDetail, ArticleComment },
  data() {
    return {
      params: {
        month: null,
        pageNo: 1,
        pageSize: 20,
      },
      dataList: [],
      monthArr: [],
      yearArr: [],
      activeName: '01',
      year: null,
      month: null,
      transMap: {
        '1月': 'January',
        '2月': 'February',
        '3月': 'March',
        '4月': 'April',
        '5月': 'May',
        '6月': 'June',
        '7月': 'July',
        '8月': 'August',
        '9月': 'September',
        '10月': 'October',
        '11月': 'November',
        '12月': 'December',
      },
      choosedMonthArr: [],
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.year = this.$route.query.year
        this.getMonthArr()
        this.monthArr = []
        getMonthOfQiKanYear({ year: this.year }).then((res) => {
          this.choosedMonthArr = res
          if (this.choosedMonthArr.length === 0) {
            return
          }
          for (var m of this.choosedMonthArr) {
            for (var temp of this.initMonthArr) {
              if (temp.value == m) {
                this.monthArr.push(temp)
              }
            }
          }
          if (this.$route.query.month != null) {
            this.activeName = this.$route.query.month
            this.month = Number(this.$route.query.month) + '月'
          } else {
            this.activeName = this.monthArr[this.monthArr.length - 1].value.toString()
            this.month = this.monthArr[this.monthArr.length - 1].label
          }
          this.params.month = this.year + this.activeName
          this.getData()
        })
      },
      immediate: true,
    },
  },
  created() {
    this.getYearArr()
  },
  methods: {
    async getData() {
      this.dataList = []
      let res = await selectQiKanList(this.params)
      for (var temp of res.results) {
        var data = {
          NEJMList: temp.articleMap[5],
          NEJMArticleList: temp.articleMap[6],
          videoList: temp.articleMap[7],
          catalogList: temp.articleMap[0],
          commentList: [],
          weeklyTitle: temp.articleWeeklyPo.title,
        }
        if (data.NEJMList != null) {
          for (var NEJM of data.NEJMList) {
            if (NEJM.wantsayTitle !== '') {
              data.commentList.push(NEJM)
            }
          }
        }
        if (data.NEJMArticleList != null) {
          for (var NEJMArticle of data.NEJMArticleList) {
            if (NEJMArticle.wantsayTitle !== '') {
              data.commentList.push(NEJMArticle)
            }
          }
        }
        if (data.videoList != null) {
          for (var video of data.videoList) {
            if (video.wantsayTitle !== '') {
              data.commentList.push(video)
            }
          }
        }
        if (data.catalogList != null) {
          for (var catalog of data.catalogList) {
            if (catalog.wantsayTitle !== '') {
              data.commentList.push(catalog)
            }
          }
        }
        this.dataList.push(data)
      }
    },
    getMonthArr() {
      this.initMonthArr = []
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      if (this.year < year) {
        for (var i = 0; i < 12; i++) {
          this.initMonthArr.push({
            value: (i + 1 < 10 ? '0' + (i + 1) : i + 1).toString(),
            label: i + 1 + '月',
          })
        }
      } else {
        for (var y = 0; y < month; y++) {
          this.initMonthArr.push({
            value: (y + 1 < 10 ? '0' + (y + 1) : y + 1).toString(),
            label: y + 1 + '月',
          })
        }
      }
    },
    getYearArr() {
      var date = new Date()
      var year = date.getFullYear()
      for (var i = year - 10; i < year + 1; i++) {
        this.yearArr.push({
          label: i,
          value: i,
        })
      }
    },
    handleClick(tab, event) {
      if (this.$isMobile) {
        this.month = event
      } else {
        this.month = tab.label
      }
      if (this.$isMobile) {
        this.params.month = this.year.toString() + tab.toString()
      } else {
        this.params.month = this.year.toString() + tab.name.toString()
      }
      let query = { ...this.$route.query }
      query.month = this.activeName.toString()
      this.$router.push({
        path: '/period-article',
        query: query,
      })
      // this.getData()
    },
    async changeYear(value) {
      let query = { ...this.$route.query }
      this.monthArr = []
      this.year = value
      this.getMonthArr()
      this.choosedMonthArr = await getMonthOfQiKanYear({ year: this.year })
      if (this.choosedMonthArr.length === 0) {
        return
      }
      for (var m of this.choosedMonthArr) {
        for (var temp of this.initMonthArr) {
          if (temp.value == m) {
            this.monthArr.push(temp)
          }
        }
      }
      console.log(this.choosedMonthArr, 'this.initMonthArr')
      this.activeName = this.monthArr[this.monthArr.length - 1].value.toString()
      this.params.month = value + this.activeName.toString()
      query.year = this.year
      query.month = this.activeName.toString()
      this.$router.push({
        path: '/period-article',
        query: query,
      })
      // this.getData()
    },
  },
}
</script>

<style lang="less" scoped>
.mobile {
  .topContent {
    height: 100px;
    .topCenter {
      width: 100%;
      .dateFit {
        font-size: 22px;
        line-height: 24px;
        color: #555;
        // padding-top: 0px;
        padding: 15px 0 10px 15px;
        span {
          font-size: 14px;
          line-height: 24px;
        }
      }
      .tabsContainerFit {
        /deep/.van-tabs {
          .van-tab {
            background-color: #ececec;
            border-right: 1px solid #d9d9d9;
            color: #6f6f6f;
            width: 60px;
          }
        }
        /deep/.van-tabs__nav--card {
          box-sizing: border-box;
          height: 30px;
          margin: 0 16px;
          border: none;
        }
        /deep/.van-tabs__nav--card .van-tab.van-tab--active {
          color: #fff;
          background-color: #e52e00;
        }
      }
    }
  }
  .articleList {
    margin-top: 0px;
    padding: 0 15px;
    .contentFitContainer {
    }
  }
}
.topContent {
  background-color: #d9d9d9;
  height: 196px;
  .topCenter {
    max-width: 1200px;
    margin: 0 auto;
    height: 196px;
    position: relative;
    .tabsContainer {
      width: 82%;
      bottom: 0;
      position: absolute;
      left: 120px;
    }
  }
  .year {
    font-size: 42px;
    // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
    color: rgba(0, 0, 0, 0.65);
    padding-top: 24px;
    line-height: 50px;
  }
  .month {
    font-size: 24px;
    // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
    color: rgba(0, 0, 0, 0.65);
    padding-top: 13px;
    line-height: 30px;
  }
}
.articleList {
  min-height: calc(100vh - 370px);
  margin-top: 40px;
}
</style>
<style lang="less">
.topCenter {
  .el-input--small .el-input__inner {
    height: 48.5px;
    width: 100px;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 18px;
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  }
  .el-tabs__content {
    padding: 0 !important;
  }
  .el-tabs--border-card {
    border: none;
    box-shadow: none !important;
  }
  .el-tabs--border-card > .el-tabs__header {
    background-color: #d9d9d9 !important;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    border: 2px solid #d9d9d9;
    border-bottom: none;
    color: #333;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #ececec;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    background-color: #ffffff;
    color: #e52e00;
  }
  .el-select .el-input.is-focus .el-input__inner {
    border: none !important;
  }
  .el-select .el-input__inner:focus {
    border: none !important;
  }
}
.articleList {
  .fr {
    border-bottom: 0;
  }
}
.contentFitContainer .card-css {
  background: #fff;
}
</style>
