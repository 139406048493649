<template>
  <div>
    <el-button v-if="$isMobile" type="primary" @click="drawer = true" class="curriculumResourcesShaiXuan">
      筛选
    </el-button>
    <el-drawer :visible.sync="drawer" :with-header="false" size="80%" class="curriculumResourcesDrawer">
      <div class="shaiXuanContainer1">
        <van-cell title="日期" :value="timeFit" @click="show = true" />
        <van-calendar :min-date="minDate" :max-date="maxDate" v-model="show" type="range" @confirm="changeTime2" />
      </div>
      <div class="shaiXuanContainer2">
        <div>类型</div>
        <el-select
          @change="changeKeChengFenZu"
          multiple
          collapse-tags
          v-model="params.subjectSerType"
          placeholder="请选择类型"
        >
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
    </el-drawer>
    <div style="width: 100%; height: 1px; background-color: #d4d4d4; margin: 2px 0 16px 0"></div>
    <div v-if="!$isMobile" class="header">
      <el-date-picker
        unlink-panels
        v-model="time"
        @change="changeTime"
        class="changeTime"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="width: 240px"
      ></el-date-picker>
      <el-select
        @change="changeKeChengFenZu"
        multiple
        collapse-tags
        v-model="params.subjectSerType"
        placeholder="请选择类型"
      >
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </div>
    <a-list
      v-if="!$isMobile"
      :grid="{ gutter: 24, column: 3 }"
      :pagination="flag === true ? pagination : null"
      :data-source="listData"
      :loading="loading"
    >
      <a-list-item style="margin-bottom: 8px" slot="renderItem" :key="index" slot-scope="item, index" class="aListItem">
        <a @click.prevent="onItemClick(item)">
          <el-image
            style="width: 100%; height: 136px"
            :src="
              item.coverPc != ''
                ? item.coverPc[0].url
                : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg08.viwik.com%2Fimages%2F20180820%2Ftooopen_sy_154340434039264.jpg&refer=http%3A%2F%2Fimg08.viwik.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1634519796&t=256bfdfa3d04ce6d27214dd3de65664c'
            "
            fit="cover"
          ></el-image>
          <i v-if="item.ifFav == 1" class="iconfont icon-shanchu" @click.stop.prevent="collect(item)"></i>
        </a>
        <div class="biankuang">
          <div class="title ellipsis">
            <a @click.prevent="onItemClick(item)">{{ item.title }}</a>
          </div>
          <div class="content ellipsisTwo">
            <a class="content" @click.prevent="onItemClick(item)">
              {{ item.subhead }}
            </a>
          </div>
          <div class="biaoQainTimeContainer">
            <div class="biaoQainContainer">
              <div v-for="(className, index) in item.classNames" :key="index">
                <a @click.prevent="onItemClick2" class="biaoqian ellipsis mianfei">
                  {{ className }}
                </a>
              </div>
            </div>
            <div class="createTime">{{ item.createTime }}</div>
          </div>
        </div>
      </a-list-item>
    </a-list>
    <a-list v-else :pagination="flag === true ? pagination : null" :data-source="listData">
      <a-list-item style="margin-bottom: 8px" slot="renderItem" :key="index" slot-scope="item, index" class="aListItem">
        <a @click.prevent="onItemClick(item)">
          <el-image
            style="width: 100%; height: 216.23px"
            :src="
              item.coverPc != ''
                ? item.coverPc[0].url
                : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg08.viwik.com%2Fimages%2F20180820%2Ftooopen_sy_154340434039264.jpg&refer=http%3A%2F%2Fimg08.viwik.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1634519796&t=256bfdfa3d04ce6d27214dd3de65664c'
            "
            fit="cover"
          ></el-image>
          <i v-if="item.ifFav == 1" class="iconfont icon-shanchu" @click.stop.prevent="collect(item)"></i>
        </a>
        <div class="biankuang">
          <div class="title ellipsis">
            <a @click.prevent="onItemClick(item)">{{ item.title }}</a>
          </div>
          <div class="content ellipsisTwo">
            <a class="content" @click.prevent="onItemClick(item)">
              {{ item.subhead }}
            </a>
          </div>
          <div class="biaoQainTimeContainer">
            <div class="biaoQainContainer">
              <div v-for="(className, index) in item.classNames" :key="index">
                <a @click.prevent="onItemClick2" class="biaoqian ellipsis mianfei">
                  {{ className }}
                </a>
              </div>
            </div>
            <div class="createTime">{{ item.createTime }}</div>
          </div>
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>
<script>
import moment from 'moment'
import { format } from '@/utils/time-utils.js'
import { kechengfenzu } from './data'
import { selectMyFavLesson } from '@/api/personal'
import { mapState } from 'vuex'
import { collectArticle, cancelCollectLesson } from '@/api/user-api.js'
import { getSessionUser } from '@/utils/app-utils'

const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}
export default {
  name: 'CurriculumResources',
  data() {
    return {
      time: [],
      options: kechengfenzu,
      params: {
        startDate: null,
        endDate: null,
        subjectSerType: null,
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      },
      pagination: {
        onChange: (page) => {
          console.log(page)
          this.getSelectMyFavLesson(page)
        },
        current: 1,
        pageSize: 9,
        total: 0,
      },
      flag: false,
      listData: [],
      loading: true,
      drawer: false,
      timeFit: '',
      show: false,
      minDate: new Date(2010, 12, 1),
      maxDate: new Date(),
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    this.getData()
  },
  methods: {
    onItemClick(item) {
      this.$router.push({ path: '/course-video', query: { id: item.lessonResId } })
    },
    onItemClick2(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    // 初始化
    async getData() {
      let res = await selectMyFavLesson({
        pageNo: 1,
        pageSize: this.pagination.pageSize,
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      })
      if (res.results.length == 0) {
        this.flag = false
      } else {
        this.flag = true
      }
      res.results.map((itme) => {
        itme.createTime = format(itme.createTime, 'YYYY-MM-DD')
        if (itme.coverPc !== '') {
          itme.coverPc = JSON.parse(itme.coverPc)
        }
      })
      let data = res.results
      this.pagination.total = res.page.total
      this.listData = data
      this.loading = false
    },
    // 分页请求
    async getSelectMyFavLesson(pageNo) {
      let param = { pageNo, pageSize: this.pagination.pageSize, ...this.params }
      let res = await selectMyFavLesson(param)
      if (res.results.length == 0) {
        this.flag = false
      } else {
        this.flag = true
      }
      res.results.map((itme) => {
        itme.createTime = format(itme.createTime, 'YYYY-MM-DD')
        if (itme.coverPc !== '') {
          itme.coverPc = JSON.parse(itme.coverPc)
        }
      })
      let data = res.results
      this.pagination.total = res.page.total
      this.pagination.current = pageNo
      this.listData = data
    },
    // 选择时间或课程分组请求
    async getSelectMyFavLesson2() {
      let param = { pageNo: 1, pageSize: this.pagination.pageSize, ...this.params }
      let res = await selectMyFavLesson(param)
      if (res.results.length == 0) {
        this.flag = false
      } else {
        this.flag = true
      }
      res.results.map((itme) => {
        itme.createTime = format(itme.createTime, 'YYYY-MM-DD')
        if (itme.coverPc !== '') {
          itme.coverPc = JSON.parse(itme.coverPc)
        }
      })
      let data = res.results
      this.pagination.total = res.page.total
      this.pagination.current = param.pageNo
      this.listData = data
    },
    // 选择时间
    changeTime(val) {
      if (val !== null) {
        this.params.startDate = moment(val[0]).format('YYYYMMDD')
        this.params.endDate = moment(val[1]).format('YYYYMMDD')
        this.getSelectMyFavLesson2()
      } else {
        this.params.startDate = null
        this.params.endDate = null
        this.getSelectMyFavLesson2()
      }
    },
    // 手机版选择时间
    changeTime2(val) {
      this.params.startDate = moment(val[0]).format('YYYYMMDD')
      this.params.endDate = moment(val[1]).format('YYYYMMDD')
      this.show = false
      this.getSelectMyFavLesson2()
      const [start, end] = val
      this.timeFit = `${this.formatDate(start)} - ${this.formatDate(end)}`
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    // 选择课程分组
    changeKeChengFenZu() {
      this.getSelectMyFavLesson2()
    },
    async collect(item) {
      if (this.user) {
        if (item.ifFav === 0) {
          //收藏
          await collectArticle({ id: item.lessonResId })
          this.getSelectMyFavLesson(this.pagination.current)
        } else {
          //取消收藏
          let res = await cancelCollectLesson({ id: item.lessonResId })
          if (res) {
            this.getSelectMyFavLesson(this.pagination.current)
          }
        }
      } else {
        this.$bus.emit('login')
      }
    },
  },
}
</script>
<style lang="less" scoped>
.header {
  margin-bottom: 32px;
  .changeTime {
    margin-right: 16px;
  }
}
.mobile {
  .biankuang {
    width: 100%;
  }
  .aListItem {
    i {
      top: 13px;
      right: 6px;
    }
  }
}
.biankuang {
  margin-top: -5px;
  .title {
    padding: 22px 16px 5px 16px;
    font-size: 14px;
    // font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: rgba(32, 33, 33, 0.85);
    line-height: 22px;
  }
  .content {
    padding: 0px 16px;
    font-size: 12px;
    // font-family: PingFangSC, PingFangSC-Regular;
    color: rgba(0, 0, 0, 0.65);
    line-height: 20px;
    height: 42px;
  }
  .biaoQainTimeContainer {
    padding: 16px 16px 22px 16px;
    display: flex;
    justify-content: space-between;
    .mianfei {
      display: block;
      font-size: 14px;
      // font-family: PingFangSC, PingFangSC-Regular;
      color: rgba(0, 0, 0, 0.85);
      line-height: 20px;
    }
    .biaoQainContainer {
      display: flex;
      flex-wrap: wrap;
      .biaoqian {
        display: block;
        width: 50px;
        height: 20px;
        font-size: 12px;
        line-height: 20px;
        // font-family: PingFangSC, PingFangSC-Regular;
        text-align: center;
        background-color: #e83b19;
        color: #fff;
        border-radius: 2px;
        margin: 0 5px 5px 0;
      }
    }
    .createTime {
      font-size: 12px;
      // font-family: PingFangSC, PingFangSC-Regular;
      color: rgba(0, 0, 0, 0.45);
      line-height: 20px;
    }
  }
}
/deep/.ant-list-pagination {
  margin: 24px 0 40px 0;
  text-align: center;
}
.aListItem {
  position: relative;
  i {
    position: absolute;
    top: 0;
    right: 14px;
  }
}
.curriculumResourcesShaiXuan {
  position: absolute;
  top: 102px;
  right: 15px;
}
.shaiXuanContainer1,
.shaiXuanContainer2 {
  margin-bottom: 10px;
}
.shaiXuanContainer2 {
  div {
    margin-bottom: 8px;
  }
}
</style>
<style lang="less">
.curriculumResourcesDrawer {
  .el-drawer__body {
    padding: 10px;
  }
  .van-cell {
    padding: 0;
  }
}
.shaiXuanContainer1 {
  .van-cell {
    .van-cell__title {
      span {
        font-size: 16px;
      }
    }
  }
}
</style>
