import { render, staticRenderFns } from "./PersonalCollection.vue?vue&type=template&id=04a97111&scoped=true"
import script from "./PersonalCollection.vue?vue&type=script&lang=js"
export * from "./PersonalCollection.vue?vue&type=script&lang=js"
import style0 from "./PersonalCollection.vue?vue&type=style&index=0&id=04a97111&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a97111",
  null
  
)

export default component.exports