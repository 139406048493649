<template>
  <div class="content_box">
    <!-- 相关文章 -->
    <div class="content_top">
      <span style="" class="content_top_title">
        {{ title }}
      </span>
      <span
        class="content_top_more"
        v-if="correlationList !== null ? correlationList.page.total > 10 : false"
        @click="more"
      >
        更多>
      </span>
    </div>
    <div v-for="item in correlationList !== null ? correlationList.results.slice(0,5) : []" :key="item.id" @click="details(item)">
      <!-- 文字信息 -->
      <ul style="font-size: 14px; padding-left: 15px; margin-bottom: 30px; cursor: pointer" class="ulDot">
        <span class="dot"></span>
        <li class="content_title">{{ item.title }}</li>
        <li class="particulars">
          <span>{{ item.typeName ? item.typeName : item.filterName }}</span>
          <span>{{ item.postdate }}</span>
        </li>
      </ul>
      <!-- 视频信息 -->
      <!-- <ul style="font-size: 12px; padding-left: 15px; overflow: hidden; margin-bottom: 20px">
        <li class="video_content">
          <img src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" alt="" />
        </li>
        <li class="video_text">
          <p>标题标题标题标题标题标题标题标题标题标题标题标题</p>
          <p>
            <span>临床病例类</span>
            <span>2021/1/28</span>
          </p>
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
export default {
  //sourceid
  name: 'Correlation',
  props: {
    correlationList: {
      type: Object,
      default: function () {
        return null
      },
    },
    correlation: {
      type: Object,
      default: function () {
        return null
      },
    },
    title: {
      type: String,
      default() {
        return '相关阅读'
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    more() {
      //跳转更多详情
      this.$router.push({
        path: '/professional-more',
        query: { articleIds: this.correlation.articleIds },
      })
    },
    details(item) {
      this.$router.push({ path: '/article-transfer', query: { sourceid: item.sourceid, permalinks: item.permalinks } })
      //防止路由冲突中转
    },
  },
}
</script>

<style lang="less" scoped>
.content_box {
  width: 100%;
  overflow: hidden;
  .content_top {
    width: 100%;
    height: 30px;
    overflow: hidden;
    padding-bottom: 42px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(208, 201, 201);
    .content_top_title {
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
      height: 26px;
      line-height: 26px;
    }
    .content_top_more {
      font-size: 14px;
      // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      font-weight: 400;
      text-align: right;
      color: rgba(0, 0, 0, 0.65);
      height: 22px;
      line-height: 22px;
    }
    span {
      &:nth-of-type(1) {
        float: left;
      }
      &:nth-of-type(2) {
        float: right;
        cursor: pointer;
      }
    }
  }
}
.content_top span:nth-of-type(2):hover {
  color: red;
}
.content_title {
  line-height: 18px;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  text-align: justify;
  color: rgba(0, 0, 0, 0.85);
  // margin-bottom: 18px;
}
.particulars {
  line-height: 1;
  overflow: hidden;
  height: 14px;
  font-size: 12px;
  color: #666666;
  span {
    &:nth-of-type(1) {
      float: left;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
    }
    &:nth-of-type(2) {
      float: right;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.video_content {
  width: 78px;
  height: 58px;
  float: left;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.video_text {
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 5px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:nth-of-type(2) {
      span {
        &:nth-of-type(1) {
          float: left;
        }
        &:nth-of-type(2) {
          float: right;
        }
      }
    }
  }
}
.ulDot {
  position: relative;
  margin: 10px 0;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .dot {
    position: absolute;
    left: 0;
    top: 4px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000;
  }
}
</style>
