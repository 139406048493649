<template>
  <el-select :value="value" placeholder="请选择职称" v-bind="$attrs" v-on="$listeners">
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'JoblevelSelect',
  props: {
    value: {
      required: true,
    },
    size: {
      type: String,
      default() {
        return 'medium'
      },
    },
    params: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: [],
    }
  },
  watch: {
    params: {
      handler() {
        this._loadData()
      },
      immediate: true,
    },
  },
  methods: {
    _loadData() {
      if (this.params == null) {
        this.options = []
      } else {
        this.options = [
          { label: '未定级（在读本科、研究生）', value: '未定级（在读本科、研究生）' },
          { label: '住院医师(初级)', value: '住院医师(初级)' },
          { label: '主治医师(中级)', value: '主治医师(中级)' },
          { label: '副主任医师(副高级）', value: '副主任医师(副高级）' },
          { label: '主任医师(正高级）', value: '主任医师(正高级）' },
        ]
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
