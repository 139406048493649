<template>
  <div class="ct-main cr-img" :style="$isMobile ? 'padding-left: 15px; padding-right: 15px' : ''">
    <div class="ct-block">
      <div :class="$isMobile ? 'item-video-mb' : 'item-video'">
        <div :style="$isMobile ? 'width: 100%;position: relative;height:auto;' : 'width: 100%;position: relative'">
          <img
            v-if="!$isMobile2"
            :src="detail.coverPc != null && detail.coverPc != '' ? JSON.parse(detail.coverPc)[0].url : ''"
            :style="$isMobile ? 'object-fit: cover' : 'object-fit: cover'"
          />
          <img
            v-else
            :src="detail.coverMobile != null && detail.coverMobile != '' ? JSON.parse(detail.coverMobile)[0].url : ''"
            :style="$isMobile ? 'object-fit: cover' : 'object-fit: cover'"
          />
          <!--          <img src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />-->
          <!--          <div class="item-head-title-bq"></div>-->
        </div>
        <div class="item-head" style="top: 60%">
          <!-- <div :style="$isMobile2 ? 'display:none' : ''" class="item-head-title">
            <div class="item-head-title-name">{{ detail.title }}</div>
          </div>
          <div :style="$isMobile2 ? 'height: 42px;display:none' : ''" class="item-head-be">
            {{ detail.subhead }}
          </div>

          <div :style="$isMobile2 ? 'display:none' : ''" class="item-head-time">
            本课程含{{ detail.childLessonList != null ? detail.childLessonList.length : '0' }}个视频
          </div>
          <div :style="$isMobile2 ? 'display:none' : ''" class="item-head-time">
            {{ detail.publishdate | formatDateTxt }}
          </div> -->

          <div style="margin-left: 0%">
            <Share
              :style="$isMobile ? 'width:100%; display: flex; color: #fff' : ''"
              :isShow="{ navigation: false, collect: true, share: true, original: false }"
              :id="detail.lessonResId"
              collectType="lesson"
              v-bind:ifCollect.sync="detail.ifFav"
            ></Share>
          </div>
        </div>
      </div>

      <div :class="$isMobile ? 'item-ct-block-mb' : 'item-ct-block'">
        <div style="width: 100%">
          <div class="item-ct-detail-title">课程简介</div>
          <RichText class="item-ct-detail-txt" :content="detail.content" :about="detail.aboutcontent" />
        </div>
        <div class="item-ct-detail-title" style="width: 100%">
          <div>课程目录</div>
        </div>
        <div class="clear">
          <a
            v-for="(item, index) of detail.childLessonList"
            :key="index"
            @click="goDetailInfo(item)"
            :href="generate(item.lessonResId)"
          >
            <div class="item-ct-detail-list" :style="$isMobile2 ? 'width: 100%' : $isMobile1 ? 'width: 50%' : ''">
              <div class="ct-at-item">
                <div class="ct-at-item-img">
                  <img
                    :style="$isMobile ? 'object-fit: cover' : ''"
                    :src="item.coverPc != null && item.coverPc != '' ? JSON.parse(item.coverPc)[0].url : ''"
                    alt=""
                  />
                  <div class="ct-at-item-txt">
                    <div class="ct-at-item-ct" style="color: rgba(0, 0, 0, 0.85)">
                      {{ item.title }}
                    </div>
                    <div
                      :class="$isMobile ? 'ct-at-item-title-mb' : 'ct-at-item-title'"
                      style="color: rgba(0, 0, 0, 0.85)"
                    >
                      {{ item.subhead }}
                    </div>
                    <div class="ct-at-item-time" style="color: rgba(0, 0, 0, 0.45)">
                      {{ item.publishdate | formatDateTxt }}
                    </div>
                  </div>
                  <!--                  <div class="item-head-title-bq-mini"></div>-->
                </div>
                <!--                :src="item.coverPc != null && item.coverPc != '' ? JSON.parse(item.coverPc)[0].url : ''"-->

                <!--                <div v-if="!$isMobile" class="ct-at-item-btn">-->
                <!--                  <div @click="goDetailInfo(item)" class="ct-at-btn">{{ '查看详情' }}</div>-->
                <!--                </div>-->
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectLessonDetail } from '@/api/course'
import RichText from '@/components/layout/RichText.vue'
import { PATH_COURSE_VIDEO } from '@/router/article/paths'
import Share from '@/components/share/Share1'
import { getSessionUser } from '@/utils/app-utils'
import { format } from '@/utils/time-utils'
import { generateHref } from '@/utils/utils'

export default {
  name: 'SeriesCourses',
  components: { RichText, Share },
  data() {
    return {
      detail: {},
    }
  },
  filters: {
    formatDateTxt(time) {
      return format(time, 'YYYY-MM-DD')
    },
  },
  async created() {
    this.detail.lessonResId = this.$route.query.id
    await this.getDetail()
  },
  methods: {
    async getDetail() {
      let loginUser = getSessionUser()
      let res = await selectLessonDetail({
        id: this.detail.lessonResId,
        memberId: null != loginUser ? loginUser.id : null,
      })
      this.detail = res
    },
    goDetailInfo(item) {
      this.$router.push({ path: PATH_COURSE_VIDEO + '?id=' + item.lessonResId })
    },
    generate(item) {
      return '' + PATH_COURSE_VIDEO + generateHref('id', JSON.stringify(item))
    },
  },
}
</script>

<style lang="less" scoped>
.cr-img {
  // background-image: url('../../../assets/imgs/cr-bg.jpeg');
  background-repeat: no-repeat;
}
.ct-main {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: 20px;
}
.ct-block {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}
.item-video {
  display: flex;
  display: -webkit-flex;
  height: 350px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 40px;
  position: relative;
  // background: #000;
  border-radius: 10px;
}
.item-video div img {
  width: 100%;
  height: 350px;
  border-radius: 10px;
}
.item-head {
  height: 236px;
  padding-top: 4px;
  width: 50%;
  position: absolute;
  left: 40px;
  top: 20%;
}
.share {
  color: #fff;
  margin: 0 30px 0 0;
}
.item-head-title {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.item-head-title-bq {
  text-align: center;
  width: 64px;
}
.item-head-title-bq-mini {
  text-align: center;
  width: 53px;
  color: white;
  background-color: #a1124e;
  font-size: 12px;
  border-radius: 2px;
  height: 26px;
  line-height: 26px;
  position: absolute;
  right: 20px;
  top: 5px;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item-head-title-name {
  text-align: left;
  width: 100%;
  font-size: 32px;
  color: #fff;
}
.item-head-be {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 28px;
  height: 30px;
  line-height: 30px;
  color: #fff;
}
.item-head-time {
  font-size: 16px;
  margin-top: 14px;
  margin-bottom: 14px;
  color: #fff;
}
.item-ct-detail {
  font-size: 16px;
  margin-bottom: 15px;
  color: #fff;
}
.item-ct-detail-txt {
  font-size: 16px;
  margin-bottom: 15px;
  padding-left: 8px;
  color: #fff;
}
.item-ct-detail-list {
  font-size: 7px;
  padding-bottom: 15px;
  color: #fff;
  width: 33.33%;
  float: left;
}
.clear {
  margin: 0 -20px;
}
.clear:after {
  content: '';
  display: block;
  clear: both;
}
.item-ct-block {
  // display: flex;
  // display: -webkit-flex;
  width: 100%;
  //align-items: center;
  // justify-content: center;
}
.item-ct-detail-title {
  font-size: 24px;
  line-height: 40px;
  height: 40px;
  padding-left: 7px;
  text-align: center;
  color: #fff;
  background: #e52e00;
  margin-bottom: 24px;
}
.ct-at-item {
  /* display: flex; */
  /* display: -webkit-flex; */
  width: 100%;
  margin-right: 7%;
  align-items: center;
  justify-content: center;
  /* background-color: #f2f2f2; */
  /* height: 118px; */
  padding: 0 20px;
}
.ct-at-item-img {
  width: 100%;
  position: relative;
  border: 1px solid #d4d4d4;
}
.ct-at-item-img img {
  width: 100%;
  // height: 240px;
  // background: #000;
  // border-radius: 10px;
  object-fit: cover;
}
.ct-at-item-txt {
  width: 100%;
  padding-left: 2%;
  height: 118px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ct-at-item-btn {
  width: 20%;
}
.ct-at-btn {
  width: 71px;
  height: 30px;
  line-height: 30px;
  background-color: #a1124e;
  color: white;
  border-radius: 4px;
  text-align: center;
  margin-left: 30%;
  cursor: pointer;
}
.ct-at-item-ct {
  height: 48px;
  line-height: 24px;
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.ct-at-item-title {
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.ct-at-item-time {
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}

.item-video-mb {
  //height: 400px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  position: relative;
  // background: #000;
}
.item-ct-block-mb {
  width: 100%;
}
.item-video-mb div img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
//################################ mobile css ###################################################
.mobile {
  .share {
    color: #fff;
    margin: 0 30px 0 0;
  }
  .item-head {
    height: 236px;
    padding-top: 4px;
    width: 100%;
    top: 20%;
    left: 0;
  }
  .ct-block {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .item-head-title {
    width: 100%;
    text-align: center;
  }
  .item-head-title-bq {
    text-align: center;
    width: 64px;
    color: white;
    background-color: #a1124e;
    font-size: 14px;
    border-radius: 2px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .item-head-title-bq-mini {
    text-align: center;
    width: 53px;
    color: white;
    background-color: #a1124e;
    font-size: 12px;
    border-radius: 2px;
    height: 26px;
    line-height: 26px;
    position: absolute;
    right: 20px;
    top: 5px;
    z-index: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .item-head-title-name {
    // text-align: center;
    width: 100%;
    font-size: 20px;
    color: #fff;
    padding-left: 20px;
  }
  .item-head-be {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 8px;
    height: auto;
    width: 78%;
    line-height: 21px;
    padding-left: 20px;
  }
  .item-head-time {
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 0;
    // text-align: center;
    padding-left: 20px;
  }
  .ct-at-item {
    /* display: flex; */
    /* display: -webkit-flex; */
    width: 100%;
    align-items: center;
    justify-content: center;
    /* background-color: #f2f2f2; */
    min-height: 118px;
    height: auto;
    padding: 0 20px;
  }
  .ct-at-item-img {
    width: 100%;
    position: relative;
    height: auto;
  }
  .ct-at-item-img img {
    //width: 184px;
    //height: 118px;
    height: auto;
    width: 100%;
  }
  .ct-at-item-txt {
    width: 100%;
    padding-left: 2px;
    height: 118px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ct-at-item-title-mb {
    height: 26px;
    line-height: 26px;
    overflow: hidden;
    //white-space: pre-wrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
  }
  .ct-at-item-ct {
    height: 42px;
    line-height: 22px;
    overflow: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }
  .ct-at-item-time {
    height: 24px;
    line-height: 24px;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.45);
  }
  .ct-at-item-btn {
    width: 30%;
  }
  .ct-at-btn {
    width: 35px;
    height: 30px;
    line-height: 30px;
    background-color: #a1124e;
    color: white;
    border-radius: 4px;
    text-align: center;
    margin-left: 30%;
    cursor: pointer;
    font-size: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
