import { render, staticRenderFns } from "./MeetingReview.vue?vue&type=template&id=be20e364&scoped=true"
import script from "./MeetingReview.vue?vue&type=script&lang=js"
export * from "./MeetingReview.vue?vue&type=script&lang=js"
import style0 from "./MeetingReview.vue?vue&type=style&index=0&id=be20e364&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be20e364",
  null
  
)

export default component.exports