<template>
  <div class="">
    <!--2021.1.28-->
    <h3 class="title" v-if="videoList">动画解读</h3>
    <!--    <a-list :grid="{ gutter: 10, column: 2 }" :data-source="videoList" v-if="videoList">-->
    <!--      <a-list-item slot="renderItem" slot-scope="item" style="width: 180px">-->
    <!--        <a :href="'/animation-particulars' + generate(item.permalinks)">-->
    <!--          <a-card style="cursor: pointer" class="article" @click="onAnimationClick(item)">-->
    <!--            &lt;!&ndash;          <video-player&ndash;&gt;-->
    <!--            &lt;!&ndash;            class="video-player vjs-custom-skin"&ndash;&gt;-->
    <!--            &lt;!&ndash;            ref="videoPlayer"&ndash;&gt;-->
    <!--            &lt;!&ndash;            :playsinline="true"&ndash;&gt;-->
    <!--            &lt;!&ndash;            style="height: 100px"&ndash;&gt;-->
    <!--            &lt;!&ndash;            :options="item.playerOptions"&ndash;&gt;-->
    <!--            &lt;!&ndash;          ></video-player>&ndash;&gt;-->
    <!--            <img :src="item.thumb" class="video-player vjs-custom-skin" alt="" height="127" />-->
    <!--            &lt;!&ndash;          <BzVideoPlayer ref="videoPlayer" :playsinline="true" :src="item.src" />&ndash;&gt;-->
    <!--            &lt;!&ndash;<img slot="cover" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" height="100" />&ndash;&gt;-->
    <!--            <a-card-meta>-->
    <!--              <div slot="description" class="videoTitle">{{ item.title }}</div>-->
    <!--            </a-card-meta>-->
    <!--          </a-card>-->
    <!--        </a>-->
    <!--      </a-list-item>-->
    <!--    </a-list>-->
    <a-list v-if="videoList" :grid="{ gutter: 10, column: 2 }" :data-source="videoList">
      <a-list-item slot="renderItem" slot-scope="item" style="width: 180px">
        <a :href="'/animation-particulars' + generate(item.permalinks)">
          <a-card class="card-css" @click.prevent="onAnimationClick(item)">
            <img slot="cover" :src="item.thumb" height="127" />
            <a-card-meta style="padding: 0px">
              <div
                slot="description"
                style="
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.85);
                  height: 55px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ item.title }}
              </div>
            </a-card-meta>
          </a-card>
        </a>
      </a-list-item>
    </a-list>
    <div v-for="(item, i) of commentList" :key="i" style="padding: 5px 0">
      <h3 class="title" v-if="item.wantsayTitle && i === 0">述评</h3>
      <a @click.prevent="onReviewClick(item)" style="font-size: 14px; color: #000" v-if="item.wantsayTitle">
        {{ item.wantsayTitle }}
      </a>
    </div>
    <div class="nejm" @click="onNejmClick" v-if="list.catalogList.length > 0">
      <h3>NEJM目录</h3>
      <div v-html="catalog.title"></div>
    </div>
  </div>
</template>

<script>
import { PATH_ANIMATION_PARTICULARS, PATH_ARTICLE_INFO, PATH_NEJM_CATALOG } from '@/router/article/paths.js'
import { generateHref } from '@/utils/utils'
import config from 'config'
export default {
  name: 'ArticleComment',
  props: {
    list: {
      required: true,
      type: Object,
    },
    month: {
      type: String,
    },
    catalogIndex: {
      type: Number,
    },
  },
  components: {},
  data() {
    return {
      videoList: [],
      commentList: [],
      catalog: [],
      playerOptions: {
        //播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: 'auto',
        language: 'zh-CN',
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: '16:9',
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          {
            //类型
            type: 'video/mp4',
            //url地址
            src: 'https://video.699pic.com/videos/26/36/01/b_uiQTHWa8BkvX1615263601_10s.mp4',
          },
        ],
        //你的封面地址
        poster: '',
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true,
        },
      },
    }
  },
  created() {
    this.videoList = this.list.videoList
    this.commentList = this.list.commentList
    this.catalog = this.list.catalogList[0]
    // this.catalog.title = this.catalog.title.replace(/<br>/, '  ')
    for (var temp of this.videoList) {
      console.log(temp, 'temp.videoUrl')
      temp.playerOptions = this.playerOptions
      temp.playerOptions.sources[0].src = config.assetsUrl + temp.videoUrl
      // temp.src = config.assetsUrl + temp.videoUrl
    }
  },
  methods: {
    generate(item) {
      return generateHref('permalinks', item)
    },
    onAnimationClick(item) {
      console.log(item)
      this.$router.push({ path: PATH_ANIMATION_PARTICULARS, query: { permalinks: item.permalinks } })
      // this.$router.push({ path: PATH_ANIMATION_PARTICULARS, query: { permalinks: 'testqt1' } })
    },
    onReviewClick(item) {
      console.log(item)
      this.$router.push({ path: PATH_ARTICLE_INFO, query: { permalinks: item.permalinks } })
    },
    onNejmClick() {
      this.$router.push({ path: PATH_NEJM_CATALOG, query: { permalinks: this.catalog.permalinks } })
      // this.$router.push({ path: PATH_NEJM_CATALOG, query: { month: this.month, catalogIndex: this.catalogIndex } })
      // console.log(this.catalog.permalinks)
      // this.$router.push({ path: '/article-info', query: { permalinks: this.catalog.permalinks } })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.ad {
  width: 100%;
  height: 250px;
  margin-bottom: 30px;
  background-color: #ccc;
}
.title {
  margin: 12px 0;
  font-weight: bold;
}
.nejm {
  margin: 20px 0;
  padding: 5px 20px;
  line-height: 1.9;
  border-left: 4px solid @clr-primary;
  //background-color: rgba(0, 0, 0, 0.04);
  background-color: #f9e093;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
  //color: white;
}
.videoTitle {
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 45px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
<style>
.article .video-js .vjs-big-play-button {
  position: absolute !important;
  top: 23px !important;
  left: 35px !important;
}
.card-css {
  cursor: pointer;
  border: none;
  background: rgb(250, 250, 250);
}
.card-css :nth-child(2) {
  zoom: 1;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
</style>
