<template>
  <component :is="component"></component>
</template>

<script>
import ArticleVideo from '@/components/article/ArticleVideo.vue'
import NejmCatalog from '@/components/article/NejmCatalog.vue'
import LatestPeriodical from '@/components/article/LatestPeriodical.vue'

const componentOf = {
  10: ArticleVideo,
  11: NejmCatalog,
  12: LatestPeriodical,
}

export default {
  name: 'Article',
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    component() {
      return componentOf[this.article?.type]
    },
  },
}
</script>

<style lang="less" scoped></style>
