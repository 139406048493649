<template>
  <el-dialog :before-close="_handleClose" @close="_handleClose" v-bind="$attrs" v-on="$listeners" class="changeDialog">
    <div class="ligin-title">
      <img class="logo" src="../../../assets/imgs/logo/logo.png" alt="NEJM医学前沿" />
    </div>
    <div class="tabs" @click="changeTab('mobile')">
      <hr class="hrBorder" />
      <img class="tabImg" src="../../../assets/imgs/youxiang1.png" />
      <hr class="hrBorder" />
    </div>
    <a-form-model :model="emailParams" :rules="rules" ref="ruleForm" label-width="30px">
      <a-form-model-item prop="email">
        <a-input v-model="emailParams.email" placeholder="输入新邮箱地址">
          <a-icon slot="prefix" type="mail" />
        </a-input>
      </a-form-model-item>
      <div style="position: relative">
        <a-form-model-item prop="code" class="emaildialog">
          <a-input v-model="emailParams.code" placeholder="输入验证码">
            <a-icon slot="prefix" type="safety-certificate" />
          </a-input>
        </a-form-model-item>
        <a-button :disabled="timeCount > 0" class="send" @click="sendCodeOpt">
          发送验证码
          <span class="time">{{ timeCount }}</span>
        </a-button>
      </div>
      <a-form-model-item class="submitContainer">
        <a-button type="primary" style="width: 150px" @click="submit">确认</a-button>
      </a-form-model-item>
    </a-form-model>
  </el-dialog>
</template>
<script>
import { sendCode2, updatePhoneNoOrEmail } from '@/api/personal'
// import { checkCode } from '@/api/login'
export default {
  name: 'ChangeEmailDialog',
  props: {
    emailVisible: {
      type: Boolean,
      default() {
        return false
      },
    },
    emailParams: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    let validateEmail = (rule, value, callback) => {
      let isEMail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
      if (!isEMail.test(value)) {
        callback(new Error('邮箱输入不正确，请重新输入'))
      } else {
        this.flag = true
        callback()
      }
    }
    return {
      rules: {
        email: [
          { required: true, message: '请输入新邮箱地址', trigger: 'blur' },
          { required: true, validator: validateEmail, trigger: 'blur' },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      timeCount: null,
      flag: false, //假不发送，真才发送验证码
      disableRepeatSend: false, //禁止狂点
    }
  },
  watch: {
    timeCount: {
      handler() {
        if (this.timeCount == -1) {
          clearInterval(this.timer)
          this.timeCount = null
          this.disableRepeatSend = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    _handleClose() {
      this.$emit('update:visible', false)
      this.$refs.ruleForm.resetFields()
    },
    async sendCodeOpt() {
      if (!this.disableRepeatSend) {
        if (this.emailParams.email !== null && this.flag) {
          let res = await sendCode2(this.emailParams)
          if (res) {
            this.disableRepeatSend = true
            this.timeCount = 59
            this.timer = setInterval(() => {
              this.timeCount--
            }, 1000)
            this.$message.success('发送成功')
          }
        }
      }
    },
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // let params = {
          //   email: this.emailParams.email,
          //   code: this.emailParams.code,
          // }
          // let isCorrectCode = await checkCode(params)
          let res = await updatePhoneNoOrEmail(this.emailParams)
          if (res) {
            this.$message.success('修改邮箱成功')
            this._handleClose()
            this.$emit('editAvatar')
            this.$bus.emit('refresh-page')
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.el-dialog {
  width: 30%;
}
.ligin-title {
  font-size: 16px;
  width: 100%;
  .logo {
    width: 100px;
  }
}
.tabs {
  display: flex;
  align-items: center;
  position: relative;
  float: left;
  width: 100%;
  height: 75px;
  .tabImg {
    position: relative;
    width: 48px;
    z-index: 1000;
  }
  .hrBorder {
    flex: 1;
    background-color: #e52e00;
    height: 4px;
    border: none;
  }
}
.send {
  width: 120px;
  position: absolute;
  top: 4px;
  right: 0;
  color: #e52e00;
  border: 1px solid #e52e00;
  .time {
    display: inline-block;
    margin-left: 5px;
  }
}
.submitContainer {
  display: flex;
  justify-content: center;
}
</style>
<style lang="less">
.emaildialog {
  width: 250px;
}
.changeDialog {
  .el-dialog__body {
    padding: 0px 40px 10px 40px;
  }
}
.mobile {
  .emaildialog {
    width: 150px;
  }
  .changeDialog {
    .el-dialog__body {
      padding: 0px 20px 20px 20px;
    }
  }
}
</style>
