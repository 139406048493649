<template>
  <MainCenter>
    <div class="title">用户注册</div>
    <!--步骤条-->
    <div style="margin-bottom: 20px">
      <div v-if="!$isMobile" class="stepContainer">
        <a-steps class="steps" :current="current">
          <a-step v-for="item in steps" :key="item.title" :title="item.title" />
        </a-steps>
      </div>

      <!--步骤内容-->
      <div class="steps-content">
        <!--tabs-->
        <div>
          <a-tabs v-if="current == 1" type="card" @change="callback">
            <a-tab-pane key="1" tab="手机号绑定"></a-tab-pane>
          </a-tabs>
          <div>
            <!--第二步-->
            <a-form-model v-if="current == 1" ref="codeForm" :model="codeForm" :rules="codeRules">
              <a-form-model-item v-if="currentKey == 1" prop="mobile">
                <a-input required ref="userNameInput" v-model="codeForm.mobile" placeholder="输入手机号码">
                  <a-icon slot="prefix" type="mobile" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item style="margin-top: -6px" prop="code">
                <div class="fr">
                  <a-input ref="code" class="valid-code" v-model="codeForm.code" placeholder="输入验证码">
                    <a-icon slot="prefix" type="safety-certificate" />
                  </a-input>
                  <a-button
                    :disabled="timeCount > 0"
                    @click="sendCodeOpt"
                    :style="timeCount ? 'width: 120px;' : 'width: 120px; color: #e52e00; border: 1px solid #e52e00'"
                  >
                    发送验证码 {{ timeCount }}
                  </a-button>
                </div>
              </a-form-model-item>
              <!-- <a-form-model-item style="margin-top: -6px" v-if="currentKey == 1" prop="email">
                <a-input ref="userNameInput" v-model="codeForm.email" placeholder="输入邮箱地址">
                  <a-icon slot="prefix" type="mail" />
                </a-input>
              </a-form-model-item> -->
              <a-checkbox @change="onChange" :checked="checked"></a-checkbox>
              确定注册代表您同意NEJM医学前沿
              <a @click="openService" class="service">《服务和隐私条款》</a>
              <a @click="openService1" class="service">《用户协议》</a>
              <div style="margin-top: 25px; text-align: center">
                <a-button @click="submitCodeData('codeForm')" style="width: 50%" type="primary" :disabled="!checked">下一步</a-button>
              </div>
            </a-form-model>
            <!--第三步-->
            <user-info-form :formData="infoForm" v-if="current == 2"></user-info-form>
          </div>
        </div>
      </div>
    </div>
        <el-dialog
      title="图片验证码"
      :visible.sync="centerDialogVisible"
      :destroy-on-close="true"
      style="width: 100%"
      center
    >
      <el-row>
        <el-col :style="!$isMobile ? 'width:33%' : 'width:50%'">
          <img id="baseimg" @click="getImg" :src="imgsrc" alt="" />
        </el-col>
        <el-col :style="!$isMobile ? 'width:67%' : 'width:50%'" class="imgput">
          <el-input v-model="uucode" placeholder="请输入计算后的结果"></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            centerDialogVisible = false
            sendcodestart()
          "
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </MainCenter>
</template>
<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import UserInfoForm from '@/components/register/UserInfoForm'
import { sendCode3, checkCode, register, login, sendimgCode,wxlogin } from '@/api/login'
import { setSessionUser } from '@/utils/app-utils'
import { mapMutations } from 'vuex'

export default {
  name: 'Register',
  components: { MainCenter, UserInfoForm },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('请输入密码'))
      } else if (value.length < 6) {
        callback(new Error('请至少输入6位'))
      } else {
        if (this.userForm.confirmPwd !== '' && this.userForm.confirmPwd !== null) {
          this.$refs.userForm.validateField('confirmPwd')
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.userForm.password) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    let validatePhone = (rule, value, callback) => {
      var isPhone = /^1\d{10}$/
      console.log(isPhone.test(value), 'isPhone.test(value)')
      if (value === '' || value === null) {
        callback(new Error('请输入手机号码'))
      } else if (!isPhone.test(value)) {
        callback(new Error('手机输入不正确，请重新输入'))
        this.flag1 = true
      } else {
        callback()
        this.flag1 = false
      }
    }
    let validateEMail = (rule, value, callback) => {
      var isEMail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
      console.log(isEMail.test(value), 'isPhone.test(value)')
      if (value === '' || value === null) {
        callback(new Error('请输入邮箱'))
      } else if (!isEMail.test(value)) {
        callback(new Error('邮箱输入不正确，请重新输入'))
        this.flag1 = true
      } else {
        callback()
        this.flag1 = false
      }
    }
    return {
      code: '',
      imgsrc: '',
      uuid: '',
      uucode: '',
      centerDialogVisible: false,
      current: 1,
      steps: [{ title: '微信授权登录' },{ title: '请绑定手机号' }, { title: '完善信息' }],
      codeForm: {
        mobile: null,
        email: null,
        code: null,
      },
      userForm: {
        membername: null,
        password: null,
        confirmPwd: null,
      },
      codeRules: {
        mobile: [{ validator: validatePhone, trigger: 'blur' }],
        email: [{ validator: validateEMail, trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      userRules: {
        membername: [{ required: true, message: '请输入用户名、手机或邮箱' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        confirmPwd: [{ validator: validatePass2, trigger: 'blur' }],
      },
      currentKey: 1,
      checked: false,
      infoForm: {},
      timeCount: null,
      flag1: true,
    }
  },
  watch: {
    timeCount: {
      handler() {
        if (this.timeCount == 0) {
          clearInterval(this.timer)
          this.timeCount = null
        }
      },
      immediate: true,
    },
  },
    created() {
    this.code = this.$route.query.code;
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    async getImg() {
      var _this = this
      let res = await sendimgCode({})
      _this.imgsrc = 'data:image/jpeg;base64,' + res.img
      _this.uuid = res.uuid
    },
    openService() {
      window.sessionStorage.setItem('serviceKey', '2')
      window.open('/about')
      // this.$router.push({ path: '/about', query: { key: '2' } })
    },
    openService1() {
      window.sessionStorage.setItem('serviceKey', '9')
      window.open('/about')
      // this.$router.push({ path: '/about', query: { key: '2' } })
    },
    callback(key) {
      console.log(key)
      this.currentKey = key
      this.codeForm = {
        mobile: null,
        email: null,
        code: null,
      }
      clearInterval(this.timer)
      this.timeCount = null
      this.$refs['codeForm'].resetFields()
    },
    next() {
      this.current++
    },
    prev() {
      this.current--
      this.codeForm = {
        mobile: null,
        email: null,
        code: null,
      }
    },
    onChange(e) {
      this.checked = e.target.checked
    },
    submitCodeData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            code: this.codeForm.code,
            email: this.codeForm.email,
            phoneNo: this.codeForm.mobile,
          }
          this.wxloginMethod(params);
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    submitUserData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            email: this.codeForm.email,
            membername: this.userForm.membername,
            mobile: this.codeForm.mobile,
            password: this.userForm.password,
          }
          console.log(params, 'paramsparams')
          register(params).then((res) => {
            console.log(res)
            this.infoForm = res
            this.$message.success('注册成功！请继续完善信息。')
            this.userLogin()
            this.next()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    userLogin() {
      var params = {
        accountName: this.userForm.membername,
        password: this.userForm.password,
      }
      login(params).then((res) => {
        this.setUser(res)
        setSessionUser(res)
        window.sessionStorage.setItem('memberId', res.id)
        // this.$message.success('自动登录成功')
        this.$emit('update:visible', false)
      })
    },
    //判断是否是微信浏览器
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    wxloginMethod(params){
      var par = {
        code: this.code,
        phone:params.phoneNo,
        verifyCode:params.code,
        iswx: this.isWeiXin(),
      }
      wxlogin(par).then((res) => {
        this.setUser(res)
          setSessionUser(res)
          window.sessionStorage.setItem('memberId', res.id)
          if(res.newFlag){
            this.infoForm.id = res.id;
            this.$message.success('绑定手机成功！请继续完善信息')
            this.$emit('update:visible', false)
            this.next();
          } else {
            this.$router.replace('/');
          }
          // if (res.ifNeedUpdate) {
          //   this.$notification.warning({
          //     message: '提示',
          //     description: '请及时更新用户资料',
          //     onClick: () => {
          //       console.log('Notification Clicked!')
          //     },
          //   })
          // }
          // this.$router.replace('/');
          
      })
    },
    async checkCodeTrue() {
      var params = {
        code: this.codeForm.code,
        email: this.codeForm.email,
        phoneNo: this.codeForm.mobile,
      }
      this.isCorrectCode = await checkCode(params)
      console.log(this.isCorrectCode, 'this.isCorrectCode')
      if (this.isCorrectCode) {
        this.wxloginMethod(params)
      }
    },
    sendCodeOpt() {
      var _this = this
      if (this.currentKey == 1) {
        if (this.codeForm.mobile == null || this.codeForm.mobile == '') {
          return this.$message.error('请输入手机号')
        }
        _this.centerDialogVisible = true
        _this.getImg()
      } else if (this.currentKey == 2) {
        if (this.codeForm.email == null || this.codeForm.email == '') {
          return this.$message.error('请输入邮箱号码')
        } else {
          this.sendcodestart()
        }
      } else {
        this.sendcodestart()
      }
    },
    sendcodestart() {
      var _this = this
      var params = {}
      var flag = true
      console.log()
      if (this.currentKey == 1) {
        params.phoneNo = this.codeForm.mobile
        params.uuid = this.uuid
        params.code = this.uucode
        if (params.phoneNo == null || params.phoneNo == '' || _this.flag1) {
          flag = false
        }
      } else if (this.currentKey == 2) {
        params.email = this.codeForm.email
        if (params.email == null || params.email == '' || _this.flag1) {
          flag = false
        }
      }
      if (flag) {
        params.codeType = 0
        sendCode3(params).then((res) => {
          console.log(res)
          this.timeCount = 59
          this.timer = setInterval(function () {
            _this.timeCount = _this.timeCount - 1
          }, 1000)
          this.$message.success('发送成功')
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.imgput {
  /deep/.el-input,
  /deep/.el-input__inner {
    height: 60px;
    line-height: 60px;
  }
}
.steps-content {
  margin-top: 16px;
  min-height: 200px;
  padding: 10px 350px 0 370px;
  .valid-code {
    width: 350px;
    margin-right: 10px;
  }
}
// 移动端样式
.mobile {
  .steps-content {
    padding: 15px;
  }
  .valid-code {
    width: 100%;
  }
}

.steps-action {
  margin-top: 24px;
}
.title {
  margin-top: 60px;
  width: 100%;
  height: 55px;
  font-size: 23px;
  /*font-weight: bold;*/
  text-align: center;
}
// 移动端样式
.mobile {
  .title {
    margin-top: 40px;
    height: 30px;
  }
}

.stepContainer {
  //background-color: #fdf4f2;
  background-color: #f6f2ef;
  padding: 0 200px;
}
.steps {
  border-left: 5px solid #ffffff;
  border-right: 5px #ffffff solid;
  padding: 15px 180px;
}
.optBtn {
  width: 110px;
}
.optBtn + .optBtn {
  margin-left: 45px;
}
</style>
<style lang="less">
.steps-content {
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    color: #e52e00 !important;
    background: #fff !important;
    border-color: #d8372d !important;
    border-radius: 2px 2px 0 0 !important;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 40px;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    height: 40px;
    background-color: #d8372d !important;
    color: #ffffff !important;
    border-color: #e8e8e8;
    border-bottom: 1px solid #fff;
  }
  .ant-tabs-bar {
    border-bottom: 1px solid #d8372d;
  }
}
.service {
  color: blue;
}
.service:hover {
  color: #e52e00;
}
</style>
