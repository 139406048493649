<template>
  <!-- 我是首页 -->
  <div class="index">
    <a-carousel :autoplay="true">
      <div v-for="(item, index) of bannners" class="ban" :key="index">
        <img class="banner" style="width: 100%; height: auto; object-fit: cover" :src="item.pic" alt="" />
        <div v-if="!$isMobile" style="width: 100%; display: flex; justify-content: center">
          <div class="container">
            <div @click="tiaoZhuan(item.url)" class="title" style="width: 100%">{{ item.title }}</div>
            <div class="intro" style="width: 100%">{{ item.intro }}</div>
          </div>
        </div>
        <div v-if="$isMobile" class="container">
          <div @click="tiaoZhuan(item.url)" class="title" style="width: 100%">{{ item.title }}</div>
          <div class="intro" style="width: 100%">{{ item.intro }}</div>
        </div>
      </div>
    </a-carousel>
    <div class="mb-content">
      <div>
        <MainCenter>
          <HotArticles />
        </MainCenter>
      </div>
      <div style="background-color: #fafafa; padding-top: 4px">
        <MainCenter>
          <h3 v-if="!$isMobile" class="sub-title" style="margin-top: 30px">{{ articleWeekly!== null ?articleWeekly.title : '' }}</h3>
          <!--        pc端布局-->
          <div v-if="!$isMobile" style="margin: 20px 0">
            <a-row :type="rowType" :gutter="50">
              <a-col flex="32 0 0">
                <LatestPeriodicalTranslation />
              </a-col>
              <!--            <a-col flex="8 0 0">-->
              <!--              <CenterRight />-->
              <!--            </a-col>-->
            </a-row>
          </div>
          <!--        手机端布局-->
          <div v-if="$isMobile" style="margin: 20px 0">
            <a-row :type="rowType" :gutter="50">
              <a-col>
                <LatestPeriodicalTranslation />
              </a-col>
              <!-- <a-col flex="8 0 0">
                <CenterRight />
              </a-col> -->
            </a-row>
          </div>
        </MainCenter>
      </div>
      <div style="background-color: #ffffff">
        <MainCenter>
          <ProfessionalField />
        </MainCenter>
      </div>
      <div style="background-color: #fafafa">
        <MainCenter>
          <NejmEdittingClass show-type="index" />
        </MainCenter>
      </div>
      <div style="background-color: #ffffff">
        <FooterNav />
      </div>
    </div>
    <!-- <HomeFooter /> -->
  </div>
</template>

<script>
// import CenterRight from '@/components/index/CenterRight.vue'
import FooterNav from '@/components/index/FooterNav.vue'
import HotArticles from '@/components/index/HotArticles.vue'
import LatestPeriodicalTranslation from '@/components/index/LatestPeriodicalTranslation.vue'
import NejmEdittingClass from '@/components/index/NejmEdittingClass.vue'
import ProfessionalField from '@/components/index/ProfessionalField.vue'
import MainCenter from '@/components/layout/MainCenter.vue'
import { getBanner } from '@/api/base'
import { newestArticle } from '@/api/nejmCatalog'
// import HomeFooter from '@/components/home/HomeFooter.vue'

export default {
  name: 'Index',
  components: {
    FooterNav,
    NejmEdittingClass,
    ProfessionalField,
    LatestPeriodicalTranslation,
    HotArticles,
    MainCenter,
  },
  data() {
    return {
      bannners: [],
      articleWeekly: null,
    }
  },
  async created() {
    await this.getBanner()
    this.getList()
  },
  computed: {
    rowType() {
      return this.$isMobile ? '' : 'flex'
    },
  },
  methods: {
    async getBanner() {
      let res = await getBanner({ code: 'home' })
      this.bannners = JSON.parse(res.content)
    },
    tiaoZhuan(item) {
      window.location.href = item
    },
    async getList() {
      let res = await newestArticle({})
      this.articleWeekly = res.articleWeeklyPo
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.ant-carousel {
  max-width: 1920px;
  margin: auto;
  .slick-dots {
    bottom: 30px !important;
    li {
      button {
        width: 12px !important;
        height: 12px !important;
        background: #ffffff;
        border-radius: 50px;
        opacity: 1;
        margin: 0 20px;
      }
    }
    li.slick-active {
      button {
        background: #e52e00;
      }
    }
  }
}
.banner {
  height: auto;
  margin-top: 0;
}
.ban {
  position: relative;
  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    //padding: 0 163px;
    top: 40%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    .title {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 3px;
      // font-family: inherit;
      font-weight: 500;
      color: #fff;
      text-shadow: 0px 1px 2px rgb(0 0 0);
      margin-bottom: 13px;
      cursor: pointer;
    }
    .intro {
      text-shadow: 0px 1px 2px rgb(0 0 0);
      color: #fff;
      // font-family: Arial, 'Microsoft YaHei', sans-serif;
      font-weight: 500;
    }
  }
}
.sub-title {
  border-radius: 2px;
  background-color: @clr-primary;
  color: #fff;
  text-align: center;
  line-height: 2;
  font-size: 18px;
  font-weight: 400;
}
// 移动端样式
.mobile {
  .index {
  }
  .mb-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .banner {
    height: 200px;
  }
  .ban {
    .container {
      top: 38%;
      width: 94%;
      padding: 0 10px;
      left: 12px;
      display: flex;
      flex-wrap: wrap;
      .title {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 3px;
        // font-family: inherit;
        font-weight: 500;
        color: #fff;
        text-shadow: 0px 1px 2px rgb(0 0 0);
        margin-bottom: 13px;
        cursor: pointer;
      }
      .intro {
        display: none;
        font-size: 12px;
        text-shadow: 0px 1px 2px rgb(0 0 0);
        color: #fff;
        // font-family: Arial, 'Microsoft YaHei', sans-serif;
        font-weight: 500;
      }
    }
  }
}
.mobile {
  .ant-carousel {
    .slick-dots {
      bottom: 25px !important;
    }
  }
}
</style>
