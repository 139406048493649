<template>
  <div class="ct-main cr-img" :style="$isMobile ? 'padding-left: 15px; padding-right: 15px' : ''">
    <div class="ct-block">
      <div id="box">
        <div class="item">{{ detail.title }}</div>
      </div>
      <div class="ct-au">
        <div class="item-au">
          {{ detail.subhead }}
        </div>
      </div>
      <div class="ct-au">
        <div class="item-au">日期: {{ detail.publishdate | formatDateTxt }}</div>
      </div>
      <div class="share">
        <Share
          :isShow="{ navigation: false, collect: true, share: true, original: false }"
          :id="detail.lessonResId"
          collectType="lesson"
          v-bind:ifCollect.sync="detail.ifFav"
        ></Share>
      </div>
      <!--      class="item-video"          v-show="detail.media != ''  "   -->
      <!-- v-show="detail.media != ''&&isShow&&subscribe_info.service_type !='0' ||  usefreeCont || isShow&&subParam.is_subscribe == 2 || isShow&&subscribe_info.service_type == 1 ||  isShow&&subscribe_info.service_type == 2" -->
      <div :class="$isMobile ? 'item-video-mb' : 'item-video'">
        <div  @click.stop="showVideo2"  v-if="(isShow&&subscribe_info.service_type!='0' ) || usefreeCont || isShow&&subParam.is_subscribe == '2' "  
         :class="$isMobile ? 'item-head-video-mb' : 'item-head-video'">
          <!-- :style="(subscribe_info.service_type =='0' || subParam.is_have_view_auth==2) || isShow&&subParam.is_subscribe !='2' ?'display:none;':''"> -->
          <audio v-if="detail.subjectType == 1 || detail.subjectType == 4" controls >
            <source :src="detail.media != '' ? detail.media[0].url : ''" type="audio/ogg" />
            <source :src="detail.media != '' ? detail.media[0].url : ''" type="audio/mpeg" />
            您的浏览器不支持 audio 元素。
          </audio>
          <BzVideoPlayer
          v-if="(isShow && subscribe_info.service_type !='0' )||  usefreeCont || isShow&&subParam.is_subscribe == 2&&subscribe_info.service_type =='0' || isShow&&subParam.is_subscribe == 2 || isShow&&subscribe_info.service_type == 1 ||  isShow&&subscribe_info.service_type == 2"
            :style="$isMobile ? ' height: 100%' : 'width: 700px;height: 100%'"
            :src="detail.media != '' ? detail.media[0].url : ''"
            :player-options="{ loop: true }"
            :showPlay="showPlay"
            :relation-id="detail.lessonResId"
            :relation-type="20"
            :subParam="subParam"
            @paramFun="getVideoParam"
          />
        </div>
        <div v-if="!isShow&&!usefreeCont || !isShow || isShow&&subscribe_info.service_type =='0'&&subParam.is_subscribe!='2'  || (isShow&&subParam.is_month_chance ==1&&subParam.is_have_view_auth==2&& subParam.is_subscribe==1)" :style="$isMobile ? ' height: 100%' : 'width: 700px;height: 100%'">
            <div class="imgDiv" @click="showVideo">
              <el-image :src="detail.coverPc?detail.coverPc[0].url:''" fit="cover" style="width: 100%; height: 100%"></el-image>
            </div>
          </div>
        <!--        <div :class="$isMobile ? 'item-head-mb' : 'item-head'">-->
        <!--          <div class="item-head-title">-->
        <!--            <div class="item-head-title-bq">{{ detail.className }}</div>-->
        <!--            <div class="item-head-title-name">{{ detail.title }}</div>-->
        <!--          </div>-->
        <!--          <div class="item-head-be">{{ detail.subhead }}</div>-->

        <!--          <div class="item-head-time">{{ detail.createTime | formatDateTxt }}</div>-->
        <!--          <div style="margin-left: 0%">-->
        <!--            <Share-->
        <!--              :isShow="{ navigation: false, collect: true, share: true, original: false }"-->
        <!--              :id="detail.lessonResId"-->
        <!--              collectType="lesson"-->
        <!--              v-bind:ifCollect.sync="detail.ifFav"-->
        <!--            ></Share>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>

      <div class="item-ct-detail">详情</div>
      <RichText :content="detail.content" v-if="isShow&&subParam.is_subscribe == 2 || usefreeCont || isShow&&subParam.is_subscribe == 1 && subParam.is_have_view_auth == 2 &&subParam.is_month_chance ==2 || subscribe_info.service_type !=0"  :about="detail.aboutcontent" class="item-ct-detail-txt" />
      <div  v-if="!isShow" :class="subParam.is_subscribe == 2 || subscribe_info.service_type !='0'|| usefreeCont?'':'login'">
        <nologinPop v-if="!isShow" @login="login"></nologinPop>
      </div>
      <div v-if="isShow" :class="subParam.is_subscribe == 2 || subscribe_info.service_type !='0'|| usefreeCont?'':'login'">
        <noFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow" ></noFreeReadPop><!--没有免费次数-->
        <noUseFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow && isClose && subParam.is_month_chance =='2'" @noclose="closePop" @getArticle="getArticleInfo" @content="getContent"></noUseFreeReadPop>
      </div>
    </div>
    
      <FreeReadPop :subParam="subParam" :islogin="islogin" v-show="isShow&&isFree" @close="close" @getArticle="getDetail"   @showContent="showContent" @content="getContent" ></FreeReadPop>
      <!-- <noFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow" ></noFreeReadPop>没有免费次数 -->
      <!-- <noUseFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow && isClose && subscribe_info.service_type == 0  && subParam.is_month_chance ==2" @noclose="closePop" @getArticle="getDetail" @content="getContent" @showContent="showContent" ></noUseFreeReadPop>点击不使用免费次数后，显示可以使用一次免费次数 -->
      <!-- <nologinPop v-if="!isShow" @login="login"></nologinPop> -->
  </div>
</template>

<script>
import { selectLessonDetail } from '@/api/course'
import RichText from '@/components/layout/RichText.vue'
import { getSessionUser, setSessionUser } from '@/utils/app-utils'
import { format } from '@/utils/time-utils'
import Share from '@/components/share/Share'
import BzVideoPlayer from '@/components/video/BzVideoPlayer'
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'
import { loginWithoutPwdwithQueryload } from '@/api/login'
import { getMemberInfo } from '@/api/personal'
import FreeReadPop from '@/components/article/popup/FreeReadPopup_video.vue';
import noFreeReadPop from '@/components/article/popup/noFreeReadPopup.vue';
// import nologinPop from '@/components/article/popup/nologinPopup.vue';
import nologinPop from '@/components/article/popup/nologinPop.vue';
import noUseFreeReadPop from '@/components/article/popup/noUseFreePopup.vue';
export default {
  name: 'CourseVideo',
  components: { RichText, Share, BzVideoPlayer ,
    FreeReadPop,noFreeReadPop,nologinPop,noUseFreeReadPop
  },
  data() {
    return {
      detail: {},
      showPlay:true,
      isFree:false,
      isShowFree:false,
      noFree:false,
      islogin:false,
      usefreeCont:false,///////使用免费次数之后
      subParam:{
        is_subscribe:null,//是否付费文章（1：是；2：否）
        is_have_view_auth:null,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
        is_month_chance:null//未付费或者已付费但是会员到期的用户，每个用户每个月有一次免费的机会 1：表示无机会；2：表示有机会
      },//会员信息
      subscribe_info:{
        service_type:null,
        service_end_date:""
      },//会员信息
      isClose:false,
      flag: false, //是否显示全部述评
      flag2: false, //是否加载更多
      ifShow: false, //述评是否显示
      memberId:null
    }
  },
  filters: {
    formatDateTxt(time) {
      return format(time, 'YYYY-MM-DD')
    },
  },
  async created() {
    if(this.isShow){
      this.islogin = true
      console.log('1')
      this.getUseinfo()
    }else{
      this.islogin = false
    }
    this.detail.lessonResId = this.$route.query.id
    // this.detail.content = this.$route.query.param.content
    // if(this.$route.query.param.is_use){
    //   this.isFree = false
    //   this.usefreeCont = true
    // }
    this.memberId = Number(window.sessionStorage.getItem('memberId'))
    await this.getDetail()
  },
  watch:{
    isShow: function(newValue, oldValue) {
      console.log(newValue,oldValue)
      console.log(this.isShow,'isShow')
      if(this.isShow){
        this.islogin = true
        this.getUseinfo()
        setTimeout(()=>{
          this.getDetail()
        },300)
      }else{
        this.islogin = false
        setSessionUser({})
        this.isClose = false //退出登录，弹窗关闭
        this.subscribe_info.service_type = 0
        window.sessionStorage.clear()
        this.getDetail()
        this.memberId = null
        window.sessionStorage.setItem('memberId', null)
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isShow() {
      return !!this.user
    },
  },
  mounted(){
      if(this.$route.query.nejmjmedssodata){
        console.log(this.$route.query.nejmjmedssodata);
        let vm =this;
        loginWithoutPwdwithQueryload({data:this.$route.query.nejmjmedssodata}).then((res) => {
          console.log(res);
          if(res){
            vm.setUser(res)
            setSessionUser(res)
            console.log(res,'====用户信息')
            vm.subscribe_info = res.subscribe_info
            vm.subscribe_info.service_type= res.subscribe_info.service_type
            window.sessionStorage.setItem('memberId', res.id)
          }
        }).catch((err)=>{
          console.log(err);
          vm.$nextTick(() => {
              vm.$bus.emit('login')
            })
        })
      }
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    getVideoParam(event){
      console.log(event)
      this.isFree = event
    },
    showContent(e){
      this.usefreeCont = e
    },
    getContent(e){
      console.log(e,'文章content')
      this.detail.content = e
    },
    showVideo() {
      if(!this.isShow){
        this.$message.warning('登录即可观看')
        this.$bus.emit('login')
        return
      }
      console.log(this.subParam.is_have_view_auth,this.subParam.is_month_chance)
      if(this.isShow&&this.subParam.is_month_chance ==1&&this.subParam.is_have_view_auth==2&& this.subParam.is_subscribe==1){
        this.$message.warning('您本月的免费浏览次数已用完，请付费观看！')
        return
      }
      if(this.isShow&&this.subParam.is_month_chance ==2&&this.subParam.is_have_view_auth==2&& this.subParam.is_subscribe==1){
       this.isFree = true
        return
      }
    },
    showVideo2(){
      if(!this.isShow){
        this.$message.warning('登录即可观看')
        this.$bus.emit('login')
        return
      }
      console.log(this.subParam.is_have_view_auth,this.subParam.is_month_chance)
      if(this.subParam.is_have_view_auth == 1 &&this.subParam.is_month_chance == 2 &&this.subParam.is_subscribe==1){
        this.isClose = false
        return
      }
      if(this.subParam.is_have_view_auth == 2 &&this.subParam.is_month_chance == 2 &&this.subParam.is_subscribe==1){
        // this.$message.warning('免费次数已经用完了!') 有免费次数
        this.isFree = true
          return
      }
      if(this.subParam.is_have_view_auth == 2 &&this.subParam.is_month_chance == 1 &&this.subParam.is_subscribe==1){
        this.$message.warning('您本月的免费浏览次数已用完，请付费观看！')
        //无机会
      //  is_subscribe 字段【是否付费文章（1：是；2：否）】 is_have_view_auth 【1：有权限，可以直接看文章详情 2：无权限，没有购买服务，详情内容要隐藏，参
      // is_month_chance 【1：表示无机会；2：表示有机会】 
      // this.$message.warning('免费次数已经用完了!') 有免费次数
        this.isFree = false
          return
      }
    },
    login() {
      // this.$emit('login')
      this.$bus.emit('login')
    },
    openVip(){
      if(!this.isShow){
        this.$message.warning('请先登录')
        this.$bus.emit('login')
        return
      }
      this.$router.push({ path:'/personal', query:{ type: 'flag4' } })
        .catch((err) => {
            console.log('输出报错', err)
        })
    },
    close(e){
      console.log(e,'=====e')
      // this.flag2 = false
      this.isFree = e.a
      this.isClose = e.b
    },
    //关闭弹窗
    closePop(e){
      console.log(e,'使用次数后')
      this.isClose = e
    },
    async getUseinfo(){
        let res = await getMemberInfo()
        console.log(res,'====用户信息')
        this.memberId = res.id
        window.sessionStorage.setItem('memberId', res.id)
        this.subscribe_info.service_type= res.subscribe_info.service_type
        this.subscribe_info = res.subscribe_info || {}
    },
    async getDetail() {
      let loginUser = getSessionUser()
      this.memberId = Number(window.sessionStorage.getItem('memberId'))
      let res = await selectLessonDetail({
        id: this.detail.lessonResId,
        memberId: null != loginUser ? loginUser.id : this.memberId,
      })
      console.log('detail::', res)
      this.detail = res
      console.log('detail::', this.detail)
      this.detail.media = JSON.parse(res.media)
      this.detail.coverPc = JSON.parse(res.coverPc)
      
      this.isFree = res.is_subscribe == 1 ? true:false
      this.noFree = res.is_subscribe == 1 ? true:false
      this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
      this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
      this.subParam.is_month_chance = res.is_month_chance
      // this.flag2 =  res.is_have_view_auth == '1'?true:false
      this.subParam.articleId = res.lessonResId
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="less" scoped>
.cr-img {
  // background-image: url('../../../assets/imgs/cr-bg.jpeg');
  background-repeat: no-repeat;
}
.no_vips{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 215px;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.53) 1%, #FFFFFF 40%, #FFFFFF 100%);
  margin-bottom: -65px;
  opacity: 0.85;
}
.login{
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
.display_none{
  display: none;
}
#box {
  display: flex;
  display: -webkit-flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.item {
  height: 44px;
  line-height: 44px;
  font-size: 32px;
  color: #a1224e;
  text-align: center;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
}
.ct-au {
  display: flex;
  display: -webkit-flex;
  height: 26px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.item-au {
  height: 26px;
  line-height: 26px;
  font-size: 18px;
  padding-right: 12px;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  font-weight: 400;
}
.item-content {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.item-content-txt {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ct-main {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 20px;
}
.ct-block {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}
.item-video {
  //display: flex;
  //display: -webkit-flex;
  //height: 240px;
  //width: 100%;
  //align-items: center;
  //justify-content: center;
  //margin-top: 10px;
  //margin-bottom: 20px;
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.item-video div video {
  width: 90%;
  height: 240px;
}
.item-head-video {
  width: 100%;
  display: flex;
  justify-content: center;
}
.item-head {
  height: 230px;
  padding-top: 10px;
  width: 60%;
}
.item-head-title {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.item-head-title-bq {
  text-align: center;
  width: 10%;
  color: white;
  background-color: #a1124e;
  font-size: 12px;
  border-radius: 2px;
  height: 22px;
  line-height: 22px;
}
.item-head-title-name {
  text-align: left;
  width: 88%;
  margin-left: 2%;
  font-size: 28px;
  color: #a1124e;
}
.item-head-be {
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 28px;
  height: 70px;
}
.item-head-time {
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.item-ct-detail {
  font-size: 22px;
  margin-bottom: 15px;
  margin-top: 40px;
}
.item-ct-detail-txt {
  margin-bottom: 15px;
}
.opt-txt {
  font-size: 14px;
  margin-left: 5px;
}
.opt-block {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  //justify-content: center;
}
.item-head-video-mb {
  width: 100%;
}
.item-head-mb {
  height: 150px;
  padding-top: 10px;
  width: 100%;
}
.item-video-mb {
  height: auto;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}
.mobile {
  #box {
    display: flex;
    display: -webkit-flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  ct-main {
    //display: flex;
    //display: -webkit-flex;
    width: 100%;
    align-items: normal;
    justify-content: normal;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .item {
    height: 44px;
    line-height: 20px;
    font-size: 20px;
    color: #a1224e;
    text-align: center;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
  }
  .ct-au {
    display: flex;
    display: -webkit-flex;
    height: 26px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .item-au {
    height: 26px;
    line-height: 18px;
    font-size: 14px;
    padding-right: 12px;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    font-weight: 400;
  }
  .ct-block {
    width: 100%;
    //margin: 2.5% auto;
  }
  .item-video-mb div video {
    width: 100%;
    height: 240px;
  }
  .item-head-title {
    display: flex;
    display: -webkit-flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .item-head-title-bq {
    text-align: center;
    width: 10%;
    color: white;
    background-color: #a1124e;
    font-size: 10px;
    border-radius: 2px;
    height: 22px;
    line-height: 22px;
    margin-left: 20%;
  }
  .item-head-title-name {
    text-align: left;
    width: 88%;
    margin-left: 2%;
    font-size: 20px;
    color: #a1124e;
  }
  .item-head-be {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 28px;
    height: 10px;
    text-align: center;
  }
  .item-head-time {
    font-size: 14px;
    margin-top: 14px;
    margin-bottom: 14px;
    text-align: center;
  }
  .item-ct-detail {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .item-ct-detail-txt {
    margin-bottom: 15px;
  }
  .opt-txt {
    font-size: 14px;
    margin-left: 5px;
  }
  .opt-block {
    display: flex;
    display: -webkit-flex;
    width: 100%;
    align-items: center;
  }
}
</style>
