<template>
  <div></div>
</template>

<script>
export default {
  name: 'transfer',
  data() {
    return {}
  },
  created() {
    if (this.$route.query.sourceid === '7' || this.$route.query.sourceid === '8') {
      //跳转至动画详情
      this.$router.replace({
        path: '/animation-particulars',
        query: { permalinks: this.$route.query.permalinks, _t: Date.now() },
      })
    } else if (this.$route.query.sourceid === '11') {
      //跳转至课程
    } else {
      //跳转至文章详情
      this.$router.replace({
        path: '/article-info',
        query: { permalinks: this.$route.query.permalinks, _t: Date.now() },
      })
    }
  },
}
</script>

<style lang="less" scoped></style>
