import { PATH_ABOUT } from '@/router/about/paths.js'
import { PATH_NEJM_LIST, PATH_NEJM_CATALYST_LIST, PATH_NEJM_PERIODICAL_LIST } from '@/router/article/paths.js'

export const nejm = {
  title: 'NEJM',
  path: PATH_NEJM_LIST,
  navs: [
    { text: '综述类', path: `${PATH_NEJM_LIST}?type=综述类` },
    { text: '临床病例类', path: `${PATH_NEJM_LIST}?type=临床病例类` },
    { text: '论著类', path: `${PATH_NEJM_LIST}?type=论著类` },
    { text: '评论类', path: `${PATH_NEJM_LIST}?type=评论类` },
    { text: '其他', path: `${PATH_NEJM_LIST}?type=其他` },
  ],
}
export const Catalyst = {
  title: 'Catalyst',
  path: PATH_NEJM_CATALYST_LIST,
  navs: [
    { text: '案例报告', path: `${PATH_NEJM_CATALYST_LIST}?type=案例报告` },
    { text: '论文', path: `${PATH_NEJM_CATALYST_LIST}?type=论文` },
    { text: '深度论文', path: `${PATH_NEJM_CATALYST_LIST}?type=深度论文` },
    { text: '特刊专栏', path: `${PATH_NEJM_CATALYST_LIST}?type=特刊专栏` },
  ],
}
export const nejmQkhc = {
  title: 'NEJM期刊荟萃',
  path: PATH_NEJM_PERIODICAL_LIST,
  navs: [
    { text: '临床指南', path: `${PATH_NEJM_PERIODICAL_LIST}?type=临床指南` },
    { text: '对临床实践的启示', path: `${PATH_NEJM_PERIODICAL_LIST}?type=对临床实践的启示` },
    { text: '临床实践的改变', path: `${PATH_NEJM_PERIODICAL_LIST}?type=临床实践的改变` },
    { text: '新兴领域', path: `${PATH_NEJM_PERIODICAL_LIST}?type=新兴领域` },
    { text: '其他', path: `${PATH_NEJM_PERIODICAL_LIST}?type=其他` },
  ],
}

export const aboutUs = {
  title: '关于我们',
  path: PATH_ABOUT,
  navs: [
    { text: '简介', path: `${PATH_ABOUT}?type=简介` },
    { text: '服务条款', path: `${PATH_ABOUT}?type=服务条款` },
    { text: '隐私政策', path: `${PATH_ABOUT}?type=隐私政策` },
    { text: '联系我们', path: `${PATH_ABOUT}?type=联系我们` },
  ],
}
