import { render, staticRenderFns } from "./UserInfoForm.vue?vue&type=template&id=bec5b0be"
import script from "./UserInfoForm.vue?vue&type=script&lang=js"
export * from "./UserInfoForm.vue?vue&type=script&lang=js"
import style0 from "./UserInfoForm.vue?vue&type=style&index=0&id=bec5b0be&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports