<template>
  <MainCenter :style="$isMobile ? 'padding-left: 15px; padding-right: 15px' : ''">
    <!-- 音频  -->
    <div :style="$isMobile ? 'margin-top: 15px' : 'margin-top: 45px'">
      <img class="logo" src="../../../../assets/imgs/logo/nejm_nejm_logo.png" alt="logo" />
    </div>
    <div class="title">
      <h2 :style="$isMobile ? 'font-size: 20px' : 'font-size: 32px'">{{ data ? data.title : '' }}</h2>
      <div :style="$isMobile ? 'font-size: 16px;margin-top: 15px;' : 'font-size: 20px;margin-top: 15px;'">
        {{ data ? data.outtitle : '' }}
      </div>
      <RichText class="titleContent" :content="data ? data.content : ''" :about="data ? data.aboutcontent : ''" />
      <div :class="$isMobile ? 'titleDate-mb' : 'titleDate'">
        {{ data ? data.classnames + ' · ' + data.postdate : '' }}
      </div>
    </div>
    <div class="main">
      <div :class="$isMobile ? 'main_top_mb' : 'main_top'">
        <Share
          :isShow="{ navigation: false, collect: true, share: true, original: true }"
          :id="data.id"
          collect-type="article"
          :ifCollect="data.ifCollect"
          :outlink="data.outlink"
        ></Share>
      </div>
      <div class="main_content">
        <div class="content_left" :style="$isMobile ? 'width:100%' : ''">
          <!--<div class="left_title">-->
          <!--<p>标题标题标题标题标题标题</p>-->
          <!--<p>副标XXXXXXXXX</p>-->
          <!--</div>-->
          <div class="audio">
            <audio :src="data ? assetsUrl + data.videoUrl : ''" controls>您的浏览器不支持播放音频</audio>
          </div>
          <div class="audio_content">
            <p>内容简介</p>
            <RichText :content="data.content" :about="data.aboutcontent"></RichText>
          </div>
        </div>
        <div v-if="!$isMobile" class="content_right">
          <Correlation :correlationList="correlationList" :correlation="correlation"></Correlation>
        </div>
      </div>
    </div>
  </MainCenter>
</template>

<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import RichText from '@/components/layout/RichText.vue'
import Share from '@/components/share/Share.vue'
import Correlation from '@/components/layout/Correlation.vue'
import { selectArticleList, getArticleDetail, getArticleDetail2 } from '@/api/serach'
// import { selectArticleList, getArticleDetail } from '@/api/serach'
import { format } from '@/utils/time-utils'
import config from 'config'
import { mapMutations } from 'vuex'
import { getSessionUser, setSessionUser } from '@/utils/app-utils'
import { loginWithoutPwd,loginWithoutPwdwithQueryload } from '@/api/login'
export default {
  components: { RichText, MainCenter, Correlation, Share },
  name: 'AudioParticulars',
  data() {
    return {
      data: null,
      assetsUrl: config.assetsUrl,
      correlation: {
        articleIds: [],
        pageNo: 1,
        pageSize: 10,
      },
      correlationList: null,
    }
  },
  async created() {
    this.dealOutLink(this.$route.query.sg)
    if (this.$route.query.prev == 'D1Co7866D71DC3Ao9B3B692DoA2o86B4') {
      await getArticleDetail2({ permalinks: this.$route.query.permalinks }).then((res) => {
        res.classnames = res.classnames.replace(/,/g, '')
        res.postdate = format(res.postdate, 'YYYY-MM-DD')
        this.data = res
      })
      // await getArticleDetail({ permalinks: this.$route.query.permalinks }).then((res) => {
      //   res.classnames = res.classnames.replace(/,/g, '')
      //   res.postdate = format(res.postdate, 'YYYY-MM-DD')
      //   this.data = res
      // })
    } else {
      await getArticleDetail({ permalinks: this.$route.query.permalinks }).then((res) => {
        res.classnames = res.classnames.replace(/,/g, '')
        res.postdate = format(res.postdate, 'YYYY-MM-DD')
        this.data = res
      })
    }

    if (this.data?.relationArticleIds?.length > 0) {
      this.correlation.articleIds = this.data.relationArticleIds
      await selectArticleList(this.correlation).then((res) => {
        if (res) {
          res.results.forEach((element) => {
            element.postdate = format(element.postdate, 'YYYY-MM-DD')
          })
          this.correlationList = res
        }
      })
    }
  },
  mounted(){
      if(this.$route.query.nejmjmedssodata){
        console.log(this.$route.query.nejmjmedssodata);
        let vm =this;
        loginWithoutPwdwithQueryload({data:this.$route.query.nejmjmedssodata}).then((res) => {
          console.log(res);
          if(res){
            vm.setUser(res)
            setSessionUser(res)
            window.sessionStorage.setItem('memberId', res.id)
          }
        }).catch((err)=>{
          console.log(err);
          vm.$nextTick(() => {
              vm.$bus.emit('login')
            })
        })
      }
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    async dealOutLink(sg) {
      let loginUser = getSessionUser()
      if (sg != null && sg == 'AbW1N' && loginUser == null) {
        this.setUser({})
        setSessionUser({})
        await loginWithoutPwd()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.logo {
  width: 280px;
}
.title {
  width: 100%;
  min-height: 148px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d6d6d6;
  margin-top: 38px;
  h2 {
    font-size: 23px;
    font-weight: 700;
    position: relative;
    padding-left: 10px;
  }
  .titleContent {
    width: 100%;
    overflow: hidden; /*必须结合的属性,当内容溢出元素框时发生的事情*/
    text-overflow: ellipsis; /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
    -webkit-line-clamp: 2; /*用来限制在一个块元素显示的文本的行数。*/
    padding: 6px 0;
    background-color: #f2f2f2;
    line-height: 20px;
    font-size: 14px;
    margin-top: 15px;
  }
  .titleDate {
    margin-top: 20px;
    color: #666;
    font-size: 16px;
  }
  .titleDate_mb {
    margin-top: 20px;
    color: #666;
    font-size: 14px;
  }
}
.title h2::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 18px;
  background-color: rgb(255, 0, 0);
}
.main {
  width: 100%;
  overflow: hidden;
}
.main_content {
  width: 100%;
  overflow: hidden;
  .content_left {
    width: 60%;
    float: left;
  }
  .content_right {
    width: 30%;
    float: right;
  }
}
.left_title {
  text-align: center;
  line-height: 20px;
  p {
    &:nth-of-type(1) {
      font-size: 16px;
    }
    &:nth-of-type(2) {
      font-size: 12px;
    }
  }
}
.audio {
  width: 100%;
  height: 60px;
  audio {
    width: 100%;
    height: 60px;
  }
}
.audio_content {
  margin-top: 40px;
  padding-bottom: 40px;
  p {
    margin-bottom: 10px;
    &:nth-of-type(2) {
      line-height: 20px;
      font-size: 14px;
    }
  }
}
.main_top_mb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: 17%;
}
</style>
